import {Injectable} from '@angular/core';
import {Entity} from '@wspsoft/frontend-backend-common';
import {TypeService} from '../../../../api';
import {DatatableColumn} from '../../../index';
import {Converter} from './converter';
import {FieldConverterService} from './field-converter.service';

@Injectable()
export class DatatableColumnConverterService implements Converter<DatatableColumn[]> {

  public constructor(private fieldConverter: FieldConverterService, private typeUtility: TypeService) {
  }

  public set entityMetaLoader(loader: () => Entity) {
    this.fieldConverter.entityMetaLoader = loader;
  }

  public getAsObject(s: string): DatatableColumn[] {
    const result: DatatableColumn[] = [];
    const entityMeta = this.fieldConverter.entityMetaLoader();
    if (s) {
      for (const fieldName of s.split(';')) {
        result.push(this.typeUtility.convertFieldToColumn(entityMeta, '', this.fieldConverter.getAsObject(fieldName)) as DatatableColumn);
      }
    }
    return result;
  }

  public getAsString(value: DatatableColumn[]): string {
    const result: string[] = [];
    if (value) {
      for (const valueElement of value) {
        result.push(this.fieldConverter.getAsString(valueElement.meta));
      }
    }
    return result.join(';');
  }
}

import {MaybePromise} from '../..';
import {DesignerEntity} from './designer.entity';

export enum ButtonType {
  SUBMIT = 'submit', DELETE = 'delete', BACK = 'back', URL = 'url', SCRIPT = 'script', DIALOG = 'dialog',
  REDIRECT_PAGE = 'redirectPage', WIZARD = 'wizard'
}

export enum ButtonPosition {
  FAB = 'fab', MENU = 'menu', ROW_ACTION = 'row action', TRAY = 'tray', TOOLBAR = 'toolbar'
}

export abstract class Button extends DesignerEntity {
  public hotkeys?: string;
  public entityId?: string;
  public parentId?: string;

  public layoutId?: string;
  public afterAction?: AfterButtonAction;
  public afterActionScript?: string;
  public submitEnabled?: boolean;
  public position?: ButtonPosition[];
  public type?: ButtonType;
  public script?: string;
  public url?: string;
  public condition?: string;
  public readonly?: string;
  public skipLeaveMessage?: boolean;
  public children?: Button[];

  public command?: (data: any) => MaybePromise<void>;
}

export enum AfterButtonAction {
  VIEW = 'VIEW', SCRIPT = 'SCRIPT', RELOAD = 'RELOAD', BACK = 'BACK'
}

abstract class PullDetailFileChanges {
  // Map of file names to the number of insertions/deletions in that file
  public Map: number;
}

abstract class PullDetailSummary {
  public changes: number;
  public insertions: number;
  public deletions: number;
}

export abstract class PullResult {
  public files?: string[];
  public insertions?: PullDetailFileChanges;
  public deletions?: PullDetailFileChanges;
  public summary?: PullDetailSummary;
  public created?: string[];
  public deleted?: string[];
}

import {DesignerEntity} from './designer.entity';
import {Layout} from './layout';

export enum GraphLayoutType {
  vertical = 'vertical', horizontal = 'horizontal', spread = 'spread', static = 'static'
}

export enum GraphEditMode {
  PAGE_RIGHT = 'PAGE_RIGHT', DIALOG = 'DIALOG', INLINE = 'INLINE'
}

export abstract class GraphNodeTemplate extends DesignerEntity {
  public entityId?: string;
  public graphId?: string;
  public shape?: string;
  public points?: string;
  public height?: number;
  public width?: number;
  public tooltip?: boolean;
  public labelTemplate?: any;
}

export abstract class LayoutGraph extends Layout {
  public edit: boolean;
  public editMode: GraphEditMode;
  public autoFit: boolean;
  public manualExpand: boolean;
  public menu: boolean;
  public randomizeLayout: boolean;
  public layoutType: GraphLayoutType;
  public getEdgeCommands: string;
  public getCoreCommands: string;
  public getNodeCommands: string;
  public onCollapse: string;
  public onEdgeAdd: string;
  public onEdgeRemove: string;
  public onEdit: string;
  public onExpand: string;
  public onInit: string;
  public onNodeAdd: string;
  public onNodeRemove: string;
  public onRefresh: string;
  public onReset: string;
  public nodeTemplates: GraphNodeTemplate[];
}


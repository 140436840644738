import {Directive, ElementRef, EventEmitter, Host, HostListener, Input, OnInit, Output, SkipSelf} from '@angular/core';

@Directive({
  selector: '[ui-resizable]'
})
export class ResizableDirective implements OnInit {
  @Input()
  public minWidth: number;
  @Input()
  public maxWidth: number;
  @Input()
  public initialWidth: number;
  /**
   * emits the parent percent after resizing
   */
  @Output()
  public onResize: EventEmitter<number> = new EventEmitter();
  public host: HTMLElement;
  public prevWidth: string;
  public startWidth: number = 0;

  public constructor(private elm: ElementRef) {
  }

  private pdisable: boolean;

  @Input()
  public get disable(): boolean {
    return this.pdisable;
  }

  public set disable(value: boolean) {
    this.pdisable = value;
    if (this.disable) {
      this.prevWidth = this.host.style.width;
      this.host.style.width = '';
    } else if (this.host) {
      this.host.style.width = this.prevWidth;
    }
  }

  private get currentWidth(): number {
    return parseFloat(this.host.style.width.replace('vw', ''));
  }

  public ngOnInit(): void {
    this.host = this.elm.nativeElement;
    if (!this.disable) {
      this.host.style.width = `${this.initialWidth ?? this.minWidth}vw`;
    }
  }

  public dragStart(): void {
    this.startWidth = this.host.style.width ? this.currentWidth / 100 * window.visualViewport.width : 0;
  }

  public dragging(diff: number): void {
    const draggedDistance = (this.startWidth + -1 * diff) / window.visualViewport.width * 100;
    const vw = Math.min(Math.max(draggedDistance, this.minWidth), this.maxWidth);
    this.host.style.width = vw + 'vw';
  }

  public dragEnd(): void {
    this.startWidth = 0;
    this.onResize.emit(this.currentWidth);
  }
}

@Directive({
  selector: '[ui-grabber]',
})
export class GrabberDirective {

  public startOffsetX: number = 0;

  public constructor(
    private elm: ElementRef,
    @Host() @SkipSelf() private resizable: ResizableDirective,
  ) {
  }

  @HostListener('mousedown', ['$event'])
  public mousedown: (e: MouseEvent) => void = (e: MouseEvent) => {
    this.startOffsetX = e.clientX;
    document.addEventListener('mousemove', this.boundDragging);
    document.addEventListener('mouseup', this.boundDragEnd);
    this.resizable.dragStart();
  };

  public readonly boundDragging: (e: MouseEvent) => void = (e) => this._dragging(e);

  public readonly boundDragEnd: (e: MouseEvent) => void = (e) => this._dragEnd();

  private _dragging(e: MouseEvent): void {
    const diff = e.clientX - this.startOffsetX;
    this.resizable.dragging(diff);
  }

  private _dragEnd(): void {
    this.startOffsetX = 0;
    document.removeEventListener('mousemove', this.boundDragging);
    document.removeEventListener('mouseup', this.boundDragEnd);
    this.resizable.dragEnd();
  }
}

import {DesignerEntity} from './designer.entity';


export abstract class Scheduler extends DesignerEntity {
  public minute?: string;
  public second?: string;
  public dayOfWeek?: string;
  public hour?: string;
  public month?: string;
  public dayOfMonth?: string;
  public payload?: string;
  public type?: SchedulerType;
  public event?: SchedulerEvent;
  public eventName?: string;
  public changeEntityId?: string;
}

export enum SchedulerType {
  time = 'time', event = 'event'
}

export enum SchedulerEvent {
  event = 'event', change = 'change', reminder = 'reminder'
}

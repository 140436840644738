import {KolibriEntity} from './kolibri-entity';

export interface KolibriTask extends KolibriEntity {
  workflowTaskState?: WorkflowTaskState;
  shTitle?: string;
  description?: string;
  shortDescription?: string;
  taskDone?: boolean;
  workflowInstanceId?: string;
}

export enum WorkflowTaskState {
  created = 'created',
  completed = 'completed',
  deleted = 'deleted'
}

import {Injectable} from '@angular/core';
import {AbstractButton} from '../util/abstract-button';

@Injectable()
export class ButtonService {

  public buttons: AbstractButton[] = [];

  public enableButtons(): void {
    for (const button of this.buttons) {
      button.progress = false;
    }
  }
}

<ng-container *ngIf="preview || disable">
  <div class="one-editor__content-wrapper">
    <div *ngIf="value" [innerHTML]="value | safe: 'html'" class="one-editor__content"></div>
    <ui-input-text *ngIf="!value" [disable]="true" class="one-editor__content"></ui-input-text>
    <ui-button (click)="togglePreview()" *ngIf="preview" class="one-editor__content-button" icon="fa fa-fw fa-edit" label="{{'Editor.Edit' |
    translate}}" type="icon-only"></ui-button>
  </div>
</ng-container>

<ng-container *ngIf="!preview && !disable">
  <ui-editor #inputElement="ngModel" #nativeInput [(ngModel)]="value" [compact]="compact" [disable]="disable" [helpMessage]="helpMessage" [label]="label"
             [mentionOptions]="mentionOptions" [required]="required" [updateWhileTyping]="updateWhileTyping"
             styleClass="one-editor--layout {{!compact ? 'one-editor--min-height' : 'one-editor--max-height'}}">
    <ng-template #buttons>
      <button (click)="togglePreview()" class="ql-format-button fab fa-fw fa-html5" title="Preview"></button>
    </ng-template>
  </ui-editor>
</ng-container>

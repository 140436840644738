import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {Steps} from 'primeng/steps';

@Component({
  selector: 'ui-steps',
  templateUrl: './steps.component.html',
  styleUrls: ['./steps.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StepsComponent extends Steps {
  @Input()
  public compactMode: boolean = false;
}

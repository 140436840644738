import {TemplateEntryJson} from './json/template';
import {KolibriEntity} from './kolibri-entity';
import {ShareableEntity} from './shareable-entity';

export abstract class Template extends ShareableEntity {
  public payload: TemplatePayload;
  public filterValue?: string;
}

export type TemplatePayload<T extends KolibriEntity = KolibriEntity> = Partial<Record<keyof T, TemplateEntryJson>> & { version?: string };


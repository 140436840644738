<ui-input-wrapper [(editMode)]="editMode" [customValidationMessage]="customValidationMessage" [dirty]="inputElement.dirty" [disable]="disable"
                  [errors]="inputElement.errors" [filled]="nativeInput.filled" [hasFocus]="hasFocus" [helpMessage]="helpMessage" [label]="label"
                  [linkify]="linkify" [name]="name" [required]="required" [touched]="inputElement.touched" [valid]="inputElement.valid">
  <ui-primeng-custom-autocomplete #inputElement="ngModel" #nativeInput (completeMethod)="onComplete($event)" (onBlur)="focusChanged($event)"
                                  (onFocus)="focusChanged($event)" (onForceClear)="clear();" (onSelect)="onSelect.emit($event)"
                                  (onUnselect)="onUnselect.emit($event)" [(ngModel)]="targetValue" [disabled]="disable" [dropdown]="dropdown"
                                  [fields]="displayFields" [forceSelection]="false" [inputStyle]="styleData" [multiple]="multiple" [name]="name"
                                  [ngModelOptions]="{updateOn: 'change'}" [required]="required" [size]="size" [suggestions]="suggestions" appendTo="body"
                                  emptyMessage="{{'AutoComplete.NoResults' | translate}}" field="representativeString">
    <ng-template let-entity pTemplate="selectedItem">
      <div (click)="openRecord(entity, $event)">
        <ng-container *ngTemplateOutlet="itemTemplate; context: {$implicit: entity}"></ng-container>
      </div>
    </ng-template>
    <ng-template let-entity pTemplate="item">
      <ng-container *ngTemplateOutlet="itemTemplate; context: {$implicit: entity}"></ng-container>
    </ng-template>
  </ui-primeng-custom-autocomplete>
</ui-input-wrapper>

<ng-template #itemTemplate let-entity>
  <ui-field-translator [data]="entity" [showIcon]="false" field="representativeString" typeName="KolibriEntity"></ui-field-translator>
  <ng-container *ngIf="displayFields">
    <ui-field-translator *ngFor="let field of displayFields" [data]="entity" [field]="field"></ui-field-translator>
  </ng-container>
</ng-template>

import {DesignerEntity} from './designer.entity';
import {Wizard} from './wizard';
import {WizardSection} from './wizard.section';

/**
 * Connection between configured fields and sections
 */
export abstract class WizardToWizardSection extends DesignerEntity {
  public wizardSection?: WizardSection;
  public wizardSectionId?: string;
  public wizard?: Wizard;
  public wizardId?: string;
}

import {KolibriEntity} from '../model/database/kolibri-entity';
import {User} from '../model/database/user';

import {AbstractEntityServiceFactory} from '../service/generated/abstract-entity-service-factory';
import {EntityServiceOptions} from '../service/generated/service-options';
import {CriteriaFunction} from './criteria-function';
import {CriteriaOperator} from './criteria-operator';

import {CriteriaOrder} from './criteria-order';
import {CriteriaQuery} from './criteria-query';

import {CriteriaSearchOperatorOnly,} from './criteria-search-operator';
import {CriteriaTenantMode} from './criteria-tenant-mode';
import {CriteriaType} from './criteria-type';
import {CriteriaQueryJson} from './json/criteria-query-json';

export abstract class AbstractCriteriaFactory {
  public operator: typeof CriteriaOperator = CriteriaOperator;
  public searchOperator: typeof CriteriaSearchOperatorOnly = CriteriaSearchOperatorOnly;
  public order: typeof CriteriaOrder = CriteriaOrder;
  public function: typeof CriteriaFunction = CriteriaFunction;
  public type: typeof CriteriaType = CriteriaType;
  public tenantMode: typeof CriteriaTenantMode = CriteriaTenantMode;

  protected constructor(protected entityServiceFactory: AbstractEntityServiceFactory) {
  }

  public get<E extends KolibriEntity>(entity: string, type: CriteriaType = CriteriaType.SELECT, user?: User,
                                      options?: EntityServiceOptions): CriteriaQuery<E> {
    const service = this.entityServiceFactory.getService<E>(entity, user, options);
    return new CriteriaQuery(service, entity, type);
  }

  public getFromJson<E extends KolibriEntity>(json: CriteriaQueryJson, entity: string, user?: User,
                                              type: CriteriaType = CriteriaType.SELECT): CriteriaQuery<E> {
    const query = this.get<E>(entity, type, user);
    if (json) {
      query.fromJson(json);
    }
    return query;
  }
}

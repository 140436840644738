import {Button} from './button';
import {DesignerEntity} from './designer.entity';
import {LayoutForm} from './layout-form';
import {LayoutList} from './layout-list';

/**
 * connection between related lists and columns
 */
export abstract class LayoutToButton extends DesignerEntity {
  public layout?: LayoutList | LayoutForm;
  public layoutId?: string;
  public button?: Button;
  public buttonId?: string;
}

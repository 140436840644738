import {User} from '../../../model/database/user';
import {LazyLoaderHandler} from './lazy-loader-handler';

export abstract class UserHandler extends LazyLoaderHandler<User> {
  public static can(permissions: { [p: string]: boolean }, operation: string | string[], entity: string): boolean | boolean[] {
    if (permissions.admin || !operation) {
      return operation && Array.isArray(operation) ? Array(operation.length).fill(true) : true;
    }

    if (typeof operation === 'string' && permissions[operation]) {
      return true;
    }

    if (Array.isArray(operation)) {
      return operation.map(o => UserHandler.can(permissions, o, entity) as boolean);
    }

    return !!(permissions[entity] && permissions[entity][operation]);
  }

  public hasRole(this: User, role: string): boolean {
    if (!role || this.permissions.admin) {
      return true;
    }
    return !!(this.roles && this.roles[role]);
  }

  public can(this: User, operation: string | string[], entity: string): boolean | boolean[] {
    // admin has always access
    const permissions = this.permissions;
    return UserHandler.can(permissions, operation, entity);
  };

  public hasGroup(this: User, group: string): boolean {
    if (this.permissions.admin) {
      return true;
    }

    return !!(this.groups && this.groups[group]?.active);
  };

  public hasTenant(this: User, tenant: string): boolean {
    if (!tenant) {
      return true;
    }
    // the user is assigned directly to the tenant itself
    if (this.tenants && this.tenants[tenant]?.active) {
      return true;
    }

    // check if tenant is child of any assigned tenants
    for (const tenantData of Object.values(this.tenants || {})) {
      const child = tenantData.children.find(childData => childData.id === tenant || childData.name === tenant);
      if (child) {
        return child.active;
      }
    }

    return false;
  };
}

for (const i of ['groups',
  'roles',
  'tenants',
  'permissions',
  'language',
  'timezone',
  'token',
  'impersonatorId',
  'rootTenantMode',
  'hasToAuthenticate',
  'loginMethod',
  'loginName']) {
  Object.defineProperty(UserHandler.prototype, i, {
    get() {
      return this.record[i];
    },
    set(v) {
      this.record[i] = v;
    },
    enumerable: true
  });
}

import {LRUCache} from 'typescript-lru-cache';
import {_, AbstractModelService, MaybePromise, Script, User} from '..';
import {KolibriEntity} from '../model/database/kolibri-entity';
import {AbstractEntityEnhancer} from '../service/util/abstract-entity-enhancer';
import {AbstractSessionService} from '../service/util/abstract-session.service';
import {AbstractKolibriScriptLibrary} from './abstract-kolibri-script.library';
import {ExportScope, FunctionParser, KolibriScriptError, ScriptCallable, ScriptParams, ScriptResult} from './script-objects';

const isNode = typeof window === 'undefined';
const useDetailedInfo = isNode || (window as any).debug;

export abstract class AbstractKolibriScriptContext {
  public conzole: Console = console;
  public exports: ExportScope = {global: {}, local: {}};
  protected entityEnhancer: AbstractEntityEnhancer<KolibriEntity>;
  protected functionParser: FunctionParser = new FunctionParser(this);
  private compilerCache: LRUCache<symbol, <T>(kc: ScriptParams) => MaybePromise<T>> = new LRUCache({maxSize: 1024});
  private sharedParams: ScriptParams;

  protected constructor(public scriptLibrary: AbstractKolibriScriptLibrary, protected sessionService: AbstractSessionService,
                        protected modelService: AbstractModelService) {
    if (!useDetailedInfo) {
      this.logScriptError = _.debounce(this.logScriptError, 2000, true);
    }
  }

  public get kc(): ScriptParams {
    return this.sharedParams;
  }

  private get user(): User {
    return this.sessionService.currentUser;
  }

  public async init(globalScript?: Script): Promise<boolean> {
    if (globalScript) {
      return this.evalGlobalScript(globalScript);
    }

    this.exports.local = {};
    this.exports.global = {};
    this.functionParser = new FunctionParser(this);
    this.compilerCache = new LRUCache({maxSize: 1024});
    let error = false;
    for (const script of _.sortBy(this.modelService.getGlobalScripts(), 'callable')) {
      if (!(await this.evalGlobalScript(script))) {
        error = true;
      }
    }
    return error;
  }

  public runScript<T>(script: string, params: ScriptParams = {}, formId?: string, rootCause?: string, allowErrors?: boolean): ScriptResult<T> {
    // define getter and setter for everything and remember observable
    const usedFields = {};

    // no valid script means no result
    if (!script) {
      return {
        usedFields,
        result: undefined
      };
    }

    if (formId) {
      const formData = this.sessionService.viewData[formId] || {};
      if (!(formId in this.exports.local)) {
        this.exports.local[formId] = formData.localScriptStorage || {};
      }
      params.local = this.exports.local[formId];

      if (this.entityEnhancer && params.record) {
        params.record = this.entityEnhancer.thirdLevelEnhancing(formId, params.record, usedFields, this.sessionService);
      }
    }

    // Inject current user as global param
    params.user = params.user || this.user;

    // @ts-ignore, just for internal use, not documented for app developers
    params.errorLogger = this.logScriptError.bind(this);

    // Set params to be shared by all scopes
    this.sharedParams = params;

    return {
      usedFields,
      result: this.getCompiledScript(script, params, rootCause, allowErrors)(params)
    };
  }

  /**
   * when the function parser has stopped make sure to create a new one and log the root cause
   */
  private maybeRebootFunctionParser<T>(result: IteratorYieldResult<(kc: ScriptParams) => MaybePromise<T>>, script: string, rootCause: string): boolean {
    if (result.done) {
      console.error('Function parser has been reinitialized during the compilation of the following code', script, rootCause || this);
      this.functionParser = new FunctionParser(this);
      return true;
    }
    return false;
  }

  private getCompiledScript(script: string, params: ScriptParams, rootCause: string, allowErrors: boolean): <T>(kc: ScriptParams) => MaybePromise<T> {
    // Get symbol for current script and params combination
    const hash = Symbol.for(script + Object.keys(params));

    // Compile and cache current script if not already done
    if (!this.compilerCache.has(hash)) {
      const result = this.functionParser.evaluate(this.getEvalScript(script, params, rootCause, allowErrors));
      if (this.maybeRebootFunctionParser(result, script, rootCause)) {
        return () => undefined;
      }
      this.compilerCache.set(hash, result.value);
    }
    return this.compilerCache.get(hash);
  }

  private getEvalScript(script: string, params: ScriptParams, rootCause: string = 'KolibriScript', allowErrors: boolean = false): string {
    return `(${script.includes('await') ? 'async ' : ''}(kc) => {

  const {${Object.keys(params)}} = kc;

  try {

${script}

  } catch (e) {
    if (e.type === '${KolibriScriptError.type}') {
      throw e;
    }
    const errorMsg = 'Critical error occurred when executing the script';
    errorLogger(errorMsg, \`${rootCause})\`, e);
    console.error(errorMsg, \`${rootCause}\`);
    console.error(e.message, e.stack, \`${rootCause}\`);
    if (${allowErrors}) {
      throw e;
    }
  }

})`;
  }

  private logScriptError(message: string, rootCause: string, e: any): void {
    if (useDetailedInfo) {
      this.scriptLibrary.message.addLog(`${isNode ? '' : `${message} `}${rootCause}: ${e.message}`, 'error', 'tray');
    } else {
      this.scriptLibrary.message.addLog(this.scriptLibrary.translate.instant('Error.ScriptMessage'), 'error', 'tray');
    }
  }

  /**
   * evaluate a single global script depending on callable
   */
  private async evalGlobalScript(script: Script): Promise<boolean> {
    try {
      if (script.callable === ScriptCallable.CLASS) {
        this.maybeRebootFunctionParser(this.functionParser.evaluate(script.payload), script.payload, `GlobalScript:${script.name}:payload`);
      } else {
        await this.runScript(script.payload, undefined, `GlobalScript:${script.name}:payload`).result;
      }
      return true;
    } catch (e: any) {
      console.error(`Critical error while parsing GlobalScript ${script.name}!`, e.stack);
      return false;
    }
  }
}

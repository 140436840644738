import {KolibriEntity, PrefQuery, User} from '../..';

export interface AbstractUserService {
  getMe(): Promise<User>;

  /**
   * execute a global search restricted to the current user
   */
  globalSearch(query: string, limit: number, offset: number, dateRange?: string[], entityClasses?: string[]): Promise<KolibriEntity[]>;

  saveUserPassword(password: string): Promise<any>;

  /**
   * Returns the prefQueries of the current user which can be given a prefQuery name to narrow the searches
   * @param scope Id to get the prefQueries of
   * @param prefQueryName name to add as search condition
   * @returns Array of found pref query
   */
  getPrefQueries(scope: string, prefQueryName?: string): Promise<PrefQuery[]>;

  /**
   * Finds a PrefQuery of the current user with a given id
   * @param id of the PrefQuery to find
   * @returns PrefQuery
   */
  getPrefQuery(id: string): Promise<PrefQuery>;
}

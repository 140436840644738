abstract class Branch {
  public name: string;
  public tracking: string;
}

abstract class Tag {
  public name: string;
  public tracking: string;
}

export abstract class FetchResult {
  public raw: string;
  public remote: string | null;
  public branches: Branch[];
  public tags: Tag[];
}

import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {ConfirmDialog} from 'primeng/confirmdialog';
import {OneConfirmService} from '../../../service/one-confirm.service';

@Component({
  selector: 'ui-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmDialogComponent {
  @Input()
  public header: string;
  @Input()
  public message: string;
  @Input()
  public key: string;

  public constructor(private oneConfirmService: OneConfirmService) {
  }

  public accept(cd: ConfirmDialog, cb: () => void): void {
    this.saveCallback(cd, cb);
    if (cd.confirmation.acceptEvent) {
      cd.confirmation.acceptEvent.emit();
    }
  }

  public reject(cd: ConfirmDialog, cb: () => void): void {
    this.saveCallback(cd, cb);
    if (cd.confirmation.rejectEvent) {
      cd.confirmation.rejectEvent.emit();
    }
  }

  private saveCallback(cd: ConfirmDialog, cb: () => void): void {
    this.oneConfirmService.confirmCallback = () => {
      cd.hide();
      cd.confirmation = null;
      cb();
    };
  }
}

import * as moment from 'moment';

import {CriteriaOperator} from './criteria-operator';
import {CriteriaQueryGroup} from './criteria-query-group';

export abstract class PrefQueryConverter {
  public static convertPrefQuery(criteriaQuery: CriteriaQueryGroup<any>, query: any, parentOr: boolean = query.condition !== 'AND'): void {
    // maiks special pref query stuff
    if (query.i18n) {
      return this.convertPrefQuery(criteriaQuery, query.rule);
    }

    // this means we have a pref query group
    if (query.condition) {
      const childrenOr = query.condition !== 'AND';
      const group = criteriaQuery.addGroup(parentOr);
      for (const rule of query.rules) {
        this.convertPrefQuery(group, rule, childrenOr);
      }
    } else {
      // simple rule
      criteriaQuery.addCondition(PrefQueryConverter.removeSuffix(query.field),
        this.convertPrefQueryOperator(query.operator), this.convertPrefQueryValue(query.value,
          query.javaType), parentOr, undefined, undefined, undefined, query.scripted);
    }
  }

  public static removeSuffix(value: string): string {
    return value.replace('Internal', '');
  }

  private static convertPrefQueryOperator(operator: string): CriteriaOperator {
    return CriteriaOperator[operator.toUpperCase()];
  }

  private static convertPrefQueryValue(value: string, type: string): any {
    if (value) {
      switch (type) {
        case 'java.util.Date':
          return moment(value, 'DD.MM.YYYY').toISOString();

        case 'java.lang.Boolean':
          return value === 'true';
        default:
          return value;
      }
    }
  }
}

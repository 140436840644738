import {User} from '../../index';

export class AbstractSessionService {
  public static readonly DEFAULT_FORM_ID: string = 'main';
  public static readonly DEFAULT_FORM_VIEW: string = 'Main';
  public viewData: any = {};
  public debug: boolean;

  public pcurrentUser: User;

  public get currentUser(): User {
    return this.pcurrentUser;
  }

  public set currentUser(value: User) {
    this.pcurrentUser = value;
  }
}

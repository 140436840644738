import {Directive, ElementRef, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import {_} from '@wspsoft/frontend-backend-common';


@Directive({
  selector: '[ui-grid-resize]'
})
export class GridResizeDirective implements OnInit {
  @Input()
  public x: number;
  @Input()
  public y: number;
  @Input()
  public maxGridCols: number;
  @Input()
  public gridCols: number;
  @Input()
  public gridRows: number;
  @Input()
  public previewElementHandle: string;
  @Input()
  public gridContainerHandle: string;
  @Input()
  public boundingBox: HTMLElement;
  @Input()
  public minRows: number = 1;
  @Input()
  public minCols: number = 1;
  @Output()
  public onResizeStart: EventEmitter<void> = new EventEmitter();
  @Output()
  public onResize: EventEmitter<{ rows: number; cols: number }> = new EventEmitter();
  @Output()
  public onResizeEnd: EventEmitter<{ rows: number; cols: number }> = new EventEmitter();

  public constructor(private elm: ElementRef) {
  }

  public get maxCols(): number {
    // subtract 1 from the max grid cols due match the 0 index
    return this.gridCols - (this.x + this.gridCols - this.maxGridCols);
  }

  public get previewElement(): HTMLElement {
    return this.boundingBox.querySelector(this.previewElementHandle);
  }

  public get gridContainer(): HTMLElement {
    return this.boundingBox.closest(this.gridContainerHandle);
  }

  public setOpacity(opacity: number): void {
    for (const child of this.boundingBox.children as any) {
      child.style.opacity = opacity.toString();
    }
  }

  /**
   * setup the draggable element logic
   */
  @HostListener('dragstart', ['$event'])
  public initDrag(e: DragEvent): void {
    e.stopPropagation();
    e.dataTransfer.effectAllowed = 'move';
    this.setOpacity(0);

    // calculate the coordinates of the item
    const startX = e.clientX;
    const startY = e.clientY;
    const {startWidth, startHeight, rowGap, columnGap} = this.getGridDimensions();
    let currentWidth = startWidth;
    let currentHeight = startHeight;

    // create a clone that we actually resize
    const overlayElement = this.createPreviewOverlay(currentWidth, currentHeight);
    this.boundingBox.append(overlayElement);

    const allowDropEverywhere = (e1): void => e1.preventDefault();

    const doDrag: (e1: DragEvent) => void = _.throttle(e1 => {
      const {widthPerCol, heightPerRow} = this.getGridDimensions();

      const origDimensions = {
        cols: this.gridCols,
        rows: this.gridRows
      };

      currentHeight = this.resizeRows(currentHeight, heightPerRow, rowGap, startHeight, e1, startY, overlayElement);
      currentWidth = this.resizeCols(currentWidth, widthPerCol, columnGap, startWidth, e1, startX, overlayElement);

      // emit only if the dimensions have changed
      if (origDimensions.cols !== this.gridCols || origDimensions.rows !== this.gridRows) {
        this.onResize.emit({
          cols: this.gridCols,
          rows: this.gridRows
        });
      }
    }, 10);

    // remove all listeners on complete
    const stopDrag: (e1: DragEvent) => void = e1 => {
      overlayElement.remove();
      e1.stopPropagation();
      this.setOpacity(1);
      this.boundingBox.removeEventListener('drag', doDrag, false);
      this.boundingBox.removeEventListener('dragend', stopDrag, false);
      document.removeEventListener('dragover', allowDropEverywhere, false);
      this.onResizeEnd.emit({rows: this.gridRows, cols: this.gridCols});
    };

    // add drag listeners
    this.boundingBox.addEventListener('drag', doDrag, false);
    this.boundingBox.addEventListener('dragend', stopDrag, false);
    document.addEventListener('dragover', allowDropEverywhere, false);
    this.onResizeStart.emit();
  }

  public ngOnInit(): void {
    (this.elm.nativeElement as HTMLElement).draggable = true;
  }

  /**
   * resize the grid to the new width
   */
  private resizeCols(currentWidth: number, widthPerCol: number, columnGap: number, startWidth: number, e1: DragEvent, startX: number,
                     overlayElement: HTMLElement): number {
    currentWidth = Math.max((widthPerCol + columnGap) * (this.minCols ?? 1) - columnGap, startWidth + e1.clientX - startX);
    // grow element if dragging beyond the right gap
    if (currentWidth - (widthPerCol + columnGap) * this.gridCols > 0) {
      this.gridCols = Math.max(this.minCols, this.gridCols + 1);
    }

    // shrink element if dragging left of the right gap
    if (currentWidth - (widthPerCol + columnGap) * (this.gridCols - 1) < 0) {
      this.gridCols = Math.max(this.minCols, this.gridCols - 1);
    }

    // limit to max grid cols for the whole grid, minus one for the 0 based index
    if (this.maxGridCols && this.gridCols + this.x > this.maxGridCols) {
      this.gridCols = this.maxCols;
      currentWidth = Math.min(currentWidth, (widthPerCol + columnGap) * this.gridCols);
    } else {
      overlayElement.style.width = `${currentWidth}px`;
    }
    return currentWidth;
  }

  /**
   * resize the element to the new height
   */
  private resizeRows(currentHeight: number, heightPerRow: number, rowGap: number, startHeight: number, e1: DragEvent, startY: number,
                     overlayElement: HTMLElement): number {
    // set rows and cols depending on the width and height ratio
    currentHeight = Math.max((heightPerRow + rowGap) * (this.minRows ?? 1) - rowGap, startHeight + e1.clientY - startY);
    // grow element if dragging beyond the lower gap
    if (currentHeight - (heightPerRow + rowGap) * this.gridRows > 0) {
      this.gridRows = Math.max(this.minRows, this.gridRows + 1);
    }

    // shrink element if dragging above the lower gap
    if (currentHeight - (heightPerRow + rowGap) * (this.gridRows - 1) < 0) {
      this.gridRows = Math.max(this.minRows, this.gridRows - 1);
    }
    overlayElement.style.height = `${currentHeight}px`;
    return currentHeight;
  }

  /**
   * calculate the dimensions of the grid
   *
   * including the gaps between the cells
   * including the width and row sizes
   */
  private getGridDimensions(): { startWidth: number; startHeight: number; widthPerCol: number; heightPerRow: number; rowGap: number; columnGap: number } {
    // get dimensions of the item
    const computedStyleBox = window.getComputedStyle(this.boundingBox);
    const computedStyleGrid = window.getComputedStyle(this.gridContainer);

    const rowGap = parseInt(computedStyleGrid.rowGap, 10);
    const columnGap = parseInt(computedStyleGrid.columnGap, 10);
    // calculate the width and height of the grid per row and column
    const startWidth = parseInt(computedStyleBox.width, 10);
    const startHeight = parseInt(computedStyleBox.height, 10);
    // calculate the width and height of the grid but remove the gaps when spanning more than one
    const widthPerCol = (startWidth - columnGap * (this.gridCols - 1)) / this.gridCols;
    const heightPerRow = (startHeight - rowGap * (this.gridRows - 1)) / this.gridRows;
    return {startWidth, startHeight, widthPerCol, heightPerRow, rowGap, columnGap};
  }

  /**
   * create the overlay element that will be used to see resize of the grid
   */
  private createPreviewOverlay(currentWidth: number, currentHeight: number): HTMLElement {
    const overlayElement = this.previewElement.cloneNode(true) as HTMLElement;
    overlayElement.style.position = 'absolute';
    overlayElement.style.top = '0';
    overlayElement.style.left = '0';
    overlayElement.style.zIndex = '5';
    overlayElement.style.background = '#ffffff';
    overlayElement.style.width = `${currentWidth}px`;
    overlayElement.style.height = `${currentHeight}px`;
    overlayElement.style.pointerEvents = 'none';
    return overlayElement;
  }
}

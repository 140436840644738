import {ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input} from '@angular/core';
import {NG_VALIDATORS, NG_VALUE_ACCESSOR} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {HotkeyService} from '../../../service/hotkey.service';
import {CustomInput} from '../custom-input';
import {I18nInput} from '../i18n-input';

@Component({
  selector: 'ui-input-text',
  templateUrl: './input-text.component.html',
  styleUrls: ['./input-text.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => InputTextComponent),
    multi: true
  }, {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => InputTextComponent),
    multi: true,
  }, {
    provide: CustomInput,
    useExisting: forwardRef(() => InputTextComponent),
    multi: true
  }],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputTextComponent extends I18nInput<any> {
  @Input()
  public type: string = 'text';
  @Input()
  public keyInput: boolean;
  @Input()
  public maxLength: number = 255;
  @Input()
  public symbol: string;
  @Input()
  public autocapitalize: string = 'on';

  public constructor(translateService: TranslateService, cdr: ChangeDetectorRef, private hotkeyService: HotkeyService) {
    super(translateService, cdr);
  }

  public get value(): any {
    if (this.keyInput && this.rawValue) {
      return (this.rawValue as string).split('+').map(key => this.hotkeyService.translateHotkey(key)).join('+');
    }

    return super.value;
  }

  public set value(value: any) {
    super.value = value;
  }

  public keydown(event: KeyboardEvent): void {
    if (!this.keyInput) {
      return;
    }
    // normal hotkey logic
    const key = HotkeyService.convertHotkeyForOs(event.key);
    if (!this.rawValue?.includes(key)) {
      this.value = !!this.rawValue ? this.rawValue + `+${key}` : key;
    }
    event.preventDefault();
    event.stopPropagation();
  }
}

import {DesignerEntity} from './designer.entity';


export abstract class Type extends DesignerEntity {
  public type?: string;
  public defaultValue?: string;
  public parseScript?: string;
  public stringifyScript?: string;
  public checkScript?: string;
  public sqlOnly?: boolean;
}

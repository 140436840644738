import {Injectable} from '@angular/core';
import {
  _,
  AbstractRuntimeModelService,
  Attribute,
  CriteriaFunction,
  CriteriaOperator,
  CriteriaOrder,
  CriteriaQuery,
  CriteriaQueryGroup,
  DateType,
  Entity,
  EntityModel,
  Field,
  FilterType,
  KolibriEntity,
  ListColumn,
  Relation,
  Utility,
  WidgetGranularity
} from '@wspsoft/frontend-backend-common';
import * as moment from 'moment';
import {FilterMetadata, SortMeta} from 'primeng/api';

import {DatatableColumn} from '../../../../ui';

import {ModelService} from '../coded/model.service';

import {ModelTranslationService} from './model-translation.service';

@Injectable()
export class TypeService {

  public constructor(private modelService: ModelService, public modelTranslationService: ModelTranslationService) {
  }

  /**
   * Sets the groupBy, criteriaFunction and optional option to the function on the query
   * This is necessary to utilize the arango date functions
   */
  private static convertWidgetGranularity(granularity: WidgetGranularity): CriteriaFunction {
    switch (granularity) {
      case WidgetGranularity.Hour:
        return CriteriaFunction.DATE_HOUR;
      case WidgetGranularity.DayOfMonth:
        return CriteriaFunction.DATE_DAY;
      case WidgetGranularity.Weekday:
        return CriteriaFunction.DATE_DAYOFWEEK;
      case WidgetGranularity.WeekOfYear:
        return CriteriaFunction.DATE_ISOWEEK;
      case WidgetGranularity.Month:
        return CriteriaFunction.DATE_MONTH;
      case WidgetGranularity.Year:
        return CriteriaFunction.DATE_YEAR;
      case WidgetGranularity.Date:
        return CriteriaFunction.DATE_TRUNC;
      default:
        return CriteriaFunction.NOP;
    }
  }

  public convertFieldToColumn(entity: Entity, column: string, field: Field, useDefault?: boolean): DatatableColumn;
  public convertFieldToColumn(entity: Entity, column: string, field: Field[], useDefault?: boolean): DatatableColumn[];
  public convertFieldToColumn(entity: Entity, column: string = '', field: Field | Field[], useDefault: boolean = true):
    DatatableColumn | DatatableColumn[] {
    if (Array.isArray(field)) {
      return field.map(x =>
        // noinspection ES6MissingAwait, await is later in await .all
        this.convertFieldToColumn(entity, x.name, x, useDefault)
      );
    }

    // if default is requested, search for the default column
    if (useDefault) {
      const defaultListColumn = this.getDefaultListColumns(entity, field);
      if (defaultListColumn) {
        return this.getColumnByName(entity, defaultListColumn);
      }
    }

    field.label = this.modelTranslationService.translateField(entity, field);
    this.modelTranslationService.translateDuplicate(field, entity);
    return {
      field: column,
      header: field.label,
      typeName: this.modelService.getTypeName(field),
      type: this.modelService.getFieldType(field),
      meta: field,
      filterable: true,
      sortable: true,
      filterType: this.getFilterType(field),
      transformationEntity: this.modelService.getDisplayTransformation(field.displayTransformId) ?? {}
    };
  }

  public getColumnByName(entityMeta: Entity, column: ListColumn): DatatableColumn {
    const fieldResponse = this.modelService.getFields(entityMeta.id, column.name);

    if (!fieldResponse.fields.length) {
      console.error(`Column ${column.name} does not exist on entity ${entityMeta.name}`);
    }

    const field = _.find(fieldResponse.fields, {entityId: entityMeta.id}) || fieldResponse.fields[0];
    const dtCol = this.convertFieldToColumn(fieldResponse.entity, column.name, field, false) as DatatableColumn;
    dtCol.defaultFilterOperator = column.defaultFilterOperator;
    dtCol.filterType = column.filterType;
    dtCol.filterable = column.filterable;
    dtCol.editable = column.editable;
    dtCol.sortable = column.sortable;
    dtCol.aggregation = column.aggregation;
    dtCol.renderType = column.renderType;
    dtCol.transformScript = column.transformScript;
    dtCol.transformationEntity = this.modelService.getDisplayTransformation(column.displayTransformId || field?.displayTransformId) ?? {};
    dtCol.sliderMinValue = column.sliderMinValue;
    dtCol.sliderMaxValue = column.sliderMaxValue;
    return dtCol;
  }

  public addOrder(sortMeta: SortMeta, field: Field, query: CriteriaQuery<KolibriEntity>): void {
    const result = this.getQueryRelevantField(sortMeta.field, field);
    query.addOrder(result, sortMeta.order === 1 ? CriteriaOrder.ASC : CriteriaOrder.DESC);
  }

  public addDrilldownFilter(group: CriteriaQueryGroup<KolibriEntity>, column: string, field: Field, granularity: WidgetGranularity, query: string,
                            localized: boolean = true, or: boolean = false): void {
    if (query === null) {
      group.addCondition(column, CriteriaOperator.IS_NULL, undefined, or);
      return;
    }
    const result = column;
    switch (this.modelService.getTypeName(field)) {
      case AbstractRuntimeModelService.CHOICE:
        if (localized) {
          const values = [];
          // grab all possible choices  and find the one with the correct name for the field
          const choice = this.modelService.getChoice((field as Attribute).typeId);
          for (const value of choice.values) {
            const translation = this.modelTranslationService.translateChoice(choice.id, value.value);
            if (Utility.matches(translation, query)) {
              // this value matches our search pattern, add its internal name for the in filter
              values.push(value.value);
            }
          }

          group.addCondition(result, CriteriaOperator.IN, values, or);

          return;
        }
        group.addCondition(result, CriteriaOperator.EQUAL, query, or);
        break;
      case AbstractRuntimeModelService.KOLIBRI_ENTITY:
        group.addCondition(result, CriteriaOperator.IS, query, or);
        break;
      case 'Date':
        const isDateGroupBy = this.modelService.getTypeName(field) === 'Date';
        const granularityFunction = TypeService.convertWidgetGranularity(isDateGroupBy ? granularity : undefined);
        group.addCondition(result, CriteriaOperator.EQUAL, query, or, undefined, granularityFunction,
          {granularity, utcOffset: moment().utcOffset() / 60});
        break;
      default:
        group.addCondition(result, CriteriaOperator.EQUAL, query, or);
        break;
    }
  }

  public addFilter(group: CriteriaQueryGroup<KolibriEntity>, {field, meta, transformationEntity}: DatatableColumn, query: FilterMetadata,
                   globalFilter: boolean,
                   or: boolean = false): void {
    const result = this.getQueryRelevantField(field, meta);
    if (!globalFilter) {
      switch (this.modelService.getTypeName(meta)) {
        case 'Date':
          const moment1 = moment(query.value);
          if (transformationEntity.dateType === DateType.TIME) {
            moment1.year(1970).month(0).date(1).add(moment1.isDST() ? 1 : 0, 'h');
          }
          const defaultGranularity = (transformationEntity.dateType === DateType.TIME) ? 'h' : 'd';
          let time;
          // @ts-ignore
          switch (query.granularity ?? defaultGranularity) {
            case 'y':
              time = moment.utc(moment1).add(moment(moment1).utcOffset(), 'minutes').startOf('y').toISOString();
              break;
            case 'm':
              time = moment.utc(moment1).add(moment(moment1).utcOffset(), 'minutes').startOf('M').toISOString();
              break;
            case 'd':
              time = moment.utc(moment1).add(moment(moment1).utcOffset(), 'minutes').startOf('d').toISOString();
              break;
            case 'h':
              time = moment.utc(moment1).add(moment(moment1).utcOffset(), 'minutes').milliseconds(0).seconds(0).minutes(0).toISOString();
              break;
            case 'i':
              time = moment.utc(moment1).add(moment(moment1).utcOffset(), 'minutes').milliseconds(0).seconds(0).toISOString();
              break;
          }
          // @ts-ignore
          const granularity = query.granularity || defaultGranularity;
          const utcOffset = moment(moment1).utcOffset() / 60;
          group.addCondition(result, Utility.getSearchOperatorFromMatchMode(query.matchMode),
            time,
            // @ts-ignore
            or, null, CriteriaFunction.DATE_TRUNC, {granularity, utcOffset});
          return;
        case AbstractRuntimeModelService.CHOICE:
          if (query.value.length) {
            group.addCondition(result, CriteriaOperator.IN, query.value.map(x => x.value), or);
          }
          return;
        case AbstractRuntimeModelService.KOLIBRI_ENTITY:
          const searchOp = Utility.getSearchOperatorFromMatchMode(query.matchMode);
          if (searchOp === CriteriaOperator.IN) {
            if (query.value.length) {
              group.addCondition(field, searchOp, query.value.map(x => x.id), or);
            }
            return;
          }
          break;
        default:
          break;
      }
    }

    const operator = Utility.getSearchOperatorFromMatchMode(query.matchMode, query.value);
    group.addCondition(result, operator, Utility.stripPercentByOperator(operator, query.value), or);
  }

  /**
   * if given attribute meta is a relation, it returns the name of the target relation
   * @param meta data of attribute definition
   * @returns target name of relation
   */

  public getKolibriType(meta: any): string {
    if (Utility.isRelation(meta)) {
      const target = (meta as Relation).targetId;
      return this.modelService.getEntity(target).name.toLowerCase();
    } else {
      return 'DEFAULT_TYPE';
    }
  }

  /**
   * returns the default list column for the given field with ancestor entities
   * @param entity the entity to get the default list column for
   * @param field the field to get the default list column for
   * @private
   */
  private getDefaultListColumns(entity: EntityModel, field: Field): ListColumn {
    if (!entity) {
      return;
    }
    // get the default list column for the ancestor entity
    return this.modelService.getDefaultListColumn(entity.id, field.name) ?? this.getDefaultListColumns(entity.ancestor, field);
  }

  private getQueryRelevantField(column: string, field: Field): string {
    let result = column;
    switch (this.modelService.getTypeName(field)) {
      case AbstractRuntimeModelService.KOLIBRI_ENTITY:
        result += '.representativeString';
        break;
      default:
        break;
    }
    return result;
  }

  private getFilterType(field: Field): FilterType {
    switch (this.modelService.getTypeName(field)) {
      case AbstractRuntimeModelService.KOLIBRI_ENTITY:
      case 'Choice':
        return FilterType.MULTISELECT;
      default:
        return FilterType.STANDARD;
    }
  }
}

<ui-dialog [(visible)]="showExportDialog" [dialogFooter]="true" header="{{'List.Export.Header' | translate}}" name="ExportDialog">
  <ng-template #content>
    <p>{{'List.Export.SelectMessage' | translate}}</p>
    <ui-select-button [(ngModel)]="selectedExportType" [options]="exportActions"></ui-select-button>
    <ui-input-text *ngIf="selectedExportType === 'csv'" [(ngModel)]="csvSeparator" name="separator"></ui-input-text>
    <p-checkbox [(ngModel)]="translateValues" [binary]="true" label="{{'List.Export.TranslateValues' | translate}}"></p-checkbox>
    <p-checkbox *ngIf="allowAllFields" [(ngModel)]="allAttributes" [binary]="true" label="{{'List.Export.AllAttributes' | translate}}"></p-checkbox>
  </ng-template>

  <ng-template #footer>
    <ui-button (clicked)="export(); $event.cb();" icon="fas fa-fw fa-download" label="{{'List.Export.Export' | translate}}" type="primary"></ui-button>
  </ng-template>
</ui-dialog>

<ui-button *ngIf="messages.length > 0" icon="fas fa-fw fa-exclamation-triangle" type="inline" class="one-error-tray__toggle-overlay"
           (clicked)="overlayPanel.toggle($event.originalEvent);$event.cb();overlayPanel.cd.detectChanges();"
           label="{{'TrayLogger.ToggleOverlay' | translate}}" tooltipPosition="left"></ui-button>

<p-overlayPanel #overlayPanel styleClass="one-error-tray__overlay">
  <ng-template pTemplate>
    <header class="one-error-tray__header">
      <div class="one-error-tray__header-content">
        <h4 class="one-error-tray__title">{{'TrayLogger.Title' | translate}}</h4>
        <div *ngIf="supportMail" class="one-error-tray__subtitle">
          {{'TrayLogger.SubTitle' | translate}}
          <a target="_blank" href="mailto:{{supportMail}}">{{supportMail}}</a>
        </div>
      </div>
      <div class="one-error-tray__header-buttons one-helper--pull-right">
        <ui-button (clicked)="clearLogs();$event.cb();" class="one-error-tray__button one-error-tray__button--clear one-helper--pull-right" type="icon-only"
                   icon="fas fa-trash" label="{{'TrayLogger.ClearAll' | translate}}" tooltipPosition="left"></ui-button>
      </div>
    </header>

    <ul class="one-error-tray__list">
      <li *ngFor="let messageObj of messages; index as i;" class="one-error-tray__list-item">
        <div class="one-error-tray__item-content">
          <div class="one-error-tray__summary">
            {{messageObj.summary}}
          </div>
          <div *ngIf="messageObj.hasDetails && messageObj.expanded" class="one-error-tray__detail">
            {{messageObj.detail}}
          </div>
        </div>
        <div class="one-error-tray__item-buttons">
          <ui-button *ngIf="messageObj.hasDetails" (clicked)="expandMessage(messageObj);$event.cb();overlayPanel.cd.detectChanges();"
                     icon="fa fa-chevron-{{!messageObj.expanded ? 'right' : 'down'}}" type="icon-only"
                     class="one-error-tray__button one-error-tray__button--toggle" label="{{'TrayLogger.ToggleDetails' | translate}}" tooltipPosition="left"></ui-button>
          <ui-button (clicked)="copyToClipboard(messageObj.detail); $event.cb();" type="icon-only" icon="fas fa-copy"
                     class="one-error-tray__button one-error-tray__button--copy" label="{{'TrayLogger.Copy' | translate}}" tooltipPosition="left"></ui-button>
        </div>
      </li>
    </ul>
  </ng-template>
</p-overlayPanel>
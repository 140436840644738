import {Directive, QueryList, ViewChildren} from '@angular/core';
import {FormControl, NgForm} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {_} from '@wspsoft/frontend-backend-common';
import {MessageService} from 'primeng/api';

import {CustomInput} from '../components/data/custom-input';

/**
 * abstract class for all components that utilize a ng form
 */
// noinspection AngularMissingOrInvalidDeclarationInModule
@Directive()
// tslint:disable-next-line:directive-class-suffix
export abstract class SubFormComponent {
  protected form: NgForm;
  @ViewChildren(CustomInput)
  protected inputs: QueryList<CustomInput<any>>;
  protected additionalInput: CustomInput<any>[] = [];
  protected hasAsyncValidations: boolean = true;

  protected constructor(protected messageService: MessageService, public translateService: TranslateService) {
  }

  public get allInputs(): CustomInput<any>[] {
    return _.union(this.inputs.toArray(), this.additionalInput);
  }

  public validate(showMessage: boolean = true): Promise<boolean> {
    this.markAsDirty();
    return new Promise<boolean>(resolve => {
      if (this.hasAsyncValidations && this.allInputs.length) {
        const subscription = this.form.statusChanges.subscribe(value => {
          if (value !== 'PENDING') {
            this.checkFormIsValid(resolve, showMessage);
            // we are done with validation, lo longer need to subscript
            subscription.unsubscribe();
          }
        });
      } else {
        this.checkFormIsValid(resolve, showMessage);
      }
    });
  }

  public registerInput(input: CustomInput<any>): void {
    if (!_.find(this.additionalInput, {name: input.name})) {
      this.additionalInput.push(input);
    }
  }

  public unregisterInput(input: CustomInput<any>): void {
    _.remove(this.additionalInput, {name: input.name});
  }

  public isValid(): boolean {
    // check if there is any error object
    const entries = _.filter(Object.entries(this.form.controls), o => o[1].errors !== null);

    const inputs = this.allInputs;
    for (const entry of entries) {
      if (_.find(inputs, o => o.name === entry[0])) {
        return false;
      }
    }
    return true;
  }

  public markAsUntouched(): void {
    // iterate over all inputs, and mark as dirty to validation fires
    for (const item of this.allInputs) {
      const control = this.form.controls[item.name] as FormControl;
      item.markAsUntouched();
      control?.updateValueAndValidity();
    }
  }

  protected focusFirstEmptyInput(): void {
    // do not refocus!
    // @ts-ignore
    if (!document.activeElement || document.activeElement !== document.body || window.e2eEnvironment) {
      return;
    }

    const activeClasses = document.activeElement.className;
    // only grab focus when not focusing an input already
    if (activeClasses.includes('ng-valid') || activeClasses.includes('ng-invalid')) {
      return;
    }
    // try to find first invalid input
    (_.find(this.allInputs, o => !o.value && o.focus) as any)?.focus();
  }

  /**
   * show a message that validation failed
   */
  protected showValidationFailedMessage(): void {
    this.messageService.add({
      severity: 'error',
      summary: '',
      detail: this.translateService.instant('Form.FailedValidationMessage'),
      key: 'growl'
    });
  }

  private checkFormIsValid(resolve: (value?: (Promise<boolean> | boolean)) => void, showMessage: boolean = true): void {
    let result = true;
    // if valid do actual save
    if (!this.form.valid && !this.isValid()) {
      this.focusFirstInvalidInput();
      if (showMessage) {
        this.showValidationFailedMessage();
      }
      result = false;
    }
    // resolve this promise and continue execution
    resolve(result);
  }

  private markAsDirty(): void {
    // iterate over all inputs, and mark as dirty to validation fires
    for (const item of this.allInputs) {
      const control = this.form.controls[item.name] as FormControl;
      item.markAsDirty();
      control?.updateValueAndValidity();
    }
  }

  private focusFirstInvalidInput(): void {
    // try to find first invalid input
    for (const entry of _.filter(Object.entries(this.form.controls), o => o[1].errors !== null)) {
      const find: any = _.find(this.allInputs, o => o.name === entry[0]);
      if (find) {
        find.focus();
        return;
      }
    }
  }
}

abstract class Author {
  public email?: string;
  public name?: string;
}

abstract class Summary {
  public changes?: number;
  public insertions?: number;
  public deletions?: number;
}

export abstract class CommitSummary {
  public author?: Author;
  public branch: string;
  public commit: string;
  public summary: Summary;
}

import {ActivityActor} from '../..';

export interface EntityServiceOptions {
  changeTracking?: boolean;
  descendants?: boolean;
  secured?: boolean;
  actor?: ActivityActor;
}

export interface EntityReadOptions {
  trigger?: boolean;
}

export interface EntityWriteOptions {
  trigger?: boolean; // normal before, after trigger
  schemaValidation?: boolean; // server null checks and co
  validation?: boolean; // script validations
  statistics?: boolean; // lastModified and co
  workflow?: boolean;
  mailing?: boolean;
  partialUpdate?: boolean;
}

import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input} from '@angular/core';
import {ButtonService} from '../../service/button.service';
import {AbstractButton} from '../../util/abstract-button';


@Component({
  selector: 'ui-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonComponent extends AbstractButton {
  @Input()
  public showDelay: number = 150;
  @Input()
  public icon: string = 'nothing';
  @Input()
  public submit: boolean = false;
  @Input()
  public stopPropagate: boolean = false;

  public constructor(public el: ElementRef, buttonService: ButtonService, cdr: ChangeDetectorRef) {
    super(buttonService, cdr);
  }

  public get nativeElement(): HTMLElement {
    return this.el.nativeElement;
  }

  public executeClick($event: MouseEvent): void {
    if (this.stopPropagate) {
      $event.stopPropagation();
    }

    this.progress = this.spinner;
    if (!this.disable) {
      this.clicked.emit({
        cb: () => {
          this.progress = false;
        }, originalEvent: $event
      });
    }
  }

}

export function delegate(field: string): any {
  return function (target: any, propertyKey: string) {
    Object.defineProperty(target, propertyKey, {
      get() {
        return this[field][propertyKey];
      },
      set(v) {
        this[field][propertyKey] = v;
      }
    });
  };
}

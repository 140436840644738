import {DesignerEntity} from './designer.entity';
import {Type} from './type';

export enum AttributeComputationType {
  GETTER = 'GETTER', COMPUTED = 'COMPUTED', FUNCTION = 'FUNCTION'
}

export abstract class Attribute extends DesignerEntity {
  public isRepresentative: boolean;
  public multiple?: boolean;
  public indexWeight?: number;
  public nullable?: boolean;
  public computed?: AttributeComputationType;
  public typeId?: string;
  public availableForMassUpdate?: boolean;
  public createIndex?: boolean;
  public entityId?: any;
  public script?: string;
  public availableForTemplate?: boolean;
  public availableForQueryBuilder?: boolean;
  public availableAsListColumn?: boolean;
  public activityFieldTracking?: boolean;
  public displayTransformId?: string;

  public type?: Type;
  public path?: string;
}

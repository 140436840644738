import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {CriteriaOrderBy, CriteriaQueryJson} from '@wspsoft/frontend-backend-common';
import {QueryBuilderService} from '../../../../service/query-builder.service';
import {CustomWriter} from '../../custom-writer';
import {QueryBuilderDataService} from '../query-builder/query-builder-data.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[ui-query-builder-sort-group]',
  templateUrl: './query-builder-sort-group.component.html',
  styleUrls: ['./query-builder-sort-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QueryBuilderSortGroupComponent extends CustomWriter<CriteriaQueryJson> implements OnInit, OnChanges {
  @Input()
  public draggable: boolean;
  @Input()
  public breadcrumb: boolean;
  @Input()
  public disable: boolean;
  @Output()
  public onExecute: EventEmitter<void> = new EventEmitter<void>();
  public nonEmptyOrders: CriteriaOrderBy[];


  public constructor(private queryBuilderService: QueryBuilderService, private translateService: TranslateService,
                     public queryBuilderDataService: QueryBuilderDataService,
                     cdr: ChangeDetectorRef) {
    super(cdr);
  }

  public ngOnInit(): void {
    this.ngOnChanges();
  }

  public ngOnChanges(): void {
    if (!this.value.orders.length) {
      this.addRule();
    }
  }

  public addRule(): void {
    this.value.orders.push(new CriteriaOrderBy(null));
  }


  public moveElement(target: Event, index: number): void {
    target.stopPropagation();
    this.queryBuilderService.onDrop();
    const element = this.queryBuilderService.draggedQueryElement;
    this.value.orders.splice(index, 0, element as CriteriaOrderBy);
    this.forceUpdate();
  }

  /**
   * Returns all non empty orders by checking if the column name and order
   * @returns the filtered CriteriaOrderBy
   */
  public getNonEmptyOrders(): CriteriaOrderBy[] {
    // push result to local array -> otherwise this function needs to be called multiple times inside the html
    this.nonEmptyOrders = this.value.orders.filter(
      orderBy => orderBy.columnName && orderBy.order);
    return this.nonEmptyOrders;
  }
}

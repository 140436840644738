import * as moment from 'moment';
import {BackupPolicy} from './backup-policy';
import {KolibriEntity} from './kolibri-entity';

export enum BackupState {
  pending = 'pending', running = 'running', completed = 'completed', error = 'error', cancelled = 'cancelled'
}

export class Backup extends KolibriEntity {
  public name: string;
  public subPath: string;
  public state: string;
  public size: number;
  public expiration: string | moment.Moment;
  public plannedStart: string | moment.Moment;
  public runStart: string | moment.Moment;
  public runEnd: string | moment.Moment;
  public runDuration: number;
  public selectedEntities: string[];
  public backupPolicy: BackupPolicy;
}

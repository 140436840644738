import {TranslateService} from '@ngx-translate/core';
import {CriteriaQueryGroupJson} from '@wspsoft/frontend-backend-common';
import {MenuItem} from 'primeng/api';

/**
 * Toggle Item class used by the BreadCrumb, since the icons and labels change on toggle
 */
export class ToggleItem implements MenuItem {
  public constructor(private value: CriteriaQueryGroupJson, private translateService: TranslateService, public command: () => void) {
  }

  /**
   * @returns the icon class depending on if the queryBuilder rule/group is active or not
   */
  public get icon(): string {
    return !this.value.active ? 'fas fa-fw fa-eye' : 'fas fa-fw fa-eye-slash';
  }

  /**
   * @returns the label depending on if the queryBuilder rule/group is active or not
   */
  public get label(): string {
    return this.translateService.instant(!this.value.active ? 'ToggleItem.Enable' : 'ToggleItem.Disable');
  }
}

export abstract class OneMenuState {
  public hidden: boolean;
  public currentTab: OneMenuFlags;
}

export enum OneMenuFlags {
  HISTORY,
  FAVORITES,
  NAVIGATION
}

import {
  CriteriaQuery,
  EntityModel,
  EntityReadOptions,
  EntityServiceOptions,
  EntityWriteOptions,
  FirstLevelEntityEnhancer,
  KolibriEntity,
  Relation,
  SearchResult
} from '../..';

export interface AbstractEntityService<E extends KolibriEntity> {
  entityMeta: EntityModel;
  entityEnhancer: FirstLevelEntityEnhancer<E>;
  options?: EntityServiceOptions;

  getEntityById(id: string, viewId?: string): Promise<E>;

  /**
   * WARNING: dotwalk ist performed in the backend as bulk operation, no lazy loading supported yet
   */
  getEntityRelations<T extends KolibriEntity>(entities: string[] | E[], nameWithDotWalk: string): Promise<T[]>;

  /**
   * internal method for lazy loading functions from entity enhancer
   * @param {string} entity
   * @param {Relation} relation
   */
  getEntityRelation<T extends KolibriEntity>(entity: string | E, relation: Relation): Promise<T | T[]>;

  executeSearchQuery(query?: CriteriaQuery<E>, options?: EntityReadOptions): Promise<SearchResult<E>>;

  execute(query?: CriteriaQuery<E>, options?: EntityReadOptions): Promise<SearchResult<any>>;

  getEntities(query?: CriteriaQuery<E>, options?: EntityReadOptions): Promise<E[]>;

  createEntity(e: E[], options?: EntityWriteOptions): Promise<E[]>;

  createEntity(e: E, options?: EntityWriteOptions): Promise<E>;

  createEntity(entity: E | E[], options?: EntityWriteOptions): Promise<E | E[]>;

  updateEntity(e: E[], options?: EntityWriteOptions): Promise<E[]>;

  updateEntity(e: E, options?: EntityWriteOptions): Promise<E>;

  updateEntity(entity: E | E[], options?: EntityWriteOptions): Promise<E | E[]>;

  deleteEntity(id: string | string[], options?: EntityWriteOptions, hard?: boolean): Promise<void>;

  deleteEntities(id: string | string[], options?: EntityWriteOptions, hard?: boolean): Promise<void>;

  getNewEntity(viewId?: string): Promise<E> | E;

  triggerBeforeDisplay(entity: E): Promise<E>;
}

import {
  OneHistory,
  OneLayoutState,
  OneMenuState,
  OnePageRightState,
  OneTableState,
  User,
  UserHotkeyOverwriteData,
  UserProfile,
  UserSessionSettings
} from '../..';

export interface AbstractBackendSessionService {
  changeActiveProfile(profile: UserProfile): Promise<void>;

  changeAvatar(attachmentId: string): Promise<void>;

  saveCurrentDashboard(dashboard: string, user: User): Promise<UserSessionSettings>;

  saveRecentGlobalSearch(query: string, user: User): Promise<UserSessionSettings>;

  saveTableState(tableId: string, tableState: OneTableState, user: User): Promise<UserSessionSettings>;

  saveLeftMenuState(leftMenuState: OneMenuState, user: User): Promise<UserSessionSettings>;

  saveUserHotkeyOverwrites(userHotkeyOverwrites: UserHotkeyOverwriteData, user: User): Promise<UserSessionSettings>;

  saveOneHistory(oneHistory: OneHistory, user: User): Promise<UserSessionSettings>;

  saveLayoutState(layoutName: string, layoutState: OneLayoutState, user: User): Promise<UserSessionSettings>;

  savePageRightState(layoutName: string, state: OnePageRightState, user: User): Promise<UserSessionSettings>;
}

import {Injectable} from '@angular/core';
import {_, Choice, ChoiceValue} from '@wspsoft/frontend-backend-common';
import {ModelTranslationService} from '../../../../api';
import {Converter} from './converter';

@Injectable()
export class ChoiceValueConverterService implements Converter<ChoiceValue | ChoiceValue[], string | string[]> {
  private choiceMeta: Choice;

  public constructor(private modelTranslationService: ModelTranslationService) {
  }

  public init(choiceMeta: Choice): void {
    this.choiceMeta = choiceMeta;
  }

  public getAsObject(s: string[]): ChoiceValue[];
  public getAsObject(s: string): ChoiceValue;
  public getAsObject(s: string | string[]): ChoiceValue | ChoiceValue[] {
    if (Array.isArray(s)) {
      if (!s.length) {
        return s as ChoiceValue[];
      }
      return s.map(s1 => this.getAsObject(s1));
    }

    const value = _.find(this.choiceMeta.values, {value: s});

    if (!value) {
      console.error(`Could not find the value ${s} for Choice ${this.choiceMeta.name}`);
    }

    value.label = this.modelTranslationService.translateChoiceValue(value, this.choiceMeta.id);
    return value;
  }

  public getAsString(value: ChoiceValue[]): string[];
  public getAsString(value: ChoiceValue): string;
  public getAsString(value: ChoiceValue | ChoiceValue[]): string | string[] {
    if (Array.isArray(value)) {
      if (!value.length) {
        return value as string[];
      }

      return value.map(s1 => this.getAsString(s1));
    }

    if (value) {
      return value.value;
    }
  }
}

import {Injectable} from '@angular/core';

import {DialogComponent} from '../components/dialog/dialog/dialog.component';

@Injectable()
export class OneDialogService {
  private dialogs: { id?: DialogComponent<any> } = {};

  public get<T>(dialogName: string): DialogComponent<T> {
    return this.dialogs[dialogName];
  }

  public show<T>(dialogName: string, data?: T): void {
    this.dialogs[dialogName].show(data);
  }

  public register<T>(dialog: DialogComponent<T>): void {
    this.dialogs[dialog.name] = dialog;
  }

  public unregister<T>(dialog: DialogComponent<T>): void {
    delete this.dialogs[dialog.name];
  }
}

import {Injectable} from '@angular/core';
import {_, ApplicationModel, Entity, EntityModel, KolibriEntity, Layout, LayoutForm, ListLayout, ScriptParams} from '@wspsoft/frontend-backend-common';
import * as uuidv4 from 'uuid-random';
import {CircularService, ModelService, UserService} from '../../../api';

@Injectable()
export class LayoutConditionService {
  public constructor(private modelService: ModelService, private userService: UserService,
                     private circularService: CircularService) {
    circularService.layoutConditionService = this;
  }

  public getCreateLayout(entityMeta: Entity): string {
    // determine create layout
    if (entityMeta.createLayoutId) { // otherwise use default create layout
      return this.modelService.getLayout(entityMeta.createLayoutId).url;
    } else {
      return this.fallbackLayoutName(entityMeta); // in emergency use generic name
    }
  }

  /**
   * calculates create layout url from entity meta
   */
  public getCreateUrlFromRecord(entityMeta: Entity): string {
    const app = this.modelService.getApplication(entityMeta.applicationId);
    const viewLayout = this.getCreateLayout(entityMeta);

    if (!viewLayout) {
      return 'error/403';
    }

    return '/' + [app.name, entityMeta.name, viewLayout].join('/');
  }

  /**
   * fetch all required data for calculating the view layout from given record
   */
  public async getViewLayoutFromRecord(entity, layoutList, formId?: string,
                                       params?: ScriptParams): Promise<{ entityMeta: EntityModel; app: ApplicationModel; viewLayout: Layout }> {
    const entityMeta = this.modelService.getEntity(entity.entityClass);
    const app = this.modelService.getApplication(entityMeta.applicationId);
    const viewLayout = await this.getViewLayout(entity, entityMeta, layoutList, formId, params);
    return {entityMeta, app, viewLayout};
  }

  public async getViewUrlFromRecord(entity: KolibriEntity, layoutList?: ListLayout, formId?: string, params?: ScriptParams): Promise<string> {
    const {entityMeta, app, viewLayout} = await this.getViewLayoutFromRecord(entity, layoutList, formId, params);

    if (!viewLayout) {
      return 'error/403';
    }

    const entityId = entity.persisted ? `${_.decapitalize(entityMeta.name)}Id=${entity.id}` : '';
    return `/${[app.name, entityMeta.name, viewLayout.url].join('/')}?${entityId}`;
  }

  public async evalLayoutConditions(formId: string, entityMeta: Entity, entity?: KolibriEntity, params: ScriptParams = {}): Promise<string> {
    // if we have an id we need to calc conditions
    for (const condition of entityMeta.layoutConditions) {
      const result = this.circularService.scriptExecutor.evalCondition(formId, condition.condition, `LayoutCondition:${condition.name}:condition`, undefined,
        {record: entity, ...params}).result;
      if (_.isPromise(result) ? await result : result) {
        return this.modelService.getLayout(condition.layoutId)?.url;
      }
    }

    if (entityMeta.defaultLayoutId) {
      // user might not have access so optionally chained
      return this.modelService.getLayout(entityMeta.defaultLayoutId)?.url;
    } else {
      return this.fallbackLayoutName(entityMeta); // in emergency use generic name
    }
  }

  public async getViewLayout(entity: KolibriEntity, entityMeta: Entity, layoutList?: ListLayout, formId: string = uuidv4(),
                             params?: ScriptParams): Promise<LayoutForm> {
    let url;
    // does the list override the default behavior?
    if (layoutList?.detailUrlId) {
      url = layoutList.detailUrlId;
    } else {
      url = await this.evalLayoutConditions(formId, entityMeta, entity, params);
    }
    return this.modelService.getLayout(url);
  }

  public async getNewButtonLayout(formId: string, entityMeta: Entity, layout: ListLayout): Promise<LayoutForm> {
    // check if we have a list, and load record layout (plus button)
    const result = this.circularService.scriptExecutor.evalCondition(formId, (layout).newRecordButtonCondition,
      'newRecordButtonCondition', undefined, {
        layout
      }).result;
    if (_.isPromise(result) ? await result : result) {
      let url;

      // does the list override the default behavior?
      if (layout.forceDetailRecordView) {
        url = layout.detailUrlId;
      } else if (layout.createDialogUrlId) {
        url = layout.createDialogUrlId;
      } else {
        url = await this.evalLayoutConditions(formId, entityMeta);
      }
      return this.modelService.getLayout(url);
    }
  }

  private fallbackLayoutName(entityMeta: Entity): string {
    return `${_.decapitalize(entityMeta.name)}.xhtml`;
  }
}

import {HttpClient} from '@angular/common/http';
import {Inject, Injectable, Optional} from '@angular/core';
import {AbstractAuthService, User} from '@wspsoft/frontend-backend-common';
import {Configuration} from '../../configuration';
import {BASE_PATH} from '../../variables';
import {AbstractService} from '../abstract-service';

@Injectable()
export class AuthService extends AbstractService implements AbstractAuthService {
  public constructor(httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string,
                     @Optional() configuration: Configuration) {
    super(httpClient, basePath, configuration);

    this.basePath = '/auth';
  }

  public async keepAlive(): Promise<void> {
    const {queryParameters, headers} = this.getHeaders();
    await this.doGet<any>(`${this.basePath}/keepAlive`, queryParameters, headers);
  }

  public login(username: string, password: string, prevLoginMethod?: string, loginMethod?: string): Promise<void> {
    // eslint-disable-next-line prefer-const
    let {queryParameters, headers} = this.getHeaders();
    if (prevLoginMethod) {
      queryParameters = queryParameters.set('prevLoginMethod', prevLoginMethod);
    }
    if (loginMethod) {
      queryParameters = queryParameters.set('loginMethod', loginMethod);
    }
    headers = headers.set('Content-Type', 'application/json')
      .set('Authorization', 'Basic ' + btoa(`${encodeURIComponent(username)}:${encodeURIComponent(password)}`));
    return this.doPost<void>(`${this.basePath}/login`, {},
      queryParameters, headers);
  }

  public logout(): Promise<void> {
    const {queryParameters, headers} = this.getHeaders();

    return this.doPost<void>(`${this.basePath}/logout`, {},
      queryParameters, headers);
  }

  public selfRegistration(user: User): Promise<void> {
    const {queryParameters, headers} = this.getHeaders();

    return this.doPost<void>(`${this.basePath}/selfRegistration`, user,
      queryParameters, headers);
  }

  public validateToken(token: string): Promise<void> {
    const {queryParameters, headers} = this.getHeaders();

    return this.doPost<void>(`${this.basePath}/validateToken`, {token},
      queryParameters, headers);
  }

  public resendToken(): Promise<void> {
    const {queryParameters, headers} = this.getHeaders();

    return this.doPost<void>(`${this.basePath}/resendToken`, {},
      queryParameters, headers);
  }

  public passwordReset(token: string, password: string): Promise<User> {
    const {queryParameters, headers} = this.getHeaders();
    return this.doPost(`${this.basePath}/passwordReset`, {token, password},
      queryParameters, headers);
  }
}

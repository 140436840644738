<ui-input-wrapper [(editMode)]="editMode" [customValidationMessage]="customValidationMessage" [dirty]="inputElement.dirty" [disable]="disable"
                  [errors]="inputElement.errors" [filled]="nativeInput.filled" [hasFocus]="hasFocus" [helpMessage]="helpMessage" [label]="label"
                  [linkify]="linkify" [name]="name" [renderInputGroup]="renderInputGroup" [required]="required" [touched]="inputElement.touched"
                  [valid]="inputElement.valid">
  <ui-primeng-custom-autocomplete #inputElement="ngModel" #nativeInput (completeMethod)="completeMethod.emit($event)" (onBlur)="focusChanged($event)"
                                  (onFocus)="focusChanged($event)" (onForceClear)="clear();" (onSelect)="onSelect.emit($event);"
                                  (onUnselect)="onUnselect.emit($event)" [(ngModel)]="value" [clearable]="clearable" [disabled]="disable" [dropdown]="dropdown"
                                  [field]="field" [forceSelection]="forceSelection" [minLength]="0" [multiple]="multiple" [required]="required" [size]="size"
                                  [suggestions]="suggestions" appendTo="body" emptyMessage="{{'AutoComplete.NoResults' | translate}}">
    <ng-template *ngIf="showIcons" let-item pTemplate="item">
      <i *ngIf="item.icon" [className]="'one-autocomplete__column--icon fa-fw ' + item.icon" [ngStyle]="{color: item.color}"></i>
      <span class="p-autocomplete-token-label">{{item[field]}}</span>
    </ng-template>
  </ui-primeng-custom-autocomplete>
</ui-input-wrapper>


<ng-container *ngIf="!centralSteps">
  <div *ngIf="!step" class="one-guided-tour__content">
    <ng-container [ngTemplateOutlet]="outsideContent"></ng-container>
  </div>

  <div *ngIf="step" [doneTemplate]="doneButton" [nextTemplate]="nextButton" [prevTemplate]="prevButton" [stepContent]="singleStepContent"
       joyrideStep="{{step.name}}" title="{{BundleKeyGenerator.stepToKey(step) | translate}}" class="one-guided-tour__content"
       [style.display]="cssDisplayValue">
    <ng-container [ngTemplateOutlet]="outsideContent"></ng-container>
  </div>
</ng-container>

<ng-container *ngIf="centralSteps"><!--Welcome Page-->
  <ng-container *ngFor="let step of centralStepList">
    <joyrideStep [doneTemplate]="doneButton" [nextTemplate]="nextButton" [prevTemplate]="prevButton" [stepContent]="centralStepContent"
                 joyrideStep="{{step.name}}" stepPosition="center" title="{{BundleKeyGenerator.stepToKey(step) | translate}}"></joyrideStep>
    <ng-template #centralStepContent>
      <ng-container *ngTemplateOutlet="tourStepContent; context: {$implicit: step}"></ng-container>
    </ng-template>
  </ng-container>
</ng-container>

<ng-template #outsideContent>
  <ng-content></ng-content>
</ng-template>

<ng-template #singleStepContent>
  <ng-container *ngTemplateOutlet="tourStepContent; context: {$implicit: step}"></ng-container>
</ng-template>

<ng-template #tourStepContent let-step>
  <div [innerHTML]="step.stepText[this.currentLang]" class="one-guided-tour__content" style="white-space: pre" [style.display]="cssDisplayValue"></div>
</ng-template>

<ng-template #doneButton>
  <ui-button class="one-login__button one-guided-tour__doneButton" icon="fa fa-fw fa-check" label="{{'GuidedTour.Done' | translate}}"
             type="primary"></ui-button>
</ng-template>

<ng-template #nextButton>
  <ui-button class="one-login__button one-guided-tour__nextButton" icon="fa fa-fw fa-arrow-right" label="{{'GuidedTour.Next' | translate}}"
             type="primary"></ui-button>
</ng-template>

<ng-template #prevButton>
  <ui-button class="one-login__button one-guided-tour__backButton" icon="fa fa-fw fa-arrow-left" label="{{'GuidedTour.Prev' | translate}}"
             type="cancel"></ui-button>
</ng-template>







import {DesignerEntity} from './designer.entity';


export abstract class LevelAgreement extends DesignerEntity {
  public calculateInterval?: any;
  public entityType?: string;
  public entityId?: any;
  public pauseCondition?: any;
  public version?: string;
  public includeSubtypes?: boolean;
  public workflowDefinitionName?: string;
  public startCondition?: any;
  public slaType?: string;
  public cancelCondition?: any;
  public defaultInterval?: string;
}

import {AbstractModelTranslationService} from '..';

export interface TranslateService {
  instant(key: string, language?: string, params?: any): string;
}

export abstract class AbstractKolibriTranslate {
  protected constructor(private translateService: TranslateService, public model: AbstractModelTranslationService) {

  }

  public instant(key: string, language?: string, params?: any): string {
    return this.translateService.instant(key, language, params);
  }
}

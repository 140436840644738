import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'prettyStringify'
})
export class PrettyStringifyPipe implements PipeTransform {

  private static syntaxHighlight(json): string {
    if (json) {
      json = json.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
      return json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g, function (match) {
        let cls = 'number';
        if (/^"/.test(match)) {
          if (/:$/.test(match)) {
            cls = 'key';
          } else {
            cls = 'string';
          }
        } else if (/true|false/.test(match)) {
          cls = 'boolean';
        } else if (/null/.test(match)) {
          cls = 'null';
        }
        return `<span class="${cls}">${match}</span><style>
pre {outline: 1px solid #ccc; padding: 5px; margin: 5px; }
.string { color: green; }
.number { color: darkorange; }
.boolean { color: blue; }
.null { color: magenta; }
.key { color: red; }
</style>`;
      });
    }
  }

  public transform(value: any, ...args: unknown[]): string {
    return PrettyStringifyPipe.syntaxHighlight(JSON.stringify(value, undefined, 2));
  }
}

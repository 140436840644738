import {ChangeDetectionStrategy, Component, ComponentRef, forwardRef, Input, OnInit, Type} from '@angular/core';
import {FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors} from '@angular/forms';
import {CodeEditorLanguages, CodeEditorOptions, LargeTextEditorMode} from '@wspsoft/frontend-backend-common';
import {OutputsType} from 'ng-dynamic-component/lib/io';
import {CodeEditorComponent} from '../../../../../standalone-components/app/component/code-editor/code-editor.component';
import {MarkdownEditorComponent} from '../../../../../standalone-components/app/component/markdown-editor/markdown-editor.component';
import {CustomInput} from '../custom-input';
import {I18nInput} from '../i18n-input';

@Component({
  selector: 'ui-large-textfield',
  templateUrl: './large-textfield.component.html',
  styleUrls: ['./large-textfield.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => LargeTextfieldComponent),
    multi: true
  }, {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => LargeTextfieldComponent),
    multi: true,
  }, {
    provide: CustomInput,
    useExisting: forwardRef(() => LargeTextfieldComponent),
    multi: true
  }],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LargeTextfieldComponent extends I18nInput<any> implements OnInit {
  public LargeTextEditorMode: typeof LargeTextEditorMode = LargeTextEditorMode;
  @Input()
  public editorMode: LargeTextEditorMode;
  @Input()
  public rows: number = 5;
  @Input()
  public recordId: string;
  @Input()
  public compact: boolean;
  @Input()
  public enableMinimap: boolean = true;
  @Input()
  public recordClass: string;
  @Input()
  public queryClass: string;
  @Input()
  public language: CodeEditorLanguages;
  @Input()
  public codeEditorOptions: CodeEditorOptions[];
  public codeEditorComponent: Type<CodeEditorComponent>;
  public markdownEditorComponent: Type<MarkdownEditorComponent>;
  public tuiOptions: string[] = ['color', 'table_merge', 'code_highlighting'];
  public ngModelOutputs: OutputsType = {
    onChange: event => this.value = event
  };

  public validate(control?: FormControl): ValidationErrors {
    return super.validate(control);
  }

  public async ngOnInit(): Promise<void> {
    if (this.editorMode === LargeTextEditorMode.CODE_EDITOR) {
      this.codeEditorComponent = (await import('../../../../../standalone-components/app/component/code-editor/code-editor.component')).CodeEditorComponent;
      this.cdr.detectChanges();
    }
    if (this.editorMode === LargeTextEditorMode.MARKDOWN) {
      // eslint-disable-next-line max-len
      this.markdownEditorComponent = (await import('../../../../../standalone-components/app/component/markdown-editor/markdown-editor.component')).MarkdownEditorComponent;
      this.cdr.detectChanges();
    }

    super.ngOnInit();
  }

  public componentCreated($event: ComponentRef<any>): void {
    this.nativeInput = $event.instance;
  }
}

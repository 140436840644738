import {ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {NG_VALIDATORS, NG_VALUE_ACCESSOR} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {_, CriteriaQuery, CriteriaType, Entity, KolibriEntity, MaybePromise} from '@wspsoft/frontend-backend-common';
import {CriteriaFactory, EntityService, EntityServiceFactory, ModelService} from '../../../../../../api';
import {UiUtil} from '../../../../util/ui-util';

import {KolibriEntityConverterService} from '../../../converter/kolibri-entity-converter.service';
import {CustomInput} from '../../custom-input';
import {AutoComplete} from '../autocomplete';

@Component({
  selector: 'ui-entity-autocomplete',
  templateUrl: './entity-autocomplete-input.component.html',
  styleUrls: ['../autocomplete.scss', './entity-autocomplete-input.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => EntityAutocompleteInputComponent),
    multi: true
  }, {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => EntityAutocompleteInputComponent),
    multi: true,
  }, {
    provide: CustomInput,
    useExisting: forwardRef(() => EntityAutocompleteInputComponent),
    multi: true
  }, {
    provide: KolibriEntityConverterService
  }],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EntityAutocompleteInputComponent extends AutoComplete<KolibriEntity | KolibriEntity[]> implements OnInit, OnChanges {
  public entityMeta: Entity = {};
  @Input()
  public groupBy: string;
  /**
   * name or id of entity
   */
  @Input()
  public entityId: string;
  @Input()
  public convertToString: boolean = false;
  @Input()
  public search: (result: CriteriaQuery<KolibriEntity>) => MaybePromise<any>;
  private entityService: EntityService<KolibriEntity>;

  public constructor(private translateService: TranslateService, private modelService: ModelService, cdr: ChangeDetectorRef,
                     private criteriaFactory: CriteriaFactory, private entityConverter: KolibriEntityConverterService,
                     private entityServiceFactory: EntityServiceFactory) {
    super(cdr);
    this.converter = entityConverter;
  }

  public ngOnInit(): void {
    this.entityConverter.convertToString = this.convertToString;
    this.entityConverter.entityNameOrId = this.entityId;
    this.entityMeta = this.modelService.getEntity(this.entityId);
    this.entityService = this.entityServiceFactory.getService(this.entityId);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.entityId && !changes.entityId.firstChange && changes.entityId.currentValue) {
      this.ngOnInit();
    }
  }

  public async onComplete($event: any): Promise<void> {
    const query = this.criteriaFactory.getFrontendQuery<any>(this.entityMeta.name, CriteriaType.SELECT);
    if (this.search) {
      const result = this.search(query);
      if (_.isPromise(result)) {
        await result;
      }
    }
    const textFilter = query.addGroup();
    textFilter.addPercentBasedCondition('representativeString', $event.query);
    for (const field of this.queryFields || []) {
      textFilter.addPercentBasedCondition(field, $event.query, true);
    }
    query.limit(this.size + $event.offset);
    query.addOrder('representativeString');

    let suggestions = await query.getResults();

    if (this.groupBy && suggestions.length) {
      suggestions = await UiUtil.groupEntitiesIntoSelectItemGroups(suggestions, this.groupBy, this.modelService, this.entityService);
    }

    if (!suggestions.length && this.defaultEntry) {
      suggestions = [this.defaultEntry($event.query)];
    }
    this.suggestions = suggestions;
    this.cdr.detectChanges();

    if ($event.originalEvent.cb) {
      $event.originalEvent.cb();
    }
  }
}

import {KolibriEntity} from '../database/kolibri-entity';
import {Application} from './application';

export abstract class DesignerEntity extends KolibriEntity {
  public name?: string;
  public order?: number;
  public description?: string;
  public applicationId?: string;
  public application?: Application;
  public overrideId?: string;


  // extension
  public label?: string;
}

import {ChangeDetectorRef, Directive, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {ControlValueAccessor, FormControl, NgModel, ValidationErrors, Validator} from '@angular/forms';
import {_, CustomValidationMessage, MaybePromise} from '@wspsoft/frontend-backend-common';
import {Converter, DefaultConverter} from '../converter/converter';

declare type FormHooks = 'change' | 'blur' | 'submit';

@Directive()
// tslint:disable-next-line:directive-class-suffix
export abstract class CustomInput<E> implements ControlValueAccessor, Validator {
  @Input()
  public name: string;
  @Input()
  public nativeElement: boolean;
  @Input()
  public styleData: { [p: string]: any };
  @Input()
  public required: boolean = false;
  @Input()
  public converter: Converter<any, any> = new DefaultConverter();
  @Output()
  public onChange: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  public onBlur: EventEmitter<E> = new EventEmitter<E>();
  @Output()
  public convertChange: EventEmitter<any> = new EventEmitter<any>();
  public editMode: boolean = false;
  public customValidationMessage: CustomValidationMessage;
  public rawValue: string | string[] | { [key: string]: any } = null;
  @ViewChild('inputElement', {static: false})
  public input: NgModel = {} as any;
  @ViewChild('nativeInput', {static: false})
  protected nativeInput: ElementRef;
  protected originalValue: E = null;
  protected originalValueOld: E = null;
  protected changeListener: ((value: string | string[] | { [key: string]: any }) => void)[] = [];
  protected touchListener: ((value: string | string[] | { [key: string]: any }) => void)[] = [];
  protected pdisableSelf: boolean;

  public constructor(public cdr: ChangeDetectorRef) {
  }

  private pdisable: boolean;

  @Input()
  public get disable(): boolean {
    return this.pdisable || this.pdisableSelf || null;
  }

  public set disable(value: boolean) {
    this.pdisable = value;
  }

  protected pmultiple: boolean = false;

  @Input()
  public get multiple(): boolean {
    return this.pmultiple;
  }

  public set multiple(value: boolean) {
    this.pmultiple = value;
  }

  private pupdateWhileTyping: boolean;

  @Input()
  public get updateWhileTyping(): boolean {
    return this.pupdateWhileTyping;
  }

  public set updateWhileTyping(value: boolean) {
    this.pupdateWhileTyping = value;
  }

  private phasFocus: boolean;

  public get hasFocus(): boolean {
    if (this.nativeInput) {
      return this.phasFocus || document.activeElement === this.nativeInput.nativeElement;
    }
    return this.phasFocus;
  }

  public set hasFocus(value: boolean) {
    this.phasFocus = value;
  }

  private plabel: string;

  @Input()
  public get label(): string {
    return this.plabel;
  }

  public set label(value: string) {
    this.plabel = value;
  }

  private phelpMessage: string;

  @Input()
  public get helpMessage(): string {
    return this.phelpMessage;
  }

  public set helpMessage(value: string) {
    this.phelpMessage = value;
  }

  private plinkify: boolean = false;

  @Input()
  public get linkify(): boolean {
    return this.plinkify;
  }

  public set linkify(value: boolean) {
    this.plinkify = value;
  }

  /**
   * get the ng model option for update
   */
  public get updateOn(): FormHooks {
    return this.updateWhileTyping ? 'change' : 'blur';
  }

  @Input()
  public get value(): E {
    // always read the object value, but write converted one
    return this.originalValue;
  }

  public set value(value: E) {
    // invoked with null on initialization and special case for smaller cycles
    if (value === null || (!!this.input && this.input.model === value && (
      this.originalValue === value || this.originalValueOld === value
    ))) {
      return;
    }

    // remember last thing
    this.originalValueOld = this.originalValue;

    _.maybeAwait(this.needsConversion(value), needConv => {
      // maybe unsafe, what about string to string conversion?
      if (needConv) {
        // e.g. entity id was inserted
        // in this case we have to convert the string to an usable object
        if (!_.isEqual(value, this.rawValue) || value === this.originalValue) {
          // @ts-ignore
          _.maybeAwait(this.converter.getAsObject(value), x => {
            this.originalValue = x;
            this.convertChange.emit(this.originalValue);

            // the raw value is correct as it is
            // @ts-ignore
            this.setRawValue(value);
          });
        }
      } else {
        // some object was selected and we have to serialize it for json
        this.originalValue = value;
        _.maybeAwait(this.converter.getAsString(value), x => {
          // new value undefined and old value null is still no change
          if (x !== this.rawValue) {
            this.setRawValue(x);
          }
        });
      }
    });
  }

  public get oldValue(): E {
    return this.originalValueOld;
  }

  public markAsUntouched(): void {
    if (this.nativeInput && 'markAsUntouched' in this.nativeInput) {
      // @ts-ignore
      this.nativeInput.markAsUntouched();
      // @ts-ignore
      this.nativeInput.markAsPristine();
    }
    if (this.input) {
      this.input.control.markAsUntouched();
      this.input.control.markAsPristine();
      this.cdr.detectChanges();
    }
  }

  public markAsDirty(): void {
    if (this.nativeInput && 'markAsDirty' in this.nativeInput) {
      // @ts-ignore
      return this.nativeInput.markAsDirty();
    }
    if (this.input) {
      this.input.control.markAsDirty();
      this.cdr.detectChanges();
    }
  }

  public validate(control?: FormControl): ValidationErrors {
    return this.validateInput();
  }

  public focus(): void {
    if (this.nativeInput) {
      if ('focusInput' in this.nativeInput) {
        // @ts-ignore
        this.nativeInput.focusInput();
      } else {
        // @ts-ignore
        const nativeElement = this.nativeInput.nativeElement || this.nativeInput.inputfieldViewChild?.nativeElement;
        if (nativeElement && 'focus' in nativeElement) {
          nativeElement.focus();
        }
      }
    }
  }

  public touch(): void {
    this.touchListener.forEach(f => f(this.rawValue));
  }

  public registerOnChange(fn: (value: string | string[] | { [key: string]: any }) => void): void {
    this.changeListener.push(fn);
  }

  public registerOnTouched(fn: (value: string | string[] | { [key: string]: any }) => void): void {
    this.touchListener.push(fn);
  }

  public writeValue(value: E): void {
    this.value = value;
  }

  public focusChanged($event: Event): void {
    this.hasFocus = $event.type === 'focus';
    if (!this.hasFocus) {
      this.editMode = false;
      this.onBlur.emit();
    }
  }

  /**
   * check if given value needs to be converted
   */
  protected needsConversion(value: any): MaybePromise<boolean> {
    if (this.converter.hasNeedsConversion && this.converter.hasNeedsConversion() && this.converter.needsConversion) {
      return this.converter.needsConversion({newValue: value, data: {originalValue: this.originalValue, rawValue: this.rawValue}});
    }
    return typeof value === 'string' || (Array.isArray(value) && _.some(value, x => typeof x === 'string'));
  }

  protected validateInput(): ValidationErrors {
    if (this.nativeInput && 'validate' in this.nativeInput) {
      // @ts-ignore
      return this.nativeInput.validate();
    }
    if (this.input) {
      // input is valid or disabled (user has no chance to edit, so it must be valid)
      return this.input.valid || this.input.isDisabled ? null : {valid: false};
    }
    return null;
  }

  protected setRawValue(value: string | string[] | { [key: string]: any }): void {
    this.rawValue = value;
    this.changeListener.forEach(f => f(this.rawValue));
    this.onChange.emit(this.nativeElement ? this.rawValue : this.value);
    this.cdr.detectChanges();
    this.touch();
  }
}

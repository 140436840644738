export interface UserLiveEventData {
  userId: string;
  connections?: number;
  color?: string;
  representativeString?: string;
  repImageId?: string;
  initials?: string;
  mousePosition?: {
    x: number;
    y: number;
    clientX?: number;
    clientY?: number;
  };
  typing?: boolean;
  away?: boolean;
  idle?: boolean;
  offline?: boolean;
}

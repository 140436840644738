import {DesignerEntity} from './designer.entity';

import {LayoutList} from './layout-list';
import {ListColumn} from './list-column';

/**
 * connection between related lists and columns
 */
export abstract class LayoutListToListColumn extends DesignerEntity {
  public visible?: boolean;
  public layoutList?: LayoutList;
  public layoutListId?: string;
  public listColumn?: ListColumn;
  public listColumnId?: string;
}

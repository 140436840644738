import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {_, BundleKeyGenerator, Field, KolibriEntity, Relation, RenderType, Utility} from '@wspsoft/frontend-backend-common';
import {CircularService, ModelService, ModelTranslationService, TypeService} from '../../../../../api';
import {DisplayTransformationConverterService} from '../../converter/display-transformation-converter.service';

@Component({
  selector: 'ui-field-translator',
  templateUrl: './field-translator.component.html',
  styleUrls: ['./field-translator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: DisplayTransformationConverterService
  }]
})
export class FieldTranslatorComponent implements OnInit {
  public BundleKeyGenerator: typeof BundleKeyGenerator = BundleKeyGenerator;
  public hideRepImage: boolean;
  @Input()
  public typeName: string;
  @Input()
  public data: any;
  @Input()
  public meta: Field;
  @Input()
  public field: string;
  @Input()
  public converter?: (obj: any) => any;
  @Input()
  public renderType?: RenderType;
  @Input()
  public transformScript?: any;
  @Input()
  public transformId?: string;
  @Input()
  public showIcon?: boolean = true;
  @Input()
  public translateNull?: boolean;
  public transformedStyle: { [p: string]: any };
  public RenderType = RenderType;
  public multiple: boolean;
  public translatedValue: string | string[];

  public constructor(private modelTranslationService: ModelTranslationService, private modelService: ModelService,
                     private translate: TranslateService,
                     private cdr: ChangeDetectorRef, public typeService: TypeService, public circularService: CircularService,
                     public converterService: DisplayTransformationConverterService) {
  }

  /**
   * returns the entityValue
   *
   * Note:
   * return value is an entity and maybe a user with initials.
   * Initials is used in html and will be linting with error,
   * because initials is not an attribute on KolibriEntity
   */
  public get entityValue(): KolibriEntity & {initials?: string} {
    return Utility.isRelation(this.meta) || Utility.isDotWalk(this.field) ? this.data[Utility.wordifyDotWalk(this.field,
      Utility.isDotWalk(this.field))] : this.data;
  }

  public get convertValue(): any {
    if (this.translatedValue === undefined) {
      _.maybeAwait(this.modelTranslationService.translateObjectValue(this.data, this.field, this.translate.currentLang, this.meta, this.translateNull),
        (x) => {
          this.translatedValue = _.isNull(x) ? '' : this.converter ? this.converter(x) : x;
          this.doTransform();
          this.multiple = Array.isArray(this.translatedValue);

          this.cdr.detectChanges();
        });
    }
    return this.translatedValue;
  }

  public ngOnInit(): void {
    if (!this.meta && this.data?.entityClass) {
      this.meta = this.getField();
    }
    if (this.meta) {
      // for relations, we have to check if we display the rep image, for attributes and dotwalks use entityId
      this.hideRepImage = this.modelService.getEntity(Utility.isRelation(this.meta) ? (this.meta as Relation).targetId : this.meta.entityId)?.hideRepImage;
    }
    if (!this.typeName) {
      if (this.data?.entityClass) {
        this.typeName = this.getFieldType();
      } else {
        this.typeName = 'string';
      }
    }
    // executes the transformScript if a render type is present
    if (this.renderType) {
      if (this.renderType !== RenderType.STEPS && this.renderType !== RenderType.DISPLAYTRANSFORM) {
        _.maybeAwait(this.circularService.scriptExecutor.runScript<{ value: any; delayedValue: any }>(this.transformScript, {
          newValue: this.convertValue,
          record: this.data
        }, undefined, `FieldTranslation:${this.field}:TransformationEntity:transformScript`).result, (x) => {
          this.translatedValue = x?.value ?? this.convertValue;
          this.cdr.markForCheck();

          if (typeof x?.delayedValue === 'function') {
            _.maybeAwait(x.delayedValue(), (y) => {
              this.translatedValue = y?.value ?? this.convertValue;
              this.cdr.markForCheck();
            });
          }
        });
      }
    }
    this.converterService.displayTransform = this.modelService.getDisplayTransformation(this.transformId || this.meta?.displayTransformId);
  }

  public getField(): Field {
    return this.modelService.getField(this.data.entityClass, this.field);
  }

  public getFieldType(): string {
    return this.modelService.getTypeName(this.getField());
  }

  /**
   * Transforms the Value and Style of the List Column
   */
  public doTransform(): void {
    if (this.data.entityClass && this.field && this.renderType !== RenderType.STEPS) {
      if (this.converterService.hasNeedsConversion() && this.converterService.needsConversion({newValue: this.convertValue})) {
        this.translatedValue = this.converterService.getAsObject(this.convertValue as any);
      }
      _.maybeAwait(
        this.circularService.scriptExecutor.runScript<{ style: { [p: string]: any } }>(
          this.transformScript ?? this.converterService.displayTransform?.transformScript, {
            newValue: this.convertValue,
            record: this.data
          }, undefined, `FieldTranslation:${this.field}:TransformationEntity:transformScript`).result, x => {
          this.transformedStyle = x?.style;
        });
    }
  }
}

import {DesignerEntity} from './designer.entity';

export enum SocketEventType {
  client = 'client', server = 'server'
}

export enum SocketEventTrigger {
  onConnect = 'onConnect', onDisconnect = 'onDisconnect', onError = 'onError', onMessage = 'onMessage'
}

export abstract class SocketEvent extends DesignerEntity {
  public event?: string;
  public script?: string;
  public type?: SocketEventType;
  public trigger?: SocketEventTrigger;
}

<ui-input-wrapper [customValidationMessage]="customValidationMessage" [dirty]="inputElement.dirty" [disable]="disable" [errors]="inputElement.errors"
                  [filled]="!!nativeInput.value" [hasFocus]="hasFocus" [helpMessage]="helpMessage" [label]="label" [name]="name"
                  [renderInputGroup]="renderInputGroup" [required]="required" [touched]="inputElement.touched" [valid]="inputElement.valid">
  <p-calendar #inputElement="ngModel" #nativeInput (onBlur)="onCalendarBlur()" (onClose)="onClose.emit($event)" (onFocus)="hasFocus = true"
              (onInput)="changeDetection()" (onMonthChange)="calculateMarkedDates()" (onShow)="calculateMarkedDates()" (onYearChange)="calculateMarkedDates()"
              [(ngModel)]="value" [dateFormat]="translations.dateFormat" [defaultDate]="defaultDate" [disabledDates]="disabledDates" [disabled]="disable"
              [firstDayOfWeek]="translations.firstDayOfWeek" [inputStyle]="styleData" [monthNavigator]="true" [placeholder]="placeholder"
              [readonlyInput]="viewportMobile" [required]="required" [selectionMode]="selectionMode" [showButtonBar]="false" [showIcon]="!disable"
              [showSeconds]="showSeconds" [showTime]="showTime" [showWeek]="true" [timeOnly]="timeOnly" [touchUI]="viewportMobile" [yearNavigator]="true"
              appendTo="body" clearButtonStyleClass="one-button one-button--cancel" styleClass="one-input {{disable ?
              'one-input--readonly' : ''}} {{showTime ? 'one-input--with-time' : ''}}" todayButtonStyleClass="one-button one-button--cancel"
              yearRange="1900:2050">
    <ng-template let-date pTemplate="date">
      <ng-container *ngIf="!!getCalendarEvent(date) else notMarked">
        <span (click)="dateClick($event, date)" class="p-datepicker-day {{!getCalendarEvent(date).disabled ? 'p-datepicker-day--marked': ''}}"
              pTooltip="{{getCalendarEvent(date).representativeString}}" style="{{getCalendarEvent(date).style}}">{{date.day}}</span>
      </ng-container>
      <ng-template #notMarked>
        <span (click)="dateClick($event, date)" class="p-datepicker-day {{isCurrentDate(date) ? 'p-datepicker-day--marked': ''}}">{{date.day}}</span>
      </ng-template>
    </ng-template>
    <ng-template *ngIf="selectionMode === 'single'" pTemplate="footer">
      <div class="one-calendar__footer">
        <ui-button (clicked)="now(); $event.cb(); nativeInput.hideOverlay()" [label]="'primeng.now' | translate" type="cancel"></ui-button>
        <ui-button (clicked)="clearTime(); $event.cb(); nativeInput.hideOverlay()" *ngIf="showTime || timeOnly" [label]="'primeng.clearTime' | translate"
                   type="cancel"></ui-button>
        <ui-button (clicked)="value = undefined; $event.cb(); nativeInput.onClearButtonClick($event.originalEvent)" [label]="'primeng.clear' | translate"
                   type="cancel"></ui-button>
      </div>
    </ng-template>
  </p-calendar>
</ui-input-wrapper>


import {KolibriEntity} from '../kolibri-entity';
import {Variable} from './variable';
import {VariableSetToEntity} from './variable-link';
import {VariableRule} from './variable-rule';
import {VariableScript} from './variable-script';

export abstract class VariableSet extends KolibriEntity {
  public name?: string;
  public order?: number;
  public variableSetToEntities?: VariableSetToEntity[];
  public variableRules?: VariableRule[];
  public variableScripts?: VariableScript[];
  public variables?: Variable[];
}

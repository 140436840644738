import {OneHistory} from './one-history';
import {OneLayoutState} from './one-layout.state';
import {OneMenuState} from './one-menu-state';
import {OnePageRightState} from './one-page-right.state';

export abstract class UserSessionSettings {
  public tableStates: { [key: string]: OneTableState };
  public layoutStates: { [key: string]: OneLayoutState };
  public pageRightStates: { [key: string]: OnePageRightState };
  public oneHistory: OneHistory;
  public leftMenuState: OneMenuState;
  public currentDashboardId?: string;
  public globalSearches?: {
    query: string;
  }[];
  public userHotkeyOverwrites: UserHotkeyOverwriteData;
}

export abstract class OneTableState {
  public columns?: string[];
  public kanbanColumns?: { [title: string]: { expanded?: boolean } };
}

export interface UserHotkeyOverwriteData {
  [id: string]: string;
}

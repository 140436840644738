import {ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input} from '@angular/core';
import {NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {ValidationLevel} from '@wspsoft/frontend-backend-common';
import {Password} from 'primeng/password';
import {CustomInput} from '../custom-input';

@Component({
  selector: 'ui-password-input',
  templateUrl: './password-input.component.html',
  styleUrls: ['./password-input.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => PasswordInputComponent),
    multi: true
  }, {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => PasswordInputComponent),
    multi: true,
  },
    {
      provide: CustomInput,
      useExisting: forwardRef(() => PasswordInputComponent),
      multi: true
    }],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PasswordInputComponent extends CustomInput<string> {
  @Input()
  public minlength: number;
  @Input()
  public strongRegex: string;
  @Input()
  public showShowPassword: boolean = true;
  @Input()
  public disableAutofill: boolean = true;

  public constructor(private translate: TranslateService, cdr: ChangeDetectorRef) {
    super(cdr);
  }

  public get feedback(): boolean {
    return !!this.strongRegex?.length;
  }


  protected validateInput(): ValidationErrors {
    const validationErrors = super.validateInput();
    if (validationErrors) {
      return validationErrors;
    }
    if (!this.disable) {
      const testStrength = (this.nativeInput as unknown as Password)?.testStrength(this.value || '');
      if (this.feedback && testStrength && testStrength !== 3) {
        this.customValidationMessage = {
          message: this.translate.instant('Password.Weak'),
          valid: false,
          severity: ValidationLevel.ERROR
        };
        return this.customValidationMessage;
      } else {
        this.customValidationMessage = null;
      }
    }
  }
}

import {Application} from '../..';
import {DesignerEntity} from './designer.entity';


export abstract class ApplicationLink extends DesignerEntity {
  public child?: Application;
  public childId?: string;
  public parent?: Application;
  public parentId?: string;
}

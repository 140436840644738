<div #container [class]="styleClass"
     [ngClass]="{'one-input p-autocomplete p-component':true,'p-autocomplete-dd':dropdown,'p-autocomplete-multiple':multiple, 'one-input--readonly':disabled}"
     [ngStyle]="style">
  <i *ngIf="value?.icon" [className]="'one-autocomplete__column--icon fa-fw ' + value.icon" [ngStyle]="{color: value.color}"></i>
  <input #in (blur)="onInputBlur($event)" (change)="onInputChange($event)" (click)="onInputClick($event)" (focus)="onInputFocus($event)"
         (input)="onInput($event)" (keydown)="onKeydown($event)" (keyup)="onKeyup($event)" (paste)="onInputPaste($event)" *ngIf="!multiple"
         [attr.aria-activedescendant]="'p-highlighted-option'" [attr.aria-controls]="listId" [attr.aria-expanded]="overlayVisible" [attr.aria-label]="ariaLabel"
         [attr.aria-labelledby]="ariaLabelledBy" [attr.aria-required]="required" [attr.autofocus]="autofocus" [attr.id]="inputId" [attr.maxlength]="maxlength"
         [attr.name]="name" [attr.placeholder]="placeholder" [attr.required]="required" [attr.size]="size" [attr.tabindex]="tabindex" [attr.type]="type"
         [autocomplete]="autocomplete" [class]="inputStyleClass" [disabled]="disabled" [ngClass]="{'p-autocomplete-dd-input':dropdown,'p-disabled': disabled}"
         [ngStyle]="inputStyle" [readonly]="readonly" [value]="inputFieldValue" aria-autocomplete="list" aria-haspopup="true"
         class="p-autocomplete-input p-inputtext p-component" role="searchbox">
  <ul #multiContainer (click)="multiIn.focus()" *ngIf="multiple" [ngClass]="{'p-disabled':disabled,'p-focus':focus}"
      class="p-autocomplete-multiple-container p-component p-inputtext">
    <li #token *ngFor="let val of value" class="p-autocomplete-token">
      <ui-pill [color]="val.color" [icon]="val.icon">
        <ng-container *ngTemplateOutlet="selectedItemTemplate; context: {$implicit: val}"></ng-container>
        <span *ngIf="!selectedItemTemplate" class="p-autocomplete-token-label">
        <ng-container *ngFor="let field of fields">{{resolveFieldData(val)}}</ng-container>
      </span>
        <span (click)="removeItem(token)" *ngIf="!disabled" class="p-autocomplete-token-icon fas fa-times"></span>
      </ui-pill>
    </li>
    <li class="p-autocomplete-input-token">
      <input #multiIn (blur)="onInputBlur($event)" (change)="onInputChange($event)" (click)="onInputClick($event)" (focus)="onInputFocus($event)"
             (input)="onInput($event)" (keydown)="onKeydown($event)" (keyup)="onKeyup($event)" (paste)="onInputPaste($event)"
             [attr.aria-activedescendant]="'p-highlighted-option'" [attr.aria-controls]="listId" [attr.aria-expanded]="overlayVisible"
             [attr.aria-label]="ariaLabel" [attr.aria-labelledby]="ariaLabelledBy" [attr.aria-required]="required" [attr.autofocus]="autofocus"
             [attr.id]="inputId" [attr.maxlength]="maxlength" [attr.placeholder]="(value&&value.length ? null : placeholder)" [attr.tabindex]="tabindex"
             [attr.type]="type" [autocomplete]="autocomplete" [class]="inputStyleClass" [disabled]="disabled" [ngStyle]="inputStyle" [readonly]="readonly"
             aria-autocomplete="list" aria-haspopup="true" role="searchbox">
    </li>
  </ul>
  <ui-button (clicked)="clear(); $event.cb()" *ngIf="value && !multiple && !disabled && clearable" [attr.tabindex]="tabindex" [disable]="disabled"
             class="p-autocomplete-clear" icon="fas fa-fw fa-times" type="inline"></ui-button>
  <ui-button #ddBtn (clicked)="handleDropdownClick($event)" *ngIf="!disabled && dropdown" [attr.tabindex]="tabindex" [disable]="disabled"
             class="p-autocomplete-dropdown" icon="fas fa-fw fa-chevron-down" type="inline"></ui-button>
  <div #panel (@overlayAnimation.start)="onOverlayAnimationStart($event)" (scrolled)="search($event, prevQuery, true)" *ngIf="overlayVisible"
       [@overlayAnimation]="{value: 'visible', params: {showTransitionParams: showTransitionOptions, hideTransitionParams: hideTransitionOptions}}"
       [class]="panelStyleClass" [infiniteScrollDistance]="1" [infiniteScrollThrottle]="50" [ngClass]="['p-autocomplete-panel p-component']"
       [ngStyle]="panelStyle" [scrollWindow]="false" [style.max-height]="scrollHeight" infiniteScroll>
    <ul [attr.id]="listId" class="p-autocomplete-items" role="listbox">
      <ng-container *ngIf="group">
        <ng-template [ngForOf]="suggestions" let-optgroup ngFor>
          <li class="p-autocomplete-item-group">
            <span *ngIf="!groupTemplate">{{getOptionGroupLabel(optgroup) || 'empty'}}</span>
            <ng-container *ngTemplateOutlet="groupTemplate; context: {$implicit: optgroup}"></ng-container>
          </li>
          <ng-container *ngTemplateOutlet="itemslist; context: {$implicit: getOptionGroupChildren(optgroup)}"></ng-container>
        </ng-template>
        <li *ngIf="noResults && showEmptyMessage" class="p-autocomplete-emptymessage p-autocomplete-item">{{emptyMessage}}</li>
      </ng-container>
      <ng-container *ngIf="!group">
        <ng-container *ngTemplateOutlet="itemslist; context: {$implicit: suggestions}"></ng-container>
      </ng-container>
      <ng-template #itemslist let-suggestionsToDisplay>
        <li (click)="selectItem(option)" *ngFor="let option of suggestionsToDisplay; let idx = index"
            [id]="highlightOption === option ? 'p-highlighted-option':''" [ngClass]="{'p-highlight': (option === highlightOption)}" [ngStyle]="inputStyle"
            class="p-autocomplete-item" pRipple role="option">
          <ng-container *ngIf="!itemTemplate">
            <i *ngIf="option?.icon" [className]="'one-autocomplete__column--icon fa-fw ' + option.icon" [ngStyle]="{color: option.color}"></i>
            <span *ngFor="let field of fields">{{resolveFieldData(option)}}</span>
          </ng-container>
          <ng-container *ngTemplateOutlet="itemTemplate; context: {$implicit: option, index: idx}"></ng-container>
        </li>
        <li *ngIf="noResults && showEmptyMessage" class="p-autocomplete-emptymessage p-autocomplete-item">{{emptyMessage}}</li>
      </ng-template>
    </ul>
  </div>
</div>

<ng-container [ngSwitch]="editorMode">
  <ng-container *ngSwitchCase="LargeTextEditorMode.HTML">
    <div class="flex align-items-center">
      <ui-wysiwyg-editor #inputElement="ngModel" #nativeInput [(ngModel)]="value" [compact]="compact" [disable]="disable" [helpMessage]="helpMessage"
                         [label]="label" [name]="name" [required]="required" [updateWhileTyping]="updateWhileTyping"></ui-wysiwyg-editor>
      <ng-container *ngIf="isI18n && !disable" [ngTemplateOutlet]="chooseLanguageButtons"></ng-container>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="LargeTextEditorMode.CODE_EDITOR">
    <ndc-dynamic (ndcDynamicCreated)="componentCreated($event)" [ndcDynamicComponent]="codeEditorComponent" [ndcDynamicInputs]="{
                  value: value,
                  label: label,
                  disable: disable,
                  language: language ?? 'javascript',
                  maxHeight: rows * 40,
                  recordClass: recordClass,
                  queryClass: queryClass,
                  enableMinimap: enableMinimap,
                  helpMessage: helpMessage,
                  required: required,
                  codeEditorOptions: codeEditorOptions
                 }" [ndcDynamicOutputs]="ngModelOutputs"></ndc-dynamic>
  </ng-container>
  <ng-container *ngSwitchCase="LargeTextEditorMode.MARKDOWN">
    <ndc-dynamic (ndcDynamicCreated)="componentCreated($event)" [ndcDynamicComponent]="markdownEditorComponent" [ndcDynamicInputs]="{
                  value: value || {html: '', markdown: ''},
                  pluginOptions: tuiOptions,
                  recordClass: recordClass,
                  helpMessage: helpMessage,
                  inline: true,
                  disable: disable,
                  label: label,
                  required: required,
                  helpMessage: helpMessage,
                  recordId
                 }" [ndcDynamicOutputs]="ngModelOutputs"></ndc-dynamic>
  </ng-container>
  <ng-container *ngSwitchDefault>
    <ui-input-wrapper [(editMode)]="editMode" [customValidationMessage]="customValidationMessage" [dirty]="inputElement.dirty" [disable]="disable"
                      [errors]="inputElement.errors" [filled]="!!value" [hasFocus]="hasFocus" [helpMessage]="helpMessage" [label]="label" [linkifyRows]="rows"
                      [linkify]="linkify" [name]="name" [required]="required" [touched]="inputElement.touched" [valid]="inputElement.valid" [value]="value">
      <div class="flex align-items-center">
      <textarea #inputElement="ngModel" #nativeInput (blur)="focusChanged($event)" (focus)="focusChanged($event)" [(ngModel)]="value" [autoResize]="false"
                [cols]="60" [disabled]="disable" [name]="name" [ngModelOptions]="{updateOn: updateOn}" [ngStyle]="styleData" [required]="required" [rows]="rows"
                class="one-input one-input--wrap {{disable ? 'one-input--readonly' : ''}}" pInputTextarea></textarea>
        <ng-container *ngIf="isI18n && !disable" [ngTemplateOutlet]="chooseLanguageButtons"></ng-container>
      </div>
    </ui-input-wrapper>
  </ng-container>
</ng-container>

<ng-template #chooseLanguageButtons>
  <p-menu #languageOptions [model]="languageOptionButtons" [popup]="true" appendTo="body"></p-menu>
  <ui-button (clicked)="languageOptions.toggle($event.originalEvent)" [spinner]="false" [type]="['icon-only']" icon="fas fa-fw fa-ellipsis-v"
             label="{{'Input.TranslateInput' | translate}}"></ui-button>
</ng-template>

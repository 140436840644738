import {DesignerEntity} from './designer.entity';


export class GuidedTourStep extends DesignerEntity {
  public stepText?: string;
  public centralStep: boolean;
  public redirectPath: string;
}





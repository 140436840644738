import {Injectable} from '@angular/core';
import {AbstractEntityService, AbstractRelationService, KolibriEntity} from '@wspsoft/frontend-backend-common';

import {KolibriRecordFactory} from '../../../../script-api/app/api/kolibri-record-factory';

import {CriteriaFactory} from '../../../app/criteria/criteria-factory';

import {ModelService} from '../../../app/service/coded/model.service';

import {EntityService} from '../../../app/service/generated/entity.service';

@Injectable()
export class RelationService extends AbstractRelationService {

  public constructor(modelService: ModelService, criteriaFactory: CriteriaFactory, kolibriRecordFactory: KolibriRecordFactory) {
    super(modelService, criteriaFactory, kolibriRecordFactory);
  }

  protected doBatchSave<E extends KolibriEntity>(entities: E[], entityService: AbstractEntityService<E>): Promise<E[]> {
    return (entityService as EntityService<E>).batchSave(entities);
  }
}

import {CriteriaQuery, MaybePromise} from '@wspsoft/frontend-backend-common';
import {SelectItem} from 'primeng/api';
import {KanbanItemState} from '../components/structure/kanban/kanban.component';

export interface KanbanItem<T> extends SelectItem<T[]> {
  displayField?: string;
  color?: string;
  add?: boolean;
  edit?: boolean;
  autoCollapse?: boolean;
  move?: boolean;
  sort?: boolean;
  query?: CriteriaQuery<T>;
  onAdd?: (item: this) => MaybePromise<T>;
  onMove?: (e: T, item: this) => MaybePromise<any>;
  onLoad?: (query: CriteriaQuery<T>) => MaybePromise<void>;
  afterLoad?: (e: T[]) => MaybePromise<any>;
  moveCondition?: (e: T, item: this) => boolean;
  sortCondition?: (a: T, i: number, item: this) => boolean;

  // internal
  refresh?: () => Promise<void>;
  state?: KanbanItemState;
  offset?: number;
  limit?: number;
}

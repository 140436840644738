import {DesignerEntity} from './designer.entity';


export abstract class Relation extends DesignerEntity {
  public descendants?: boolean;
  public includeInJson?: boolean;
  public targetId?: string;
  public nullable?: boolean;
  public inverse?: boolean;
  public cascadeDelete?: boolean;
  public dumpMandatory?: boolean;
  public createIndex?: boolean;
  public entityId?: any;
  public cardinality?: CardinalityEnum;
  public availableForMassUpdate?: boolean;
  public availableForTemplate?: boolean;
  public availableForQueryBuilder?: boolean;
  public availableAsListColumn?: boolean;
  public activityFieldTracking?: boolean;
  public targetRelationName?: string;
  public indexWeight?: number;
  public path?: string;
  public displayTransformId?: string;
}

export enum CardinalityEnum {
  OneToOne = 'OneToOne',
  OneToMany = 'OneToMany',
  ManyToOne = 'ManyToOne',
  ManyToMany = 'ManyToMany'
}

import {ShareableEntity} from './shareable-entity';

export enum DashboardType {
  mixed = 'mixed', scoped = 'scoped'
}

export class Dashboard extends ShareableEntity {
  public jsonConfig: any[] = [];
  public icon: string;
  public liveReloadInterval: string;
  public entityScope: string;
  public filterableAttributes: string[];
  public type: DashboardType;
}

export default Dashboard;

import {HttpClient} from '@angular/common/http';
import {Inject, Injectable, Optional} from '@angular/core';
import {AbstractMonacoService} from '@wspsoft/frontend-backend-common';
import {environment} from '../../../../config/environments/environment';
import {Configuration} from '../../configuration';
import {BASE_PATH} from '../../variables';
import {AbstractService} from '../abstract-service';

@Injectable()
export class MonacoService extends AbstractService implements AbstractMonacoService {
  public constructor(httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string,
                     @Optional() configuration: Configuration) {
    super(httpClient, basePath, configuration);

    this.basePath = '/api/rest/public/portal/monaco';
  }


  public loadMustacheLibrary(frontend: boolean, backend: boolean): Promise<string> {
    // @ts-ignore
    return this.httpClient.post(`${environment.serverAdd}${this.basePath}/mustachelibrary`, {frontend, backend}, {
      responseType: 'text'
    }).toPromise();
  }
}

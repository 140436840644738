<login-authentication-page (onEnter)="save()" [sectionClass]="{
'one-login--error': error
}">
  <ng-template #header>
    <ng-container *ngIf="!error">
      <span class="one-login__greeting--big one-login__greeting--welcome">{{'Login.Greeting' | translate}} </span>
      <span>{{'Login.Welcome' | translate}}</span>
    </ng-container>
    <ng-container *ngIf="error">
      <span class="one-login__greeting--big one-login__greeting--error">{{'Login.ErrorHeader' | translate}} </span>
      <span>{{'Login.Error' | translate}}</span>
    </ng-container>
  </ng-template>
  <ng-template #content>
    <form #form="ngForm" class="one-login__form" method="post">
      <div class="one-login__form-fields">
        <ui-input-text #loginUsername [(ngModel)]="username" autocapitalize="off" label="{{'PlatformUser.username.Value' | translate}}"
                       name="username"></ui-input-text>
        <ui-input-text [(ngModel)]="password" label="{{'PlatformUser.password.Value' | translate}}" name="password" type="password"></ui-input-text>
      </div>
      <footer class="one-login__footer">
        <ui-button (clicked)="save($event.cb)" class="one-login__button--full-width" icon="fas fa-fw fa-sign-in-alt" label="Login"
                   styleClass="one-login__button" type="primary"></ui-button>
        <ui-button (clicked)="navigateToRegistration();" *ngIf="allowSelfRegistration" [spinner]="false" icon="fas fa-fw fa-user-plus"
                   label="{{'Login.Registration' | translate}}" type="layout"></ui-button>
        <ui-button (clicked)="navigateToPasswordReset();" *ngIf="allowPasswordReset" [spinner]="false" icon="fas fa-fw fa-key"
                   label="{{'Login.PasswordReset.Button' | translate}}" type="layout"></ui-button>
        <div *ngIf="showMultiAuthErrorMessage" class="one-login__info one-login__info--error">
          <span [innerHTML]="multiAuthErrorMessage | safe: 'html'"></span>
        </div>
        <div *ngIf="settingsService.platformSettings.loginInfoText" class="one-login__info">
          <span [innerHTML]="settingsService.platformSettings.loginInfoText | safe: 'html'"></span>
        </div>
      </footer>
    </form>
  </ng-template>
</login-authentication-page>

import {ElementRef} from '@angular/core';

export class ResizedEvent {
  public constructor(
    public readonly element: ElementRef,
    public readonly newWidth: number,
    public readonly newHeight: number,
    public readonly oldWidth: number,
    public readonly oldHeight: number
  ) {
  }
}

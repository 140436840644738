import {ChangeDetectionStrategy, Component, ContentChild, EventEmitter, Input, Output, TemplateRef} from '@angular/core';

@Component({
  selector: 'login-authentication-page',
  templateUrl: './authentication-page.component.html',
  styleUrls: ['./authentication-page.component.scss', '../form.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AuthenticationPageComponent {
  @Input()
  public showLogo: boolean = true;
  @Input()
  public sectionClass: any;
  @Output()
  public onEnter: EventEmitter<void> = new EventEmitter();
  @ContentChild('header', {static: true})
  public header: TemplateRef<any>;
  @ContentChild('content', {static: true})
  public content: TemplateRef<any>;
}

import {DesignerEntity} from './designer.entity';

export abstract class MenuItem extends DesignerEntity {

  public command?: string;
  public url?: string;
  public hotkeys?: string;
  public parent?: MenuItem;
  public parentId?: string;
  public permissionId?: string;
  public items?: MenuItem[];
  public name?: string;
  public applicationId?: string;
  public order?: number;
}

import * as moment from 'moment';
import {Attribute} from '../../model/xml/attribute';
import {AbstractRuntimeModelService} from '../../service/coded/abstract-runtime-model.service';
import {Utility} from '../../util/utility';
import {CriteriaOperator} from '../criteria-operator';

export abstract class JsUtil {
  /**
   * convert the value for a specific field a use able js script value
   */
  public static convertValueToString(value: any, field: Attribute, operator?: CriteriaOperator, relation?: boolean): any {
    // define here the aql rep of an object
    if (Array.isArray(value)) {
      return this.convertArray(value);
    }
    // always convert dates, the won't work correctly otherwise
    if (field?.typeId === AbstractRuntimeModelService.DATE_TYPE && !Utility.isFunctionalOperator(operator as any)) {
      return this.convertDate(value, typeof value === 'string');
    }
    if (relation && !Utility.isSubstringOperator(operator)) {
      // we might receive an object for the IS check
      return JSON.stringify(value?.id || value || '');
    }
    if (typeof value === 'string') {
      return JSON.stringify(value);
    }
    return value;
  }

  /**
   * convert js array to js eval array
   */
  public static convertArray(array: string[]): string {
    return `[${array.join(',')}]`;
  }

  /**
   * convert date to timestamp with moment
   */
  public static convertDate(value: string | moment.Moment, isString: boolean = true): string | number {
    const s = isString ? `'${value}'` : value;
    return moment.isMoment(value) ? value.valueOf() : `moment.utc(${s}).valueOf()`;
  }

  /**
   * access a attribute of a to many array or single record
   */
  public static accessAttribute(js: string, field: string, arrayAccess?: boolean): string {
    return arrayAccess ? `${js}.map(e => e?.${field})` : `${js}?.${field}`;
  }

  /**
   * access a relation of a to many array or single record
   * loads single results with await or to many in bulk
   */
  public static accessRelationAttribute(js: string, field: string, wasToMany?: boolean, fieldIsToMany?: boolean): string {
    const s = this.hasArrayAccess(js) ? js : `[${js}]`;
    const flatMap = fieldIsToMany ? '_.flatMap' : '';
    return wasToMany ? `${flatMap}((await ks.record.getEntityRelations(${s}, '${field}'), user))` : `(await ${js}.${field})`;
  }

  /**
   * concat conditions with and/or
   */
  public static appendStatement(prevStatement: string, appendStatement: string, or: boolean): string {
    if (!prevStatement) {
      return appendStatement;
    }
    return `(${prevStatement} ${or ? '||' : '&&'} ${appendStatement})`;
  }

  /**
   * check if given js string contains a map
   */
  public static hasArrayAccess(js: string): boolean {
    return js.includes('.map(');
  }
}

import {KolibriEntity} from '../../../model/database/kolibri-entity';
import {_} from '../../../util/underscore';

export enum EnhancementLevel {
  First, Second, Third, Variable
}

export abstract class TransientHandler<T extends KolibriEntity> {
  public enhanced: EnhancementLevel = EnhancementLevel.First;

  protected constructor(public record: T) {
    this.record.localExports ??= {};
  }

  public toJSON(): any {
    const cloneProperties = _.cloneProperties(this, true);

    for (const [key, value] of Object.entries(cloneProperties)) {
      if (value?.toJSON) {
        cloneProperties[key] = this[key].toJSON();
      }
    }

    return cloneProperties;
  }

  public keys(): string[] {
    return Object.keys(this.record);
  }
}

for (const i of ['_rev', '_id', '_key', 'persisted', 'deleted', 'localExports']) {
  Object.defineProperty(TransientHandler.prototype, i, {
    get() {
      return this.record[i];
    },
    set(v) {
      this.record[i] = v;
    },
    enumerable: true
  });
}

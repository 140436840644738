import {ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Input, OnInit,} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {_, BundleKeyGenerator, GuidedTourStep} from '@wspsoft/frontend-backend-common';

import {JoyrideService} from 'ngx-joyride';
import {ModelService} from '../../../../../api';
import {GuidedTourService} from '../../../service/guidedTour.service';


@Component({
  selector: 'ui-guided-tour-step',
  templateUrl: './guided-tour-step.component.html',
  styleUrls: ['./guided-tour-step.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
/**
 * The Component that renders one Step of a guidedTour
 * */
export class GuidedTourStepComponent implements OnInit {

  @Input()
  public stepId: string;
  @Input()
  @HostBinding('style.display')
  public cssDisplayValue: 'flex' | 'block' | 'inline-flex' | 'inline-block' = 'block';
  @Input()
  @HostBinding('style.flex')
  public cssFlexValue: 'auto' | 'none' = 'none';

  @Input()
  // Used when the step is a hardcoded one - All hardcoded stepNames start with res...
  public reservedStepName: string;

  @Input()
  // Used when the step is not attached to a specific Input
  public centralSteps: boolean;
  public step: GuidedTourStep;
  public centralStepList: GuidedTourStep[] = [];
  public currentLang: string;
  public BundleKeyGenerator: typeof BundleKeyGenerator = BundleKeyGenerator;

  public constructor(private guidedTourService: GuidedTourService, public cdr: ChangeDetectorRef, private readonly joyrideService: JoyrideService,
                     private modelService: ModelService, private translateService: TranslateService) {
  }

  /**
   * Initialize the Component and depending on the type of step fill the global variables, which are used in the html
   * */
  public ngOnInit(): void {
    this.currentLang = this.translateService.currentLang;
    // In case the Step ist not attached to a specific Input
    if (this.centralSteps) {
      this.centralStepList = _.filter(this.modelService.getGuidedTourSteps(), 'centralStep');
      // In case the Step is Hardcoded or connected to an Input
    } else if (this.stepId || this.reservedStepName) {
      this.step = this.modelService.getGuidedTourStep(this.reservedStepName || this.stepId);
    }
  }
}

import {ChangeDetectionStrategy, Component, EventEmitter, forwardRef, Input, Output} from '@angular/core';
import {NG_VALIDATORS, NG_VALUE_ACCESSOR} from '@angular/forms';
import {MultiSelect as PrimengMultiSelect} from 'primeng/multiselect';
import {CustomInput} from '../../custom-input';
import {MultiSelect} from '../multi-select';

@Component({
  selector: 'ui-multi-select-input',
  templateUrl: './multi-select-input.component.html',
  styleUrls: ['./multi-select-input.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => MultiSelectInputComponent),
    multi: true
  }, {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => MultiSelectInputComponent),
    multi: true,
  }, {
    provide: CustomInput,
    useExisting: forwardRef(() => MultiSelectInputComponent),
    multi: true
  }],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MultiSelectInputComponent extends MultiSelect<any> {
  @Input()
  public dataKey: string;
  @Input()
  public optionLabel: string = 'label';
  @Input()
  public optionValue: string = 'value';
  @Output()
  public onFilter: EventEmitter<any> = new EventEmitter();

  /**
   * must be there to refresh the input correctly. see more #2455
   */
  public doChange(): void {
    // does nothing, but has to be there because magic is happening
  }

  /**
   * emit event from primeng and enrich with cb for refresh
   */
  public emitOnFilter(filter: string): void {
    this.onFilter.emit({
      filter, cb: () => {
        const nativeInput = this.nativeInput as any as PrimengMultiSelect;
        nativeInput.activateFilter();
        nativeInput.cd.detectChanges();
      }
    });
  }
}

import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {NG_VALIDATORS, NG_VALUE_ACCESSOR} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {_, CalendarEvent, User} from '@wspsoft/frontend-backend-common';
import * as moment from 'moment';
import {Calendar, LocaleSettings} from 'primeng/calendar';
import {UiUtil} from '../../../util/ui-util';
import {DateConverterService} from '../../converter/date-converter.service';
import {CustomInput} from '../custom-input';
import {languages} from './translations';

interface PrimeNgDate {
  day: number;
  month: number;
  year: number;
}

@Component({
  selector: 'ui-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CalendarComponent),
    multi: true
  }, {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => CalendarComponent),
    multi: true,
  }, {
    provide: CustomInput,
    useExisting: forwardRef(() => CalendarComponent),
    multi: true
  }, DateConverterService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CalendarComponent extends CustomInput<string> implements OnInit, OnChanges {
  @Input()
  public renderInputGroup: boolean = true;
  @Input()
  public placeholder: string = '';
  @Input()
  public showTime: boolean = true;
  @Input()
  public timeOnly: boolean = false;
  @Input()
  public showSeconds: boolean = true;
  @Input()
  public selectionMode: string = 'single';
  @Output()
  public onClose: EventEmitter<void> = new EventEmitter<void>();
  @Output()
  public onDateRangeChange: EventEmitter<{ start: Date; end: Date }> = new EventEmitter();
  public translations: LocaleSettings;
  public user: User;
  @Input()
  public markedDates: CalendarEvent[] = [];
  public disabledDates: Date[] = [];
  public markedDatesCache: { [key: string]: CalendarEvent } = {};
  public firstSelectedDate: Date;
  public defaultDate: Date;

  public constructor(private translateService: TranslateService, cdr: ChangeDetectorRef, public dateConverter: DateConverterService) {
    super(cdr);
    // @ts-ignore
    this.converter = dateConverter;
    // primeng calendar breaks with null values
    this.originalValue = undefined;
    this.originalValueOld = undefined;
  }

  public get viewportMobile(): boolean {
    return UiUtil.isMobile();
  }

  public get value(): any {
    return super.value;
  }

  public set value(value: any) {
    if (value === null && !this.hasFocus) {
      super.value = undefined;
    }
    super.value = value;
  }

  public ngOnInit(): void {
    this.translations = languages[this.translateService.currentLang] || languages.en;
    this.dateConverter.timeOnly = this.timeOnly;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.markedDates && this.markedDates) {
      this.markedDatesCache = {};
      this.disabledDates = [];

      // prepare given calendar events and cache them for faster checks
      for (const markedDate of this.markedDates) {
        if (markedDate.disabled) {
          this.disabledDates.push(moment(markedDate.date).toDate());
        }
        this.markedDatesCache[moment(markedDate.date).format('D.M.YYYY')] = markedDate;
      }
    }
  }

  public isCurrentDate(date: any): boolean {
    // check current value of input
    return moment(this.value).isSame(date, 'day');
  }


  /**
   * Returns the matching markedObject as CalendarEvent for the given Day, if there is one
   * @param date
   * @returns {CalendarEvent}
   */
  public getCalendarEvent(date: PrimeNgDate): CalendarEvent {
    // primeng month count starts with 0
    const month = date.month + 1;
    return this.markedDatesCache[`${date.day}.${month}.${date.year}`];
  }

  public now(): void {
    this.value = moment().toDate();
    if (!this.showTime) {
      this.clearTime();
    }
  }

  /**
   * check on blur if the calendar has a null value
   * and reset own value
   *
   * this allows editing a date manually without clearing the input immediately
   */
  public onCalendarBlur(): void {
    this.validate();
    this.hasFocus = false;
    const calendar = this.nativeInput as any as Calendar;
    if (calendar.value === null) {
      this.value = undefined;
    }
  }

  /**
   * resets time of calendar
   */
  public clearTime(): void {
    const date = moment(this.value);
    date.set('h', 0);
    date.set('m', 0);
    date.set('s', 0);
    date.set('ms', 0);
    this.value = date.toISOString();
  }

  /**
   * calculates the first and last day of the current shown month and executes the marked days script
   */
  public calculateMarkedDates(): void {
    const calendar = this.nativeInput as unknown as Calendar;
    const firstDayOfMonth = moment().set('M', calendar.currentMonth).set('y', calendar.currentYear).startOf('month');
    const lastDayOfMonth = moment(firstDayOfMonth).endOf('month');
    this.onDateRangeChange.emit({start: firstDayOfMonth.toDate(), end: lastDayOfMonth.toDate()});
  }

  /**
   * noop function, just to ensure cdr works
   */
  public changeDetection(): void {
  }

  /**
   * Handle the Click event on an Date of the Calendar
   * @param event
   * @param date The clicked date in form of a DateMeta Object
   */
  public dateClick(event: MouseEvent, date: any): void {
    const calendar = this.nativeInput as unknown as Calendar;
    const dateObj = new Date(date.year, date.month, date.day);

    // if the user clicked on a disabled date return and use primeng default behaviour
    if (this.disabledDates?.includes(dateObj) || calendar.selectionMode !== 'multiple' || this.value?.includes(dateObj)) {
      return;
    }
    if (UiUtil.hasKeyPressedForOs(event, 'Shift') && this.firstSelectedDate) {
      this.addDateRange(dateObj);
      this.firstSelectedDate = null;
      this.cdr.detectChanges();
      return;
    }
    this.firstSelectedDate = dateObj;
  }

  /**
   * Calculates the Dates that should be added to the Value and adds them
   * @param {Date} selectedDateObj: The Date to which the Range should be Calculated
   */
  public addDateRange(selectedDateObj: Date): void {
    let startDate;
    let endDate;

    // Check which of the Date bounds are the start and end Date
    if (selectedDateObj > this.firstSelectedDate) {
      startDate = moment(this.firstSelectedDate).startOf('day');
      endDate = moment(selectedDateObj).endOf('day');
    } else {
      startDate = moment(selectedDateObj).startOf('day');
      endDate = moment(this.firstSelectedDate).endOf('day');
    }
    // Calculate the difference between the Dates and for each day difference add another day to the Value
    const dateDifference = moment.duration(endDate.diff(startDate)).asDays();

    for (let i = 1; i < dateDifference - 1; i++) {
      const dateToAdd = (moment(startDate).add(i, 'day').startOf('day')).toDate();
      if (!_.some(this.disabledDates, r => r.getTime() === dateToAdd.getTime()) && !_.some(this.value, r => r.getTime() === dateToAdd.getTime())) {
        this.value.push(dateToAdd);
      }
    }
  }
}

import {ChangeDetectionStrategy, Component, forwardRef, Input} from '@angular/core';
import {NG_VALIDATORS, NG_VALUE_ACCESSOR} from '@angular/forms';
import {_} from '@wspsoft/frontend-backend-common';
import {Chips} from 'primeng/chips';
import {InputNumber} from 'primeng/inputnumber';
import {CustomInput} from '../custom-input';

@Component({
  selector: 'ui-input-number',
  templateUrl: './input-number.component.html',
  styleUrls: ['./input-number.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => InputNumberComponent),
    multi: true
  }, {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => InputNumberComponent),
    multi: true,
  }, {
    provide: CustomInput,
    useExisting: forwardRef(() => InputNumberComponent),
    multi: true
  }],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputNumberComponent extends CustomInput<number | number[]> {

  @Input()
  public locale: string;
  @Input()
  public renderInputGroup: boolean = true;
  @Input()
  public showButtons: boolean = true;
  @Input()
  public allowEmpty: boolean = true;
  @Input()
  public step: number = 1;
  @Input()
  public buttonLayout: 'stacked' | 'horizontal' | 'vertical' = 'horizontal';
  @Input()
  public max: number;
  @Input()
  public min: number;
  @Input()
  public maxFractionDigits: number;
  @Input()
  public minFractionDigits: number;
  @Input()
  public currency: string;

  public isFilled(): boolean {
    // we cant use the default "filled" function of primeng for input numbers anymore because when using chips(multiple) we have 2 native inputs
    return !_.isNullOrEmpty(this.value);
  }

  public get nativeInputNumber(): InputNumber | Chips {
    return this.nativeInput as any;
  }

  public get value(): number | number[] {
    return this.originalValue;
  }

  public set value(value: any) {
    super.value = value === null ? undefined : value;
  }
}

import {ActivityLogType} from '../activity-log-entry';

export abstract class OneLayoutState {
  public tabStates?: { [key: string]: OneTabState };
  public viewMode?: OneLayoutViewMode;
  public activityFilter?: OneLayoutActivityFilter;
  public zoomLevel?: number;
}

export abstract class OneTabState {
  public tabId: string;
  public tabState: boolean;
}

export enum OneLayoutViewMode {
  ACTIVITY_STREAM, FORM, BOTH
}

export abstract class OneLayoutActivityFilter {
  public types?: ActivityLogType[];
  public queryString?: string;
  public bestMatch?: boolean;
}

import {AfterContentInit, Component, NgZone} from '@angular/core';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {MessageService} from 'primeng/api';
import {AuthService, UserService} from '../../../../api';
import {FormComponent} from '../../../../ui';

@Component({
  selector: 'login-request-password-reset',
  templateUrl: './request-password-reset.component.html',
  styleUrls: ['./request-password-reset.component.scss', '../form.scss']
})
export class RequestPasswordResetComponent extends FormComponent implements AfterContentInit {
  public username: string;

  public constructor(private authService: AuthService, messageService: MessageService, private router: Router,
                     translateService: TranslateService, private zone: NgZone, private userService: UserService) {
    super(messageService, translateService);
    this.hasAsyncValidations = false;
    this.showSuccessMessage = false;
  }

  public ngAfterContentInit(): void {
    // well there is no real done, so trigger some time later...
    const subscription = this.zone.onStable.subscribe(() => {
      subscription.unsubscribe();
      this.focusFirstEmptyInput();
    });
  }

  public async doSave(): Promise<boolean> {
    try {
      await this.userService.requestPasswordReset(this.username);
      // inform the user that the password reset will continue in the email
      this.messageService.add({
        severity: 'success',
        detail: this.translateService.instant('Login.PasswordReset.Success'),
        summary: ''
      });
      return true;
    } catch (e: any) {
      // inform the user that the password reset failed
      this.messageService.add({
        severity: 'error',
        detail: this.translateService.instant('Login.PasswordReset.Error'),
        summary: ''
      });
      return false;
    }
  }

}

import {ActivityLogType} from './activity-log-entry';
import {KolibriEntity} from './kolibri-entity';
import {User} from './user';

export class RecordSubscription extends KolibriEntity {
  public userId?: string;
  public user?: User;
  public recordDataId?: string;
  public expiresOn?: string;
  public type: RecordSubscriptionType;
  public activityType: ActivityLogType;
}

export enum RecordSubscriptionType {
  ENTITY = 'entity', RECORD = 'record', ACTIVITY = 'log'
}

<ui-input-wrapper [customValidationMessage]="customValidationMessage" [dirty]="model.dirty" [disable]="disable" [errors]="model.errors" [hasFocus]="hasFocus"
                  [hasInlineLabel]="true" [helpMessage]="helpMessage" [label]="label" [name]="name" [renderInputGroup]="renderInputGroup" [required]="required"
                  [touched]="model.touched" [valid]="model.valid">
  <ng-container *ngIf="!multiple && imageCropper">
    <ui-image-cropper (onCrop)="cropFile(); showCropper=false" *ngIf="value && showCropper" [(ngModel)]="croppedFile" [image]="originalFile"></ui-image-cropper>

    <figure *ngIf="!showCropper || !value" [ngClass]="{'one-avatar--empty': !value}" class="one-avatar one-avatar--original">
      <img *ngIf="value" class="one-avatar__content" defaultImage="{{environment.serverAdd}}/static/images/digits.gif"
           lazyLoad="{{environment.serverAdd}}/files/{{value.id}}?width=300&height=300&rev={{value._rev}}">

      <div class="one-avatar__overlay">
        <ng-container [ngTemplateOutlet]="uploader"></ng-container>
        <ui-button (clicked)="showCropper = true; $event.cb()" *ngIf="!showCropper && value" class="one-avatar__button one-avatar__button--cropper"
                   icon="fa fa-fw fa-crop" label="{{'FileUpload.Crop' | translate}}" type="icon-only"></ui-button>
      </div>
    </figure>

  </ng-container>

  <ng-container *ngIf="!multiple && !imageCropper">
    <ng-container *ngIf="value?.persisted else fileDescription">
      <a href="{{environment.serverAdd}}/files/{{value.id}}" target="_blank">
        <ng-container [ngTemplateOutlet]="fileDescription"></ng-container>
      </a>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="!imageCropper" [ngTemplateOutlet]="uploader"></ng-container>
</ui-input-wrapper>

<ng-template #fileDescription>
  <i>{{value ? value.fileName : disable ? ('FileUpload.None' | translate) : ''}}</i>
</ng-template>

<ng-template #uploader>
  <p-fileUpload #fu (onRemove)="onRemove(($event).file)" (onSelect)="showMessage(fu.msgs)" (uploadHandler)="uploadFile($event)" [accept]="fileType"
                [auto]="true" [chooseIcon]="chooseIcon" [customUpload]="true" [disabled]="disable"
                [invalidFileLimitMessageSummary]="'FileUpload.LimitExceeded' | translate"
                [invalidFileSizeMessageSummary]="'FileUpload.SizeExceeded' | translate" [invalidFileTypeMessageSummary]="'FileUpload.InvalidType' | translate"
                [maxFileSize]="32000000" [multiple]="multiple" [showDelay]="150" [style]="styleData" chooseLabel="" mode="advanced"
                pTooltip="{{tooltip | translate}}" styleClass="{{disable ? 'p-hidden' : 'one-button one-button--icon-only'}}"
                tooltipPosition="top"></p-fileUpload>
  <ui-button (clicked)="deleteFile($event.cb)" *ngIf="value && !disable" [type]="['icon-only', 'red']" icon="fa fa-fw fa-trash"
             label="{{'FileUpload.Delete' | translate}}"></ui-button>
</ng-template>

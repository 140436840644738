import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {ValidationErrors} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {CustomValidationMessage} from '@wspsoft/frontend-backend-common';
import linkifyStr from 'linkifyjs/string';

@Component({
  selector: 'ui-input-wrapper',
  templateUrl: './input-wrapper.component.html',
  styleUrls: ['./input-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputWrapperComponent {
  @Input()
  public required: boolean = false;
  @Input()
  public renderInputGroup: boolean = true;
  @Input()
  public hasInlineLabel: boolean = false;
  @Input()
  public hasReversedLabelPosition: boolean = false;
  @Input()
  public hasCenteredLabel: boolean = false;
  @Input()
  public hasLabelAlwaysUp: boolean = false;
  @Input()
  public filled: boolean = false;
  @Input()
  public label: string;
  @Input()
  public helpMessage: string;
  @Input()
  public name: string;
  @Input()
  public valid: boolean;
  @Input()
  public dirty: boolean;
  @Input()
  public hasFocus: boolean;
  @Input()
  public customValidationMessage: CustomValidationMessage;
  @Input()
  public disable: boolean;
  @Input()
  public linkify: boolean;
  @Input()
  public linkifyRows: number = 1;
  @Input()
  public editMode: boolean;
  @Output()
  public editModeChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input()
  public touched: boolean;
  @Input()
  public errors: ValidationErrors = {};
  public hovered: boolean = false;
  public linkifyValue: string;

  private isLinkified: boolean;

  public constructor(private translate: TranslateService) {
  }

  private pvalue: any;

  @Input()
  public get value(): any {
    return this.pvalue;
  }

  public set value(value: any) {
    this.pvalue = value;

    if (this.linkify && typeof value === 'string') {
      const linkifyValue1 = linkifyStr(value);
      this.linkifyValue = linkifyValue1.replace(/\n/g, '<br>');
      this.isLinkified = linkifyValue1 !== value;
    }
  }

  public get isSpecialFormat(): boolean {
    return this.linkify && typeof this.value === 'string' && this.isLinkified;
  }

  public get severity(): string {
    if (this.hasFocus && this.helpMessage) {
      return 'info';
    }
    if (this.customValidationMessage?.severity) {
      return this.customValidationMessage.severity;
    }
    return 'error';
  }

  public get hasErrors(): boolean {
    if (!this.errors) {
      return false;
    }
    return !!Object.keys(this.errors).length;
  }

  public get showMessage(): boolean {
    return this.hasHelpMessage || this.isDirty && this.hasAnyError && this.hasSpecificError || !!this.customValidationMessage;
  }

  public get hasHelpMessage(): boolean {
    return !!(this.hasFocus && this.helpMessage);
  }

  public get hasSpecificError(): boolean {
    return this.errors.required || this.errors.minlength || this.errors.maxlength;
  }

  public get hasAnyError(): boolean {
    return !this.valid && !!this.errors;
  }

  public get isDirty(): boolean {
    return this.dirty || this.touched;
  }

  public get message(): string {
    if (this.hasFocus && this.helpMessage) {
      return this.translate.instant(this.helpMessage);
    }

    if (this.customValidationMessage?.message) {
      return this.customValidationMessage.message;
    }

    if (this.hasErrors) {
      if (this.errors.required) {
        return this.translate.instant('Form.Required');
      }
      if (this.errors.minlength) {
        return this.translate.instant('Form.MinLength', {requiredLength: this.errors.minlength.requiredLength});
      }
      if (this.errors.maxlength) {
        return this.translate.instant('Form.MaxLength',
          {requiredLength: this.errors.maxlength.requiredLength});
      }
    }
    return '';
  }
}

import {KolibriEntity} from './kolibri-entity';
import {User} from './user';
import {UserGroup} from './user-group';

export abstract class GroupMembership extends KolibriEntity {
  public user?: User;
  public userId: string;
  public usergroup?: UserGroup;
  public usergroupId: string;
}

export class MultiLevelEntry {
  public data: any;
  public hasSublevel: boolean;
  public sublevelActive: boolean;
  public sublevelData: any[];
  public sublevelDataLoader: () => Promise<any[]>;


  public constructor(data: any, hasSublevel: boolean, sublevelDataLoader: () => Promise<any[]>) {
    this.data = data;
    this.hasSublevel = hasSublevel;
    this.sublevelDataLoader = sublevelDataLoader;
  }
}

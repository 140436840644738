<ui-input-wrapper [(editMode)]="editMode" [customValidationMessage]="customValidationMessage" [dirty]="inputElement.dirty" [disable]="disable"
                  [errors]="inputElement.errors" [hasLabelAlwaysUp]="true" [label]="label" [linkify]="false" [name]="name" [renderInputGroup]="false"
                  [required]="required" [touched]="inputElement.touched" [valid]="inputElement.valid">
  <p-editor #inputElement="ngModel" #nativeInput (keydown)="autoCompleteMention($event)" (onInit)="onInit($event.editor)" [(ngModel)]="value"
            [class]="styleClass" [modules]="modules" [ngModelOptions]="{updateOn: updateOn}" [required]="required"
            class="one-editor one-editor--lang-{{this.translate.currentLang}}" placeholder="{{helpMessage || 'Editor.Placeholder' | translate}}">
    <p-header>
      <ng-container *ngIf="header">
      <span class="one-editor__header">
        <ng-container *ngTemplateOutlet="header"></ng-container>
      </span>
      </ng-container>

      <ng-container *ngIf="subheader">
        <span class="one-editor__subheader">
          <ng-container *ngTemplateOutlet="subheader"></ng-container>
        </span>
      </ng-container>

      <span class="one-editor__buttons">
        <span *ngIf="!compact" class="ql-formats">
          <div pTooltip="{{'Editor.Toolbar.Headings.Value' | translate}}" tooltipPosition="top">
            <select class="ql-header">
              <option value="1">{{'Editor.Toolbar.Headings.H1' | translate}}</option>
              <option value="2">{{'Editor.Toolbar.Headings.H2' | translate}}</option>
              <option selected>{{'Editor.Toolbar.Headings.normal' | translate}}</option>
            </select>
          </div>
          <div pTooltip="{{'Editor.Toolbar.Fonts' | translate}}" tooltipPosition="top">
            <select class="ql-font" pTooltip="{{'Editor.Toolbar.Underline' | translate}}" tooltipPosition="top">
              <option selected>Sans Serif</option>
              <option value="serif">Serif</option>
              <option value="monospace">Monospace</option>
            </select>
          </div>
        </span>
        <span class="ql-formats">
          <button aria-label="Bold" class="ql-bold" type="button" pTooltip="{{'Editor.Toolbar.Bold' | translate}}" tooltipPosition="top"></button>
          <button aria-label="Italic" class="ql-italic" type="button" pTooltip="{{'Editor.Toolbar.Italic' | translate}}" tooltipPosition="top"></button>
          <button aria-label="Underline" class="ql-underline" type="button" pTooltip="{{'Editor.Toolbar.Underline' | translate}}"
                  tooltipPosition="top"></button>
        </span>
        <span class="ql-formats">
          <div pTooltip="{{'Editor.Toolbar.FontColor' | translate}}" tooltipPosition="top">
            <select class="ql-color"></select>
          </div>
          <div pTooltip="{{'Editor.Toolbar.FontBackground' | translate}}" tooltipPosition="top">
            <select class="ql-background"></select>
          </div>
        </span>
        <span *ngIf="!compact" class="ql-formats">
          <button aria-label="Ordered List" class="ql-list" type="button" value="ordered" pTooltip="{{'Editor.Toolbar.OrderedList' | translate}}"
                  tooltipPosition="top"></button>
          <button aria-label="Unordered List" class="ql-list" type="button" value="bullet" pTooltip="{{'Editor.Toolbar.UnorderedList' | translate}}"
                  tooltipPosition="top"></button>
          <div pTooltip="{{'Editor.Toolbar.TextAlignment' | translate}}" tooltipPosition="top">
            <select class="ql-align">
              <option selected></option>
              <option value="center"></option>
              <option value="right"></option>
              <option value="justify"></option>
            </select>
          </div>
        </span>
        <span class="ql-formats">
          <button aria-label="Insert Link" class="ql-link" type="button" pTooltip="{{'Editor.Toolbar.Link' | translate}}" tooltipPosition="top"></button>
          <button aria-label="Insert Image" class="ql-image" type="button" pTooltip="{{'Editor.Toolbar.Image' | translate}}" tooltipPosition="top"></button>
          <button aria-label="Insert Code Block" class="ql-code-block" type="button" pTooltip="{{'Editor.Toolbar.Code' | translate}}"
                  tooltipPosition="top"></button>
        </span>
        <span *ngIf="!compact" class="ql-formats">
          <button aria-label="Remove Styles" class="ql-clean" type="button" pTooltip="{{'Editor.Toolbar.CleanStyles' | translate}}"
                  tooltipPosition="top"></button>
        </span>
        <ng-container *ngIf="buttons && !compact">
          <span class="ql-formats">
            <ng-container *ngTemplateOutlet="buttons"></ng-container>
          </span>
        </ng-container>
        </span>
    </p-header>
  </p-editor>
</ui-input-wrapper>

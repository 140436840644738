import {
  Application,
  ApplicationModel,
  Attribute,
  Choice,
  ChoiceValue,
  DisplayTransformation,
  Entity,
  EntityModel,
  Field,
  FieldResponse,
  FullCalendarEvent,
  FullCalendarResource,
  GuidedTour,
  GuidedTourStep,
  Layout,
  Relation,
  Script,
  Type,
  View,
  VirtualCollection,
  Wizard,
  WizardSection
} from '../..';

export interface AbstractModelService {
  applications: { [key: string]: ApplicationModel };

  init(): Promise<void> | void;

  updateApplication(modelXml: any): Application;

  getClientData(): Promise<ApplicationModel[]>;

  getApplications(name?: string): ApplicationModel[];

  getApplication(application: string): ApplicationModel;

  getTypes(name?: string): Type[];

  getType(type: string): Type;

  getFullCalendarResource(idOrName: string): FullCalendarResource;

  getFullCalendarResources(idOrName?: string): FullCalendarResource[];

  getFullCalendarEvent(idOrName: string): FullCalendarEvent;

  getFullCalendarEvents(idOrName?: string): FullCalendarEvent[];

  getWizard(nameOrId: string): Wizard;

  getWizardSection(nameOrId: string): WizardSection;

  getVirtualCollection(nameOrId: string): VirtualCollection;

  getView(nameOrId: string): View;

  getTypeName(field: Field): string;

  getChoice(name: string): Choice;

  getChoiceValue(choiceName: string, choiceValue: string): ChoiceValue;

  getEntities(name?: string): EntityModel[];

  getEntitiesLocalized(name?: string): Promise<Entity[]>;

  getEntity(entity: string): EntityModel;

  getEntityByType(relation1: Relation): EntityModel;

  getScriptTriggers(entity: string): Script[];

  getLayout(nameOrUrl: string, entity?: string): Layout;

  getRelations(entity: string): Relation[];

  getRelation(entity: string, relationId: string): Relation;

  getAttributes(entity: string): Attribute[];

  getFieldInfo(entityIdOrName: string, fieldIdOrName: string): { field: Field; entity: EntityModel; own: boolean };

  getFields(entity: string, name?: string, substring?: boolean): FieldResponse;

  getFieldsLocalized(entity: string, name?: string): Promise<any>;

  getField(entity: string, nameWithDotWalk: string): Field;

  iterateObject(hostEntityMeta: Entity, currentPath: string, path: string[],
                fn: (field: Field, entityMeta: Entity, last: boolean, column?: string) => void): void;

  findDescendants(entity: EntityModel): EntityModel[];

  getGlobalScripts(type?: string): Script[];

  getFieldType(field: Field): Type;

  getDisplayTransformations(idOrName?: string): DisplayTransformation[];

  getDisplayTransformation(displayTransformation?: string): DisplayTransformation;

  getGuidedTours(idOrName?: string): GuidedTour[];

  getGuidedTour(idOrName: string): GuidedTour;

  getGuidedTourSteps(idOrName?: string): GuidedTourStep[];

  getGuidedTourStep(idOrName?: string): GuidedTourStep;
}

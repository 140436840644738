import {CriteriaQueryJson} from '../../../criteria/json/criteria-query-json';
import {KolibriEntity} from '../../../model/database/kolibri-entity';
import {Attribute} from '../../../model/xml/attribute';
import {_, MaybePromise} from '../../../util/underscore';
import {EvalAttributeType, Utility} from '../../../util/utility';
import {EntityWriteOptions} from '../../generated/service-options';
import {TransientHandler} from './transient-handler';

export abstract class ApiHandler<T extends KolibriEntity> extends TransientHandler<T> {
  public static readonly _REV_DELETED_PERSISTED_LOCALEXPORTS: string[] = ['_rev', 'deleted', 'persisted', 'localExports'];
  public _recordOld: T;

  public constructor(record: T, public _enhancer: any, createRecordOld: boolean) {
    super(record);
    if (createRecordOld) {
      this.recordOld = record;
    }
  }

  public get recordOld(): T {
    this._recordOld = this._enhancer.firstLevelEnhancing(this._recordOld, false);
    return this._recordOld;
  }

  public set recordOld(entity: T) {
    this._recordOld = _.cloneDeep(entity instanceof ApiHandler ? (entity as ApiHandler<T>).record : entity);
  }

  public get dirty(): boolean {
    return !_.isEqualWith(this.record, this.recordOld.record, (o1, o2, key) => {
      // skip these, they are not relevant, internal or relation cache fields
      if (key && (ApiHandler._REV_DELETED_PERSISTED_LOCALEXPORTS.includes(key) || key.endsWith('Cached'))) {
        return true;
      }

      // in case someone typed and deleted it (input sends undefined)
      if (o1 === null && o2 === undefined || o1 === undefined && o2 === null) {
        return true;
      }
    });
  }

  public get differences(): { field: string; newValue: any; oldValue: any }[] {
    return Utility.differences(this.record, this.recordOld.record);
  }

  public clone(): T {
    return this._enhancer.firstLevelEnhancing(_.cloneDeep(this.record));
  }

  public copy(): T {
    const copyEntity = this.clone();
    copyEntity.id = null;
    copyEntity._key = null;
    copyEntity._rev = null;
    copyEntity._id = null;
    copyEntity.persisted = false;
    return copyEntity;
  }

  public merge(entity: T): void {
    const lp = this.record.localExports;
    const entityClass = this.record.entityClass;
    _.assign(this, entity instanceof ApiHandler ? (entity as ApiHandler<T>).record : entity);
    _.assign(lp, this.record.localExports);
    this.record.entityClass = entityClass;
    this.record.localExports = lp;
  }

  public async insert(options?: EntityWriteOptions): Promise<this> {
    this.merge(await this._enhancer.entityService(this.record).createEntity(this.record, options));
    this.clearCache();
    return this;
  }

  public async update(options?: EntityWriteOptions): Promise<this> {
    this.merge(await this._enhancer.entityService(this.record).updateEntity(this.record, options));
    this.clearCache();
    return this;
  }

  public delete(options?: EntityWriteOptions, hard: boolean = false): Promise<boolean> {
    return this._enhancer.entityService(this.record).deleteEntity(this.record.id, options, hard);
  }

  public matches(query: CriteriaQueryJson): MaybePromise<boolean> {
    return this._enhancer.scriptExecutor.runScript(this._enhancer.criteriaCompiler.compile(query) || 'true',
      {record: this, user: this._enhancer.user}, undefined, `KolibriEntity:${this.record.representativeString}:matches`).result;
  }

  public changes(field: string): boolean {
    const {newValue, oldValue} = this.getNewAndOldFieldValues(field);
    return !_.isEqual(newValue, oldValue);
  }

  /**
   * returns whether the field changes from the given value or not
   * @param field the field to check
   * @param value the value to check
   */
  public changesFrom(field: string, value: any): boolean {
    const {newValue, oldValue} = this.getNewAndOldFieldValues(field);
    let chgFrom = !_.isEqual(newValue, value) && _.isEqual(oldValue, value);
    if (this.isMultipleAttribute(field)) {
      chgFrom = _.every(newValue, nvx => !_.isEqual(nvx, value)) && _.some(oldValue, ovx => _.isEqual(ovx, value));
    }
    return chgFrom;
  }

  /**
   * returns whether the field changes to the given value or not
   * @param field the field to check
   * @param value the value to check
   */
  public changesTo(field: string, value: any): boolean {
    const {newValue, oldValue} = this.getNewAndOldFieldValues(field);
    let chgTo = _.isEqual(newValue, value) && !_.isEqual(oldValue, value);
    if (this.isMultipleAttribute(field)) {
      chgTo = _.some(newValue, nvx => _.isEqual(nvx, value)) && _.every(oldValue, ovx => !_.isEqual(ovx, value));
    }
    return chgTo;
  }

  public async refresh(viewId?: string): Promise<this> {
    if (!this.record.persisted) {
      return this;
    }

    const dbState = await this._enhancer.entityService(this.record).getEntityById(this.record.id, viewId);
    this.merge(dbState.record);
    this._recordOld = dbState.recordOld;
    return this;
  }

  public stringify(sql: boolean = false): this {
    // stringify all attributes
    for (const attribute of this._enhancer.getMeta(this.record).allAttributes.filter(a => !a.computed)) {
      this.record[attribute.name] = Utility.evalAttributeType(this._enhancer.modelService, attribute, EvalAttributeType.stringify,
        this._enhancer.scriptExecutor,
        this.record, sql);
    }
    return this;
  }

  public parse(sql: boolean = false): this {
    // parse all attributes
    for (const attribute of this._enhancer.getMeta(this.record).allAttributes.filter(a => !a.computed)) {
      this.record[attribute.name] = Utility.evalAttributeType(this._enhancer.modelService, attribute, EvalAttributeType.parse, this._enhancer.scriptExecutor,
        this.record, sql);
    }
    return this;
  }

  public abstract clearCache(relationName?: string): void;

  private getNewAndOldFieldValues(field: string): { newValue: any; oldValue: any } {
    const meta = this._enhancer.getMeta(this.record);
    const toOne: boolean = Utility.isToOneRelation(this._enhancer.modelService.getField(meta.name, field));
    const newValue: any = toOne ? this[Utility.parameterizeEntityName(field)] : this[field];
    const oldValue: any = toOne ? this.recordOld[Utility.parameterizeEntityName(field)] : this.recordOld[field];
    return {newValue, oldValue};
  }

  /**
   * returns whether the field is a multiple attribute or not
   * @param field the field to check
   * @private
   */
  private isMultipleAttribute(field: string): boolean {
    const meta = this._enhancer.getMeta(this.record);
    const fieldObj = this._enhancer.modelService.getField(meta.name, field);
    return Utility.isAttribute(fieldObj) && (fieldObj as Attribute).multiple;
  }
}

import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {Utility} from '@wspsoft/frontend-backend-common';
import * as Chroma from 'chroma-js';
import {TypeService} from '../../../../api';
import {environment} from '../../../../config/environments/environment';

@Component({
  selector: 'ui-rep-image',
  templateUrl: './rep-image.component.html',
  styleUrls: ['./rep-image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RepImageComponent implements OnInit {
  public environment: typeof environment = environment;
  @Input()
  public data: any;
  @Input()
  public field: string = 'repImageId';
  @Input()
  public icon: string = 'fa-image';
  @Input()
  public margin: boolean = true;
  @Input()
  public showStatus: boolean = false;
  @Input()
  public typing: boolean = false;
  @Input()
  public away: boolean = false;
  @Input()
  public idle: boolean = false;
  @Input()
  public big: boolean = false;
  @Input()
  public dimension: string = '40';
  @Input()
  public lazy: boolean = true;
  public imageId: string;
  public srcUrl: string;
  public foregroundColor: string;

  public constructor(public typeUtility: TypeService) {
  }

  public ngOnInit(): void {
    if (!this.data.color) {
      this.foregroundColor = 'currentColor';
    } else {
      const chroma = Chroma(this.data.color);
      const lum = chroma.luminance();
      this.foregroundColor = chroma.luminance(lum > 0.5 ? lum - 0.5 : lum + 0.5).hex(); // reduce or raise luminance by 50%
    }

    Utility.doDotWalk(this.data, Utility.parameterizeEntityName(this.field), v => {
      if (v) {
        this.imageId = v;
        this.srcUrl = `${environment.serverAdd}/files/${v}?width=${this.dimension}&height=${this.dimension}&placeholder=image`;
      }
    });
  }
}

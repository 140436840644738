import {EventEmitter, Injectable, NgZone} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {_, DynamicContent, DynamicContentType} from '@wspsoft/frontend-backend-common';
import {StructureService} from './structure.service';

@Injectable()
export class DynamicContentService {
  // for script api
  public type: typeof DynamicContentType = DynamicContentType;
  public dynamicContent: { [key: string]: DynamicContent } = {};
  public pageRightElements: DynamicContent[] = [];
  public dialogElements: DynamicContent[] = [];
  public wizardElements: DynamicContent[] = [];
  public onRefresh: EventEmitter<{ type?: DynamicContentType; index: number }> = new EventEmitter();

  public constructor(private router: Router, public structureService: StructureService, private zone: NgZone) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.clearNonPersistent();
      }
    });
  }

  /**
   * adds content with specific key
   *
   * @param type - the content type
   * @param key - the content key
   * @param layoutName - the layout name
   * @param options - the content options
   */
  public add(type: DynamicContentType, key: string, layoutName: string, options: Partial<DynamicContent> = {}): void {
    this.dynamicContent[key] = {
      type, layoutName, key, ...options
    };

    this.refresh({type, index: Object.keys(this.dynamicContent).indexOf(key)});
  }

  /**
   * updates content of specific key
   *
   * @param key - the content key
   * @param data - the content
   */
  public update(key: string, data?: any): void {
    if (this.exists(key)) {
      const content = this.dynamicContent[key];
      if (data) {
        content.data = data;
      }
      this.add(content.type, content.key, content.layoutName, content);
    }
  }

  /**
   * checks if specific key exists
   *
   * @param key - the content key
   */
  public exists(key: string): boolean {
    return key in this.dynamicContent;
  }

  /**
   * removes content of specific key
   *
   * @param key - the content key
   */
  public remove(key: string): void {
    if (this.exists(key)) {
      const type = this.dynamicContent[key].type;
      delete this.dynamicContent[key];
      this.refresh({type, index: -1});
    }
  }

  /**
   * remove all entries with given type
   *
   * @param type - the content type
   */
  public removeAll(type: DynamicContentType): void {
    this.dynamicContent = _.pickBy(this.dynamicContent, content => content.type !== type);
    this.refresh({index: 0});
  }

  /**
   * remove all non persistent entries
   */
  public clearNonPersistent(): void {
    for (const content of Object.values(this.dynamicContent)) {
      if (!content.persistent) {
        delete this.dynamicContent[content.key];
      }
    }
    // we do not now if the current component was removed, so decide self
    this.refresh({index: -1});
  }

  /**
   * refresh a given event of type and index
   *
   * @param event - the event
   */
  private refresh(event: { type?: DynamicContentType; index: number }): void {
    // no sort it into correct arrays for the components
    this.pageRightElements = [];
    this.wizardElements = [];
    this.dialogElements = [];
    const values = Object.values(this.dynamicContent);
    for (const content of values) {
      switch (content.type) {
        case DynamicContentType.PAGE_RIGHT:
          this.pageRightElements.push(content);
          break;
        case DynamicContentType.WIZARD:
          this.wizardElements.push(content);
          break;
        case DynamicContentType.DIALOG:
          this.dialogElements.push(content);
          break;
        default:
          break;
      }
    }

    this.zone.run(() => {
      this.onRefresh.emit(event);
    });
  }
}

import {Layout} from './layout';


export abstract class LayoutWebsite extends Layout {
  public content?: any;
  public contentType?: WebsiteContentType;
  public editPermissionId?: string;
  public childRelationId?: string;
  public parentRelationId?: string;
  public publik?: boolean;
}

export enum WebsiteContentType {
  html = 'html', markdown = 'markdown'
}

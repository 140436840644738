import {Button} from './button';
import {DesignerEntity} from './designer.entity';
import {Entity} from './entity';
import {FullCalendarEvent} from './full-calendar-event';
import {FullCalendarResourceToButton} from './full-calendar-resource-to-button';
import {FullCalendarResourceToEvent} from './full-calendar-resource-to-event';
import {Relation} from './relation';


export abstract class FullCalendarResource extends DesignerEntity {
  public entityId?: string;
  public parentFieldId?: string;
  public filterScript?: string;
  public sorting?: any;
  public resourceToButtons?: FullCalendarResourceToButton[];
  public resourcesToEvents?: FullCalendarResourceToEvent[];

  public entity?: Entity;
  public parentField?: Relation;

  // internal
  public events: FullCalendarEvent[];
  public buttons: Button[];

}

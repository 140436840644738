import {KolibriEntity} from './kolibri-entity';

export abstract class ApplicationDumpData extends KolibriEntity {
  public recordClass?: string;
  public recordId?: string;
  public recordRepString?: string;
  public recordData?: KolibriEntity;
  public currentData?: KolibriEntity;
  public conflict: boolean;
}

import {
  Application,
  Button,
  EntityModel,
  GraphNodeTemplate,
  GuidedTour,
  GuidedTourStep,
  KanbanColumn,
  Layout,
  LayoutGraph,
  LayoutSection,
  Wizard,
  WizardSection
} from '..';

import {Field} from '../model/response/field';

import {Choice, ChoiceValue, Entity, MenuItem} from '../model/xml/models';

export abstract class BundleKeyGenerator {
  public static modelService: any;
  public static readonly CHOICE_PREFIX: string = 'Choice';
  public static readonly TRANSLATION_KEY: string = 'Value';
  private static readonly SEP: string = '.';

  public static choiceValueToKey(choice: Choice, value: ChoiceValue): string {
    if (choice.name === 'EntityName') {
      return this.entityToKey({name: value.value});
    }

    return this.concat(this.CHOICE_PREFIX, choice, value.value);
  }

  public static menuItemToKey(item: MenuItem): string {
    return this.concat('MenuItem', item.name);
  }

  public static wizardToKey(wizard: Wizard): string {
    return this.concat(wizard);
  }

  public static tourToKey(tour: GuidedTour): string {
    return this.concat('GuidedTour', tour);
  }

  public static stepToKey(step: GuidedTourStep): string {
    return this.concat('GuidedTourStep', step);
  }

  public static wizardSectionToKey(wizard: Wizard, wizardSection: WizardSection): string {
    return this.concat(wizard, wizardSection);
  }

  public static wizardSectionDescriptionToKey(wizard: Wizard, wizardSection: WizardSection): string {
    return this.concat(wizard, wizardSection, wizardSection.description);
  }

  /**
   * convert entity field to i18n key
   * @param {Field} field
   * @param {EntityModel} entity
   * @returns {string} *entity*.*field*.value
   */
  public static fieldToKey(field: Field, entity: EntityModel): string {
    const fieldInfo = this.modelService.getFieldInfo(entity.id, field.id);

    if (!fieldInfo) {
      console.warn(`Field ${field.name} with id ${field.id} not found or may be corrupted on entity ${entity.name}`);
    }

    // field is not from the current entity, it may be from its ancestors
    if (fieldInfo && !fieldInfo.own) {
      return this.fieldToKey(field, entity.ancestor);
    }

    return this.concat(entity, field);
  }

  public static kanbanColumnToKey(entity: Entity, column: KanbanColumn): string {
    return this.concat(entity, column);
  }

  public static areaToKey(entity: Entity, area: LayoutSection): string {
    return this.concat(entity, area);
  }

  public static buttonToKey(entityMeta: Entity | Application, button: Button): string {
    return this.concat(entityMeta, button);
  }

  public static entityToKey(entityMeta: Entity): string {
    return this.concat(entityMeta.name);
  }

  public static layoutToKey(entity: Entity, layout: Layout): string {
    return this.concat(entity, layout);
  }

  public static nodeTemplateToKey(entity: Entity, layout: LayoutGraph, node: GraphNodeTemplate): string {
    return this.concat(entity, layout, node);
  }

  private static concat(...args: any[]): string {
    let result = args[0].name ? args[0].name : args[0];

    for (let i = 1; i < args.length; i++) {
      const s = args[i];
      result += this.SEP + (s?.name ? s.name : s);
    }
    return result + this.SEP + this.TRANSLATION_KEY;
  }
}

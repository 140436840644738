<p-tree (onNodeCollapse)="treeValues = values" (onNodeDrop)="hackNodeDrop($event)" (onNodeExpand)="onNodeExpand($event.node)" (onNodeSelect)="viewOnClick($event.originalEvent,
        $event.node.data)" [(selection)]="selection" [contextMenu]="cm" [draggableNodes]="hasEditAction" [droppableNodes]="hasEditAction" [filter]="false"
        [ngClass]="{'one-tree--url': treeNodeType === 'url',
        'one-tree--list': treeNodeType !== 'url','one-tree--draggable' : hasEditAction,'one-tree--has-data' : !!values.length,'one-tree--hidden' : !values.length && treeNodeType === 'url'}"
        [selectionMode]="treeSelectionMode" [value]="values" class="one-tree" draggableScope="self" droppableScope="self" filterPlaceholder="Filter">
  <ng-template pTemplate="header">
    <header *ngIf="treeNodeType !== 'url'" class="one-tree__header">
      <h2 *ngIf="title" class="one-tree__title">{{title}}</h2>
      <ng-container *ngIf="header" [ngTemplateOutlet]="header"></ng-container>

      <div class="one-tree__tools">
        <ng-container *ngIf="tools" [ngTemplateOutlet]="tools"></ng-container>
        <ng-container *ngTemplateOutlet="toolbarButton ; context: {table: this}"></ng-container>
        <ui-guided-tour-step reservedStepName="resDataViewGlobalSearchStep">
          <ui-search-input (search)="loadPage({globalFilter: $event, first: 0})" *ngIf="globalSearch" name="search"></ui-search-input>
        </ui-guided-tour-step>

        <ui-button (clicked)="list.create($event.cb)" *ngIf="hasCreateAction" icon="fas fa-fw fa-plus" label="{{'List.Actions.Create' | translate}}"
                   type="icon-only"></ui-button>
      </div>
    </header>
    <div *ngIf="subHeader" class="one-tree__subheader">
      <ng-container [ngTemplateOutlet]="subHeader"></ng-container>
    </div>
  </ng-template>

  <ng-template let-node pTemplate="url">
    <span [ngClass]="{'one-tree__url--folder': !node.leaf}" class="one-tree__url">{{node.label}}</span>
  </ng-template>

  <ng-template let-node pTemplate="default">
    <div class="one-tree__node-content">
      <span class="one-tree__node-name">{{node.label}}</span>
      <div (click)="$event.stopPropagation()" *ngIf="rowActionTemplate && node.data">
        <ng-container *ngTemplateOutlet="rowActionTemplate; context: {$implicit: node, data: node.data}"></ng-container>
        <ui-button (clicked)="delete(node.data, $event.cb)" *ngIf="hasDeleteAction" [type]="['icon-only', 'red']" icon="fas fa-fw fa-trash"
                   label="{{'List.Actions.Delete' | translate}}"></ui-button>
      </div>
    </div>
  </ng-template>

  <ng-template pTemplate="empty">
    <div class="p-treenode-content">{{'AutoComplete.NoResults' | translate}}</div>
  </ng-template>

  <ng-template pTemplate="footer">
    <p-paginator (onPageChange)="loadPage($event)" *ngIf="totalRecords > 0" [alwaysShow]="false" [first]="tableState.first" [pageLinkSize]="7"
                 [rowsPerPageOptions]="rowsPerPageOptions" [rows]="tableState.rows ?? rows" [showCurrentPageReport]="true" [showFirstLastIcon]="true"
                 [showPageLinks]="true" [templateLeft]="paginatorleft" [templateRight]="paginatorright" [totalRecords]="exactTotalRecords ?? totalRecords"
                 currentPageReportTemplate="{{(!exactTotalRecords ? 'List.EstimatedPaginatorText' : 'List.PaginatorText') | translate}}"
                 dropdownAppendTo="body"></p-paginator>
  </ng-template>

  <ng-template #paginatorleft>
    <ui-button (clicked)="count()" [spinner]="false" icon="fa fa-fw fa-calculator" label="{{'List.Count' | translate}}" type="icon-only"></ui-button>
  </ng-template>

  <ng-template #paginatorright>
    <ui-button (clicked)="refresh()" [spinner]="false" icon="fa fa-fw fa-sync" label="{{'List.Refresh' | translate}}" type="icon-only"></ui-button>
  </ng-template>
</p-tree>

<p-contextMenu #cm [model]="contextMenu" appendTo="body"></p-contextMenu>

import {Clipboard} from '@angular/cdk/clipboard';
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Message, MessageService} from 'primeng/api';
import {OverlayPanel} from 'primeng/overlaypanel';
import {SettingsService} from '../../../../../api';

interface MessageObject {
  detail: string;
  summary: string;
  expanded: boolean;
  hasDetails: boolean;
}

@Component({
  selector: 'ui-tray-logger',
  templateUrl: './tray-logger.component.html',
  styleUrls: ['./tray-logger.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TrayLoggerComponent implements OnInit {

  public messages: MessageObject[] = [];
  @ViewChild(OverlayPanel)
  private overlayPanel: OverlayPanel;
  public supportMail: string;

  public constructor(public clipboard: Clipboard, public messageService: MessageService,
                     public cdr: ChangeDetectorRef, public settingsService: SettingsService, public translate: TranslateService) {

  }

  public ngOnInit(): void {
    this.messageService.messageObserver.subscribe(x => this.addMessage(x as Message & { severity: string }));
    this.supportMail = this.settingsService.platformSettings.supportMail;
  }

  public addMessage(message: Message & { severity: string } | Message & { severity: string }[]): void {
    if (message.key && message.key !== 'tray') {
      return;
    }

    const summarySubstr = message.summary?.length > 40 ? message.summary.substring(0, 40) + '...' : message.summary;
    // in some cases there is no summary so we just use the details as backup
    const detailSubstr = message.detail?.length > 40 ? message.detail.substring(0, 40) + '...' : message.detail;
    switch (message.severity) {
      case 'error':
        this.messages.push({
          detail: message.detail,
          summary: summarySubstr ?? detailSubstr,
          hasDetails: !!message.detail,
          expanded: false,
        });
        this.cdr.detectChanges();
        break;
      case 'success':
      case 'info':
      case 'warn':
        this.messageService.add({...message, key: 'growl'});
        break;
    }
  }

  public clearLogs(): void {
    this.overlayPanel.hide();
    this.messages.length = 0;
    this.cdr.detectChanges();
  }

  public copyToClipboard(message: string): void {
    this.clipboard.copy(message);
    this.messageService.add({
      severity: 'info',
      detail: this.translate.instant('Clipboard.Copied'),
      key: 'growl'
    });
    this.overlayPanel.hide();
  }

  public expandMessage(message: MessageObject): void {
    message.expanded = !message.expanded;
  }
}

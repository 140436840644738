import {ListLayout} from './list-layout';

export class LayoutDataView extends ListLayout {
  public gridDataViewTemplate: any;
  public rowDataViewTemplate: any;
}

export enum DataViewType {
  cards = 'cards', rows = 'rows', both = 'both'
}

import {Attachment} from '../../model/database/attachment';

export interface AbstractModelDeploymentService {
  /**
   * check code ownership for application
   */
  isCodeOwner(applicationIdOrName: string, workspace?: string): Promise<boolean> | boolean;

  /**
   * Dumps every application in arango into separate folders
   * For each entityType it creates one json File
   */
  dump(g, entities?: string[]): Promise<void>;

  /**
   * Dumps a specific application in arango
   * For each entityType it creates one json File
   */
  dumpApplication(workspace: string, application: string, entities?: string[]): Promise<void>;

  /**
   * zip the app based on the application directory
   */
  bundleApplication(workspace: string, application: string): Promise<Attachment>;
}

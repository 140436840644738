import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import * as moment from 'moment';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {PrimeNGConfig} from 'primeng/api';
import {SettingsService} from '../../api';

@Component({
  selector: 'login-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  public ready: boolean;

  public constructor(private primengConfig: PrimeNGConfig, private loader: NgxUiLoaderService, private settingsService: SettingsService,
                     private translateService: TranslateService) {
  }

  public ngOnInit(): void {
    this.primengConfig.ripple = true;
    this.settingsService.loadPlatformSettings();

    const lang = navigator.language.split('-')[0];
    this.translateService.setDefaultLang('en');
    this.translateService.use(lang);

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    moment.locale(lang);
    this.ready = true;
  }
}

import {ChangeDetectionStrategy, Component, forwardRef, Input} from '@angular/core';
import {NG_VALIDATORS, NG_VALUE_ACCESSOR} from '@angular/forms';
import {SelectItem} from 'primeng/api';
import {ButtonSize, ButtonType} from '../../../entities/button-entities';
import {CustomInput} from '../custom-input';

@Component({
  selector: 'ui-select-button',
  templateUrl: './select-button.component.html',
  styleUrls: ['./select-button.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => SelectButtonComponent),
    multi: true
  }, {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => SelectButtonComponent),
    multi: true,
  }, {
    provide: CustomInput,
    useExisting: forwardRef(() => SelectButtonComponent),
    multi: true
  }],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectButtonComponent extends CustomInput<number> {
  @Input()
  public options: SelectItem[];
  @Input()
  public type: ButtonType = ButtonType.FORM;
  @Input()
  public size: ButtonSize = ButtonSize.SMALL;
  @Input()
  public dataKey: string;
  @Input()
  public orientation: string = 'horizontal';
  @Input()
  public clearable: boolean = true;
}


import {Directive, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {MessageService} from 'primeng/api';

import {SubFormComponent} from './sub-form-component';

/**
 * abstract class for all components that utilize a ng form
 */
// noinspection AngularMissingOrInvalidDeclarationInModule
@Directive()
// tslint:disable-next-line:directive-class-suffix
export abstract class FormComponent extends SubFormComponent {
  @ViewChild('form', {static: false})
  public form: NgForm;
  protected showSuccessMessage: boolean = true;
  protected entityName: string = 'Form.Record';
  protected recordName: string;

  protected constructor(messageService: MessageService, translateService: TranslateService) {
    super(messageService, translateService);
  }

  public async save(cb?: () => void, validate: boolean = true): Promise<boolean> {
    const success = await this.checkForm(validate);

    this.addSuccessMessage(success);

    cb?.();

    return success;
  }

  public async checkForm(save: boolean = true, validate: boolean = true): Promise<boolean> {
    // if valid do actual save
    if (!validate || await this.validate()) {
      if (save) {
        return this.doSave();
      }
      return true;
    }
    return false;
  }

  public abstract doSave(): Promise<boolean>;

  public addSuccessMessage(success: boolean): void {
    if (success && this.showSuccessMessage) {
      this.messageService.add({
        severity: 'success',
        summary: '',
        detail: this.translateService.instant('Form.SaveMessage',
          {entityName: this.translateService.instant(this.entityName), recordName: this.recordName || ''})
      });

      this.markAsUntouched();
    }
  }
}

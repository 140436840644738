import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {MenuItem} from 'primeng/api';
import {ButtonService} from '../../service/button.service';
import {AbstractButton} from '../../util/abstract-button';

@Component({
  selector: 'ui-split-button',
  templateUrl: './split-button.component.html',
  styleUrls: ['./split-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SplitButtonComponent extends AbstractButton implements OnChanges {
  @Input()
  public buttons: MenuItem[] = [];
  public multiModel: MenuItem[];
  public firstButton: MenuItem;

  public constructor(buttonService: ButtonService, cdr: ChangeDetectorRef) {
    super(buttonService, cdr);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.buttons) {
      this.firstButton = this.buttons[0];
      this.multiModel = this.buttons.slice(1);
    }
  }
}

import {HttpClient} from '@angular/common/http';
import {Inject, Injectable, Optional} from '@angular/core';
import {AbstractModelDeploymentService, Attachment} from '@wspsoft/frontend-backend-common';
import {Configuration} from '../../configuration';
import {BASE_PATH} from '../../variables';
import {AbstractService} from '../abstract-service';

@Injectable()
export class ModelDeploymentService extends AbstractService implements AbstractModelDeploymentService {

  public constructor(httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string,
                     @Optional() configuration: Configuration) {
    super(httpClient, basePath, configuration);

    this.basePath = '/api/rest/private/designer/deployment';
  }

  public getOwnedApps(workspace: string = 'development'): Promise<string[]> {
    const {queryParameters, headers} = this.getHeaders();
    return this.doGet(`${this.basePath}/codeOwner/${workspace}/applications`, queryParameters, headers);
  }

  /**
   * @see AbstractModelDeploymentService.dump
   */
  public async isCodeOwner(application: string, workspace: string = 'development'): Promise<boolean> {
    const {queryParameters, headers} = this.getHeaders();
    return (await this.doPost<any>(`${this.basePath}/codeOwner/${workspace}/applications/${application}`, {}, queryParameters, headers)).valid;
  }

  /**
   * @see AbstractModelDeploymentService.dump
   */
  public dump(workspace: string, entities?: string[]): Promise<void> {
    const {queryParameters, headers} = this.getHeaders();
    return this.doPost(`${this.basePath}/dump/${workspace}`, entities, queryParameters, headers);
  }

  /**
   * @see AbstractModelDeploymentService.dumpApplication
   */
  public dumpApplication(workspace: string, application: string, entities?: string[]): Promise<void> {
    const {queryParameters, headers} = this.getHeaders();

    return this.doPost(`${this.basePath}/dump/${workspace}/applications/${application}`, entities, queryParameters, headers);
  }


  /**
   * @see AbstractModelDeploymentService.bundleApplication
   */
  public bundleApplication(workspace: string, application: string, entities?: string[]): Promise<Attachment> {
    const {queryParameters, headers} = this.getHeaders();

    return this.doPost(`${this.basePath}/dump/${workspace}/applications/${application}/bundle`, entities, queryParameters, headers);
  }

  /**
   * @see AbstractModelDeploymentService.activateWorkspace
   */
  public activateWorkspace(workspace: string): Promise<void> {
    const {queryParameters, headers} = this.getHeaders();

    return this.doPost(`${this.basePath}/activate/${workspace}`, {}, queryParameters, headers);
  }

}

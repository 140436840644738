import {CriteriaQueryJson} from '../..';

import {ShareableEntity} from '../database/shareable-entity';

export class PreDefinedCondition extends ShareableEntity {
  public script?: string;
  public query?: CriteriaQueryJson;
  public typeId?: string;
  public entityId?: string;
}

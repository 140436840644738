import {ConditionBuilderReturnType} from '../../model/xml/condition-builder-return-type';
import {AbstractModelService} from '../../service/coded/abstract-model.service';
import {_} from '../../util/underscore';
import {CriteriaQueryJson} from '../json/criteria-query-json';
import {AbstractCriteriaJsQueryCompiler} from './criteria-js-query-compiler';

export abstract class AbstractCriteriaJsCompiler extends AbstractCriteriaJsQueryCompiler {
  protected constructor(protected modelService: AbstractModelService) {
    super(modelService);
  }

  /**
   * compile any criteria query to a runnable js script
   */
  public compile(query: CriteriaQueryJson, returnType: ConditionBuilderReturnType = ConditionBuilderReturnType.BOOLEAN): string {
    if (!query) {
      return;
    }
    query = _.cloneDeep(query);

    if (returnType === ConditionBuilderReturnType.BOOLEAN) {
      return this.compileBooleanScript(query);
    } else {
      return this.compileQueryScript(query);
    }
  }
}

import {AfterViewChecked, ChangeDetectionStrategy, Component, ElementRef, forwardRef, Input, ViewChild} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {Chips} from 'primeng/chips';
import {InputNumber} from 'primeng/inputnumber';

@Component({
  selector: 'ui-number-chips',
  templateUrl: './number-chips.component.html',
  styleUrls: ['./number-chips.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => NumberChipsComponent),
    multi: true
  }],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NumberChipsComponent extends Chips implements AfterViewChecked {
  @Input()
  public locale: string;
  @Input()
  public currency: string;
  @Input()
  public showButtons: boolean = true;
  @Input()
  public step: number = 1;
  @Input()
  public buttonLayout: string = 'horizontal';
  @Input()
  public maxValue: number;
  @Input()
  public minValue: number;
  @Input()
  public maxFractionDigits: number;
  @Input()
  public minFractionDigits: number;
  @Input()
  public mode: string;
  @ViewChild(InputNumber, {static: true})
  public inputNumberViewChild: InputNumber;
  public inputViewChild: ElementRef;
  public lastValue: string = '';

  public ngAfterViewChecked(): void {
    const self = this;
    this.inputViewChild = {
      get nativeElement() {
        return {
          focus: () => self.inputNumberViewChild.input.nativeElement.focus(),
          get value() {
            return self.inputNumberViewChild.value;
          },
          set value(v: any) {
            self.inputNumberViewChild.input.nativeElement.value = v;
            self.inputNumberViewChild.value = v;
          }
        };
      }
    };
  }

  /**
   * overrides the original onKeydown to handle different input type
   * @param event the keydown event to handle
   */
  public onKeydown(event: KeyboardEvent): void {
    switch (event.which) {
      // backspace
      case 8:
        if ((!this.lastValue) && this.value && this.value.length > 0) {
          this.value = [...this.value];
          const removedItem = this.value.pop();
          this.onModelChange(this.value);
          this.onRemove.emit({
            originalEvent: event,
            value: removedItem
          });
          this.updateFilledState();
        }
        break;
      // enter
      case 13:
        super.onKeydown(event);
        break;
      // tab
      case 9:
        if (this.addOnTab && this.inputViewChild.nativeElement.value !== '' && this.inputNumberViewChild.input.nativeElement.value !== '') {
          // prevent from tabbing out if there is a value
          event.stopImmediatePropagation();
          this.addItem(event, this.inputViewChild.nativeElement.value || +this.inputNumberViewChild.input.nativeElement.value, true);
        }
        break;
      default:
        if (this.max && this.value && this.max === this.value.length) {
          event.preventDefault();
          if (this.separator === event.key) {
            // why on earth value of the native element of the inputViewChild is null but if you press enter it will work.
            this.addItem(event, this.inputViewChild.nativeElement.value || +this.inputNumberViewChild.input.nativeElement.value, true);
          }
        }
        break;
    }
    setTimeout(() => {
      // this timeout is because the f***ing backspace will delete when there is only one character in input
      this.lastValue = this.inputNumberViewChild.input.nativeElement.value as any;
    }, 50);
  }

  /**
   * overrides the original onKeydown to handle different input type
   * @param {Event} event
   * @param {string} item
   * @param {boolean} preventDefault
   */
  public addItem(event: Event, item: string, preventDefault: boolean): void {
    this.value = this.value || [];
    if (item || item as any === 0) {
      if (this.allowDuplicate || this.value.indexOf(item) === -1) {
        this.value = [...this.value, item];
        this.onModelChange(this.value);
        this.onAdd.emit({
          originalEvent: event,
          value: item
        });
      }
    }
    this.updateFilledState();
    this.updateMaxedOut();
    this.inputViewChild.nativeElement.value = null;
    if (preventDefault) {
      event.preventDefault();
    }
  }

}

<p-toast #toast key="growl" [baseZIndex]="10000">
  <ng-template let-message pTemplate="message">
    <ng-container *ngIf="!message.data">
      <ng-container *ngTemplateOutlet="messageContent context: {$implicit: message}"></ng-container>
    </ng-container>
    <ng-container *ngIf="message.data">
      <ng-container *ngTemplateOutlet="confirmContent context: {$implicit: message}"></ng-container>
    </ng-container>
  </ng-template>
</p-toast>

<ng-template #confirmContent let-message>
  <div class="p-toast-message--with-footer">
    <div class="p-toast-message-content-wrapper">
      <ng-container *ngTemplateOutlet="messageContent context: {$implicit: message}"></ng-container>
    </div>
    <div class="p-toast-message-footer">
      <ui-button (clicked)="rejectToast(toast, message, $event.cb);" [type]="['layout','red']" icon="fas fa-fw fa-times" label="{{'Confirm.No' |
    translate}}"></ui-button>
      <ui-button (clicked)="acceptToast(toast, message, $event.cb);" [type]="['layout','green']" icon="fas fa-fw fa-check" label="{{'Confirm.Yes' |
    translate}}"></ui-button>
    </div>
  </div>
</ng-template>

<ng-template #messageContent let-message>
  <span [class]="'p-toast-message-icon pi' + (message.icon ? ' ' + message.icon : '')" [ngClass]="{'pi-info-circle': message.severity === 'info', 'pi-exclamation-triangle': message.severity === 'warn',
                        'pi-times-circle': message.severity === 'error', 'pi-check' :message.severity === 'success'}"></span>
  <div class="p-toast-message-text">
    <div [innerHTML]="message.summary | safe: 'html'" class="p-toast-summary"></div>
    <div [innerHTML]="message.detail | safe: 'html'" class="p-toast-detail"></div>
  </div>
</ng-template>

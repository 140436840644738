import {CriteriaFunction} from './criteria-function';
import {CriteriaOperator} from './criteria-operator';
import {CriteriaSearchOperator} from './criteria-search-operator';
import {ConditionValueType, CriteriaConditionJson} from './json/criteria-condition-json';

export class CriteriaCondition<O extends CriteriaOperator | CriteriaSearchOperator = CriteriaOperator> {
  public active: boolean = true;
  private value: any[] | any;

  public constructor(private columnName?: string, private operator?: O, private or: boolean = false, rightHandValue1: any = '', rightHandValue2: any = '',
                     private scripted?: ConditionValueType, public criteriaFunction?: CriteriaFunction, public options?: any) {
    this.value = rightHandValue2 ? [rightHandValue1, rightHandValue2] : rightHandValue1;
  }

  public getJson(): CriteriaConditionJson<O> {
    return {
      columnName: this.columnName + '',
      value: this.value,
      or: this.or,
      operator: this.operator,
      active: this.active,
      options: this.options,
      criteriaFunction: this.criteriaFunction,
      scripted: this.scripted
    };
  }

  public fromJson(condition: CriteriaConditionJson<O>): void {
    this.columnName = condition.columnName;
    this.value = condition.value;
    this.or = condition.or;
    this.operator = condition.operator;
    this.active = condition.active;
    this.scripted = condition.scripted;
    this.options = condition.options;
    this.criteriaFunction = condition.criteriaFunction;
  }

  public isEmpty(): boolean {
    return !this.columnName;
  }
}

import {DesignerEntity} from './designer.entity';

import {LayoutForm} from './layout-form';

import {LayoutSection} from './layout-section';


/**
 * connection between layouts and sections. aka the new payload
 */
export abstract class LayoutFormToLayoutSection extends DesignerEntity {
  public position: {
    [columns: number]: {
      x?: number;
      y?: number;
    };
  };
  public layoutFormId?: string;
  public layoutForm?: LayoutForm;
  public layoutSectionId?: string;
  public layoutSection?: LayoutSection;
}

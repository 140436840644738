import {Injectable} from '@angular/core';
import {EntityModel} from '@wspsoft/frontend-backend-common';
import {ModelService, ModelTranslationService} from '../../../../api';
import {Converter} from './converter';

@Injectable()
export class EntityConverterService implements Converter<EntityModel> {

  public constructor(private modelService: ModelService, private modelTranslationService: ModelTranslationService) {
  }

  public getAsObject(s: string): EntityModel {
    if (s) {
      const entity = this.modelService.getEntity(s);
      entity.label = this.modelTranslationService.translateEntity(entity);
      return entity;
    }
    return undefined;
  }

  public getAsString(x: EntityModel): string {
    if (x) {
      return x.name;
    }
    return undefined;
  }
}

import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {_, BundleKeyGenerator, GuidedTour} from '@wspsoft/frontend-backend-common';
import {MenuItem} from 'primeng/api';
import {ModelService} from '../../../../api';
import {GuidedTourService} from '../../service/guidedTour.service';

@Component({
  selector: 'ui-guided-tour',
  templateUrl: './guided-tour.component.html',
  styleUrls: ['./guided-tour.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
/**
 * Class with functions to navigate and use a guidedTour
 * */
export class GuidedTourComponent implements OnInit {
  @Input()
  public dashboard: boolean;
  @Input()
  public gitCommander: boolean;
  @Input()
  public layoutId: string;
  public buttons: MenuItem[] = [];

  public constructor(private guidedTourService: GuidedTourService, private modelService: ModelService,
                     private cdr: ChangeDetectorRef, private translate: TranslateService) {
  }

  /**
   * Initializes the component and starts watchers to detect a page change
   * */
  public ngOnInit(): void {
    this.calculateTourButtons();
  }

  /**
   * Checks and creates the buttons in the header to start a Tour
   * */
  public calculateTourButtons(): void {
    this.buttons = [];
    if (this.dashboard) {
      const tours = _.filter(this.modelService.getGuidedTours(), 'startsOnDashboard');
      for (const tour of tours) {
        this.pushToTourButton(tour);
      }
    } else if (this.gitCommander) {
      const tours = _.filter(this.modelService.getGuidedTours(), 'startsOnGitCommander');
      for (const tour of tours) {
        this.pushToTourButton(tour);
      }
    } else if (this.layoutId) {
      const tours = this.modelService.getGuidedTours();
      for (const tour of tours) {
        if (tour.startPageLayoutId === this.layoutId) {
          this.pushToTourButton(tour);
        }
      }
    }
  }

  /**
   * Helper function to create a Button object for starting a Tour
   * @param {GuidedTour} t - The Tour that can start on the current Page
   * */
  private pushToTourButton(t: GuidedTour): void {
    const button = {
      label: this.translate.instant(BundleKeyGenerator.tourToKey(t)),
      command: () => this.guidedTourService.startTour(t.name)
    };
    this.buttons.push(button);
    this.cdr.detectChanges();
  }
}




import {CriteriaQueryJson} from '../../../criteria/json/criteria-query-json';
import {KolibriEntity} from '../kolibri-entity';
import {Variable} from './variable';
import {VariableAppliesTo} from './variable-applies-to';
import {VariableSet} from './variable-set';

export abstract class VariableRule extends KolibriEntity {
  public appliesTo?: VariableAppliesTo;
  public name?: string;
  public order?: number;

  public mandatory?: CriteriaQueryJson;
  public readonly?: CriteriaQueryJson;
  public rendered?: CriteriaQueryJson;
  public variableId?: string;
  public variable?: Variable;

  public entityId?: string;
  public variableSetId?: string;
  public variableSet?: VariableSet;
}

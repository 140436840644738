import {animate, style, transition, trigger} from '@angular/animations';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {_, ConditionBuilderOptions, EntityModel, Field, FieldResponse, MaybePromise, Relation, Utility} from '@wspsoft/frontend-backend-common';
import {PrimeNGConfig} from 'primeng/api';
import {ConnectedOverlayScrollHandler, DomHandler} from 'primeng/dom';
import {ZIndexUtils} from 'primeng/utils';
import {ModelService, ModelTranslationService} from '../../../../../../../api';
import {SearchInputComponent} from '../../../search-input/search-input.component';

@Component({
  animations: [
    trigger('overlayAnimation', [
      transition(':enter', [
        style({opacity: 0, transform: 'scaleY(0.8)'}),
        animate('{{showTransitionParams}}')
      ]),
      transition(':leave', [
        animate('{{hideTransitionParams}}', style({opacity: 0}))
      ])
    ])
  ],
  selector: 'ui-field-overlay',
  templateUrl: './field-overlay.component.html',
  styleUrls: ['./field-overlay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FieldOverlayComponent implements OnDestroy, OnChanges {
  @Input()
  public entityMeta: EntityModel;
  @Input()
  public level: number;
  @Input()
  public allowDotWalk: boolean = true;
  @Input()
  public autoSelect: boolean = true;
  @Input()
  public value: string;
  // custom parts from field ac itself
  @Input()
  public localized: boolean = true;
  @Input()
  public isTransient: (field: Field, dotWalk: boolean) => boolean;
  @Input()
  public search: (result: Field[]) => MaybePromise<Field[]>;
  @Input()
  public conditionBuilderOptions: ConditionBuilderOptions[];
  @Input()
  public additionalFields: Field[] = [];
  // primeng stuff
  @Input()
  public containerEl: HTMLElement;
  @Input()
  public rootEl: HTMLElement;
  @Input()
  public showTransitionOptions: string = '.12s cubic-bezier(0, 0, 0.2, 1)';
  @Input()
  public hideTransitionOptions: string = '.1s linear';
  @Output()
  public visibleChange: EventEmitter<boolean> = new EventEmitter();
  @Output()
  public valueChange: EventEmitter<string> = new EventEmitter();
  @Output()
  public onSelect: EventEmitter<Field> = new EventEmitter();
  @Output()
  public onHide: EventEmitter<any> = new EventEmitter();
  @Output()
  public onOpenNextLevel: EventEmitter<number> = new EventEmitter();
  public selectedField: Field;
  public suggestions: Field[] = [];
  public overlayVisible: boolean;
  public subOverlayData: { entityMeta: EntityModel };
  public aligned: boolean;
  public lastQuery = '';
  public overlayEl: HTMLElement;
  public highlightIndex: number = 0;
  @ViewChild('child', {static: false})
  public childOverlay: FieldOverlayComponent;
  @ViewChild('searchInput')
  public searchInput: SearchInputComponent;
  private size: number = 30;
  private fieldMeta: FieldResponse;
  private currentOffset: number = 0;
  private scrollHandler: ConnectedOverlayScrollHandler;
  private resizeListener: () => void;
  private keyNavigationListener: (event) => void;
  private outsideClickListener: (event) => void;
  private pAllowAutoExpand: boolean = true;
  private maxLevel = 3;

  public constructor(private modelService: ModelService, private modelTranslationService: ModelTranslationService,
                     private cdr: ChangeDetectorRef, private config: PrimeNGConfig) {
  }

  /**
   * returns the root overlay element.
   */
  public get rootOverlay(): HTMLElement {
    if (this.level > 0) {
      return this.rootEl;
    }
    return this.overlayEl;
  }

  private pvisible: boolean;

  @Input()
  public get visible(): boolean {
    return this.pvisible;
  }

  public set visible(value: boolean) {
    this.pvisible = value;
    this.overlayVisible = false;
    this.visibleChange.emit(value);
  }

  @Input()
  public get allowAutoExpand(): boolean {
    // if the first level is opened and there is no overlay visible auto expand all
    if (this.level === 0 && !this.overlayVisible) {
      this.pAllowAutoExpand = true;
    }
    return this.pAllowAutoExpand;
  }

  public set allowAutoExpand(value: boolean) {
    this.pAllowAutoExpand = value;
  }

  /**
   * get current field name of this level
   * required to auto open the next level when pre filled
   */
  private get currentValueForLevel(): string {
    return (this.value?.split('.') ?? [])[this.level];
  }

  public ngOnChanges(changes: SimpleChanges): void {
    // call show and hide functions to init/remove listeners
    if (changes.visible) {
      if (changes.visible.currentValue) {
        this.show();
      } else {
        this.hide();
      }
    }
    if (changes.entityMeta && !changes.entityMeta.firstChange && this.visible) {
      // entity changed, and the ac is open, so we have to search again for new fields
      this.onSearch('', false).then();
    }
  }

  /**
   * kill all listeners and remove overlay from body
   */
  public ngOnDestroy(): void {
    this.unbindOutsideClickListener();
    this.unbindScrollListener();
    this.unbindResizeListener();
    this.unbindKeyNavigationListener();
    this.restoreAppend();
    this.overlayEl = null;
  }

  /**
   * search for fields on open
   * also check if it has to open the next level automatically
   */
  public show(): void {
    this.onComplete({
      query: '',
      offset: 0
    }).then(() => {
      this.visible = true;
      if (this.autoSelect) {
        // set value for the current select field, if any
        const field = this.getInitialOpenField();
        if (field && this.allowAutoExpand && this.canExpand(field)) {
          this.openNextLevel(field);
        } else {
          this.selectedField = field;
        }
      } else {
        this.value = null;
        this.selectedField = null;
      }
    });
  }

  /**
   * close overlay
   */
  public hide(): void {
    this.highlightIndex = 0;
    this.visible = false;
    this.cdr.detectChanges();
  }

  /**
   * get the field object, if any, for the current value
   */
  public getInitialOpenField(): Field {
    const fieldName = this.currentValueForLevel;
    return _.find(this.suggestions, {name: fieldName});
  }

  /**
   * initialize overlay
   */
  public onOverlayAnimationStart(event: any): void {
    switch (event.toState) {
      case 'visible':
        this.overlayEl = event.element;
        this.onOverlayEnter();
        this.overlayEl.querySelector('.one-field-panel-item--highlight')?.scrollIntoView(false);
        break;
    }
  }

  /**
   * remove overlay
   */
  public onOverlayAnimationDone(event: any): void {
    switch (event.toState) {
      case 'void':
        this.onOverlayLeave();
        break;
    }
  }

  /**
   * search function for infinite scroll and search input
   */
  public onSearch(query: string = this.lastQuery, scroll: boolean): Promise<void> {
    // scroll increases offset, search starts from scratch
    if (scroll) {
      this.currentOffset += 30;
    } else {
      // close overlays, we are changing content
      this.overlayVisible = false;
      this.currentOffset = 0;
    }
    return this.onComplete({
      query,
      offset: this.currentOffset
    });
  }

  /**
   * when selecting a field, update the value
   * and close yourself
   */
  public selectItem(field: Field): void {
    this.selectedField = field;
    this.valueChange.emit(field.name);
    this.onSelect.emit(field);
    this.hide();
    this.cdr.detectChanges();
  }

  /**
   * setup the data for the next level depending on the selected field
   */
  public openNextLevel(field: Field, event?: MouseEvent): void {
    event?.stopPropagation();
    // if pressing the same button again, just toggle
    this.overlayVisible = this.selectedField?.id === field.id ? !this.overlayVisible : true;
    this.selectedField = field;
    this.subOverlayData = {
      entityMeta: this.modelService.getEntity((field as Relation).targetId)
    };
    if (this.overlayVisible) {
      this.unbindKeyNavigationListener();
    }

    // bring selected field to the top
    const selectedField = _.remove(this.suggestions, {name: field.name})[0];
    this.suggestions = _.sortBy(this.suggestions, 'label');
    this.suggestions.unshift(selectedField);
    this.cdr.detectChanges();
  }

  /**
   * condition for the next level button
   *
   * only relations and level less than 3
   */
  public canExpand(field: Field): boolean {
    return this.allowDotWalk && field.entityClass === 'Relation' && this.level < this.maxLevel && !_.find(this.additionalFields, {name: field.name});
  }

  /**
   * recursively calculate the dotwalk value
   */
  public calculateValue(childValue: string): void {
    this.valueChange.emit([this.selectedField.name, childValue].join('.'));
  }

  /**
   * bind listener on keypress event to navigate the overlay
   */
  public bindKeyNavigationListener(): void {
    if (!this.keyNavigationListener && !this.overlayVisible) {
      this.keyNavigationListener = (event) => {
        this.keyNavigation(event);
      };
      window.addEventListener('keydown', this.keyNavigationListener);
    }
  }

  public unbindKeyNavigationListener(): void {
    if (this.keyNavigationListener) {
      window.removeEventListener('keydown', this.keyNavigationListener);
      this.keyNavigationListener = null;
    }
  }

  public disableAutoExpand(): void {
    this.allowAutoExpand = false;
  }

  /**
   * calculates positioning of this component
   * the result will be like a waterfall positioning
   * @param currentOpenedLevel the number of open overlays
   */
  public calculateOverlayPosition(currentOpenedLevel: number): void {
    this.onOpenNextLevel.emit(currentOpenedLevel);

    const viewport = DomHandler.getViewport();
    const rootOffset = DomHandler.getOffset(this.rootOverlay);
    const rootWidth = DomHandler.getOuterWidth(this.rootOverlay);

    // get the rowHeight of an entry to calculate the top correctly
    const tr = this.rootOverlay.querySelector('tr');
    const rowHeight = DomHandler.getOuterHeight(tr);

    // calculate the space that is available on the right side of the root element
    const space = viewport.width - (rootOffset.left + rootWidth);
    // calculate the left offset of the current overlay based by the current open overlays
    const leftOffset = rootOffset.left + space / currentOpenedLevel * this.level;

    // set the top of the root element offset for the current level (rowHeight * 2 to render under the first real table row entry)
    this.overlayEl.style.top = rootOffset.top + rowHeight * 2 * this.level + 'px';
    // set the left offset of the current level
    this.overlayEl.style.left = leftOffset + 'px';
  }

  /**
   * search for fields localized or raw
   * depending on the given entity meta
   */
  private async onComplete($event: { query: string; offset: number }): Promise<void> {
    if (!this.entityMeta) {
      this.suggestions = [];
      return;
    }

    this.lastQuery = $event.query;

    if (this.conditionBuilderOptions === undefined || this.conditionBuilderOptions?.includes('Record' as ConditionBuilderOptions)) {
      if (this.localized) {
        this.fieldMeta = await this.modelService.getFieldsLocalized(this.entityMeta.id, $event.query);
        for (const field of this.fieldMeta.fields) {
          // and join with . for display
          field.label = this.modelTranslationService.translateField(this.fieldMeta.entity, field);
          this.modelTranslationService.translateDuplicate(field);
        }
      } else {
        this.fieldMeta = this.modelService.getFields(this.entityMeta.id, $event.query, true);
        this.fieldMeta.fields = _.cloneDeep(this.fieldMeta.fields);
        for (const field of this.fieldMeta.fields) {
          field.label = field.representativeString;
        }
      }

      if (this.search) {
        const result = this.search(this.fieldMeta.fields);
        this.fieldMeta.fields = _.isPromise(result) ? await result : result as Field[];
      }
    }
    const fields = this.fieldMeta ? this.fieldMeta.fields : [];
    // always keep the selected value, to allow auto open of everything
    const selectedField = _.remove(fields, {name: this.currentValueForLevel})[0];
    // sort by translation
    this.suggestions = _.sortBy([...fields, ...this.additionalFields.filter(f => Utility.matches(f.label, $event.query))], 'label')
      .filter(x => {
        const b = this.isTransient ? this.isTransient(x, Utility.isDotWalk($event.query)) : false;
        return !b;
      })
      .slice(0, this.size + $event.offset);
    if (selectedField) {
      this.suggestions.unshift(selectedField);
    }
    this.cdr.detectChanges();
  }

  /**
   * bind all listener and append to body
   */
  private onOverlayEnter(): void {
    ZIndexUtils.set('overlay', this.overlayEl, this.config.zIndex.overlay);
    this.appendContainer();
    this.alignOverlay();
    this.bindOutsideClickListener();
    this.bindScrollListener();
    this.bindResizeListener();
    this.bindKeyNavigationListener();
    this.searchInput.focus();
  }

  /**
   * remove from body and destroy listener
   */
  private onOverlayLeave(): void {
    this.unbindOutsideClickListener();
    this.unbindScrollListener();
    this.unbindResizeListener();
    this.unbindKeyNavigationListener();
    ZIndexUtils.clear(this.overlayEl);
    this.overlayEl = null;
  }

  /**
   * position overlay
   *
   * below the input or right of the prev overlay
   */
  private alignOverlay(): void {
    if (this.level === 0) {
      // position below the input element
      DomHandler.absolutePosition(this.overlayEl, this.containerEl);
      this.overlayEl.style.maxWidth = DomHandler.getOuterWidth(this.containerEl) + 'px';
    } else {
      const parentOverlay = this.containerEl;
      const parentOffset = DomHandler.getOffset(parentOverlay);
      const parentWidth = DomHandler.getOuterWidth(parentOverlay);
      const ownOverlay = this.overlayEl;
      const ownWidth = ownOverlay.offsetParent ? ownOverlay.offsetWidth : DomHandler.getHiddenElementOuterWidth(
        ownOverlay);
      const viewport = DomHandler.getViewport();


      // check if there is enough space
      if ((parseInt(parentOffset.left, 10) + parentWidth + ownWidth) > (viewport.width - DomHandler.calculateScrollbarWidth())) {
        // position ontop of the parent
        this.calculateOverlayPosition(this.level);
        this.onOpenNextLevel.emit(this.level);
      } else {
        // position right to the parent
        ownOverlay.style.top = parentOffset.top + 'px';
        ownOverlay.style.left = parentOffset.left + parentWidth + 'px';
      }
    }
    setTimeout(() => this.aligned = true, 100);
  }

  private appendContainer(): void {
    document.body.appendChild(this.overlayEl);
  }

  private restoreAppend(): void {
    if (this.overlayEl) {
      document.body.removeChild(this.overlayEl);
    }
  }

  /**
   * hide overlay when scrolling elsewhere
   */
  private bindScrollListener(): void {
    if (!this.scrollHandler) {
      this.scrollHandler = new ConnectedOverlayScrollHandler(this.containerEl, e => {
        if (this.visible) {
          this.hide();
        }
      });
    }

    this.scrollHandler.bindScrollListener();
  }

  private unbindScrollListener(): void {
    if (this.scrollHandler) {
      this.scrollHandler.unbindScrollListener();
    }
  }

  /**
   * hide when resizing the window
   */
  private bindResizeListener(): void {
    if (!this.resizeListener) {
      this.resizeListener = () => {
        if (this.visible) {
          this.hide();
        }
      };
      window.addEventListener('resize', this.resizeListener);
    }
  }

  private unbindResizeListener(): void {
    if (this.resizeListener) {
      window.removeEventListener('resize', this.resizeListener);
      this.resizeListener = null;
    }
  }

  /**
   * hide if clicked anywhere, but not in yourself or any open children
   */
  private bindOutsideClickListener(): void {
    if (!this.outsideClickListener) {
      this.outsideClickListener = (event) => {
        if (this.visible && !this.containsElement(event.target)) {
          this.hide();
        }
      };
      document.addEventListener('click', this.outsideClickListener);
    }
  }

  private unbindOutsideClickListener(): void {
    if (this.outsideClickListener) {
      document.removeEventListener('click', this.outsideClickListener);
      this.outsideClickListener = null;
    }
  }

  /**
   * check if the current html element is inside the own overlay or a child
   */
  private containsElement(el: HTMLElement): boolean {
    return this.overlayEl?.contains(el) || this.childOverlay?.containsElement(el) || this.level === 0 && this.containerEl.contains(el);
  }

  private keyNavigation(event: KeyboardEvent): void {
    let navigationKey = true;
    const field = this.suggestions[this.highlightIndex];
    switch (event.key) {
      case 'ArrowUp':
        this.highlightIndex = this.highlightIndex >= 1 ? this.highlightIndex - 1 : this.suggestions.length - 1;
        break;
      case 'Enter':
        this.selectItem(field);
        break;
      case 'ArrowRight':
        if (this.highlightIndex >= 0 && this.highlightIndex < this.suggestions.length && this.canExpand(field)) {
          this.highlightIndex = 0;
          this.openNextLevel(field);
        }
        break;
      case 'ArrowDown':
        this.highlightIndex = this.highlightIndex < this.suggestions.length - 1 ? this.highlightIndex + 1 : 0;
        break;
      case 'ArrowLeft':
        this.hide();
        this.onHide.emit();
        break;
      default:
        navigationKey = false;
    }
    if (navigationKey) {
      event?.preventDefault();
      event?.stopPropagation();
      this.allowAutoExpand = false;
      this.cdr.detectChanges();
    }
  }
}

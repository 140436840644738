<div [class]="styleClass" [ngClass]="{'one-steps':true,'one-steps--readonly':readonly, 'one-steps--full':!compactMode}" [ngStyle]="style">
  <ul class="one-steps__list" role="tablist">
    <li #menuitem *ngFor="let item of model; let i = index" [attr.aria-expanded]="i === activeIndex" [attr.aria-selected]="i === activeIndex"
        [class]="item.styleClass" [ngClass]="{'one-steps__item--current':(i === activeIndex),
                        'one-steps__item--incomplete':(i !== activeIndex),
                        'one-steps__item--complete':(i < activeIndex),
                        'one-steps__item--disabled one-steps__item--incomplete':item.disabled||(i !== activeIndex && readonly)}" [ngStyle]="item.style"
        class="one-steps__item" role="tab">
      <a (click)="itemClick($event, item, i)" (keydown.enter)="itemClick($event, item, i)" *ngIf="!item.routerLink" [attr.href]="item.url" [attr.id]="item.id"
         [attr.tabindex]="item.disabled||(i !== activeIndex && readonly) ? null : (item.tabindex ? item.tabindex : '0')" [attr.target]="item.target"
         class="one-steps__bar" role="presentation">
        <span class="one-steps__icon {{item.icon}}" pTooltip="{{item.label}}" tooltipPosition="top"></span>
        <ng-container *ngIf="!compactMode">
          <span class="one-steps__title">{{item.label}}</span>
        </ng-container>
      </a>
      <a (click)="itemClick($event, item, i)" (keydown.enter)="itemClick($event, item, i)" *ngIf="item.routerLink" [attr.id]="item.id"
         [attr.tabindex]="item.disabled||(i !== activeIndex && readonly) ? null : (item.tabindex ? item.tabindex : '0')" [attr.target]="item.target"
         [fragment]="item.fragment" [preserveFragment]="item.preserveFragment" [queryParamsHandling]="item.queryParamsHandling" [queryParams]="item.queryParams"
         [replaceUrl]="item.replaceUrl" [routerLinkActiveOptions]="item.routerLinkActiveOptions||{exact:false}" [routerLinkActive]="'one-steps__bar--active'"
         [routerLink]="item.routerLink" [skipLocationChange]="item.skipLocationChange" [state]="item.state" class="one-steps__bar" role="presentation">
        <span class="one-steps__icon {{item.icon}}"></span>
        <span class="one-steps__title">{{item.label}}</span>
      </a>
    </li>
  </ul>
</div>

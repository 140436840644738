import {MaybePromise} from '../../util/underscore';
import {KolibriEntity} from './kolibri-entity';

export class Attachment extends KolibriEntity {
  public fileName?: string;
  public mimeType?: string;
  public payload?: string;
  public payloadData?: MaybePromise<string>;
  public publik?: boolean;
  public entity?: KolibriEntity;
  public entityId?: string;
}

import {ChangeDetectionStrategy, Component, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import {NG_VALIDATORS, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Utility} from '@wspsoft/frontend-backend-common';
import {CustomInput} from '../../custom-input';
import {AutoComplete} from '../autocomplete';

@Component({
  selector: 'ui-autocomplete',
  templateUrl: './autocomplete-input.component.html',
  styleUrls: ['../autocomplete.scss', './autocomplete-input.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => AutocompleteInputComponent),
    multi: true
  }, {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => AutocompleteInputComponent),
    multi: true,
  }, {
    provide: CustomInput,
    useExisting: forwardRef(() => AutocompleteInputComponent),
    multi: true
  }],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AutocompleteInputComponent extends AutoComplete<any> implements OnInit {
  @Input()
  public renderInputGroup: boolean = true;
  @Input()
  public field: string;
  @Input()
  public showIcons: boolean;
  @Output()
  public completeMethod: EventEmitter<any> = new EventEmitter();
  private originalSuggestions: any[];


  public ngOnInit(): void {
    if (!this.completeMethod.observers.length) {
      this.originalSuggestions = this.suggestions;
      this.subscription = this.completeMethod.subscribe($event => {
        this.suggestions = this.originalSuggestions.filter(t => Utility.matches(t.label, $event.query));

        if ($event.originalEvent.cb) {
          $event.originalEvent.cb();
        }
      });
    }
  }
}

import {
  BranchDeleteSummary,
  CommitSummary,
  DiffResult,
  FetchResult,
  PullResult,
  PushResult,
  ResetMode,
  StashList,
  StatusResult,
  Workspace
} from '../..';

export interface AbstractGitService {
  clone(workspace: Workspace, token?: string): Promise<void>;

  checkoutBranch(branch: string, workspace: Workspace): Promise<void>;

  /**
   * deletes a branch and sets the workspace to the defined new branch
   * @param branch to delete
   * @param workspace current workspace
   * @param newBranch new branch to set the workspace to
   * @param force force delete
   * @returns promise with the summary of the deleted branch
   */
  deleteBranch(branch: string, workspace: Workspace, newBranch: string, force: boolean): Promise<BranchDeleteSummary>;

  newLocalBranch(branch: string, workspace: Workspace): Promise<void>;

  commit(commitMessage: string, files: string[], workspace: Workspace): Promise<CommitSummary>;

  add(files: string[], workspace: Workspace): Promise<void>;

  remove(files: string[], workspace: Workspace): Promise<void>;

  /**
   * pushes the workspace to the server
   * @param workspace the workspace to push
   * @param force if true enable force push
   * @param newBranch is this branch new
   * @param branch name of the new branch
   */
  push(workspace: Workspace, force?: boolean, newBranch?: boolean, branch?: string): Promise<PushResult>;

  pull(workspace: Workspace): Promise<PullResult>;

  status(workspace: Workspace): Promise<StatusResult>;

  fetch(workspace: Workspace): Promise<FetchResult>;

  merge(workspace: Workspace, from: string, to: string): Promise<void>;

  stash(workspace: Workspace, message?: string, filesToStash?: string[]): Promise<boolean>;

  getStash(workspace: Workspace): Promise<any>;

  unstash(workspace: Workspace, hash?: string): Promise<void>;

  stashList(workspace: Workspace): Promise<StashList>;

  clearStash(workspace: Workspace): Promise<void>;

  dropStashEntry(workspace: Workspace, hash: string): Promise<void>;

  revertCommit(workspace: Workspace, hash: string): Promise<void>;

  reset(workspace: Workspace, mode: ResetMode, commitHash?: string): Promise<void>;

  log(workspace: Workspace): Promise<StashList>;

  /**
   * get git diff for all or one file
   */
  diff(workspace: Workspace, files?: string[]): Promise<DiffResult>;
}

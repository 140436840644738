import {HttpClient} from '@angular/common/http';
import {Inject, Injectable, Optional} from '@angular/core';
import {
  AbstractKolibriFileService,
  CompressOptions,
  ConvertOptions,
  DecompressOptions,
  ResizeOptions,
  ScaleOptions,
  TransformOptions
} from '@wspsoft/frontend-backend-common';
import {Configuration} from '../../configuration';
import {BASE_PATH} from '../../variables';
import {AbstractService} from '../abstract-service';

@Injectable()
export class FileService extends AbstractService implements AbstractKolibriFileService {

  public constructor(httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string,
                     @Optional() configuration: Configuration) {
    super(httpClient, basePath, configuration);

    this.basePath = '/files';
  }

  public async compress(file: string, options: CompressOptions): Promise<string> {
    const {queryParameters, headers} = this.getHeaders();
    const opts = {
      payload: file,
      opts: options
    };

    return (await this.doPost<{ payload: string }>(`${this.basePath}/compress`, opts, queryParameters, headers)).payload;
  }

  public async decompress(file: string, options: DecompressOptions): Promise<string> {
    const {queryParameters, headers} = this.getHeaders();
    const opts = {
      payload: file,
      opts: options
    };

    return (await this.doPost<{ payload: string }>(`${this.basePath}/decompress`, opts, queryParameters, headers)).payload;
  }

  public async html2pug(html: string): Promise<string> {
    const {queryParameters, headers} = this.getHeaders();
    return (await this.doPost<{ payload: string }>(`${this.basePath}/html2pug`, {payload: html}, queryParameters, headers)).payload;
  }

  public async transform(img: string, options: TransformOptions): Promise<string> {
    const {queryParameters, headers} = this.getHeaders();
    const opts = {
      payload: img,
      opts: options
    };
    return (await this.doPost<{ payload: string }>(`${this.basePath}/transform`, opts, queryParameters, headers)).payload;
  }

  public async convert(img: string, options: ConvertOptions): Promise<string> {
    const {queryParameters, headers} = this.getHeaders();
    const opts = {
      payload: img,
      opts: options,
    };
    return (await this.doPost<{ payload: string }>(`${this.basePath}/convert`, opts, queryParameters, headers)).payload;
  }

  public async resize(img: string, resizeOptions: ResizeOptions): Promise<string> {
    const {queryParameters, headers} = this.getHeaders();
    const opts = {
      payload: img,
      opts: resizeOptions
    };
    return (await this.doPost<{ payload: string }>(`${this.basePath}/resize`, opts, queryParameters, headers)).payload;
  }

  public async scale(img: string, options: ScaleOptions): Promise<string> {
    const {queryParameters, headers} = this.getHeaders();
    const opts = {
      payload: img,
      opts: options,
    };
    return (await this.doPost<{ payload: string }>(`${this.basePath}/scale`, opts, queryParameters, headers)).payload;
  }
}

abstract class NamespaceInfoSchemaCamelize {
  public id: number;
  public name: string;
  public path: string;
  public kind: string;
  public fullPath: string;
}


export abstract class VcsProjectSchema {
  public id: number;
  public name: string;
  public nameWithNamespace: string;
  // eslint-disable-next-line camelcase
  public path_with_namespace: string;
  public path: string;
  public pathWithNamespace: string;
  public namespace: NamespaceInfoSchemaCamelize;
  public sshUrlToRepo: string;
  public httpUrlToRepo: string;
  public archived: boolean;
}



<ng-template #queryBuilderContent>
  <div *ngIf="!first" class="one-conditionbuilder__query-conditions">
    <p-radioButton (onClick)="forceUpdate()" [(ngModel)]="value.or" [disabled]="disable" [value]="false"
                   class="one-conditionbuilder__condition-label one-conditionbuilder__condition-label--left" label="{{'QueryBuilder.Group.And' | translate}}"
                   name="{{value.id}}_or_radio"></p-radioButton>
    <p-radioButton (onClick)="forceUpdate()" [(ngModel)]="value.or" [disabled]="disable" [value]="true"
                   class="one-conditionbuilder__condition-label one-conditionbuilder__condition-label--right" label="{{'QueryBuilder.Group.Or' | translate}}"
                   name="{{value.id}}_or_radio"></p-radioButton>
  </div>
  <div [ngClass]="{'one-conditionbuilder__rule-content--vertical': columnName === '$Script'}" class="one-conditionbuilder__rule-content">
    <ui-field-autocomplete (convertChange)="selectField($event, false)" (onSelect)="selectField($event)" [(ngModel)]="columnName"
                           [additionalFields]="queryBuilderDataService.additionalFields" [allowDotWalk]="queryBuilderDataService.allowDotWalk"
                           [conditionBuilderOptions]="queryBuilderDataService.conditionBuilderOptions" [disable]="disable"
                           [entityName]="queryBuilderDataService.entityMeta.name" [isTransient]="queryBuilderDataService.isTransient" [localized]="queryBuilderDataService.localized
                           ?? true" [required]="true" class="one-conditionbuilder__rule-filter" label="{{'QueryBuilder.Rule.Field' | translate}}"
                           name="field"></ui-field-autocomplete>
    <ui-autocomplete (completeMethod)="searchOperator($event)" (convertChange)="selectOperator($event, false)" (onSelect)="selectOperator($event)"
                     *ngIf="selectedField && operatorsForField.length > 1" [(ngModel)]="value.operator" [converter]="operatorConverter" [disable]="disable"
                     [dropdown]="true" [required]="true" [size]="30" [suggestions]="operators" class="one-conditionbuilder__rule-operator" field="label"
                     label="{{'QueryBuilder.Rule.Operator.Tooltip' | translate}}" name="operator"></ui-autocomplete>

    <ng-container *ngIf="showValueContainer()">
      <div class="one-conditionbuilder__rule-value">
        <div (click)="menu.show($event)" *ngIf="showValueTypeToggle()" class="one-conditionbuilder__toggle one-button one-button--icon-only">
          <i [class]="selectedValueType.icon"></i>
          <p-menu #menu [model]="valueTypesMenu" [popup]="true" appendTo="body"></p-menu>
        </div>
        <ui-field-value-selector (onChange)="forceUpdate()" [(ngModel)]="value.value" [codeEditorOptions]="queryBuilderDataService.codeEditorOptions"
                                 [conditionValueType]="value.scripted" [disable]="disable" [entityName]="targetId"
                                 [fieldMeta]="selectedField" [filter]="filterOnTypeAndEntity" [ignoreMappingEntity]="true" [multiple]="selectedOperator.isInOperator()&& value.scripted
                                  !== ConditionValueType.SCRIPTED" [operator]="value.operator" [queryEntityClass]="queryBuilderDataService.entityMeta.name" [recordEntityClass]="queryBuilderDataService.recordEntity"
                                 [scriptLanguage]="queryBuilderDataService.scriptLanguage"
                                 [stringValue]="selectedOperator.isStringType"></ui-field-value-selector>
      </div>
    </ng-container>
  </div>
  <div *ngIf="!disable" class="one-conditionbuilder__actions">
    <ui-button (clicked)="removeRule()" *ngIf="deletable" [showDelay]="150" [spinner]="false" [type]="['icon-only', 'red']" class="one-conditionbuilder__delete"
               data-delete="rule" icon="fas fa-fw fa-trash" label="{{'QueryBuilder.Rule.Remove' | translate}}" tooltipPosition="top"></ui-button>

    <ui-button (onDragEnd)="moveRule()" (onDragStart)="moveRule(value)" [spinner]="false" class="one-conditionbuilder__drag" dragEffect="move"
               icon="fas fa-fw fa-arrows-alt" pDraggable="queryElement" type="icon-only"></ui-button>
  </div>
</ng-template>

<ng-template #breadCrumbContent>
  <span *ngIf="!first" class="one-filter-nav__item-text one-filter-nav__item-text--rule">
    <ng-container>{{'QueryBuilder.Group.' + (value.or ? 'Or' : 'And') | translate}}</ng-container>
  </span>
  <span [innerHTML]="breadcrumbString | async | safe: 'html'" class="one-filter-nav__item-content"></span>
  <p-menu #menu [model]="breadcrumbMenu" [popup]="true" appendTo="body"></p-menu>
  <ui-button (clicked)="menu.toggle($event.originalEvent); $event.cb();" [disable]="disable" class="one-filter-nav__item-button" icon="fa fa-fw fa-ellipsis-v"
             type="icon-only"></ui-button>
</ng-template>

<ng-container *ngIf="breadcrumb" [ngTemplateOutlet]="breadCrumbContent"></ng-container>
<ng-container *ngIf="!breadcrumb" [ngTemplateOutlet]="queryBuilderContent"></ng-container>

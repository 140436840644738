import {Injectable} from '@angular/core';
import * as moment from 'moment';
import {Converter} from './converter';

@Injectable()
export class TimestampConverter implements Converter<Date> {

  public constructor() {
  }

  public getAsObject(s: string): Date {
    if (s) {
      return moment(parseInt(s, 10)).toDate();
    }
    return undefined;
  }

  public getAsString(x: Date): string {
    if (x) {
      return x.getTime().toString();
    }
    return undefined;
  }
}

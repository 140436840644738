<ui-input-wrapper [customValidationMessage]="customValidationMessage" [dirty]="input.dirty" [disable]="disable" [errors]="input.errors"
                  [filled]="isFilled()" [hasCenteredLabel]="buttonLayout === 'vertical'" [hasFocus]="hasFocus"
                  [hasInlineLabel]="!renderInputGroup"
                  [hasReversedLabelPosition]="buttonLayout === 'vertical'" [helpMessage]="helpMessage" [label]="label + (!renderInputGroup ? ':' : '')" [name]="name"
                  [renderInputGroup]="renderInputGroup" [required]="required" [touched]="input.touched"
                  [valid]="input.valid">
  <div [ngClass]="{'one-input': renderInputGroup, 'one-input--inline': !renderInputGroup, 'one-input--readonly': renderInputGroup && disable}">
    <ng-container *ngIf="!multiple else chips">
      <span *ngIf="currency" class="one-input__decorator">{{currency}}</span>
      <p-inputNumber #inputElement="ngModel" #nativeInput (onBlur)="validate(); hasFocus = false" (onFocus)="hasFocus = true" [(ngModel)]="value"
                     [allowEmpty]="allowEmpty" [buttonLayout]="buttonLayout" [disabled]="disable" [locale]="locale" [maxFractionDigits]="maxFractionDigits"
                     [max]="max" [minFractionDigits]="minFractionDigits" [min]="min" [ngModelOptions]="{updateOn: updateOn}" [ngStyle]="styleData"
                     [required]="required" [showButtons]="showButtons && !disable" [step]="step" mode="decimal"></p-inputNumber>
    </ng-container>
  </div>
</ui-input-wrapper>
<ng-template #chips>
  <ui-number-chips #inputElement="ngModel" #nativeInput (onBlur)="focusChanged($event)" (onFocus)="focusChanged($event)" [(ngModel)]="value" [addOnBlur]="true"
                   [addOnTab]="true" [allowDuplicate]="false" [buttonLayout]="buttonLayout" [disabled]="disable" [locale]="locale"
                   [maxFractionDigits]="maxFractionDigits" [maxValue]="max" [minFractionDigits]="minFractionDigits" [minValue]="min"
                   [ngModelOptions]="{updateOn: updateOn}" [required]="required" [showButtons]="showButtons && !disable" [step]="step" [styleData]="styleData"
                   mode="decimal" separator="."></ui-number-chips>
</ng-template>

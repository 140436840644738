import {DesignerEntity} from './designer.entity';
import {GuidedTourMappingEntity} from './guided-tour-mapping-entity';


export class GuidedTour extends DesignerEntity {
  public startPageLayoutId?: string;
  public startsOnDashboard?: boolean;
  public startsOnGitCommander?: boolean;
  public mapping?: GuidedTourMappingEntity[];
}




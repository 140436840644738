import {AbstractKolibriScriptExecutor} from '../../api/abstract-kolibri-script-executor';
import {AbstractCriteriaJsCompiler} from '../../criteria/compiler/criteria-js-compiler';

import {KolibriEntity} from '../../model/database/kolibri-entity';
import {User} from '../../model/database/user';
import {_} from '../../util/underscore';

import {AbstractModelService} from '../coded/abstract-model.service';

import {EntityModel} from '../entities/entity-model';
import {AbstractEntityService} from '../generated/abstract-entity-service';

import {AbstractEntityServiceFactory} from '../generated/abstract-entity-service-factory';
import {EntityServiceOptions} from '../generated/service-options';
import {AbstractEntityEnhancer, ModifierOptions} from './abstract-entity-enhancer';
import {DesignerHandler} from './handler/designer-handler';
import {LazyLoaderHandler} from './handler/lazy-loader-handler';
import {UserHandler} from './handler/user-handler';

export class FirstLevelEntityEnhancer<E extends KolibriEntity> implements AbstractEntityEnhancer<E> {
  public static instanceDefinitions: {
    [entityName: string]: new<T extends KolibriEntity>(record: T, enhancer?: FirstLevelEntityEnhancer<T>, createRecordOld?: boolean) => LazyLoaderHandler<T>;
  } = {};

  public constructor(private entityServiceFactory?: AbstractEntityServiceFactory, private options?: EntityServiceOptions,
                     private entityMeta?: EntityModel,
                     protected modelService?: AbstractModelService, protected criteriaCompiler?: AbstractCriteriaJsCompiler,
                     private scriptExecutor?: AbstractKolibriScriptExecutor, private user?: User) {
  }

  /**
   * resets the instance definitions or deletes the definition for the given entity
   */
  public static resetInstanceDefinitions(entity?: string): void {
    if (entity) {
      delete FirstLevelEntityEnhancer.instanceDefinitions[entity];
    } else {
      FirstLevelEntityEnhancer.instanceDefinitions = {};
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public secondLevelEnhancing(o: KolibriEntity, formData: any, eventHandler: any, zone: any): KolibriEntity {
    return;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public thirdLevelEnhancing(formId: string, record: KolibriEntity,
                             // eslint-disable-next-line @typescript-eslint/no-unused-vars
                             usedFields: any, sessionService: any): KolibriEntity {
    return record;
  }

  /**
   * eval all relations and create a lazy loading getter for each
   */
  public firstLevelEnhancing(e: E[], recordOld?: boolean, modifiers?: ModifierOptions<E>): E[];

  public firstLevelEnhancing(e: E, recordOld?: boolean, modifiers?: ModifierOptions<E>): E;

  public firstLevelEnhancing(e: E | E[], recordOld?: boolean, modifiers?: ModifierOptions<E>): E | E[];

  public firstLevelEnhancing(e: E | E[], recordOld: boolean = true, {
    skipModifier = () => false,
    modifier = (entity) => entity.parse()
  }: ModifierOptions<E> = {}): E | E[] {
    if (Array.isArray(e)) {
      // we have a nested array from to many bulk loading
      if (Array.isArray(e[0])) {
        return e.map(e1 => this.firstLevelEnhancing(e1 as any, recordOld, {skipModifier, modifier})) as any;
      }
      return e.map(e1 => this.firstLevelEnhancing(e1, recordOld, {skipModifier, modifier}));
    }

    if (_.isNull(e)
      || !this.modelService.getEntity(e.entityClass)
      || e instanceof LazyLoaderHandler
      || skipModifier(e)) {
      return e;
    }

    if (!FirstLevelEntityEnhancer.instanceDefinitions[e.entityClass]) {
      // eslint-disable-next-line no-eval
      const newInstanceDef = eval(`(function (_outerSuper) {
        class ${e.entityClass} extends _outerSuper {
        }

        return ${e.entityClass};
      })
      `)(e.entityClass === 'PlatformUser' ? UserHandler : LazyLoaderHandler);
      LazyLoaderHandler.addAttributeWrapper(newInstanceDef.prototype, this.getMeta(e));
      LazyLoaderHandler.addLazyLoader(newInstanceDef.prototype, this.getMeta(e));
      LazyLoaderHandler.addComputedFields(newInstanceDef.prototype, this.getMeta(e));
      FirstLevelEntityEnhancer.instanceDefinitions[e.entityClass] = newInstanceDef;
    }

    e = new FirstLevelEntityEnhancer.instanceDefinitions[e.entityClass](e, this, recordOld) as any as E;
    modifier(e);

    return e;
  }

  public designerEnhancement(modelService: AbstractModelService, e: E): E {
    if (e.entityClass === 'Entity') {
      this.modelService = modelService;
      return new DesignerHandler(e, this).record as E;
    }
    return e;
  }

  /**
   * remove all extensions from an entity to allow serialization
   */
  public removeApiExtensions(e: E[]): E[];

  public removeApiExtensions(e: E): E;

  public removeApiExtensions(e: E | E[]): E | E[];

  public removeApiExtensions(entity: E | E[]): E | E[] {
    if (!entity) {
      return;
    }

    if (Array.isArray(entity)) {
      return entity.map(e => this.removeApiExtensions(e));
    }

    // might not be enhanced
    if (entity.stringify) {
      entity.stringify();
    }

    if (entity.toJSON) {
      entity = entity.toJSON();
    }
    if ('representativeString' in entity) {
      delete entity.representativeString;
      entity.representativeString = entity[this.getMeta(entity).representativeAttribute.name];
    }

    return entity;
  }

  public getMeta(o: KolibriEntity): EntityModel {
    if (!o.entityClass || !this.modelService) {
      return this.entityMeta;
    }

    return this.modelService.getEntity(o.entityClass) ?? this.entityMeta;
  }

  public entityService(o: KolibriEntity): AbstractEntityService<E> {
    return this.entityServiceFactory.getService(o.entityClass, this.user, {...this.options});
  }
}

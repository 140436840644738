<ui-input-wrapper [customValidationMessage]="customValidationMessage" [dirty]="inputElement.dirty" [disable]="disable" [errors]="inputElement.errors"
                  [filled]="nativeInput.filled" [hasFocus]="hasFocus" [helpMessage]="helpMessage" [label]="label" [name]="name" [required]="required"
                  [touched]="inputElement.touched" [valid]="inputElement.valid">
  <ui-primeng-custom-autocomplete #inputElement="ngModel" #nativeInput (completeMethod)="onComplete($event)" (onBlur)="focusChanged($event)"
                                  (onFocus)="focusChanged($event)" (onForceClear)="clear();" (onSelect)="onSelect.emit($event)" [(ngModel)]="value"
                                  [disabled]="disable" [dropdown]="dropdown" [forceSelection]="forceSelection" [inputStyle]="styleData" [required]="required"
                                  [size]="size" [suggestions]="suggestions" appendTo="body" emptyMessage="{{'AutoComplete.NoResults' | translate}}" field="name"
                                  styleClass="one-input--icon-picker">
    <ng-template let-item pTemplate="item">
      <i *ngIf="item" [className]="item.icon"></i>
      <span>{{item.name}}</span>
    </ng-template>
  </ui-primeng-custom-autocomplete>
</ui-input-wrapper>


import {TemplateEntryOperator} from '../../../service/entities/template.entity';
import {VersionableJson} from './versionable-json';

export interface TemplateEntryJson {
  value: any;
  operator: TemplateEntryOperator;
}

export abstract class LegacyTemplate extends VersionableJson {
  public fields: Array<LegacyTemplateType>;
}

export type LegacyTemplateType = {
  fieldType: string;
  key: string;
  value?: any;
  fieldId?: string; // fieldId (KolibriEntities or Picklist)
  dateType?: string; // 'DateTime', Date
};

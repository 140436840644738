export interface AbstractKolibriFileService {
  /**
   * convert html to pug data
   */
  html2pug(html: string): Promise<string>;

  compress(file: string, options: CompressOptions): Promise<string>;

  decompress(file: string, options: DecompressOptions): Promise<string>;

  transform(img: string, options: TransformOptions): Promise<string>;

  convert(img: string, options: ConvertOptions): Promise<string>;

  resize(img: string, options: ResizeOptions): Promise<string>;

  scale(img: string, options: ScaleOptions): Promise<string>;
}

export interface CompressOptions {
  algorithm: 'lz4';
}

export interface DecompressOptions {
  algorithm: 'lz4';
}

export interface TransformOptions {
  height?: number;
  width?: number;
  scale?: number;
  format?: string;
}

export interface ConvertOptions {
  targetFormat: string;
}

export interface ScaleOptions {
  factor: number;
}

export interface RGBA {
  r?: number;
  g?: number;
  b?: number;
  alpha?: number;
}

export type Color = string | RGBA;

export interface FitEnum {
  contain: 'contain';
  cover: 'cover';
  fill: 'fill';
  inside: 'inside';
  outside: 'outside';
}

export interface KernelEnum {
  nearest: 'nearest';
  cubic: 'cubic';
  mitchell: 'mitchell';
  lanczos2: 'lanczos2';
  lanczos3: 'lanczos3';
}

export interface ResizeOptions {
  /**
   * Alternative means of specifying width. If both are present this take priority.
   */
  width?: number;

  /**
   * Alternative means of specifying height. If both are present this take priority.
   */
  height?: number;

  /**
   * How the image should be resized to fit both provided dimensions, one of cover, contain, fill, inside or outside. (optional, default 'cover')
   */
  fit?: keyof FitEnum;

  /**
   * Position, gravity or strategy to use when fit is cover or contain. (optional, default 'centre')
   */
  position?: number | string;

  /**
   * Background colour when using a fit of contain, parsed by the color module,
   * defaults to black without transparency. (optional, default {r:0,g:0,b:0,alpha:1})
   */
  background?: Color;

  /**
   * The kernel to use for image reduction. (optional, default 'lanczos3')
   */
  kernel?: keyof KernelEnum;

  /** Do not enlarge if the width or height are already less
   * than the specified dimensions, equivalent to GraphicsMagick's > geometry option. (optional, default false)
   */
  withoutEnlargement?: boolean;

  /**
   * Take greater advantage of the JPEG and WebP shrink-on-load feature,
   * which can lead to a slight moiré pattern on some images. (optional, default true)
   */
  fastShrinkOnLoad?: boolean;
}

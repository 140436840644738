import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ContentChild, forwardRef, HostBinding, Input, OnInit, TemplateRef} from '@angular/core';
import {NG_VALIDATORS, NG_VALUE_ACCESSOR} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {_, CriteriaOperator, User, Utility} from '@wspsoft/frontend-backend-common';
import Quill, {StringMap} from 'quill';
import {ImageDrop} from 'quill-image-drop-module';
import 'quill-mention';
import {CriteriaFactory} from '../../../../../api';
import {environment} from '../../../../../config/environments/environment';
import {CustomInput} from '../custom-input';

Quill.register('modules/imageDrop', ImageDrop);

@Component({
  selector: 'ui-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => EditorComponent),
    multi: true
  }, {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => EditorComponent),
    multi: true,
  }, {
    provide: CustomInput,
    useExisting: forwardRef(() => EditorComponent),
    multi: true
  }],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditorComponent extends CustomInput<string> implements OnInit {

  @ContentChild('buttons', {static: true})
  public buttons: TemplateRef<any>;
  @ContentChild('header', {static: true})
  public header: TemplateRef<any>;
  @ContentChild('subheader', {static: true})
  public subheader: TemplateRef<any>;
  @Input()
  public styleClass: boolean;
  @Input()
  public compact: boolean = false;
  @Input()
  public mentionOptions: any;
  @HostBinding('style.--saveText')
  public saveText: string;
  @HostBinding('style.--linkText')
  public linkText: string;
  @HostBinding('style.--formulaText')
  public formulaText: string;
  @HostBinding('style.--videoText')
  public videoText: string;
  @HostBinding('style.--visitText')
  public visitText: string;
  @HostBinding('style.--editText')
  public editText: string;
  @HostBinding('style.--removeText')
  public removeText: string;
  public editor: Quill;
  private skipChange: boolean;
  public modules: StringMap = {
    imageDrop: true,
    mention: {
      allowedChars: /^[A-Za-z0-9\sàèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ@.-]*$/,
      mentionDenotationChars: ['@'],
      positioningStrategy: 'fixed',
      renderItem: (item) => EditorComponent.getUserMentionHtml(item.user),
      source: async (searchTerm, renderList) => {
        renderList((await this.criteriaFactory.getFrontendQuery<User>('PlatformUser')
          .addCondition('representativeString', CriteriaOperator.BEGINS_WITH, searchTerm)
          .limit(10)
          .getResults()).map(u => (
          {
            id: u.id,
            user: u,
            value: EditorComponent.getUserMentionHtml(u, false)
          })));
      },
      renderLoading: () => this.translate.instant('Editor.Mention.Loading'),
      onSelect: (item, insertItem) => {
        // mention will delete the next character, we have to ignore that change
        this.skipChange = true;
        insertItem(item);
      }
    }
  };

  public constructor(cdr: ChangeDetectorRef, private criteriaFactory: CriteriaFactory, public translate: TranslateService,) {
    super(cdr);
  }

  public get value(): any {
    // the getter is necessary to prevent errors
    return super.value;
  }

  public set value(value: any) {
    if (this.skipChange) {
      this.skipChange = false;
      return;
    }

    // the p-editor doesn't return undefined instead it ever return null
    super.value = value === null ? undefined : value;
  }

  private static getUserMentionHtml(u: User, withImage: boolean = true): string {
    const repImage = u.repImageId && withImage ?
      `<figure class="one-avatar one-avatar--margin one-avatar--normal">
<img src="${environment.serverAdd}/files/${u.repImageId}?width=20&height=20&placeholder=image" class="one-avatar__content">
</figure>` : '';
    return repImage + u.representativeString;
  }

  public ngOnInit(): void {
    if (this.mentionOptions) {
      this.modules.mention = _.merge(this.modules.mention, this.mentionOptions);
    }
    this.saveText = JSON.stringify(this.translate.instant('Editor.StyleText.Save'));
    this.linkText = JSON.stringify(this.translate.instant('Editor.StyleText.Link'));
    this.formulaText = JSON.stringify(this.translate.instant('Editor.StyleText.Formula'));
    this.videoText = JSON.stringify(this.translate.instant('Editor.StyleText.Video'));
    this.visitText = JSON.stringify(this.translate.instant('Editor.StyleText.Visit'));
    this.editText = JSON.stringify(this.translate.instant('Editor.StyleText.Edit'));
    this.removeText = JSON.stringify(this.translate.instant('Editor.StyleText.Remove'));
  }

  /**
   * auto complete mention suggestions using .
   */
  public autoCompleteMention($event: KeyboardEvent): void {
    const mention = this.editor.getModule('mention');
    if (mention.isOpen && $event.key === '.' && this.mentionOptions?.autocomplete) {
      const selectedElement = _.find(mention.mentionList.childNodes, x => x.classList.contains('selected'));
      if (selectedElement) {
        const textBeforeCursor = mention.getTextBeforeCursor().substring(Math.max(mention.mentionCharPos - 1, 0), mention.cursorPos);
        const itemText = selectedElement.innerHTML;

        // try to find the missing pieces from the auto filled value
        const textToInsert = Utility.findSuffixToAppend(textBeforeCursor, itemText);

        // @ts-ignore
        const {USER} = Quill.sources;
        this.editor.insertText(mention.cursorPos, textToInsert, USER);
        this.editor.setSelection(mention.cursorPos + textToInsert.length, USER);
      }
    }
  }

  public onInit($event: Quill): void {
    this.editor = $event;
  }
}

import * as uuidv4 from 'uuid-random';
import {CriteriaSearchOperator} from '../criteria-search-operator';
import {CriteriaConditionJson} from './criteria-condition-json';

export class CriteriaQueryGroupJson {
  public groups?: CriteriaQueryGroupJson[] = [];
  public whereCondition?: CriteriaConditionJson[] = [];
  public searchCondition?: CriteriaConditionJson<CriteriaSearchOperator>[] = [];
  public useOr?: boolean = false;
  public id?: string = uuidv4();
  public active?: boolean = true;
}

import {KolibriEntity} from '../..';

/**
 * Container element for the /search route
 */
export abstract class SearchResult<E extends KolibriEntity> {
  public results: E[];
  public count?: number;
  public _links?: {
    next?: string;
    prev?: string;
  };
}

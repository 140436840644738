import {ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input, OnChanges, SimpleChanges} from '@angular/core';
import {NG_VALIDATORS, NG_VALUE_ACCESSOR} from '@angular/forms';
import {_, MaybePromise, Utility} from '@wspsoft/frontend-backend-common';
import {CustomInput} from '../../custom-input';
import {AutoComplete} from '../autocomplete';

@Component({
  selector: 'ui-custom-list-autocomplete',
  templateUrl: './custom-list-autocomplete-input.component.html',
  styleUrls: ['../autocomplete.scss', './custom-list-autocomplete-input.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CustomListAutocompleteInputComponent),
    multi: true
  }, {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => CustomListAutocompleteInputComponent),
    multi: true,
  }, {
    provide: CustomInput,
    useExisting: forwardRef(() => CustomListAutocompleteInputComponent),
    multi: true
  }],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomListAutocompleteInputComponent extends AutoComplete<any> implements OnChanges {
  @Input()
  public values: string[];
  @Input()
  public displayField: string;
  @Input()
  public isObject: boolean;
  @Input()
  public search: (result: any[], query: string) => MaybePromise<any[]>;


  public constructor(cdr: ChangeDetectorRef) {
    super(cdr);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.values && !changes.values.firstChange) {
      this.suggestions = [];
    }
  }

  public onComplete($event: any): void {
    (async () => {
      let values = this.values;

      // apply custom filter function
      if (this.search) {
        const result = this.search(values, $event.query);
        values = _.isPromise(result) ? await result : result as string[];
      }

      // sort values either by their displayField or undefined if the values are just plain strings
      const sortedList = _.sortBy(_.filter(values, v => Utility.matches(this.getLabel(v), $event.query)), this.isObject ? this.displayField : undefined);
      this.suggestions = sortedList.slice(0, $event.offset + 30);
      this.cdr.detectChanges();

      if ($event.originalEvent.cb) {
        $event.originalEvent.cb();
      }
    })();
  }

  protected restoreOldValue(): void {
    if (this.values.map(v => this.getLabel(v)).includes(this.getLabel(this.oldValue))) {
      super.restoreOldValue();
    }
  }

  private getLabel(value: any): string {
    return this.isObject ? value?.[this.displayField] : value;
  }
}

import {AbstractCriteriaFactory} from '../criteria/abstract-criteria-factory';

import {CriteriaOperator} from '../criteria/criteria-operator';

import {TemplateEntryJson} from '../model/database/json/template';

import {KolibriEntity} from '../model/database/kolibri-entity';

import {Template, TemplatePayload} from '../model/database/template';
import {User} from '../model/database/user';
import {Field} from '../model/response/field';
import {Relation} from '../model/xml/relation';

import {AbstractModelService} from '../service/coded/abstract-model.service';

import {EntityModel} from '../service/entities/entity-model';

import {TemplateEntity, TemplateEntryOperator} from '../service/entities/template.entity';

import {AbstractEntityService} from '../service/generated/abstract-entity-service';

import {AbstractEntityServiceFactory} from '../service/generated/abstract-entity-service-factory';

import {AbstractRelationService, RelationInfo} from '../service/util/abstract-relation.service';
import {_} from '../util/underscore';

import {Utility} from '../util/utility';
import {AbstractKolibriMessage} from './abstract-kolibri-message';
import {AbstractKolibriScriptExecutor} from './abstract-kolibri-script-executor';
import {ScriptParams} from './script-objects';


export abstract class AbstractKolibriTemplate {
  protected constructor(protected criteriaFactory: AbstractCriteriaFactory, protected message: AbstractKolibriMessage,
                        protected relationService: AbstractRelationService, protected modelService: AbstractModelService,
                        protected entityServiceFactory: AbstractEntityServiceFactory) {
  }

  protected abstract get scriptExecutor(): AbstractKolibriScriptExecutor;

  protected static isAddOperator(templateEntryJson: TemplateEntryJson): boolean {
    return templateEntryJson.operator === TemplateEntryOperator.add || templateEntryJson.operator === TemplateEntryOperator.set;
  }

  protected static isRemoveOperator(templateEntryJson: TemplateEntryJson): boolean {
    return templateEntryJson.operator === TemplateEntryOperator.clear || templateEntryJson.operator === TemplateEntryOperator.set;
  }

  /**
   * returns the template as json or loads the template with the name or id
   * @param template the name, id of the template or the template itself
   */
  public async get(template: string | Template | TemplatePayload): Promise<TemplatePayload> {
    let templateToUse: Template;
    if (typeof template === 'string') {
      templateToUse = await this.loadTemplate(template);
    } else if ('entityClass' in template && template.entityClass === 'Template') {
      templateToUse = template as Template;
    } else {
      templateToUse = {payload: template as TemplatePayload};
      if (!('version' in templateToUse.payload)) {
        templateToUse.payload.version = TemplateEntity.TEMPLATE_VERSION;
      }
    }
    return this.getTemplateJson(templateToUse);
  }

  /**
   * apply a template to the given record
   */
  public async apply<T extends KolibriEntity>(recordsToApply: T | T[], templateOrId: string | Template | TemplatePayload<T>, user?: User,
                                              scriptParams: ScriptParams = {},
                                              formId?: string): Promise<(() => Promise<void>)[]> {
    try {
      if (!Array.isArray(recordsToApply)) {
        recordsToApply = [recordsToApply];
      }
      const template = await this.get(templateOrId);
      const result = [];
      for (const record of recordsToApply) {
        await this.runTemplateValueScripts(record, template, user, scriptParams, formId);

        record.merge(this.convertTemplateForAssign(template, record.entityClass) as any);

        for (const entry of Object.entries(template)) {
          const relationName = entry[0];
          const relation = this.modelService.getField(record.entityClass, relationName);
          if (!Utility.isRelation(relation) || !Utility.isToManyRelation(relation)) {
            continue;
          }
          // @ts-ignore
          const templateEntryJson = entry[1] as TemplateEntryJson;

          const destinationIds: string[] = templateEntryJson.value;
          const sourceMeta = this.modelService.getEntity(record.entityClass);
          const destinationMeta = this.modelService.getEntity((relation as Relation).targetId);
          const relationInfo = this.relationService.findInverseRelations(relationName, sourceMeta, destinationMeta);
          if (record.persisted) {
            await this.applyToMany(destinationMeta, user, templateEntryJson, record, relationInfo, destinationIds, relation);
          } else {
            result.push((record1 => {
              this.applyToMany(destinationMeta, user, templateEntryJson, record1, relationInfo, destinationIds, relation);
            }));
          }
        }
        if (template.hasOwnProperty('$Script')) {
          // @ts-ignore
          this.scriptExecutor.runScript(template.$Script.value, {...scriptParams, record, user}, formId, `Template:${template.name}:scriptedValue`);
        }
      }
      return result;
    } catch (ex: any) {
      this.message.addLog(ex);
    }
  }

  /**
   * returns the TemplateJson. if the version is old, it will be converted and saved in new version
   * @param template the template to load
   * @return the converted TemplateJson
   * @protected
   */
  protected getTemplateJson(template: Template): TemplatePayload {
    const templateEntity = new TemplateEntity(template.payload);
    return templateEntity.getJson();
  }

  /**
   * load the template with the name or id
   * @param templateNameOrId the Id of the templateToLoad
   * @return the preference
   * @private
   */
  protected loadTemplate(templateNameOrId: string): Promise<Template> {
    // insert name condition, if the template should be found in script with the name (see changes in annotate)
    return this.criteriaFactory.get<Template>('Template')
      .addCondition('id', CriteriaOperator.EQUAL, templateNameOrId)
      .getResult();
  }

  /**
   * apply to many relation by changing or saving the relation objects
   */
  private async applyToMany(destinationMeta: EntityModel, user: User, templateEntryJson: TemplateEntryJson, record: any | KolibriEntity,
                            relationInfo: RelationInfo, destinationIds: string[], relation: Field): Promise<void> {
    if (destinationMeta.mappingEntity) {
      // handle ManyToMany relations
      const mappingService = this.entityServiceFactory.getService(destinationMeta.id, user);
      await this.handleManyToMany(templateEntryJson, record, relationInfo, mappingService, destinationIds);
    } else {
      // clear, set and add OneToMany relations
      if (AbstractKolibriTemplate.isRemoveOperator(templateEntryJson)) {
        await this.removeAllOneToMany(destinationMeta, relation, record);
      }
      if (AbstractKolibriTemplate.isAddOperator(templateEntryJson)) {
        await this.addOneToMany(destinationMeta, destinationIds, relation, record);
      }
    }
  }

  /**
   * converts the template json to something _.assign can handle
   * @param templateJson the template payload
   * @param entityName name of the entity from the record that would be applied
   * @param formId form id if executed in the frontend
   * @private
   */
  private convertTemplateForAssign(templateJson: TemplatePayload, entityName: string): TemplatePayload {
    const convertedTemplateJson: TemplatePayload = {};
    for (const entry of Object.entries(templateJson)) {
      const key = entry[0];
      if (key === 'version') {
        continue;
      }
      let calculatedKey = key;
      const relationOrAttribute = this.modelService.getField(entityName, key);
      if (Utility.isRelation(relationOrAttribute) && Utility.isToOneRelation(relationOrAttribute)) {
        calculatedKey = Utility.parameterizeEntityName(key);
      }
      const templateEntryJson = entry[1] as TemplateEntryJson;
      switch (templateEntryJson.operator) {
        case TemplateEntryOperator.clear:
          convertedTemplateJson[calculatedKey] = null;
          break;
        // script will be converted to a value
        case TemplateEntryOperator.script:
        case TemplateEntryOperator.set:
          convertedTemplateJson[calculatedKey] = templateEntryJson.value;
          break;
        // TemplateEntryOperator.add only for toMany relations, what doesn't have to be handled here
      }
    }
    return convertedTemplateJson;
  }

  /**
   * add set or clears the many to many relations
   * @param templateEntryJson
   * @param record the source record to apply to
   * @param relationInfo relation information for many to many
   * @param mappingService the mappingService to execute queries
   * @param destinationIds the uuids of the relations to delete
   * @private
   */
  private async handleManyToMany(templateEntryJson: TemplateEntryJson, record: any | KolibriEntity, relationInfo: RelationInfo,
                                 mappingService: AbstractEntityService<KolibriEntity>, destinationIds: string[]): Promise<void> {
    if (templateEntryJson.operator === TemplateEntryOperator.clear) {
      await this.relationService.removeAllMappingEntities(record, relationInfo.sourceRelation, mappingService.entityMeta.name);
    } else if (templateEntryJson.operator === TemplateEntryOperator.add) {
      const destinationEntities = await this.criteriaFactory.get(relationInfo.destinationRelation.targetId)
        .addCondition('id', CriteriaOperator.IN, destinationIds)
        .getResults();
      await this.relationService.createMappingEntities(record, destinationEntities, relationInfo.sourceRelation.name,
        relationInfo.destinationRelation.name, mappingService, {doSave: true});
    } else {
      const destinationEntities = await this.criteriaFactory.get(relationInfo.destinationRelation.targetId)
        .addCondition('id', CriteriaOperator.IN, destinationIds)
        .getResults();
      await this.relationService.setMappingEntities(record, destinationEntities, relationInfo.sourceRelation.name,
        relationInfo.destinationRelation.name, mappingService, {doSave: true});
    }
  }

  /**
   * adds the oneToMany relations
   * @param destinationMeta the entity model of the destination entity
   * @param destinationIds the uuids of the records to add
   * @param relation the relation of the record
   * @param recordToApply the record to apply
   * @private
   */
  private async addOneToMany(destinationMeta: EntityModel, destinationIds: string[], relation: Relation,
                             recordToApply: KolibriEntity): Promise<void> {
    const destinationEntities = await this.criteriaFactory.get(destinationMeta.id)
      .addCondition('id', CriteriaOperator.IN, destinationIds)
      .getResults();
    for (const destinationEntity of destinationEntities) {
      destinationEntity[relation.targetRelationName] = recordToApply;
      await destinationEntity.update();
    }
  }

  /**
   * removes all relations of the record for the given relation
   * @param destinationMeta the entity model of the destination entity
   * @param relation the relation of the record
   * @param recordToApply the record to apply
   * @private
   */
  private async removeAllOneToMany(destinationMeta: EntityModel, relation: Relation, recordToApply: KolibriEntity): Promise<void> {
    const destinationEntities = await this.criteriaFactory.get(destinationMeta.id)
      .addCondition(relation.targetRelationName, CriteriaOperator.EQUAL, recordToApply.id)
      .getResults();
    for (const destinationEntity of destinationEntities) {
      destinationEntity[relation.targetRelationName] = null;
      await destinationEntity.update();
    }
  }

  /**
   * execute all script fields in the template
   */
  private runTemplateValueScripts<T extends KolibriEntity>(record: T, template: TemplatePayload, user: User,
                                                           scriptParams: ScriptParams, formId: string): Promise<void> {
    return _.parallelDo(Object.values(template), async templateEntryJson => {
      if (typeof templateEntryJson === 'object' && templateEntryJson.operator === TemplateEntryOperator.script) {
        templateEntryJson.value = await this.scriptExecutor.runScript(templateEntryJson.value, {
          ...scriptParams, record, user
        }, formId).result;
      }
    });
  }
}

import {ChangeDetectorRef, Directive, EventEmitter, Input, Output} from '@angular/core';

// noinspection AngularMissingOrInvalidDeclarationInModule
@Directive()
// tslint:disable-next-line:directive-class-suffix
export abstract class CustomWriter<E> {
  @Input()
  public nativeElement: boolean;
  @Input()
  public valueBinding: E;
  @Output()
  public valueBindingChange: EventEmitter<E> = new EventEmitter();
  @Output()
  public onUpdate: EventEmitter<E> = new EventEmitter();

  protected constructor(protected cdr: ChangeDetectorRef) {
  }

  public get value(): E {
    return this.valueBinding;
  }

  public set value(value: E) {
    if (value !== undefined) {
      this.valueBinding = value;
      this.valueBindingChange.emit(this.valueBinding);
      this.cdr.detectChanges();
    }
  }

  /**
   * force update of value in case it is an object
   */
  public forceUpdate(): void {
    this.onUpdate.emit(this.valueBinding);
  }
}

import {AfterContentInit, Component, NgZone, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {LoginMethod} from '@wspsoft/frontend-backend-common';
import {MessageService} from 'primeng/api';
import {AuthService, UserService} from '../../../../api';
import {FormComponent} from '../../../../ui';

@Component({
  selector: 'login-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss', '../form.scss']
})
export class PasswordResetComponent extends FormComponent implements OnInit, AfterContentInit {
  public newPassword: string;
  public newPasswordRep: string;
  public token: string;

  public constructor(private authService: AuthService, messageService: MessageService,
                     private activateRoute: ActivatedRoute,
                     translateService: TranslateService, private zone: NgZone, private userService: UserService) {
    super(messageService, translateService);
    this.hasAsyncValidations = false;
    this.showSuccessMessage = false;
  }

  public ngOnInit(): void {
    this.token = this.activateRoute.snapshot.queryParams.token;
  }

  public ngAfterContentInit(): void {
    // well there is no real done, so trigger some time later...
    const subscription = this.zone.onStable.subscribe(() => {
      subscription.unsubscribe();
      this.focusFirstEmptyInput();
    });
  }

  public async doSave(): Promise<boolean> {
    if (this.newPassword !== this.newPasswordRep) {
      this.messageService.add({
        severity: 'error',
        detail: this.translateService.instant('UserProfile.ChangePassword.Match')
      });
      return false;
    }

    try {
      if (this.token) {
        const user = await this.authService.passwordReset(this.token, this.newPassword);
        await this.authService.login(user.username, this.newPassword, undefined, LoginMethod.basic);
      } else {
        await this.userService.saveUserPassword(this.newPassword);
      }
      window.location.href = '/';
      return true;
    } catch (e: any) {
      this.messageService.add({
        severity: 'error',
        detail: this.translateService.instant('Login.PasswordReset.Error'),
        summary: ''
      });
      return false;
    }
  }
}

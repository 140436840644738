import {AbstractEntityService, ApplicationModel, EntityModel, KolibriEntity, User} from '../..';

import {AbstractModelService} from '../coded/abstract-model.service';
import {EntityServiceOptions} from './service-options';

export abstract class AbstractEntityServiceFactory {
  protected constructor(protected modelService: AbstractModelService) {
  }

  /**
   *
   * @param entityNameOrId name or id of the entity
   * @param user currentUser
   * @param options configure service capabilities
   */
  public abstract getService<E extends KolibriEntity>(entityNameOrId: string, user?: User, options?: EntityServiceOptions): AbstractEntityService<E>;

  public getModelData(entityName: string): { entity: EntityModel; application: ApplicationModel } {
    const entity = this.modelService.getEntity(entityName);

    if (!entity) {
      return {entity: undefined, application: undefined};
    }

    const application = this.modelService.getApplication(entity.applicationId);
    return {entity, application};
  }
}

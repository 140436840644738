export interface ButtonClickEvent {
  originalEvent?: MouseEvent | KeyboardEvent;
  data?: any;
  cb?: () => void;
}

export enum ButtonSize {
  SMALL = 'small', LARGE = 'large', MEDIUM = 'medium'
}

export enum ButtonType {
  PRIMARY = 'primary',
  CANCEL = 'cancel',
  OTHER = 'other',
  NEGATIVE = 'negative',
  POSITIVE = 'positive',
  RED = 'red',
  GREEN = 'green',
  BLUE = 'blue',
  ICON_ONLY = 'icon-only',
  ICON_RIGHT = 'icon-right',
  LAYOUT_BUTTON = 'layout',
  INLINE = 'inline',
  FAB = 'fab',
  FAB_ITEM = 'fab-item',
  TRAY = 'tray',
  SNIPPET_RIGHT = 'snippet-right',
  SNIPPET_LEFT = 'snippet-left',
  TAB = 'tab',
  TAB_ICON_ONLY = 'tab-icon-only',
  TAB_RIGHT = 'tab-right',
  TAB_LEFT = 'tab-left',
  FORM = 'form'
}

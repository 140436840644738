import {Field} from '../response/field';
import {Attribute} from './attribute';
import {DesignerEntity} from './designer.entity';
import {Entity} from './entity';


export abstract class FullCalendarEvent extends DesignerEntity {
  public entityId?: string;
  public filterScript?: string;
  public resourceFieldId?: string;
  public startFieldId?: string;
  public endFieldId?: string;

  public entity?: Entity;
  public resourceField?: Field;
  public startField?: Attribute;
  public endField?: Attribute;
}

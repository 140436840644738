<ui-input-wrapper [customValidationMessage]="customValidationMessage" [dirty]="inputElement.dirty" [disable]="disable" [errors]="inputElement.errors"
                  [filled]="nativeInput.filled" [hasFocus]="hasFocus" [hasLabelAlwaysUp]="true" [helpMessage]="helpMessage" [label]="label" [name]="name"
                  [renderInputGroup]="renderInputGroup" [required]="required" [touched]="inputElement.touched" [valid]="inputElement.valid">
  <p-dropdown #inputElement="ngModel" #nativeInput (onHide)="onHide.emit($event)" (onShow)="onShow.emit($event)" [(ngModel)]="value"
              [autoDisplayFirst]="autoDisplayFirst" [dataKey]="dataKey" [disabled]="disable" [dropdownIcon]="dropdownIcon" [editable]="false" [filter]="filter"
              [group]="!!groupBy" [ngClass]="{'one-input': renderInputGroup}" [optionLabel]="optionLabel" [options]="options"
              [panelStyleClass]="panelStyleClass" [placeholder]="placeholder" [required]="required" [resetFilterOnHide]="false" [styleClass]="styleClass"
              appendTo="body" emptyFilterMessage="{{emptyMessage | translate: {minCharacters} }}"
              emptyMessage="{{emptyMessage |  translate: {minCharacters} }}">
    <ng-template *ngIf="!!groupBy" let-group pTemplate="group">
      <ui-field-translator [data]="group.items[0]" [field]="groupBy" [translateNull]="true"></ui-field-translator>
    </ng-template>
    <ng-template *ngIf="itemTemplate" let-item pTemplate="item">
      <ng-container *ngTemplateOutlet="itemTemplate; context: {$implicit: item}"></ng-container>
    </ng-template>
    <ng-template *ngIf="!itemTemplate" let-item pTemplate="item">
      <ui-field-translator [data]="item" field="representativeString" typeName="KolibriEntity"></ui-field-translator>
    </ng-template>
  </p-dropdown>
</ui-input-wrapper>


import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'join'
})
export class JoinPipe implements PipeTransform {

  public transform(value: string[], ...args: unknown[]): string {
    return value.join(' ');
  }

}

import {ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input, OnInit} from '@angular/core';
import {NG_VALIDATORS, NG_VALUE_ACCESSOR} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {_, CriteriaOperator, CriteriaType} from '@wspsoft/frontend-backend-common';
import {CriteriaFactory, ModelService, ModelTranslationService} from '../../../../../../api';
import {CustomInput} from '../../custom-input';
import {MultiSelect} from '../multi-select';

@Component({
  selector: 'ui-entity-multiselect',
  templateUrl: './entity-multi-select-input.component.html',
  styleUrls: ['./entity-multi-select-input.component.scss'],
  // using same less file as for choices multiselect, keeping empty original less file in here for completeness
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => EntityMultiSelectInputComponent),
    multi: true
  }, {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => EntityMultiSelectInputComponent),
    multi: true,
  }, {
    provide: CustomInput,
    useExisting: forwardRef(() => EntityMultiSelectInputComponent),
    multi: true
  }],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EntityMultiSelectInputComponent extends MultiSelect<any[]> implements OnInit {
  @Input()
  public targetEntity: string;
  @Input()
  public size: number = 30;

  public constructor(private translateService: TranslateService, private modelService: ModelService, private criteriaFactory: CriteriaFactory,
                     private modelTranslationService: ModelTranslationService, cdr: ChangeDetectorRef) {
    super(cdr);
  }

  public get value(): any[] {
    return super.value;
  }

  public set value(value: any[]) {
    // if we have a value, and no options, we are forced to load some
    // as primeng won't show anything without options
    if (this.value?.length && this.options.length === 0) {
      (async () => {
        await this.setupOptions();
        // we need to update the reference
        this.options = [...this.options];
        this.cdr.detectChanges();
        super.value = value;
      })();
    } else {
      super.value = value;
    }
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  public ngOnInit(): void {
    // override parent and prevent default
  }

  public async narrowDownOptions(event: any): Promise<void> {
    await this.setupOptions(event.filter);
    event.cb();
  }

  protected async setupOptions(filterString?: string): Promise<void> {
    this.loading = true;
    this.cdr.detectChanges();

    const entity = this.modelService.getEntity(this.targetEntity);
    const query =
      this.criteriaFactory.getFrontendQuery(entity.name, CriteriaType.SELECT)
        .limit(this.size)
        .addOrder('representativeString');
    if (filterString) {
      query.addCondition('representativeString', CriteriaOperator.BEGINS_WITH, filterString);
    }

    const entities = _.uniqBy((this.value ?? []).concat(await query.getResults()), 'id');

    // move translations up to the entity for ac
    for (const value of entities) {
      value.label = await this.modelTranslationService.translateObjectValue(value, 'representativeString', this.translateService.currentLang);
    }
    // remove all entries that don't have a Value
    const selectArray = _.remove(entities, r => r.label);
    this.options.length = 0;
    this.options.push(..._.sortBy(selectArray, 'label').map(value => ({
      value,
      label: value.label,
      icon: value.icon,
      title: value.label
    })));

    this.loading = false;
    this.cdr.detectChanges();
  }
}

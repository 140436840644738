import {DesignerEntity} from './designer.entity';
import {GuidedTour} from './guided-tour';
import {GuidedTourStep} from './guided-tour-step';


export class GuidedTourMappingEntity extends DesignerEntity {
  public guidedTour: GuidedTour;
  public guidedTourStep: GuidedTourStep;
  public guidedTourStepId?: string;
  public guidedTourId?: string;
}

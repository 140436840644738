import {AbstractEntityService, CriteriaGroupBy, EntityReadOptions, KolibriEntity, SearchResult} from '..';
import {CriteriaField} from './criteria-field';
import {CriteriaFunction} from './criteria-function';
import {CriteriaOrder} from './criteria-order';
import {CriteriaOrderBy} from './criteria-order-by';
import {CriteriaQueryGroup} from './criteria-query-group';
import {CriteriaTenantMode} from './criteria-tenant-mode';
import {CriteriaTransform} from './criteria-transform';
import {CriteriaType} from './criteria-type';
import {CriteriaQueryJson} from './json/criteria-query-json';

/**
 * This describes an abstraction layer for database queries
 */
export class CriteriaQuery<E extends KolibriEntity> extends CriteriaQueryGroup<E> {
  private static readonly CRITERIA_JSON_VERSION: string = '1.0';
  // noinspection JSUnusedLocalSymbols (model entity name used for api)
  public pTenancyFilter: CriteriaTenantMode = CriteriaTenantMode.PRIMARY;
  public pOffset: number = 0;
  public pView: string;
  public pIndexHint: string[] | string;
  public pLimit: number;
  public fullCount: boolean;
  public pDescendants: boolean;
  public pAutoIndex: boolean;
  public pIncludeShared: boolean = false;
  public orders: CriteriaOrderBy[] = [];
  public transforms: CriteriaTransform[] = [];
  public groupBys: CriteriaGroupBy[] = [];
  public selectFields: CriteriaField[] = [];
  public deselectFields: CriteriaField[] = [];
  // not used right now
  private criteriaFunction: CriteriaFunction;

  public constructor(private entityService?: AbstractEntityService<E>, private modelEntityName?: string, public type: CriteriaType = CriteriaType.SELECT) {
    super(false);
  }

  public get entityName(): string {
    return this.modelEntityName;
  }

  public view(view: string): this {
    this.pView = view;
    return this;
  }

  public indexHint(indexName: string[] | string): this {
    this.pIndexHint = indexName;
    return this;
  }

  public limit(size: number): this {
    this.pLimit = size;
    return this;
  }

  public descendants(descendants: boolean): this {
    this.pDescendants = descendants;
    return this;
  }

  /**
   * includes the shareable entity permission query
   * @param includeShared optional (true ist default)
   * @return {this}
   */
  public includeShared(includeShared: boolean = true): this {
    this.pIncludeShared = includeShared;
    return this;
  }

  /**
   * set the tenancy filter of the query
   * ATTENTION: CriteriaTenantMode.NONE is only allowed in backend
   * @param filter the tenancy filter mode to use
   */
  public tenancyFilter(filter: CriteriaTenantMode): this {
    this.pTenancyFilter = filter;
    return this;
  }

  public autoIndex(autoIndex: boolean = true): this {
    this.pAutoIndex = autoIndex;
    return this;
  }

  public addOffset(offset: number): this {
    this.pOffset += offset;
    return this;
  }

  public offset(offset: number = 0): this {
    this.pOffset = offset;
    return this;
  }

  // noinspection JSUnusedGlobalSymbols
  public addOrder(columnName: Extract<keyof E, string> | string, orderDir: CriteriaOrder = CriteriaOrder.ASC, fn: CriteriaFunction = CriteriaFunction.NOP,
                  options?: any): this {
    this.orders.push(new CriteriaOrderBy(columnName, orderDir, fn, options));
    return this;
  }

  public addTransform(sourceColumn: Extract<keyof E, string> | string, targetColumn: Extract<keyof E, string> | string = sourceColumn,
                      criteriaFunction?: CriteriaFunction, options?: any): this {
    this.transforms.push(new CriteriaTransform(sourceColumn, targetColumn, criteriaFunction, options));
    return this;
  }

  public addGroupBy(name: Extract<keyof E, string> | string, fn: CriteriaFunction = CriteriaFunction.NOP, options?: any): this {
    this.groupBys.push({columnName: name, criteriaFunction: fn, options});
    return this;
  }

  public addSelectField(name: Extract<keyof E, string> | string, fn: CriteriaFunction = CriteriaFunction.NOP, options?: any): this {
    this.selectFields.push({columnName: name, criteriaFunction: fn, options});
    return this;
  }

  public addDeselectField(name: Extract<keyof E, string> | string): this {
    this.deselectFields.push({columnName: name});
    return this;
  }

  /**
   * execute query and get single result
   * @returns {Promise<E>}
   */
  public async getResult(options?: EntityReadOptions): Promise<E> {
    this.limit(1);
    const results = await this.getResults(options);
    if (results.length) {
      return results[0];
    }
    return null;
  }

  /**
   * get results with fullCount
   * @see getResults
   * @returns {Promise<SearchResult<E>>}
   */
  public search(options?: EntityReadOptions): Promise<SearchResult<E>> {
    delete this.criteriaFunction;
    this.fullCount = true;
    return this.entityService.executeSearchQuery(this, options);
  }

  /**
   * execute query and retrieve result list
   * @returns {Promise<E[]>}
   */
  public getResults(options?: EntityReadOptions): Promise<E[]> {
    delete this.criteriaFunction;
    this.fullCount = false;
    return this.type === CriteriaType.SELECT ? this.entityService.getEntities(this, options) : this.execute(options);
  }

  /**
   * returns the arango query explain plan
   */
  // eslint-disable-next-line require-await
  public async explain(): Promise<string> {
    if ('explain' in this.entityService) {
      // @ts-ignore, only possible in backend
      return this.entityService.explain(this);
    }
    return 'not supported';
  }

  /**
   * execute a query that returns custom results of modifies data
   */
  public async execute(options?: EntityReadOptions): Promise<any> {
    this.fullCount = false;
    return (await this.entityService.execute(this, options)).results;
  }

  /**
   * just count the results and check if
   */
  public async hasResults(count: number = 1, options?: EntityReadOptions): Promise<boolean> {
    this.limit(count || 1);
    const rowCount = await this.getRowCount(options);
    return rowCount === count;
  }

  /**
   * just count the results
   */
  public async getRowCount(options?: EntityReadOptions): Promise<number> {
    this.fullCount = false;
    this.criteriaFunction = CriteriaFunction.COUNT;
    const searchResult = await this.entityService.execute(this, options);
    return searchResult.results[0] ?? 0;
  }

  public fromJson(queryJson: CriteriaQueryJson): this {
    super.fromJson(queryJson);

    this.groupBys = queryJson.groupBys || [];
    this.orders = queryJson.orders || [];
    this.transforms = queryJson.transforms || [];
    this.selectFields = queryJson.selectFields || [];
    this.deselectFields = queryJson.deselectFields || [];
    this.pLimit = queryJson.limit;
    this.pOffset = queryJson.offset;
    this.pIndexHint = queryJson.indexHint;
    this.pView = queryJson.view;
    this.criteriaFunction = queryJson.criteriaFunction;
    this.pAutoIndex = queryJson.autoIndex;
    this.fullCount = queryJson.fullCount;
    this.pDescendants = queryJson.descendants;
    this.pIncludeShared = queryJson.includeShared;
    this.pTenancyFilter = queryJson.tenancyFilter;
    this.type = queryJson.type;
    this.active = queryJson.active;

    return this;
  }

  public getJson(): CriteriaQueryJson {
    return {
      ...super.getJson(),
      orders: this.orders.filter(value => value.columnName),
      transforms: this.transforms,
      groupBys: this.groupBys,
      selectFields: this.selectFields,
      deselectFields: this.deselectFields,
      criteriaFunction: this.criteriaFunction,
      useOr: false,
      view: this.pView,
      indexHint: this.pIndexHint,
      limit: this.pLimit,
      offset: this.pOffset,
      descendants: this.pDescendants ?? this.entityService.options.descendants,
      includeShared: this.pIncludeShared,
      tenancyFilter: this.pTenancyFilter,
      fullCount: this.fullCount,
      autoIndex: this.pAutoIndex,
      type: this.type,
      entity: this.entityService?.entityMeta.name,
      version: CriteriaQuery.CRITERIA_JSON_VERSION,
      active: this.active
    };
  }

  /**
   * clones the current query
   */
  public clone(): CriteriaQuery<E> {
    const clone = new CriteriaQuery<E>(this.entityService, this.modelEntityName, this.type);
    clone.fromJson(this.getJson());
    return clone;
  }

  public merge(query: CriteriaQuery<any>): void {
    this.pView = this.pView || query.pView;
    this.pIndexHint = this.pIndexHint || query.pIndexHint;
    this.pLimit = this.pLimit || query.pLimit;
    this.pOffset = this.pOffset || query.pOffset;
    this.pDescendants = this.pDescendants || query.pDescendants;
    this.pIncludeShared = this.pIncludeShared || query.pIncludeShared;
    this.pTenancyFilter = this.pTenancyFilter || query.pTenancyFilter;
    this.pAutoIndex = this.pAutoIndex || query.pAutoIndex;
    this.mergeArrayField(query, 'groups');
    this.mergeArrayField(query, 'orders');
    this.mergeArrayField(query, 'whereCondition');
    this.mergeArrayField(query, 'searchCondition');
    this.mergeArrayField(query, 'selectFields');
    this.mergeArrayField(query, 'deselectFields');
    this.mergeArrayField(query, 'groupBys');
  }

  private mergeArrayField(query: CriteriaQuery<any>, field: string): void {
    this[field] = [...(this[field] || []), ...(query[field] || [])];
  }
}

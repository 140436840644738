import {CriteriaOrder} from '@wspsoft/frontend-backend-common';

export class QueryOrder {
  public constructor(public order: CriteriaOrder, public label: string, private value?: string) {
  }

  public getValue(): string {
    return this.value || CriteriaOrder[this.order];
  }
}

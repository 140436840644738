<ui-input-wrapper [customValidationMessage]="customValidationMessage" [dirty]="inputElement.dirty" [disable]="disable" [errors]="inputElement.errors"
                  [filled]="nativeInput.filled()" [hasFocus]="hasFocus" [helpMessage]="helpMessage" [label]="label" [name]="name" [required]="required"
                  [touched]="inputElement.touched" [valid]="inputElement.valid">

  <ng-container *ngIf="disableAutofill">
    <!-- 'autocomplete="off"' on form and on input doesn't work for chrome anymore if there are more than 2 fields in form -->
    <!-- fake fields are a workaround for chrome autofill getting the wrong fields -->
    <!-- display:none on these inputs also doesn't work anymore -->
    <input autocomplete="new-username" name="fakeusernameremembered" style="position: fixed;top:-120px;left:-100px; width:5px;" tabindex="-1" type="text"/>
    <input autocomplete="new-password" name="fakepasswordremembered" style="position: fixed;top:-100px;left:-100px; width:5px;" tabindex="-1" type="password"/>
  </ng-container>

  <p-password #inputElement="ngModel" #nativeInput [(ngModel)]="value" [disabled]="disable" [feedback]="feedback" [inputStyle]="styleData"
              [mediumRegex]="strongRegex" [minlength]="minlength" [ngModelOptions]="{updateOn: 'change'}" [required]="required" [strongRegex]="strongRegex"
              [toggleMask]="showShowPassword" appendTo="body" class="one-password  one-input {{disable ? 'one-input--readonly' : ''}}"
              mediumLabel="{{ 'Password.Medium' | translate}}" promptLabel="{{ 'Password.Empty' | translate}}" strongLabel="{{ 'Password.Strong' | translate}}"
              weakLabel="{{ 'Password.Weak' | translate}}">
    <ng-template *ngIf="helpMessage" pTemplate="footer">
      <div [innerHTML]="helpMessage | translate" class="one-password__footer"></div>
    </ng-template>
  </p-password>
</ui-input-wrapper>


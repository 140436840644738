import {HttpClient} from '@angular/common/http';
import {Inject, Injectable, Optional} from '@angular/core';
import {
  AbstractBackendSessionService,
  OneHistory,
  OneLayoutState,
  OneMenuState,
  OnePageRightState,
  OneTableState,
  User,
  UserHotkeyOverwriteData,
  UserProfile,
  UserSessionSettings
} from '@wspsoft/frontend-backend-common';
import {Configuration} from '../../configuration';
import {BASE_PATH} from '../../variables';
import {AbstractService} from '../abstract-service';

@Injectable()
export class BackendSessionService extends AbstractService implements AbstractBackendSessionService {

  public constructor(httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string,
                     @Optional() configuration: Configuration) {
    super(httpClient, basePath, configuration);

    this.basePath = '/api/rest/private/portal/session';
  }

  public changeAvatar(attachmentId: string): Promise<void> {
    const {queryParameters, headers} = this.getHeaders();

    return this.doPost<void>(`${this.basePath}/changeAvatar/${attachmentId}`, {},
      queryParameters, headers);
  }

  public changeActiveProfile(profile: UserProfile): Promise<void> {
    const {queryParameters, headers} = this.getHeaders();

    return this.doPut<void>(`${this.basePath}/activeProfile`, profile,
      queryParameters, headers);
  }

  public async saveCurrentDashboard(dashboardId: string, user: User): Promise<UserSessionSettings> {
    const {queryParameters, headers} = this.getHeaders();

    return user.activeProfile.sessionSettings = await this.doPost<UserSessionSettings>(`${this.basePath}/currentDashboard/${dashboardId}`, {},
      queryParameters, headers);
  }

  /**
   * @see AbstractBackendSessionService.saveRecentGlobalSearch
   */
  public async saveRecentGlobalSearch(query: string, user: User): Promise<UserSessionSettings> {
    const {queryParameters, headers} = this.getHeaders();

    return user.activeProfile.sessionSettings = await this.doPost<UserSessionSettings>(`${this.basePath}/globalSearches`, {query},
      queryParameters, headers);
  }

  public async saveTableState(tableId: string, tableState: OneTableState, user: User): Promise<UserSessionSettings> {
    const {queryParameters, headers} = this.getHeaders();

    return user.activeProfile.sessionSettings = await this.doPost<UserSessionSettings>(`${this.basePath}/tableState/${tableId}`, tableState,
      queryParameters, headers);
  }


  public async saveLeftMenuState(leftMenuState: OneMenuState, user: User): Promise<UserSessionSettings> {
    const {queryParameters, headers} = this.getHeaders();

    return user.activeProfile.sessionSettings = await this.doPost<UserSessionSettings>(`${this.basePath}/leftMenuState`, leftMenuState,
      queryParameters, headers);
  }

  public async saveUserHotkeyOverwrites(userHotkeyOverwrites: UserHotkeyOverwriteData, user: User): Promise<UserSessionSettings> {
    const {queryParameters, headers} = this.getHeaders();
    return user.activeProfile.sessionSettings = await this.doPost<UserSessionSettings>(`${this.basePath}/userHotkeyOverwrites`, userHotkeyOverwrites,
      queryParameters, headers);
  }

  public async saveOneHistory(oneHistory: OneHistory, user: User): Promise<UserSessionSettings> {
    const {queryParameters, headers} = this.getHeaders();

    return user.activeProfile.sessionSettings = await this.doPost<UserSessionSettings>(`${this.basePath}/oneHistory`, oneHistory,
      queryParameters, headers);
  }

  public async saveLayoutState(layoutName: string, tabState: OneLayoutState, user: User): Promise<UserSessionSettings> {
    const {queryParameters, headers} = this.getHeaders();

    return user.activeProfile.sessionSettings = await this.doPut<UserSessionSettings>(`${this.basePath}/layoutState/${layoutName}`, tabState,
      queryParameters, headers);
  }

  public async savePageRightState(layoutName: string, state: OnePageRightState, user: User): Promise<UserSessionSettings> {
    const {queryParameters, headers} = this.getHeaders();

    return user.activeProfile.sessionSettings = await this.doPut<UserSessionSettings>(`${this.basePath}/pageRightState/${layoutName}`, state,
      queryParameters, headers);
  }
}

import {Injectable} from '@angular/core';
import {AbstractCriteriaFactory, CriteriaQuery, CriteriaType, EntityServiceOptions, KolibriEntity} from '@wspsoft/frontend-backend-common';

import {EntityServiceFactory} from '../service/generated/entity-service-factory';

@Injectable()
export class CriteriaFactory extends AbstractCriteriaFactory {
  public constructor(entityServiceFactory: EntityServiceFactory) {
    super(entityServiceFactory);
  }

  public getFrontendQuery<E extends KolibriEntity>(entity: string, type: CriteriaType = CriteriaType.SELECT, options?: EntityServiceOptions): CriteriaQuery<E> {
    // user is not relevant for ui
    return this.get(entity, type, null, options);
  }
}

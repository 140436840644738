import {CriteriaOrder} from '../../criteria/criteria-order';
import {CriteriaQueryJson} from '../../criteria/json/criteria-query-json';
import {AggregationFunction} from '../xml/aggregation-function';
import {KolibriEntity} from './kolibri-entity';
import {ShareableEntity} from './shareable-entity';

export abstract class WidgetDataSet extends KolibriEntity {
  public name: string;
  public order: number;
  public aggregationFunction: AggregationFunction;
  public aggregationField: string;
}

export class Widget extends ShareableEntity {
  public type: WidgetType;
  public entity: string;
  public query: CriteriaQueryJson;
  public groupBy: string;
  public count: number = 5;
  public order: CriteriaOrder = CriteriaOrder.DESC;
  public limit: boolean = false;
  public descendants: boolean = false;
  public advancedConfig: boolean = false;
  public url: string;
  public icon: string;
  public openInNewTab: boolean;
  public columns: string[] = [];
  public aggregationFunction: AggregationFunction;
  public aggregationField: string;
  public granularity: WidgetGranularity;
  public gradient: Gradient;
  public gaugeType: GaugeType;
  public gaugeMaxType: GaugeMaxType;
  public gaugeThreshold: GaugeThreshold;
  public fixedGaugeValue: number;
  public scriptGaugeValue: string;
  public customThreshold: number;
  public thresholdInPercent: boolean;
  public disableDrilldown: boolean;
  public drilldownListId: string;

  public cssContent: string;
  public htmlContent: string;
  public jsContent: string;

  public layout: string;
  public initScript: string;
  public datasets: WidgetDataSet[];
}

export enum WidgetType {
  GLOBAL_SEARCH = 'GLOBAL_SEARCH',
  COLUMN_CHART = 'COLUMN_CHART',
  COUNT = 'COUNT',
  BAR_CHART = 'BAR_CHART',
  LINK = 'LINK',
  TABLE = 'TABLE',
  PIE_CHART = 'PIE_CHART',
  LINE_CHART = 'LINE_CHART',
  GAUGE_CHART = 'GAUGE_CHART',
  DUMBBELL_CHART = 'DUMBBELL_CHART',
  CUSTOM = 'CUSTOM',
  LAYOUT = 'LAYOUT',
}

export enum WidgetGranularity {
  Hour = 'hour',
  DayOfMonth = 'dayOfMonth',
  Weekday = 'weekday',
  WeekOfYear = 'weekOfYear',
  Month = 'month',
  Year = 'year',
  Date = 'date',
}

export enum Gradient {
  redToLeft = 'redToLeft',
  redToRight = 'redToRight',
}

export enum GaugeType {
  absolute = 'absolute',
  percent = 'percent',
}

export enum GaugeMaxType {
  fixed = 'fixed',
  script = 'script',
}

export enum GaugeThreshold {
  none = 'none',
  custom = 'custom',
}

export default Widget;

<ui-input-wrapper [customValidationMessage]="customValidationMessage" [dirty]="inputElement.dirty" [disable]="disable" [errors]="inputElement.errors"
                  [filled]="nativeInput.filled" [hasFocus]="hasFocus" [helpMessage]="helpMessage" [label]="label" [name]="name" [renderInputGroup]="false"
                  [required]="required" [touched]="inputElement.touched" [valid]="inputElement.valid">
  <p-multiSelect #inputElement="ngModel" #nativeInput (onBlur)="validate()" (onChange)="doChange()" (onFilter)="emitOnFilter($event.filter)"
                 (onPanelHide)="onPanelHide.emit(value)" (onPanelShow)="emitOnFilter('')" [(ngModel)]="value" [dataKey]="dataKey" [defaultLabel]="placeholder"
                 [disabled]="disable" [filterBy]="optionLabel" [filter]="true" [id]="name" [maxSelectedLabels]="2" [optionLabel]="optionLabel"
                 [optionValue]="optionValue" [options]="options" [panelStyleClass]="loading ? 'one-multiselect--loading': ''" [placeholder]="placeholder" [required]="required"
                 appendTo="body" class="one-multiselect--table-filter"
                 emptyFilterMessage="{{'AutoComplete.NoResults' | translate}}" emptyMessage="{{'AutoComplete.NoResults' | translate}}"
                 selectedItemsLabel="{{'MultiSelect.SelectedLabel' | translate}}">
    <ng-template let-item pTemplate="item">
      <i *ngIf="item.icon" [className]="'one-autocomplete__column--icon fa-fw ' + item.icon" [ngStyle]="{color: item.value.color}"></i>
      <span class="p-autocomplete-token-label">{{item[optionLabel]}}</span>
    </ng-template>
  </p-multiSelect>
</ui-input-wrapper>

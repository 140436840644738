import {APP_BASE_HREF, CommonModule} from '@angular/common';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {NgxUiLoaderHttpModule, NgxUiLoaderModule, NgxUiLoaderRouterModule} from 'ngx-ui-loader';
import {MessageService} from 'primeng/api';
import {ApiModule} from '../../api';
import {HttpLoaderFactory, UiModule} from '../../ui';
import {AppRoutingModule} from './app-routing.module';


import {AppComponent} from './app.component';
import {AuthenticationPageComponent} from './components/authentication-page/authentication-page.component';
import {LoginComponent} from './components/login/login.component';
import {LogoutComponent} from './components/logout/logout.component';
import {MultiAuthComponent} from './components/multi-auth/multi-auth.component';
import {PasswordResetComponent} from './components/password-reset/password-reset.component';
import {RequestPasswordResetComponent} from './components/request-password-reset/request-password-reset.component';
import {SelfRegistrationComponent} from './components/self-registration/self-registration.component';

@NgModule({
  declarations: [
    AppComponent,
    LogoutComponent,
    LoginComponent,
    SelfRegistrationComponent,
    PasswordResetComponent,
    RequestPasswordResetComponent,
    MultiAuthComponent,
    AuthenticationPageComponent
  ],
  imports: [
    ApiModule,
    AppRoutingModule,
    UiModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    CommonModule,
    NgxUiLoaderModule.forRoot({
      fastFadeOut: true,
      minTime: 50
    }),
    NgxUiLoaderRouterModule.forRoot({loaderId: 'routeLoader'}),
    NgxUiLoaderHttpModule.forRoot({loaderId: 'httpLoader', delay: 100, showForeground: false}),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    MessageService,
    {provide: APP_BASE_HREF, useValue: '/'}
  ],
  bootstrap: [AppComponent]
})
export class LoginModule {
}

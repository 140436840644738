abstract class BranchSummaryBranch {
  public current: boolean;
  public name: string;
  public commit: string;
  public label: string;
}

export abstract class BranchSummary {
  public detached: boolean;
  public current: string;
  public all: string[];
  public branches: BranchSummaryBranch;
}

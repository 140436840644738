import {
  ApplicationLink,
  Button,
  DisplayTransformation,
  GuidedTour,
  GuidedTourStep,
  MenuItem,
  NpmDependency,
  Scheduler,
  Script,
  VirtualCollection,
  Wizard,
  WizardSection
} from '../..';
import {ApplicationToButton} from './application-to-button';
import {ApplicationToPlatformEntity} from './application-to-platform.entity';
import {Choice} from './choice';
import {DesignerEntity} from './designer.entity';
import {SocketEvent} from './socket-event';
import {Type} from './type';
import {View} from './view';

export class Application extends DesignerEntity {
  public owner?: string;
  public modelVersion?: string;
  public extended?: boolean;
  public package?: string;
  public basedOn?: Array<string>;

  public entities?: any;
  public npmDependencies?: NpmDependency[];
  public wizards?: Wizard[];
  public wizardSections?: WizardSection[];
  public globalScripts?: Script[];
  public schedulers?: Scheduler[];
  public types?: (Choice | Type)[];
  public navigationMenus?: MenuItem[];
  public socketEvents?: SocketEvent[];
  public applicationToButtons?: ApplicationToButton[];
  public virtualCollections?: VirtualCollection[];
  public views?: View[];
  public buttons?: Button[];
  public displayTransformations?: DisplayTransformation[];
  public guidedTours?: GuidedTour[];
  public guidedTourSteps?: GuidedTourStep[];
  public ApplicationLinkParents?: ApplicationLink[];
  public ApplicationLinkChilds?: ApplicationLink[];
  public platformEntities?: ApplicationToPlatformEntity[];
}

import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, Output} from '@angular/core';
import {NG_VALIDATORS, NG_VALUE_ACCESSOR} from '@angular/forms';
import {_, Utility} from '@wspsoft/frontend-backend-common';
import {FaIcon, faIcons} from '../../../../util/fa-icons';
import {CustomInput} from '../../custom-input';
import {AutoComplete} from '../autocomplete';

@Component({
  selector: 'ui-icon-autocomplete',
  templateUrl: './icon-autocomplete-input.component.html',
  styleUrls: ['../autocomplete.scss', './icon-autocomplete-input.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => IconAutocompleteInputComponent),
    multi: true
  }, {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => IconAutocompleteInputComponent),
    multi: true,
  }, {
    provide: CustomInput,
    useExisting: forwardRef(() => IconAutocompleteInputComponent),
    multi: true
  }],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconAutocompleteInputComponent extends AutoComplete<FaIcon> {
  @Input()
  public field: string;
  @Output()
  private completeMethod: EventEmitter<any> = new EventEmitter();


  public constructor(cdr: ChangeDetectorRef) {
    super(cdr);

    this.converter = {
      getAsObject(s: string): FaIcon {
        return {
          icon: s,
          name: s.replace('fas', '').replace('fa-fw', '').replace('fa-', '')
        };
      },
      getAsString(x: FaIcon): string {
        if (x) {
          return x.icon;
        }
      }
    };
  }

  public onComplete($event: any): void {
    const collection = [];
    for (const faIcon of faIcons) {
      if (Utility.matches(faIcon.name, $event.query)) {
        collection.push(faIcon);
        if (collection.length === (this.size + $event.offset)) {
          break;
        }
      }
    }
    this.suggestions = _.sortBy(collection, ['name']);
    this.cdr.detectChanges();

    if ($event.originalEvent.cb) {
      $event.originalEvent.cb();
    }
  }
}

import {NgModule} from '@angular/core';
import {ResizedDirective} from './resized.directive';

@NgModule({
  declarations: [ResizedDirective],
  imports: [],
  exports: [ResizedDirective]
})
export class AngularResizedEventModule {
}

import {KolibriEntity} from './kolibri-entity';

export class AutoSequencer extends KolibriEntity {
  public currentNumber?: number;
  public digits: number;
  public entityName: string;
  public prefix: string;
  public sequence: number;
  public startNumber: number;
}

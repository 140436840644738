<main (keydown.enter)="onEnter.emit()" class="one-fullscreen">
  <div class="one-fullscreen__wrapper">
    <section [ngClass]="sectionClass" class="one-login">
      <header>
        <div *ngIf="showLogo" class="one-login__logo"></div>
        <div *ngIf="header" class="one-login__greeting">
          <ng-container [ngTemplateOutlet]="header"></ng-container>
        </div>
      </header>
      <ng-container [ngTemplateOutlet]="content"></ng-container>
    </section>
  </div>
  <footer class="one-portal__footer">
    <portal-footer></portal-footer>
  </footer>
</main>

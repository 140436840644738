import {Injectable} from '@angular/core';
import {AbstractCriteriaJsCompiler} from '@wspsoft/frontend-backend-common';
import {ModelService} from '../coded/model.service';


// additional criteria fields with 'script'
@Injectable()
export class CriteriaJsCompilerService extends AbstractCriteriaJsCompiler {

  public constructor(modelService: ModelService) {
    super(modelService);

  }
}

import {DesignerEntity} from './designer.entity';
import {WizardToWizardSection} from './wizard-to-wizard-section';


export abstract class Wizard extends DesignerEntity {
  public steps?: boolean;
  public cancelScript?: string;
  public saveScript?: string;
  public openScript?: string;
  public saveButtonCondition?: string;
  public wizardToWizardSections?: WizardToWizardSection[];
}

import {AbstractCriteriaJsCompiler} from '../../criteria/compiler/criteria-js-compiler';
import {CriteriaQueryGroupJson} from '../../criteria/json/criteria-query-group-json';
import {_} from '../underscore';

export interface PugElement {
  tagName?: string;
  id?: string;
  indent: number;
  originalValue: string;
  attributes: { [attr: string]: any };
}

export abstract class PugUtil {
  private static absoluteHrefRegex: RegExp = new RegExp('href=\'/(.*?)\'', 'gm');
  private static srcRegex: RegExp = new RegExp('src=\'/(.*?)\'', 'gm');
  private static hrefRegex: RegExp = new RegExp('href=\'(.*?)\'', 'gm');

  /**
   * parse on line of pug code as element
   */
  public static parsePugElement(s: string): PugElement {
    const originalValue = s.trim();
    const result: PugElement = {
      attributes: {},
      originalValue,
      indent: s.length - originalValue.length
    };
    const [tagAndId, attributes] = result.originalValue.split(')')[0].split('(');

    // check if it has an id and split
    if (tagAndId.includes('#')) {
      const [tag, id] = tagAndId.split('#');
      result.tagName = tag;
      result.id = id;
    } else {
      result.tagName = tagAndId;
    }

    if (attributes) {
      for (const attribute of attributes.split('\', ')) {
        const [attr, value] = attribute.split('=');
        result.attributes[attr.replace('data-', '')] = _.trim(value, '\'');
      }
    }

    return result;
  }

  /**
   * search for pug element with specific attribute
   */
  public static getElementsByAttribute(pug: string, tag: string): PugElement[] {
    const regex = new RegExp(`^\s*[\w.#]*(.*${tag}.*).*$`, 'gm');
    const result = [];
    for (const match of pug.match(regex) || []) {
      result.push(this.parsePugElement(match));
    }
    return result;
  }

  /**
   * search for pug elements with specific tag
   */
  public static getElementsByTag(pug: string, tag: string): PugElement[] {
    const regex = new RegExp(`^\\s*${tag}.*$`, 'gm');
    const result = [];
    for (const match of pug.match(regex) || []) {
      result.push(this.parsePugElement(match));
    }
    return result;
  }

  /**
   * convert temp fake loop from grapes to pug code
   */
  public static convertLoopElementToPug(pug: string, element: PugElement, compiler: AbstractCriteriaJsCompiler): string {
    const loopPug = this.getLoopPug(element, compiler);
    return pug.replace(element.originalValue, loopPug);
  }

  /**
   * convert table body with loop attributes to pug code
   */
  public static convertTableBodyElementToPug(pug: string, element: PugElement, compiler: AbstractCriteriaJsCompiler): string {
    return pug.replace(element.originalValue, `${element.originalValue}
${' '.repeat(element.indent)}${this.getLoopPug(element, compiler)}`);
  }

  /**
   * convert temp fake if from grapes to pug code
   */
  public static convertIfElementToPug(pug: string, element: PugElement, compiler: AbstractCriteriaJsCompiler): string {
    const js = compiler.compile(element.attributes.query);
    const loopPug = `if scriptExecutor.runScript('${js.replace(/await /g,
      '')}', {record: ${element.attributes.recordvariable}, user}, undefined, 'HtmlTemplate:if').result`;
    return pug.replace(element.originalValue, loopPug);
  }

  /**
   * convert the #{} expression in recordLinks to working markup for pug
   */
  public static convertLinkElementToPug(pug: string, element: PugElement): string {
    return pug.replace(new RegExp(`href='(.*?=)#{(${element.attributes.dotwalk}.id})'`, 'gm'),
      'href=`$1\${$2`');
  }

  /**
   * convert record field access to translated output
   */
  public static convertFieldAccess(pug: string, element: PugElement): string {
    const quillAttribute = element.attributes['record-variable'];
    const variable = quillAttribute || element.attributes.recordvariable;
    const field = element.attributes.field;
    const quillContent = quillAttribute ? '([|\\s*])' : '()#';
    const replacedWithTranslation = pug.replace(new RegExp(`(?!=')${quillContent}{${variable}(.${field})?}(?!')`, 'm'),
      `$1#{translate.translateObjectValue(${variable}, '${field || 'representativeString'}', language, undefined, undefined, true, timezone)}`);

    if (quillAttribute) {
      return replacedWithTranslation
        .replace('.mention', '')
        .replace(/\s*span.ql-mention-denotation-char #/m, '');
    } else {
      // in case of attribute interpolation use es6 template strings
      return replacedWithTranslation.replace(new RegExp(`='#{(${variable}(.${field})?)}'`, 'gm'), '=`\${JSON.stringify($1)}`');
    }
  }

  /**
   * post process grapes html output to its final pug form
   * also keeps track of all used fields for pre loading data on template generation
   */
  public static postProcessTemplatePug(pug: string, e: { html: string; css: string },
                                       queryCompiler: AbstractCriteriaJsCompiler): { pug: string; recordFields: string[]; widgets: string[] } {
    let recordFields = [];
    const widgets = [];

    // insert grapes generated css
    const headElement = this.getElementsByTag(pug, 'head')[0];
    const actionableMessage = this.getElementsByTag(pug, 'actionablemessage')[0];
    if (actionableMessage?.attributes.code) {
      const message = this.convertActionableMessageToPug(actionableMessage) + '\n';
      pug = pug.replace(actionableMessage.originalValue, message);
    }

    pug = pug.replace(headElement.originalValue, `${headElement.originalValue}
    meta(http-equiv='Content-Type' content='text/html; charset=utf-8')
    style.
      !{css}`);

    // convert loops for pug
    for (const loop of this.getElementsByTag(pug, 'loop')) {
      if (loop.attributes.query) {
        loop.attributes.query = JSON.parse(loop.attributes.query);
        this.getAllAccessFields(loop.attributes.query, recordFields, loop.attributes.recordpath || loop.attributes.recordvariable);
      }

      pug = this.convertLoopElementToPug(pug, loop, queryCompiler);
      recordFields.push(`${loop.attributes.recordpath || loop.attributes.recordvariable}.${loop.attributes.field}`);
      recordFields = this.uniqFields(recordFields);
    }

    // convert if blocks
    for (const ifElement of this.getElementsByTag(pug, 'if')) {
      if (ifElement.attributes.query) {
        ifElement.attributes.query = JSON.parse(ifElement.attributes.query);
        pug = this.convertIfElementToPug(pug, ifElement, queryCompiler);
        this.getAllAccessFields(ifElement.attributes.query, recordFields, ifElement.attributes.recordpath || ifElement.attributes.recordvariable);
      }
    }


    // convert recordlinks
    for (const recordLink of this.getElementsByTag(pug, 'a')) {
      if (recordLink.attributes.href) {
        pug = this.convertLinkElementToPug(pug, recordLink);
      }
      if (recordLink.attributes.recordpath) {
        recordFields.push(`${recordLink.attributes.recordpath}.${recordLink.attributes.field}`);
      }
    }

    // convert table bodies
    for (const tableBody of this.getElementsByTag(pug, 'tbody')) {
      if (tableBody.attributes.recordvariable) {
        if (tableBody.attributes.query) {
          tableBody.attributes.query = JSON.parse(tableBody.attributes.query);
          this.getAllAccessFields(tableBody.attributes.query, recordFields, tableBody.attributes.recordpath || tableBody.attributes.recordvariable);
        }

        pug = this.convertTableBodyElementToPug(pug, tableBody, queryCompiler);
        recordFields.push(`${tableBody.attributes.recordpath || tableBody.attributes.recordvariable}.${tableBody.attributes.field}`);
        recordFields = this.uniqFields(recordFields);
      }
    }

    // convert table bodies
    for (const widget of this.getElementsByTag(pug, 'one-widget')) {
      if (widget.attributes.widgetid) {
        pug = this.convertOneWidget(pug, widget);
        widgets.push(widget.attributes.widgetid);
      }
    }

    // convert all field accesses and wrap with translator
    for (const interpolation of this.getElementsByAttribute(pug, 'record[-]?variable')) {
      pug = this.convertFieldAccess(pug, interpolation);
      const variable = interpolation.attributes['record-path'] || interpolation.attributes['record-variable']
        || interpolation.attributes.recordpath || interpolation.attributes.recordvariable;
      const field = interpolation.attributes.field;
      recordFields.push(`${variable}.${field}`);
      recordFields = this.uniqFields(recordFields);
    }

    // convert all relative urls to absolute urls with interpolation
    pug = pug.replace(this.absoluteHrefRegex, 'href=`${serverAdd}/$1`')
      .replace(this.srcRegex, 'src=`${serverAdd}/$1`')
      // allow interpolation of any href
      .replace(this.hrefRegex, 'href=`$1`');

    return {recordFields, pug, widgets};
  }

  private static convertOneWidget(pug: string, widget: PugElement): string {
    return pug.replace(widget.originalValue, widget.originalValue.replace(/widget='.*'/,
      // eslint-disable-next-line max-len
      `widget=\`$\{JSON.stringify(widgetData['${widget.attributes.widgetid}'].widget)}\` data=\`$\{JSON.stringify(widgetData['${widget.attributes.widgetid}'].data)\}\``));
  }

  private static getLoopPug(element: PugElement, compiler: AbstractCriteriaJsCompiler): string {
    const loopExpression = element.attributes.field ? `${element.attributes.recordvariable}.${element.attributes.field}` : element.attributes.recordvariable;
    let filter = '';
    if (element.attributes.query) {
      const js = compiler.compile(element.attributes.query);
      filter = `.filter(x => scriptExecutor.runScript('${js
        .replace(/await /g, '')}', {record: x, user}, undefined, 'HtmlTemplate:Loop:filter').result)`;
    }
    return `each ${element.attributes.loopvariable} in ${loopExpression}${filter}`;
  }

  /**
   * remove duplicated or included dotwalks
   */
  private static uniqFields(fields: string[]): string[] {
    return _.uniqWith(_.orderBy(fields, 'length', 'desc'), (a, b) => b.startsWith(a));
  }

  /**
   * search for all field accesses in criteria
   */
  private static getAllAccessFields(group: CriteriaQueryGroupJson, recordFields: string[], recordVariable: string): void {
    for (const cond of group.whereCondition) {
      recordFields.push(`${recordVariable}.${cond.columnName}`);
      recordFields = this.uniqFields(recordFields);
    }
    for (const subGroup of group.groups) {
      this.getAllAccessFields(subGroup, recordFields, recordVariable);
    }
  }

  private static convertActionableMessageToPug(element: PugElement): string {
    return `
    section(itemscope='' itemtype='http://schema.org/SignedAdaptiveCard')
      meta(itemprop='@context' content='http://schema.org/extensions')
      meta(itemprop='@type' content='SignedAdaptiveCard')
      div(itemprop='signedAdaptiveCard' style='mso-hide:all;display:none;max-height:0px;overflow:hidden;')
        script(type='application/adaptivecard+json').
          ${element.attributes.code}`;
  }
}

import {DesignerEntity} from './designer.entity';
import {LayoutToButton} from './layout-to-button';


export abstract class Layout extends DesignerEntity {
  public permissionId?: string;
  public entityId?: any;
  public url?: string;
  public buttons?: LayoutToButton[];
  public multiUserSynchronize?: boolean;
}

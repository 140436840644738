interface Subscription {
  unsubscribe(): void;
}

export abstract class AbstractKolibriObservableService<E = any> {
  protected observables: { [key: string]: E } = {};

  /**
   * emit event and auto create observable
   * @param {string} event
   * @param data
   */
  public emit(event: string, data: any): void {
    if (!(event in this.observables)) {
      this.define(event);
    }
    this.emitEvent(event, data);
  }

  /**
   * listen repeatedly to events
   * @param {string} event
   * @param {(data: any) => void} fn
   */
  public on(event: string, fn: (data: any) => void): Subscription | void {
    if (!(event in this.observables)) {
      this.define(event);
    }
    return this.listenOn(event, fn);
  }

  /**
   * listen one time to an event
   * @param {string} event
   * @param {(data: any) => void} fn
   */
  public once(event: string, fn: (data: any) => void): void {
    if (!(event in this.observables)) {
      this.define(event);
    }
    return this.listenOnce(event, fn);
  }

  protected abstract listenOn(event: string, fn: (data: any) => void): Subscription | void;

  protected abstract listenOnce(event: string, fn: (data: any) => void): void;

  protected abstract getEventEmitter(): E;

  protected abstract emitEvent(event: string, data: any): void;

  /**
   * define an observable for later usage
   * @param {string} event
   */
  private define(event: string): void {
    this.observables[event] = this.getEventEmitter();
  }
}

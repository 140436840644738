import {HttpClient} from '@angular/common/http';
import {Inject, Injectable, Optional} from '@angular/core';
import {AbstractActivityLogService, ActivityLogEntry, CustomActivityMessage, KolibriEntity} from '@wspsoft/frontend-backend-common';
import {Configuration} from '../../configuration';
import {BASE_PATH} from '../../variables';
import {AbstractService} from '../abstract-service';

@Injectable()
export class ActivityLogService extends AbstractService implements AbstractActivityLogService {
  public constructor(httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string,
                     @Optional() configuration: Configuration) {
    super(httpClient, basePath, configuration);

    this.basePath = '/api/rest/public/portal/activityLogs';
  }

  public createCustomActivity(entity: KolibriEntity, customActivity: CustomActivityMessage): Promise<ActivityLogEntry> {
    const {queryParameters, headers} = this.getHeaders();

    return this.doPost<any>(`${this.basePath}/${entity.entityClass}/${entity.id}`, customActivity,
      queryParameters, headers);
  }
}

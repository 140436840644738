import * as moment from 'moment';
import 'moment/min/locales';

export interface DateOptions {
  language?: string;
  timezone?: string;
}

export abstract class MomentUtil {
  public static readonly UTC_FLAG: string = '[UTC]';
  private static readonly timeFormats: { [key: string]: { date: string; time?: string; datetime?: string; utcOffset?: number } } = {
    de: {
      date: 'DD.MM.YYYY',
      datetime: 'DD.MM.YYYY HH:mm:ss',
      time: 'HH:mm:ss',
      utcOffset: 1
    },
    en: {
      date: 'MM.DD.YYYY',
      datetime: 'MM.DD.YYYY HH:mm:ss',
      time: 'HH:mm:ss',
      utcOffset: 0
    },
    fr: {
      date: 'DD.MM.YYYY',
      datetime: 'DD.MM.YYYY HH:mm:ss',
      time: 'HH:mm:ss',
      utcOffset: 1
    },
    it: {
      date: 'DD.MM.YYYY',
      datetime: 'DD.MM.YYYY HH:mm:ss',
      time: 'HH:mm:ss',
      utcOffset: 1
    }
  };

  public static parseDate(date: string, options?: DateOptions): moment.Moment {
    return date ? this.getLocaleMoment(options, date.replace(MomentUtil.UTC_FLAG, '')) : moment();
  }

  public static dateToString(date: string, options?: DateOptions): string {
    return this.toLocaleFormat(date, 'date', options);
  }

  public static datetimeToString(date: string, options?: DateOptions): string {
    return this.toLocaleFormat(date, 'datetime', options);
  }

  public static timeToString(date: string, options?: DateOptions): string {
    return this.toLocaleFormat(date, 'time', options);
  }

  public static getLocaleMoment(options?: DateOptions, date?: string): moment.Moment {
    const momentDate = moment(date).locale(options?.language || moment.locale());
    const timeFormat = MomentUtil.timeFormats[momentDate.locale()];

    // manual override, otherwise use user settings for timezone
    // this is only required in the backend as moment uses the browser tz automatically
    if (options && typeof window === 'undefined') {
      if (!options.timezone) {
        momentDate.utcOffset(timeFormat.utcOffset + (momentDate.isDST() ? 1 : 0));
      } else {
        momentDate.tz(options.timezone);
      }
    }
    return momentDate;
  }

  private static toLocaleFormat(date: string, type: string, options?: DateOptions): string {
    if (date) {
      const momentDate = this.parseDate(date, options);
      const timeFormat = MomentUtil.timeFormats[momentDate.locale()];
      return momentDate.format(timeFormat[type]);
    }
    return '';
  }
}

import * as uuidv4 from 'uuid-random';
import {CriteriaFunction} from '../criteria-function';
import {CriteriaOperator} from '../criteria-operator';
import {CriteriaSearchOperator} from '../criteria-search-operator';

export enum ConditionValueType {
  SCRIPTED = 'scripted',
  SCRIPTED_VALUE = 'scriptedValue',
  MANUAL = 'manual'
}

export class CriteriaConditionJson<O extends CriteriaOperator | CriteriaSearchOperator = CriteriaOperator> {
  public columnName: string;
  public value: any;
  public operator: O;
  public or: boolean = false;
  public active: boolean = true;
  public scripted: ConditionValueType = ConditionValueType.MANUAL;
  public id?: string = uuidv4();
  public criteriaFunction?: CriteriaFunction;
  public options?: any;
}

<ui-input-wrapper [(editMode)]="editMode" [customValidationMessage]="customValidationMessage" [dirty]="input.dirty" [disable]="disable" [errors]="input.errors"
                  [filled]="!!value" [hasFocus]="hasFocus" [helpMessage]="helpMessage" [label]="label" [linkify]="linkify" [name]="name" [required]="required"
                  [touched]="input.touched" [valid]="input.valid" [value]="value">
  <ng-container *ngIf="!multiple else chips" [ngTemplateOutlet]="inputText"></ng-container>
</ui-input-wrapper>
<ng-template #inputText>
  <div class="one-input {{disable ? 'one-input--readonly' : ''}}">
    <span *ngIf="symbol" class="one-input__decorator">{{symbol}}</span>
    <span *ngIf="isI18n" class="one-input__decorator">{{currentLanguage}}</span>
    <input #inputElement="ngModel" #nativeInput (blur)="focusChanged($event)" (focus)="focusChanged($event)" (keydown)="keydown($event)" [(ngModel)]="value"
           [autocapitalize]="autocapitalize" [disabled]="disable" [maxlength]="maxLength" [ngModelOptions]="{updateOn: updateOn}" [ngStyle]="styleData"
           [readonly]="keyInput" [required]="required" [type]="type" pInputText style="width:100%">
    <ng-container *ngIf="isI18n && !disable">
      <p-menu #languageOptions [model]="languageOptionButtons" [popup]="true" appendTo="body"></p-menu>
      <ui-button (clicked)="languageOptions.toggle($event.originalEvent)" [spinner]="false" [type]="['icon-only',
      'inline']" icon="fas fa-fw fa-ellipsis-v" label="{{'Input.TranslateInput' | translate}}"></ui-button>
    </ng-container>
    <ng-container *ngIf="keyInput && !disable">
      <ui-button (clicked)="this.value = undefined" [spinner]="false" [type]="['icon-only', 'inline']" icon="fas fa-times"
                 label="{{'Input.Reset' | translate}}"></ui-button>
    </ng-container>
  </div>
</ng-template>
<ng-template #chips>
  <p-chips #inputElement="ngModel" #nativeInput (onBlur)="focusChanged($event)" (onFocus)="focusChanged($event)" [(ngModel)]="value" [addOnBlur]="true"
           [addOnTab]="true" [allowDuplicate]="false" [disabled]="disable" [ngModelOptions]="{updateOn: updateOn}" [required]="required"
           class="one-input {{disable ? 'one-input--readonly' : ''}}" separator=","></p-chips>
</ng-template>

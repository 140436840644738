import {EventEmitter} from '@angular/core';
import {_, CriteriaQuery, CriteriaQueryJson, PrefQuery, PrefQueryConverter, Visibility} from '@wspsoft/frontend-backend-common';
import {DbJsonWrapper} from '../../../api';


export class PrefQueryEntity implements DbJsonWrapper<PrefQuery> {
  public criteria: CriteriaQueryJson | CriteriaQueryJson[] = {};
  public readonly json: PrefQuery;
  public prefQueryChange: EventEmitter<void> = new EventEmitter();

  public constructor(prefQuery: PrefQuery, public drilldown?: boolean) {
    this.json = prefQuery;
    if (!prefQuery.payload) {
      this.criteria = {};
    } else {
      // @ts-ignore, legacy
      if (prefQuery.payload !== '{}') {
        const query = prefQuery.payload;

        this.criteria = Array.isArray(query) ? query.map(q => this.upgradeQuery(q)) : this.upgradeQuery(query);
      }
    }
  }

  public get id(): string {
    return this.json.id;
  }

  public get name(): string {
    return this.json.name;
  }

  public set name(value: string) {
    this.json.name = value;
  }

  public get scope(): string {
    return this.json.scope;
  }

  public set scope(value: string) {
    this.json.scope = value;
  }

  public get payload(): CriteriaQueryJson | CriteriaQueryJson[] {
    return this.json.payload;
  }

  public set payload(value: CriteriaQueryJson | CriteriaQueryJson[]) {
    this.criteria = value;
    this.json.payload = value;
  }

  public get hiddenPayload(): CriteriaQueryJson | CriteriaQueryJson[] {
    return this.json.hiddenPayload;
  }

  public set hiddenPayload(value: CriteriaQueryJson | CriteriaQueryJson[]) {
    this.json.hiddenPayload = value;
  }

  public get ownerId(): string {
    return this.json.ownerId;
  }

  public set ownerId(value: string) {
    this.json.ownerId = value;
  }

  public get visibility(): Visibility {
    return this.json.visibility;
  }

  public set visibility(value: Visibility) {
    this.json.visibility = value;
  }

  public get active(): boolean {
    return this.json.active;
  }

  public set active(value: boolean) {
    this.json.active = value;
  }

  public get permissionId(): string {
    return this.json.permissionId;
  }

  public set permissionId(value: string) {
    this.json.permissionId = value;
  }

  public getJson(): PrefQuery {
    // destroy reference, then remove all ids (would break qb otherwise) and then stringify
    this.json.payload = _.cloneDeep(this.criteria);
    return this.json;
  }

  /**
   * check query version and applies upgrade
   */
  private upgradeQuery(query: CriteriaQueryJson): CriteriaQueryJson {
    let criteria = _.cloneDeep(query);
    switch (criteria.version) {
      case '1.0':
        // nothing to to this is the latest version
        break;
      default:
        const criteriaQuery = new CriteriaQuery<any>();
        PrefQueryConverter.convertPrefQuery(criteriaQuery, criteria);
        criteria = criteriaQuery.getJson();
        break;
    }
    return criteria;
  }
}

import {HttpClient} from '@angular/common/http';
import {Inject, Injectable, Optional} from '@angular/core';
// @ts-ignore
import {AbstractSettingsService, EnvVariable, PlatformComponentVersions, PlatformSettings} from '@wspsoft/frontend-backend-common';
import {UiUtil} from '../../../../ui/app/util/ui-util';
import {Configuration} from '../../configuration';
import {BASE_PATH} from '../../variables';
import {AbstractService} from '../abstract-service';

import {EntityServiceFactory} from '../generated/entity-service-factory';


@Injectable()
export class SettingsService extends AbstractService implements AbstractSettingsService {
  public platformSettings: PlatformSettings;

  public constructor(httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string,
                     @Optional() configuration: Configuration, private serviceFactory: EntityServiceFactory) {
    super(httpClient, basePath, configuration);

    this.basePath = '/api/rest/private/portal';
  }

  public loadPlatformSettings(): void {
    this.platformSettings = UiUtil.loadPugVariable('platformSettings');
  }

  public async updatePlatformSettings(): Promise<void>;
  public async updatePlatformSettings(): Promise<any> {
    const {queryParameters, headers} = this.getHeaders();
    await this.doPost<PlatformSettings>(`${this.basePath}/platformSettings`, {}, queryParameters, headers);
  }

  public getVariables(all: boolean, keys?: string[]): Promise<any> {
    // eslint-disable-next-line prefer-const
    let {queryParameters, headers} = this.getHeaders();

    queryParameters = queryParameters.set('all', String(all));

    return this.doPost<EnvVariable[]>(`${this.basePath}/environmentVariables`, keys || [], queryParameters, headers);
  }

  public getVersions(): Promise<PlatformComponentVersions> {
    const {queryParameters, headers} = this.getHeaders();
    return this.doGet<PlatformComponentVersions>(`${this.basePath}/versions`, queryParameters, headers);
  }
}

import {CriteriaQueryJson} from '../../criteria/json/criteria-query-json';


import {Layout} from './layout';


export abstract class ListLayout extends Layout {
  public rowDelete?: string;
  public rowEdit?: string;
  public queryBuilderPermissionId?: string;
  public selectionMode?: ListSelectionMode;
  public selectActionId?: string;
  public descendants?: boolean;
  public searchable?: boolean;
  public forceDetailRecordView?: boolean;
  public filterScript?: string;
  public queryBuilderEditorCondition?: string;
  public detailUrlId?: string;
  public newRecordButtonCondition?: string;
  public createDialogUrlId?: string;
  public sorting?: CriteriaQueryJson;
  public queryBuilderEnabled?: boolean;
  public liveAvatars?: boolean;
}

export enum ListSelectionMode {
  NONE = 'none', SINGLE = 'single', MULTIPLE = 'multiple', BUTTON = 'button', CUSTOM = 'custom'
}

import {ValidationLevel} from '../ui/custom-validation-message';
import {KolibriScriptError} from './script-objects';

export abstract class AbstractKolibriMessage {
  public level: typeof ValidationLevel = ValidationLevel;

  /**
   * adds a log message (growl in fe, console in be)
   * @param {string} message
   * @param {string} severity
   * @param {string} key which determines the type of message (growl / tray)
   */
  public abstract addLog(message: string, severity?: string, key?: 'growl' | 'tray'): void;

  /**
   * throws an exception and cancels execution
   */
  public throw(message: string, code?: number, suppressGrowl?: boolean): void {
    throw new KolibriScriptError(message, code, suppressGrowl);
  }
}

import * as moment from 'moment';
import Dashboard from './dashboard';
import {GroupMembership} from './group-membership';
import {Notification} from './notification';
import {TenantMembership} from './tenant-membership';
import {ThirdPartyCredential} from './third-party-credential';
import {UserProfile} from './user-profile';
import {VariableEntity} from './variable/variable-entity';

export abstract class User extends VariableEntity {
  public username: string;
  public password?: string;
  public initials?: string;
  public validFrom?: string;
  public validTill?: string;
  public shTitle?: string;
  public firstName?: string;
  public lastName?: string;
  public email?: string;
  public salutation?: string;
  public locked?: boolean;
  public selfRegistered?: boolean;
  public lockedSince?: string | moment.Moment;
  public thirdPartyCredentials?: ThirdPartyCredential[];
  public groupMemberships?: GroupMembership[];
  public tenantMemberships?: TenantMembership[];
  public notifications?: Notification[];
  public activeProfile?: UserProfile;
  public activeProfileId?: string;
  public startDashboard?: Dashboard;
  public startDashboardId?: string;
  public forcePasswordChange?: boolean;
  public passwordLastModifiedOn?: string;

  // generated data
  public language?: string;
  public timezone?: string;

  // temporary data
  public hasToAuthenticate?: boolean;
  public rootTenantMode?: boolean;
  public loginMethod?: LoginMethod;
  public loginName?: string;
  public impersonatorId?: string;

  // security cache data
  public tenants?: { [tenantId: string]: { active: boolean; id: string; children: { active: boolean; id: string; name: string }[] } };
  public roles?: { [roleIdOrName: string]: boolean };
  public groups?: { [groupIdOrName: string]: { active: boolean; id: string } };
  public permissions?: { [permissionIdOrKey: string]: boolean };

  // security functions
  public hasRole?: (role: string) => boolean;
  public hasTenant?: (tenant: string) => boolean;
  public hasGroup?: (group: string) => boolean;

  public abstract can?(operation: string, entity?: string): boolean;
  public abstract can?(operation: string[], entity?: string): boolean[];
  public abstract can?(operation: string | string[], entity?: string): boolean | boolean[];
}

export enum LoginMethod {
  basic = 'basic', local = 'local', bearer = 'bearer', saml = 'saml', ldap = 'ldap', impersonate = 'impersonate', ldap2 = 'ldap2'
}

import {ListLayout} from './list-layout';


export abstract class LayoutTree extends ListLayout {
  public downRelationId?: string;
  public orderFieldId?: string;
  public treeNodeType?: TreeNodeType;
}

export enum TreeNodeType {
  default = 'default', url = 'url'
}

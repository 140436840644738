export interface BasicCredentials {
  username: string;
  password: string;
}

export interface HttpService {
  get(url: string, config?: KolibriRequestConfig): any;

  delete(url: string, config?: KolibriRequestConfig): any;

  head(url: string, config?: KolibriRequestConfig): any;

  post(url: string, data?: any, config?: KolibriRequestConfig): any;

  put(url: string, data?: any, config?: KolibriRequestConfig): any;

  patch(url: string, data?: any, config?: KolibriRequestConfig): any;
}

export interface KolibriRequestConfig {
  headers?: {
    [header: string]: string;
  };
  params?: {
    [param: string]: any;
  };
  responseType?: any;
  withCredentials?: boolean;
  auth?: BasicCredentials;
}

export abstract class AbstractKolibriHttpClient {
  protected constructor(private httpClient: HttpService) {
  }

  public get<T = any>(url: string, config?: KolibriRequestConfig): Promise<T> {
    return this.httpClient.get(url, config).toPromise();
  }


  public delete<T = any>(url: string, config?: KolibriRequestConfig): Promise<T> {
    return this.httpClient.delete(url, config).toPromise();
  }


  public head<T = any>(url: string, config?: KolibriRequestConfig): Promise<T> {
    return this.httpClient.head(url, config).toPromise();
  }

  public post<T = any>(url: string, data?: any, config?: KolibriRequestConfig): Promise<T> {
    return this.httpClient.post(url, data, config).toPromise();
  }

  public put<T = any>(url: string, data?: any, config?: KolibriRequestConfig): Promise<T> {
    return this.httpClient.put(url, data, config).toPromise();
  }

  public patch<T = any>(url: string, data?: any, config?: KolibriRequestConfig): Promise<T> {
    return this.httpClient.patch(url, data, config).toPromise();
  }
}

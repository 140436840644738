import {Directive, ElementRef, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {ResizedEvent} from './resized-event';
import ResizeSensor from './ResizeSensor';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[resized]'
})
export class ResizedDirective implements OnInit, OnDestroy {
  @Output()
  public readonly resized: EventEmitter<ResizedEvent> = new EventEmitter();

  private oldWidth: number;
  private oldHeight: number;

  private resizeSensor: ResizeSensor;

  public constructor(private readonly element: ElementRef) {
  }

  public ngOnInit(): void {
    // only initialize resize watching if sensor is availablei
    if (ResizeSensor) {
      this.resizeSensor = new ResizeSensor(this.element.nativeElement, () => this.onResized());
    }
  }

  public ngOnDestroy(): void {
    if (this.resizeSensor) {
      this.resizeSensor.detach();
    }
  }

  private onResized(): void {
    const newWidth = this.element.nativeElement.clientWidth;
    const newHeight = this.element.nativeElement.clientHeight;

    if (newWidth === this.oldWidth && newHeight === this.oldHeight) {
      return;
    }

    const event = new ResizedEvent(
      this.element,
      newWidth,
      newHeight,
      this.oldWidth,
      this.oldHeight
    );

    this.oldWidth = this.element.nativeElement.clientWidth;
    this.oldHeight = this.element.nativeElement.clientHeight;

    this.resized.emit(event);
  }

}

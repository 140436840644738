import {HttpParams} from '@angular/common/http';
import {Inject, Optional} from '@angular/core';

import {
  AbstractCriteriaJsCompiler,
  AbstractEntityService,
  AbstractModelService,
  CriteriaQuery,
  EntityModel,
  EntityServiceOptions,
  EntityWriteOptions,
  KolibriEntity,
  Relation,
  SearchResult,
  User
} from '@wspsoft/frontend-backend-common';
import {BASE_PATH} from '../../variables';
import {AbstractService} from '../abstract-service';

import {EntityEnhancer} from '../util/entity-enhancer';

export class EntityService<E extends KolibriEntity> extends AbstractService implements AbstractEntityService<E> {
  public constructor(private serviceFactory: any, public entityMeta: EntityModel,
                     private modelService?: AbstractModelService, private criteriaCompiler?: AbstractCriteriaJsCompiler, private currentUser?: User,
                     @Optional() @Inject(BASE_PATH) basePath?: string, public options: EntityServiceOptions = {}) {
    super(serviceFactory.httpClient, basePath, serviceFactory.configuration);
  }

  public pentityEnhancer: EntityEnhancer<E>;

  public get entityEnhancer(): EntityEnhancer<E> {
    if (!this.pentityEnhancer) {
      this.pentityEnhancer = new EntityEnhancer(this.serviceFactory, this.options, this.entityMeta, this.modelService, this.criteriaCompiler,
        this.serviceFactory.circularService.scriptExecutor, this.currentUser);
    }
    return this.pentityEnhancer;
  }

  private static addWriteOptions(options: EntityWriteOptions, queryParameters: HttpParams): HttpParams {
    for (const key of Object.keys(options || {})) {
      queryParameters = queryParameters.append(key, options[key]);
    }
    return queryParameters;
  }

  public async getEntityById(id: string, viewId?: string): Promise<E>;

  public async getEntityById(id: string, viewId?: string): Promise<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling getEntityById.');
    }

    // eslint-disable-next-line prefer-const
    let {queryParameters, headers} = this.getHeaders();

    if (viewId) {
      queryParameters = queryParameters.set('viewId', viewId);
    }
    queryParameters = this.addServiceOptions(queryParameters);

    return this.entityEnhancer.firstLevelEnhancing(await this.doGet<E>(`${this.basePath}/${encodeURIComponent(id)}`,
      queryParameters, headers));
  }

  public async getEntityRelations<T extends KolibriEntity>(entities: string[] | E[], nameWithDotWalk: string): Promise<T[]> {
    if (entities === null || entities === undefined) {
      throw new Error('Required parameter entity was null or undefined when calling getEntityRelations.');
    }

    if (nameWithDotWalk === null || nameWithDotWalk === undefined) {
      throw new Error('Required parameter nameWithDotWalk was null or undefined when calling getEntityRelations.');
    }

    // eslint-disable-next-line prefer-const
    let {queryParameters, headers} = this.getHeaders();

    queryParameters = this.addServiceOptions(queryParameters);

    return this.enhanceRelations(await this.doPost<T[]>(
      `${this.basePath}/relations/${nameWithDotWalk}`, entities, queryParameters, headers));
  }

  public async getEntityRelation<T extends KolibriEntity>(entity: string | E, relation: Relation): Promise<T | T[]> {
    if (entity === null || entity === undefined) {
      throw new Error('Required parameter entity was null or undefined when calling getEntityRelation.');
    }

    if (relation === null || relation === undefined) {
      throw new Error('Required parameter relation was null or undefined when calling getEntityRelation.');
    }

    // eslint-disable-next-line prefer-const
    let {queryParameters, headers} = this.getHeaders();

    queryParameters = this.addServiceOptions(queryParameters);

    // grab correct service for multi dotwalk
    return this.enhanceRelations(await this.doPost<T>(
      `${this.basePath}/${encodeURIComponent(typeof entity === 'string' ? entity : entity.id)}/relations/${relation.name}`, entity, queryParameters, headers));
  }

  public executeSearchQuery(query?: CriteriaQuery<E>): Promise<SearchResult<E>>;

  public async executeSearchQuery(query?: CriteriaQuery<E>): Promise<any> {
    // eslint-disable-next-line prefer-const
    let {queryParameters, headers} = this.getHeaders();

    queryParameters = this.addServiceOptions(queryParameters);

    const result = await this.doPost<SearchResult<E>>(`${this.basePath}/search`, query.getJson(), queryParameters,
      headers);
    result.results = this.entityEnhancer.firstLevelEnhancing(result.results);
    return result;
  }

  public execute(query?: CriteriaQuery<E>): Promise<SearchResult<any>>;

  public execute(query?: CriteriaQuery<E>): Promise<any> {
    // eslint-disable-next-line prefer-const
    const {queryParameters, headers} = this.getHeaders();

    return this.doPost<any>(`${this.basePath}/query`, query.getJson(), queryParameters,
      headers);
  }

  public async getEntities(query?: CriteriaQuery<E>): Promise<E[]>;

  public async getEntities(query?: CriteriaQuery<E>): Promise<any> {
    // eslint-disable-next-line prefer-const
    let {queryParameters, headers} = this.getHeaders();

    queryParameters = this.addServiceOptions(queryParameters);

    return this.entityEnhancer.firstLevelEnhancing((await this.doPost<SearchResult<E>>(`${this.basePath}/search`, query.getJson(), queryParameters,
      headers)).results);
  }

  public createEntity(e: E[], options?: EntityWriteOptions): Promise<E[]>;

  public createEntity(e: E, options?: EntityWriteOptions): Promise<E>;

  public createEntity(e: E | E[], options?: EntityWriteOptions): Promise<E | E[]>;

  public async createEntity(entity: E | E[], options?: EntityWriteOptions): Promise<any> {
    // eslint-disable-next-line prefer-const
    let {queryParameters, headers} = this.getHeaders();

    if (entity === null || entity === undefined) {
      throw new Error('Required parameter entity was null or undefined when calling createEntity.');
    }

    let singleEntity = entity as E;
    singleEntity = this.entityEnhancer.removeApiExtensions(singleEntity);

    queryParameters = EntityService.addWriteOptions(options, queryParameters);

    return this.entityEnhancer.firstLevelEnhancing((await this.doPost<E>(`${this.basePath}`, singleEntity, queryParameters,
      headers)));
  }

  public async batchSave(entities: E[], options?: EntityWriteOptions): Promise<E[]>;
  public async batchSave(entities: E[], options?: EntityWriteOptions): Promise<E[]>;
  public async batchSave(entities: E[], options?: EntityWriteOptions): Promise<any> {
    // eslint-disable-next-line prefer-const
    let {queryParameters, headers} = this.getHeaders();

    if (entities === null || entities === undefined) {
      throw new Error('Required parameter entities was null or undefined when calling batchSaveEntity.');
    }

    if (!Array.isArray(entities)) {
      entities = [entities];
    }

    for (let entity of entities) {
      entity = this.entityEnhancer.removeApiExtensions(entity);
    }

    queryParameters = EntityService.addWriteOptions(options, queryParameters);

    const result = (await this.doPut<E[]>(`${this.basePath}`, entities, queryParameters,
      headers));
    return this.entityEnhancer.firstLevelEnhancing(result);
  }

  public updateEntity(e: E[], options?: EntityWriteOptions): Promise<E[]>;

  public updateEntity(e: E, options?: EntityWriteOptions): Promise<E>;

  public updateEntity(e: E | E[], options?: EntityWriteOptions): Promise<E | E[]>;

  public async updateEntity(entity: E | E[], options?: EntityWriteOptions): Promise<E | E[]> {
    if (Array.isArray(entity)) {
      return this.batchSave(entity, options);
    }

    // eslint-disable-next-line prefer-const
    let {queryParameters, headers} = this.getHeaders();

    if (entity === null || entity === undefined) {
      throw new Error('Required parameter entity was null or undefined when calling createEntity.');
    }

    let singleEntity = entity as E;
    queryParameters = EntityService.addWriteOptions(options, queryParameters);

    singleEntity = this.entityEnhancer.removeApiExtensions(singleEntity);

    return this.entityEnhancer.firstLevelEnhancing((await this.doPut<E>(`${this.basePath}/${singleEntity.id || ''}`, singleEntity, queryParameters,
      headers)));
  }

  public deleteEntity(id: string, options?: EntityWriteOptions, hard?: boolean): Promise<void>;

  public deleteEntity(id: string, options?: EntityWriteOptions, hard: boolean = false): Promise<void> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling getEntityById.');
    }

    // eslint-disable-next-line prefer-const
    let {queryParameters, headers} = this.getHeaders();

    queryParameters = EntityService.addWriteOptions(options, queryParameters);
    queryParameters = queryParameters.append('hard', String(hard));

    return this.doDelete(`${this.basePath}/${encodeURIComponent(String(id))}`, queryParameters, headers);
  }

  public deleteEntities(id: string[], options?: EntityWriteOptions, hard?: boolean): Promise<void>;

  public deleteEntities(id: string[], options?: EntityWriteOptions, hard: boolean = false): Promise<void> {
    if (id === null || id === undefined || id.length === 0) {
      throw new Error('Required parameter id was null or undefined when calling getEntityById.');
    }

    // eslint-disable-next-line prefer-const
    let {queryParameters, headers} = this.getHeaders();
    queryParameters = EntityService.addWriteOptions(options, queryParameters);
    queryParameters = queryParameters.append('hard', String(hard));

    return this.doDelete(`${this.basePath}`, queryParameters, headers, id);
  }

  public getNewEntity(viewId?: string): Promise<E> {
    return this.getEntityById('new', viewId);
  }

  public async triggerBeforeDisplay(entity: E): Promise<E> {
    const {queryParameters, headers} = this.getHeaders();
    return this.entityEnhancer.firstLevelEnhancing(await this.doPost<E>(`${this.basePath}/${encodeURIComponent(entity.id)}/trigger`, entity,
      queryParameters, headers));
  }

  private addServiceOptions(queryParameters: HttpParams): HttpParams {
    if (this.options.descendants) {
      queryParameters = queryParameters.set('descendants', String(this.options.descendants));
    }
    return queryParameters;
  }

  /**
   * enhances all entries depending on their classes
   * used in bulk relation loader
   */
  private enhanceRelations<T extends KolibriEntity>(ts: T[]): T[];
  private enhanceRelations<T extends KolibriEntity>(ts: T): T;
  private enhanceRelations<T extends KolibriEntity>(ts: T | T[]): T | T[] {
    // @ts-ignore because different subtypes are no problem here
    return this.entityEnhancer.firstLevelEnhancing(ts, true, {
      skipModifier: (e) => !e.entityClass
    });
  }
}

import {KolibriEntity} from './kolibri-entity';
import {User} from './user';

export abstract class Chat extends KolibriEntity {
  public name?: string;
  public messages?: ChatMessage[];
}

export abstract class ChatMessage extends KolibriEntity {
  public chat?: Chat;
  public chatId?: string;
  public content?: string;
  public authorName?: string;
  public author?: User;
  public authorId?: string;
}

export abstract class ChatMember extends KolibriEntity {
  public chat?: Chat;
  public user?: User;
}

export enum CriteriaFunction {
  COUNT = 'COUNT',
  SUM = 'SUM',
  AVG = 'AVG',
  MIN = 'MIN',
  MAX = 'MAX',
  NOP = 'NOP',
  TRANSLATE = 'TRANSLATE',
  BM25 = 'BM25',
  DISTANCE = 'DISTANCE',
  DATE_TRUNC = 'DATE_TRUNC',
  DATE_DAYOFWEEK = 'DATE_DAYOFWEEK',
  DATE_YEAR = 'DATE_YEAR',
  DATE_MONTH = 'DATE_MONTH',
  DATE_DAY = 'DATE_DAY',
  DATE_HOUR = 'DATE_HOUR',
  DATE_ISOWEEK = 'DATE_ISOWEEK',
  LENGTH = 'LENGTH',
  LOWER = 'LOWER'
}

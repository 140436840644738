import {AfterContentInit, Component, NgZone} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {MessageService} from 'primeng/api';
import {AuthService, SettingsService} from '../../../../api';
import {FormComponent} from '../../../../ui';

@Component({
  selector: 'login-multi-auth',
  templateUrl: './multi-auth.component.html',
  styleUrls: ['./multi-auth.component.scss', '../form.scss']
})
export class MultiAuthComponent extends FormComponent implements AfterContentInit {
  public token: string;
  public showMultiAuthErrorMessage: boolean = false;
  public resendToken: boolean;

  public constructor(private authService: AuthService, messageService: MessageService, translateService: TranslateService, private zone: NgZone,
                     public settingsService: SettingsService) {
    super(messageService, translateService);
    this.hasAsyncValidations = false;
    this.showSuccessMessage = false;
    this.resendToken = (window as any).resendToken;
  }

  public ngAfterContentInit(): void {
    // well there is no real done, so trigger some time later...
    const subscription = this.zone.onStable.subscribe(() => {
      subscription.unsubscribe();
      this.focusFirstEmptyInput();
    });
  }

  public async doSave(): Promise<boolean> {
    try {
      await this.authService.validateToken(this.token);
      window.location.href = '/';
      return true;
    } catch (e: any) {
      this.messageService.add({
        severity: 'error',
        detail: this.translateService.instant('Error.InvalidToken'),
        summary: ''
      });
      return false;
    }
  }

  public async resend(): Promise<boolean> {
    try {
      await this.authService.resendToken();
      this.messageService.add({
        severity: 'success',
        detail: this.translateService.instant('MultiAuth.NewTokenGenerated'),
        summary: ''
      });
      return true;
    } catch (e: any) {
      if (e.status === 500) {
        this.showMultiAuthErrorMessage = true;
      }
      this.messageService.add({
        severity: 'error',
        detail: this.translateService.instant('MultiAuth.MaximumRetriesReached'),
        summary: ''
      });
      return false;
    }
  }

  /**
   * perform a logout and redirect to auth url
   * nobody logout will be redirected to login form
   */
  public async logout(url: string = 'logout'): Promise<void> {
    await this.authService.logout();
    window.location.href = `/auth/${url}`;
  }
}

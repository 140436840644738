<div (click)="onClick()" [class]="styleClass" [ngClass]="'p-chips p-component'" [ngStyle]="style">
  <ul [ngClass]="{'p-inputtext p-chips-multiple-container':true,'p-focus':focus,'p-disabled':disabled}">
    <li #token (click)="onItemClick($event, item)" *ngFor="let item of value; let i = index;" class="p-chips-token">
      <ng-container *ngTemplateOutlet="itemTemplate; context: {$implicit: item}"></ng-container>
      <span *ngIf="!itemTemplate" class="p-chips-token-label">{{field ? resolveFieldData(item, field) : item}}</span>
      <span (click)="removeItem($event,i)" *ngIf="!disabled" class="p-chips-token-icon pi pi-times-circle"></span>
    </li>
    <li class="p-chips-input-token">
      <span *ngIf="currency" class="one-input__decorator">{{currency}}</span>
      <p-inputNumber (input)="onInput()" (keydown)="onKeydown($event)" (onBlur)="onInputBlur($event)" (paste)="onPaste($event)"
                     [attr.aria-labelledby]="ariaLabelledBy" [attr.id]="inputId" [attr.placeholder]="(value && value.length ? null : placeholder)"
                     [attr.tabindex]="tabindex" [buttonLayout]="buttonLayout" [class]="inputStyleClass" [currency]="currency" [step]="step"
                     [disabled]="disabled" [locale]="locale" [maxFractionDigits]="maxFractionDigits" [max]="maxValue" [minFractionDigits]="minFractionDigits" [min]="minValue" [mode]="mode"
                     [style]="style" name="numberValue"></p-inputNumber>
    </li>
  </ul>
</div>

import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {NG_VALIDATORS, NG_VALUE_ACCESSOR} from '@angular/forms';
import {_, CriteriaOperator, CriteriaQuery, Entity, KolibriEntity, MaybePromise, Relation, RelationInfo} from '@wspsoft/frontend-backend-common';
import {CriteriaFactory, EntityService, EntityServiceFactory, ModelService} from '../../../../../../api';
import {RelationService} from '../../../../../../api/app/service/util/relation.service';
import {RedirectorService} from '../../../../service/redirector.service';

import {KolibriEntityConverterService} from '../../../converter/kolibri-entity-converter.service';
import {CustomInput} from '../../custom-input';
import {AutoComplete} from '../autocomplete';


@Component({
  selector: ' ui-multi-select-autocomplete',
  templateUrl: './multi-select-autocomplete-input.component.html',
  styleUrls: ['../autocomplete.scss', './multi-select-autocomplete-input.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => MultiSelectAutocompleteInputComponent),
    multi: true
  }, {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => MultiSelectAutocompleteInputComponent),
    multi: true,
  }, {
    provide: CustomInput,
    useExisting: forwardRef(() => MultiSelectAutocompleteInputComponent),
    multi: true
  }, {
    provide: KolibriEntityConverterService
  }],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MultiSelectAutocompleteInputComponent extends AutoComplete<KolibriEntity[], KolibriEntity> implements OnInit, OnDestroy {
  @Input()
  public entityId: string;
  public entityMeta: Entity;
  public mappingEntity: Entity;
  @Input()
  public parentEntityId: string;
  public parentEntityMeta: Entity;
  @Input()
  public search: (result: CriteriaQuery<KolibriEntity>) => MaybePromise<any>;
  @Input()
  public convertToString: boolean = false;
  @Input()
  public ignoreMappingEntity: boolean = false;
  @Input()
  public currentRecord: KolibriEntity;
  @Input()
  public fieldId: string;
  private relationInfo: RelationInfo;
  @Output()
  private targetValueChange: EventEmitter<KolibriEntity[]> = new EventEmitter<KolibriEntity[]>();
  private isMappingEntity: boolean;
  private mappingService: EntityService<KolibriEntity>;

  public constructor(private modelService: ModelService, cdr: ChangeDetectorRef, private criteriaFactory: CriteriaFactory,
                     private entityConverter: KolibriEntityConverterService, private relationService: RelationService,
                     private entityServiceFactory: EntityServiceFactory, private redirectorService: RedirectorService) {
    super(cdr);
    this.multiple = true;
    this.converter = entityConverter;
  }

  private ptargetValue: KolibriEntity[];

  @Input()
  public get targetValue(): KolibriEntity[] {
    if (this.convertToString) {
      return this.value;
    } else {
      return this.ptargetValue;
    }
  }

  public set targetValue(value: KolibriEntity[]) {
    if (this.convertToString) {
      this.value = value;
    } else {
      this.ptargetValue = value;
      this.targetValueChange.emit(this.ptargetValue);
    }
  }

  private get ownRelation(): Relation {
    const relations: Relation[] = this.modelService.getRelations(this.entityMeta.id);
    return _.find(relations, {targetRelationName: this.relationInfo.destinationRelation.name, targetId: this.relationInfo.parentRelation.targetId});
  }

  public ngOnInit(): void {
    this.entityConverter.convertToString = this.convertToString;
    this.entityConverter.entityNameOrId = this.entityId;

    this.entityMeta = this.modelService.getEntity(this.entityId);
    this.parentEntityMeta = this.modelService.getEntity(this.parentEntityId);

    this.relationInfo = this.relationService.findInverseRelations(this.fieldId, this.parentEntityMeta, this.entityMeta);

    if (this.entityMeta.mappingEntity && !this.ignoreMappingEntity) {
      this.isMappingEntity = true;
      const {destinationRelation} = this.relationInfo;
      this.mappingEntity = this.entityMeta;
      this.mappingService = this.entityServiceFactory.getService(this.mappingEntity.name);
      this.entityMeta = this.modelService.getEntityByType(destinationRelation);
      // the converter must load from destinationEntity and not from mapping
      this.entityConverter.entityNameOrId = this.entityMeta.id;
    }

    this.loadValues().then();
  }

  public onComplete($event: any): void {
    (async () => {
      this.suggestions = await this.getResults(true, $event.query);

      if (!this.suggestions.length && this.defaultEntry) {
        this.suggestions = [this.defaultEntry($event.query)];
      }
      this.cdr.detectChanges();

      if ($event.originalEvent.cb) {
        $event.originalEvent.cb();
      }
    })();
  }

  public async loadValues(): Promise<void> {
    // if it loads to initiate and current record is not set, then load it from value
    if (!this.currentRecord) {
      return;
    }

    this.value = await this.getResults(false);

    if (this.isMappingEntity && !this.convertToString) {
      this.targetValue = await this.mappingService.getEntityRelations(this.value, this.relationInfo.destinationRelation.name);
    } else {
      this.targetValue = [...this.value]; // "clone" array
    }
  }

  public openRecord(entity: KolibriEntity, $event: MouseEvent): Promise<boolean> {
    return this.redirectorService.redirectToEntity(entity, $event);
  }

  private async getResults(forComplete: boolean, queryString?: string): Promise<KolibriEntity[]> {
    let query: CriteriaQuery<KolibriEntity>;

    // exclude things that are already marked as added
    if (forComplete) {
      query = this.criteriaFactory.getFrontendQuery(this.entityMeta.name);

      if (this.relationInfo.destinationRelation) {
        query.descendants(this.relationInfo.destinationRelation.descendants);
      } else {
        query.descendants(this.relationInfo.parentRelation.descendants);
      }

      if (this.search) {
        const result = this.search(query);
        if (_.isPromise(result)) {
          await result;
        }
      }
      const textFilter = query.addGroup();
      // if value is undefined use en empty array instead to get results
      query.addCondition('id', CriteriaOperator.NOT_IN,
        (this.targetValue || []).map(x => x.id));
      textFilter.addPercentBasedCondition('representativeString', queryString);
      for (const field of this.queryFields || []) {
        textFilter.addPercentBasedCondition(field, queryString, true);
      }
    } else {
      query = this.criteriaFactory.getFrontendQuery(this.isMappingEntity ? this.mappingEntity.name : this.entityMeta.name);

      const group = query.addGroup();
      let columnName;
      if (this.isMappingEntity) {
        // for m2m on the right side, avoid the join
        columnName = this.isMappingEntity ? this.relationInfo.sourceRelation.name : this.ownRelation.name + '.' + this.relationInfo.sourceRelation.name;
        if (!this.isMappingEntity) {
          query.descendants(this.relationInfo.destinationRelation.descendants);
        }
      } else {
        columnName = this.relationInfo.parentRelation.targetRelationName;
        query.descendants(this.relationInfo.parentRelation.descendants);
      }
      group.addCondition(columnName, CriteriaOperator.IS, this.currentRecord, true);
      group.addCondition(columnName, CriteriaOperator.IS_NOT_NULL, undefined, false);
    }

    // cast is only for intellij, so that it don't show warning on addOrder
    (query as CriteriaQuery<KolibriEntity>)
      .addOrder('representativeString')
      .limit(this.size);

    return query.getResults();
  }
}

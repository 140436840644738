import {Attribute, EmailRule, Entity, Relation, Script} from '../..';

export interface EntityModel extends Entity {
  descendants?: EntityModel[];
  isDescendantOf?: (e: EntityModel) => boolean;
  isAncestorOf?: (e: EntityModel) => boolean;
  ancestor?: EntityModel;
  ancestors?: EntityModel[];
  allRelations?: Relation[];
  allAttributes?: Attribute[];
  allScripts?: Script[];
  allEmailRules?: EmailRule[];
}

import {ActivityActorObjectType, ActivityLogType} from './activity-log-entry';
import {KolibriEntity} from './kolibri-entity';

export abstract class AuditLog extends KolibriEntity {
  public affectedRecordId?: string;
  public recordEntityClass?: string;
  public recordRepresentativeString?: string;
  public operation?: ActivityLogType;
  public actor?: ActivityActorObjectType;
  public actorName?: string;
  public loginMethod?: string;
  public recordDifferences?: any;
}

import {DesignerEntity} from './designer.entity';
import {MatrixViewFormButton} from './matrix-view-form-button';


export abstract class MatrixView extends DesignerEntity {
  public dataField?: string;
  public headerField?: string;
  public matrixName?: string;
  public sumValueAsLink?: boolean;
  public showOnlyFilled?: boolean;
  public rowValueLabel?: string;
  public queryScript?: string;
  public sumLinkMode?: string;
  public showRowSum?: string;
  public secUrl?: string;
  public dataFieldJavaType?: string;
  public entityId?: any;
  public url?: string;
  public uuidField?: string;
  public rowValueAsLink?: boolean;
  public rowField?: string;
  public matrixViewFormButtons?: MatrixViewFormButton[];
}

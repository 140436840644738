abstract class StatusResultRenamed {
  public from: string;
  public to: string;
}

abstract class FileStatusResult {

  /** Original location of the file, when the file has been moved */
  public from?: string;

  /** Path of the file */
  public path: string;

  /** First digit of the status code of the file, e.g. 'M' = modified.
   Represents the status of the index if no merge conflicts, otherwise represents
   status of one side of the merge. */
  public index: string;

  /** Second digit of the status code of the file. Represents status of the working directory
   if no merge conflicts, otherwise represents status of other side of a merge. */
    // eslint-disable-next-line camelcase
  public working_dir: string;
}

export abstract class StatusResult {
  // eslint-disable-next-line camelcase
  public not_added: string[];
  public conflicted: string[];
  public created: string[];
  public deleted: string[];
  public modified: string[];
  public renamed: StatusResultRenamed[];
  public staged: string[];
  public files: FileStatusResult[];
  public ahead: number;
  public behind: number;
  public current: string | null;
  public tracking: string | null;
}



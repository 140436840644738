import {QRCodeToDataURLOptions, QRCodeToStringOptions} from 'qrcode';

export abstract class AbstractKolibriQrCodeFactory {

  /**
   * Generates and downloads a QR-Code. Meant to be used on single records. ks.qr.generateQrCode(...);
   * The entity and the repString define the name of the downloaded file in the following format: {entity}_{repString}.png
   * @param {string} baseUrl e.g. http://www.our-most-important-customer.com
   * @param {string} urlString e.g. /PlatformApplication/platformUser/platformUser.xhtml?platformUserId=8f3ddb05-d024-4052-b448-c13459a2ec73
   * @param {string} entity first part of the name
   * @param {string} repString second part of the name
   * @param {QRCodeToDataURLOptions | QRCodeToStringOptions} options for either the qrCode that is created as an dataURL(direct download in the frontend)
   * or created as a string (default: svg-xml, meant for the backend)
   * @return {Promise<string>} if called from the frontend the return value is an empty string.
   * If called from the backend the return value is the qrcode as svg in xml format.
   */
  public abstract generateQrCode(baseUrl: string, urlString: string, entity: string, repString: string,
                                 options?: QRCodeToDataURLOptions | QRCodeToStringOptions): Promise<string>;

  /**
   * Generates and downloads as many qrCodes as there are urlStrings. Downloads them separately. Meant to be used on lists. ks.qr.generateQrCodes(...);
   * The entity and the repString define the name of the downloaded files in the following format: {entity}_{repString}.png
   * @param {string} baseUrl e.g. http://www.our-most-important-customer.com
   * @param {string[]} urlStrings e.g. /PlatformApplication/platformUser/platformUser.xhtml?platformUserId=8f3ddb05-d024-4052-b448-c13459a2ec73
   * @param {string} entity first part of the name
   * @param {string} repString second part of the name
   * @param {QRCodeToDataURLOptions | QRCodeToStringOptions} options for either the qrCode that is created as an dataURL(direct download in the frontend)
   * or created as a string (default: svg-xml, meant for the backend)
   * @return {Promise<string[]>} if called from the frontend the return value is an array filled with empty strings.
   * If called from the backend the return value is an array with the qrcodes as svg in xml format.
   */
  public async generateQrCodes(baseUrl: string, urlStrings: string[], entity: string, repString: string,
                               options?: QRCodeToDataURLOptions | QRCodeToStringOptions): Promise<string[]> {
    const qrCodes: string[] = [];
    for (const urlStr of urlStrings) {
      qrCodes.push(await this.generateQrCode(baseUrl, urlStr, entity, repString, options));
    }
    return qrCodes;
  }
}

import {ChangeDetectionStrategy, Component, forwardRef, Input} from '@angular/core';
import {NG_VALIDATORS, NG_VALUE_ACCESSOR} from '@angular/forms';

import {CustomInput} from '../custom-input';

@Component({
  selector: 'ui-wysiwyg-editor',
  templateUrl: './wysiwyg-editor.component.html',
  styleUrls: ['./wysiwyg-editor.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => WysiwygEditorComponent),
    multi: true
  }, {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => WysiwygEditorComponent),
    multi: true,
  }, {
    provide: CustomInput,
    useExisting: forwardRef(() => WysiwygEditorComponent),
    multi: true
  }],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WysiwygEditorComponent extends CustomInput<any> {
  @Input()
  public compact: boolean = false;
  @Input()
  public mentionOptions: any;
  @Input()
  public preview: boolean = false;

  public togglePreview(): void {
    this.preview = !this.preview;
    this.cdr.detectChanges();
  }
}

<ui-input-wrapper [customValidationMessage]="customValidationMessage" [dirty]="inputElement.dirty" [disable]="disable" [errors]="inputElement.errors"
                  [hasFocus]="hasFocus" [hasLabelAlwaysUp]="true" [helpMessage]="helpMessage" [label]="label" [name]="name" [renderInputGroup]="type === 'form'"
                  [required]="required" [touched]="inputElement.touched" [valid]="inputElement.valid">
  <p-selectButton #inputElement="ngModel" #nativeInput [(ngModel)]="value" [dataKey]="dataKey" [disabled]="disable" [multiple]="multiple" [options]="options"
                  [required]="required" [style]="styleData"
                  styleClass="one-selectbutton one-selectbutton--{{type}} one-selectbutton--size-{{size}} one-selectbutton--{{orientation}} {{disable ?
                  'p-disabled' : ''}}">

    <ng-template let-item>
      <div class="one-selectbutton__button-wrapper" style="--selectButtonColor: {{item.color}}">
        <ui-button (clicked)="$event.cb()" [disable]="disable" [type]="type === 'form' ? '' : type" class="one-selectbutton__button" icon="{{item.icon}}"
                   label="{{item.label}}" styleClass="{{item.value === value ? item.styleClass : ''}}"></ui-button>
        <ui-button (clicked)="$event.cb(); value = undefined;" *ngIf="!disable && !multiple && type==='form' && item.value === value && clearable"
                   [stopPropagate]="true" class="one-selectbutton__button one-selectbutton__button--clear" icon="fas fa-fw fa-times"
                   label="{{'primeng.clear' | translate}}" type="icon-only"></ui-button>
      </div>
    </ng-template>

  </p-selectButton>
</ui-input-wrapper>

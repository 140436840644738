<ui-input-wrapper [(editMode)]="editMode" [customValidationMessage]="customValidationMessage" [dirty]="inputElement.dirty" [disable]="disable"
                  [errors]="inputElement.errors" [filled]="nativeInput.filled" [hasFocus]="hasFocus" [helpMessage]="helpMessage" [label]="label"
                  [linkify]="linkify" [name]="name" [required]="required" [touched]="inputElement.touched" [valid]="inputElement.valid">
  <ui-primeng-custom-autocomplete #inputElement="ngModel" #nativeInput (completeMethod)="onComplete($event)" (onBlur)="focusChanged($event)"
                                  (onFocus)="focusChanged($event)" (onForceClear)="clear();" (onHide)="onHide.emit($event)" (onSelect)="onSelect.emit($event)"
                                  (onShow)="onShow.emit($event)" [(ngModel)]="value" [disabled]="disable" [dropdown]="dropdown" [field]="displayField"
                                  [forceSelection]="forceSelection" [inputStyle]="styleData" [multiple]="multiple" [required]="required" [size]="size"
                                  [suggestions]="suggestions" appendTo="body"
                                  emptyMessage="{{'AutoComplete.NoResults' | translate}}"></ui-primeng-custom-autocomplete>
</ui-input-wrapper>


import {ChangeDetectionStrategy, Component} from '@angular/core';
import {Message} from 'primeng/api';
import {Toast} from 'primeng/toast';
import {OneConfirmService} from '../../../service/one-confirm.service';

@Component({
  selector: 'ui-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToastComponent {

  public constructor(private oneConfirmService: OneConfirmService) {
  }

  /**
   * call accept function from data of toast message
   */
  public acceptToast(toast: Toast, message: Message, cb: () => void): void {
    this.saveToastCallback(toast, message, cb);
    message.data.accept();
  }

  /**
   * call reject function from data of toast message
   */
  public rejectToast(toast: Toast, message: Message, cb: () => void): void {
    this.saveToastCallback(toast, message, cb);
    message.data.reject();
  }

  /**
   * remember toast data to close the toast once the action is resolved
   */
  private saveToastCallback(toast: Toast, message: Message, cb: () => void): void {
    this.oneConfirmService.confirmCallback = () => {
      const index = toast.messages.findIndex(x => x.id === message.id);
      toast.onMessageClose({
        index,
        message
      });
      cb();
    };
  }
}

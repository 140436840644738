abstract class PushResultPushedItem {
  public local: string;
  public remote: string;

  public readonly deleted: boolean;
  public readonly tag: boolean;
  public readonly branch: boolean;
  public readonly new: boolean;
  public readonly alreadyUpdated: boolean;
}

abstract class Branch {
  public local: string;
  public remote: string;
  public remoteName: string;
}

abstract class Head {
  public local: string;
  public remote: string;
}

abstract class Hash {
  public from: string;
  public to: string;
}

abstract class PushResultBranchUpdate {
  public head: Head;
  public hash: Hash;
}

abstract class Ref {
  public local: string;
}

export abstract class PushResult {
  public repo?: string;
  public ref?: Ref;
  public pushed: PushResultPushedItem[];
  public branch?: Branch;
  public update?: PushResultBranchUpdate;
}

<ui-dialog [(visible)]="display" [dialogFooter]="!isFirstPage || !isLastPage && nextButton" [header]="header" [icon]="icon">
  <ng-template #content>
    <ng-container [ngTemplateOutlet]="activePage.content"></ng-container>
  </ng-template>

  <ng-template #footer>
    <ui-button (clicked)="back(); $event.cb()" *ngIf="!isFirstPage && indexHistory.length" icon="fas fa-fw fa-arrow-left" label="{{'Wizard.Prev' | translate}}"
               type="cancel"></ui-button>
    <ui-button (clicked)="next(); $event.cb()" *ngIf="!isLastPage && nextButton" icon="fas fa-fw fa-arrow-right" label="{{'Wizard.Next' | translate}}"
               type="cancel"></ui-button>
    <ui-button (clicked)="save($event.cb)" *ngIf="isLastPage" icon="fas fa-fw fa-save" label="{{'Dialog.Save'
    | translate}}" type="primary"></ui-button>
  </ng-template>
</ui-dialog>

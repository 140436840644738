<div (@overlayAnimation.done)="onOverlayAnimationDone($event)" (@overlayAnimation.start)="onOverlayAnimationStart($event)"
     (scrolled)="onSearch(undefined, true)" *ngIf="visible"
     [@overlayAnimation]="{value: 'visible', params: {showTransitionParams: showTransitionOptions, hideTransitionParams: hideTransitionOptions}}"
     [infiniteScrollDistance]="1" [infiniteScrollThrottle]="50" [scrollWindow]="false" class="one-field-panel" infiniteScroll>
  <table class="one-field-panel-content" name="table">
    <thead>
    <tr>
      <th class="one-field-panel-search">
        <ui-search-input #searchInput (search)="onSearch($event, false)" [value]="lastQuery"></ui-search-input>
      </th>
    </tr>
    </thead>
    <tbody name="tbody">
    <tr (click)="selectItem(field)" *ngFor="let field of suggestions; let i = index" [ngClass]="{
          'one-field-panel-item--highlight': field.name === selectedField?.name,
          'p-highlight': i === highlightIndex && !childOverlay,
          'one-field-panel-item--expandable' : canExpand(field)
          }" class="one-field-panel-item">
      <td *ngIf="field.icon" class="one-field-panel-item__icon">
        <i [class]="field.icon"></i>
      </td>
      <td class="one-field-panel-item__label">
        {{field.label}}
      </td>
      <td class="one-field-panel-item__action">
        <ui-button (clicked)="disableAutoExpand(); openNextLevel(field, $event.originalEvent)" *ngIf="canExpand(field)" [spinner]="false"
                   class="one-field-panel-expand" icon="fas fa-fw fa-chevron-right" type="icon-only"></ui-button>
      </td>
    </tr>
    <tr *ngIf="!suggestions.length" class="one-field-panel-item">
      <td class="one-field-panel-item__label">
        {{'AutoComplete.NoResults' | translate}}
      </td>
    </tr>
    </tbody>
  </table>
</div>

<ui-field-overlay #child (onHide)="bindKeyNavigationListener(); searchInput.focus()" (onOpenNextLevel)="calculateOverlayPosition($event)"
                  (onSelect)="this.hide(); this.onSelect.emit($event)" (valueChange)="calculateValue($event)" *ngIf="overlayVisible && overlayEl && aligned"
                  [(visible)]="overlayVisible" [allowAutoExpand]="allowAutoExpand" [allowDotWalk]="allowDotWalk"
                  [conditionBuilderOptions]="conditionBuilderOptions" [containerEl]="overlayEl" [entityMeta]="subOverlayData.entityMeta"
                  [isTransient]="isTransient" [level]="level + 1" [localized]="localized" [rootEl]="rootOverlay" [search]="search"
                  [value]="value"></ui-field-overlay>

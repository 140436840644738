import * as moment from 'moment';
import {KolibriEntity} from './kolibri-entity';

export enum BackupPolicyRunType {
  scheduled = 'scheduled', ondemand = 'ondemand'
}

export class BackupPolicy extends KolibriEntity {
  public directory: string;
  public selectedEntities: string[];
  public retentionTime: number;
  public runInterval: number;
  public runType: string;
  public runStart: string | moment.Moment;
  public name: string;
}

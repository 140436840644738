import {VersionableJson} from '../../model/database/json/versionable-json';
import {CriteriaField} from '../criteria-field';
import {CriteriaFunction} from '../criteria-function';
import {CriteriaGroupBy} from '../criteria-group-by';
import {CriteriaOrderBy} from '../criteria-order-by';
import {CriteriaTenantMode} from '../criteria-tenant-mode';
import {CriteriaTransform} from '../criteria-transform';
import {CriteriaType} from '../criteria-type';
import {CriteriaQueryGroupJson} from './criteria-query-group-json';

export interface CriteriaQueryJson extends CriteriaQueryGroupJson, VersionableJson {
  orders?: CriteriaOrderBy[];
  transforms?: CriteriaTransform[];
  type?: CriteriaType;
  groupBys?: CriteriaGroupBy[];
  selectFields?: CriteriaField[];
  deselectFields?: CriteriaField[];
  limit?: number;
  descendants?: boolean;
  includeShared?: boolean;
  tenancyFilter?: CriteriaTenantMode;
  autoIndex?: boolean;
  fullCount?: boolean;
  entity?: string;
  view?: string;
  indexHint?: string[] | string;
  offset?: number;
  criteriaFunction?: CriteriaFunction;
}

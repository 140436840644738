export function enumerable(value: boolean): any {
  return function (target: any, propertyKey: string, descriptor: PropertyDescriptor) {
    if (descriptor) {
      descriptor.enumerable = value;
      return descriptor;
    }
    Object.defineProperty(target, propertyKey, {
      set(setValue) {
        Object.defineProperty(this, propertyKey, {
          value: setValue, writable: true, configurable: true,
        });
      },
      configurable: true,
    });
  };
}
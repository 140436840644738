import {Injectable} from '@angular/core';
import * as uuidv4 from 'uuid-random';

export interface MultiTabData {
  tabId: string;
  event: string;
  payload: any;
}

export type MultiTabEventFunction = (e: MultiTabData) => any;

interface MultiTabEventConfiguration {
  [key: string]: MultiTabEventFunction[];
}

@Injectable()
export class MultiTabSynchronizerService {
  private static readonly TAB_ID: string = uuidv4();
  private readonly events: MultiTabEventConfiguration = {};

  public constructor() {
    // keeping this one, on tab change we check for changes through another tab
    window.addEventListener('storage', (e) => {
      if (e.newValue) {
        this.broadCast(e.key, JSON.parse(e.newValue));
      }
    });
  }

  public getData(event: string, parse: boolean = true): MultiTabData {
    const item = localStorage.getItem(event);

    if (!item) {
      return {
        payload: undefined,
        tabId: MultiTabSynchronizerService.TAB_ID,
        event
      };
    }

    return parse ? JSON.parse(item) : item;
  }

  public on(event: string, fn: MultiTabEventFunction): void {
    if (!(event in this.events)) {
      this.events[event] = [];
    }

    this.events[event].push(fn);
  }

  public fire(event: string, payload: any): void {
    // set own id into data object, we don't wanna listen to our own change
    localStorage.setItem(event, JSON.stringify({
      tabId: MultiTabSynchronizerService.TAB_ID,
      payload,
      event
    }));
  }

  private broadCast(event: string, e: MultiTabData): void {
    // when we have listeners for this event fire them and also only for foreign events
    if (event && e.tabId !== MultiTabSynchronizerService.TAB_ID && (event in this.events)) {
      for (const eventElement of this.events[event]) {
        eventElement(e);
      }
    }
  }
}

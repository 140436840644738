import {sort} from 'fast-sort';
// @ts-ignore
import * as countBy from 'lodash/countBy';
// @ts-ignore
import * as findIndex from 'lodash/findIndex';
// @ts-ignore
import * as merge from 'lodash/merge';
// @ts-ignore
import * as mergeWith from 'lodash/mergeWith';
// @ts-ignore
import * as orderBy from 'lodash/orderBy';
// @ts-ignore
import * as pickBy from 'lodash/pickBy';
// @ts-ignore
import * as remove from 'lodash/remove';
// @ts-ignore
import * as sortedLastIndexBy from 'lodash/sortedLastIndexBy';
// @ts-ignore
import * as transform from 'lodash/transform';
// @ts-ignore
import * as zipWith from 'lodash/zipWith';
import {ApiHandler} from '../service/util/handler/api-handler';
import {VariableHandler} from '../service/util/handler/variable-handler';

export type MaybePromise<T> = T | Promise<T>;
export type NestedArray<T> = Array<NestedArray<T> | T>;

export abstract class Underscore {
  public static countBy = countBy;
  public static merge = merge;
  public static mergeWith = mergeWith;
  public static orderBy = orderBy;
  public static pickBy = pickBy;
  public static remove = remove;
  public static sortedLastIndexBy = sortedLastIndexBy;
  public static transform = transform;
  public static zipWith = zipWith;
  public static findIndex = findIndex;

  /**
   * escapes the string to be used inside the regex
   */
  public static escapeRegExp(s: string): string {
    return s.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
  }

  /**
   * Like object assign but ignoring any errors that might occur due to getters
   */
  public static assign<E, F>(target: E, source: F): E & F {
    for (const key of Object.keys(source)) {
      const newValue = source[key];

      if (newValue !== undefined) {
        try {
          target[key] = newValue;
        } catch (e: any) {
          // might throw errors for read only attributes
        }
      }
    }
    return target as E & F;
  }

  /**
   * First character to upper
   */
  public static capitalize(s: string): string {
    return s.charAt(0).toUpperCase() + s.slice(1);
  }

  /**
   * Creates an array of elements split into groups the length of size
   */
  public static chunk<T>(input: T[], size: number): T[][] {
    const chunks = [];
    for (let i = 0; i < input.length; i += size) {
      if ((i + size) > input.length) {
        chunks.push([...input.slice(i)]);
        break;
      }
      chunks.push([...input.slice(i, i + size)]);
    }
    return chunks;
  }

  /**
   * Creates a really fast shallow clone of value
   */
  public static clone<T = any>(value: T): T {
    // just return primitive value
    if (typeof value !== 'object' || value === null) {
      return value;
    }
    // return date as a new generic date
    if (value instanceof Date) {
      return new (value.constructor as DateConstructor)(value) as any;
    }
    // spread to new array
    if (Array.isArray(value)) {
      return [...value] as any;
    }
    // spread to new object
    return {...value};
  }

  /**
   * Creates a really fast deep clone of value
   */
  public static cloneDeep<T = any>(value: T): T {
    // just return primitive values
    if (typeof value !== 'object' || value === null) {
      return value;
    }
    // return date as a new generic date
    if (value instanceof Date) {
      return new (value.constructor as any)(value);
    }
    // in case value is an enhanced entity
    if (value instanceof VariableHandler || value instanceof ApiHandler) {
      return value.clone();
    }
    let i;
    let newValue;
    // iterate recursive over array
    if (Array.isArray(value)) {
      const length = value.length;
      newValue = Array(length);
      for (i = length; i-- !== 0;) {
        newValue[i] = this.cloneDeep(value[i]);
      }
      return newValue;
    }
    // iterate recursive over generic object
    newValue = Object.create(Object.getPrototypeOf(value));
    for (i in value) {
      newValue[i] = this.cloneDeep(value[i]);
    }
    return newValue;
  }

  /**
   * Creates a really fast shallow clone of value with all properties defined
   */
  public static cloneProperties<T = Record<string, any>>(value: T, enumerable: boolean = false): Partial<T> {
    return Object.entries(this.getAllProperties(value)).reduce((acc, [key, desc]) => {
      if (enumerable) {
        if (desc.enumerable) {
          acc[key] = value[key];
        }
      } else {
        acc[key] = value[key];
      }

      return acc;
    }, {});
  }

  /**
   * First character to lower
   */
  public static decapitalize(s: string): string {
    return s.charAt(0).toLowerCase() + s.slice(1);
  }

  /**
   * Creates a debounced function that delays invoking func until after wait milliseconds
   * have elapsed since the last time the debounced function was invoked
   */
  // eslint-disable-next-line space-before-function-paren
  public static debounce<T extends (...args: any) => any>(func: T, delay: number, leading?: boolean): T {
    let timer;
    let waiting;
    return function (this, ...args) {
      if (waiting === undefined && leading) {
        func.call(this, ...args);
      }
      if (!waiting) {
        waiting = true;
        clearTimeout(timer);
        setTimeout(() => {
          timer = setTimeout(() => func.call(this, ...args), delay);
          waiting = false;
        });
      }
    } as T;
  }

  /**
   * Creates an array of unique array values not included in the other provided arrays
   */
  public static difference<T = any>(array: T[], ...arrays: T[][]): T[] {
    array ??= [];
    const values = new Set(...arrays);
    return array.filter((x) => !values.has(x));
  }

  /**
   * This method is like _.difference except that it accepts iteratee which is invoked for each
   * element of array and values to generate the criterion by which uniqueness is computed
   */
  public static differenceBy<T1 = any, T2 = any>(array1: T1[] = [], array2: T2[] = [], iteratee: string): T1[] {
    const values = new Set(array2.map((x) => x[iteratee]));
    return array1.filter((x) => !values.has(x[iteratee]));
  }

  /**
   * This method is like _.difference except that it accepts comparator which is invoked to compare elements of array to values
   * The order and references of result values are determined by the first array
   */
  public static differenceWith<T1 = any, T2 = any>(array1: T1[] = [], array2: T2[] = [], comparator: (a: T1, b: T2) => boolean): T1[] {
    return array1.reduce((target, a) => {
      if (!array2.some((b) => comparator(a, b))) {
        target.push(a);
      }
      return target;
    }, []);
  }

  /**
   * Checks if predicate returns truthy for all elements of collection
   * Iteration is stopped once predicate returns falsy
   */
  public static every<T = any>(values: T[] | { [key: string]: T }, predicate: any): boolean {
    values ??= [];
    if (!Array.isArray(values)) {
      return this.every(Object.values(values), predicate);
    }
    if (typeof predicate === 'string') {
      return values.every((x) => !!x[predicate]);
    }
    if (typeof predicate === 'object') {
      let i;
      let key;
      const keys = Object.keys(predicate);
      const length = keys.length;
      return values.every((x) => {
        for (i = length; i-- !== 0;) {
          key = keys[i];
          if (predicate[key] !== x[key]) {
            return false;
          }
        }
        return true;
      });
    }
    return values.every(predicate);
  }

  /**
   * Iterates over elements of array, returning an array of all elements predicate returns truthy for
   */
  public static filter<T = any>(values: T[] | { [key: string]: T } = [], predicate: any): T[] {
    if (!Array.isArray(values)) {
      return this.filter(Object.values(values), predicate);
    }
    if (typeof predicate === 'string') {
      return values.filter((x) => !!x[predicate]);
    }
    if (typeof predicate === 'object') {
      let i;
      let key;
      const keys = Object.keys(predicate);
      const length = keys.length;
      return values.filter((x) => {
        for (i = length; i-- !== 0;) {
          key = keys[i];
          if (predicate[key] !== x[key]) {
            return false;
          }
        }
        return true;
      });
    }
    return values.filter(predicate);
  }

  /**
   * Iterates over elements of array, returning the first element predicate returns truthy for
   */
  public static find<T = any>(values: T[] | { [key: string]: T } = [], predicate: any): T | undefined {
    if (!Array.isArray(values)) {
      return this.find(Object.values(values), predicate);
    }
    if (typeof predicate === 'string') {
      return values.find((x) => !!x[predicate]);
    }
    if (typeof predicate === 'object') {
      let i;
      let key;
      const keys = Object.keys(predicate);
      const length = keys.length;
      return values.find((x) => {
        for (i = length; i-- !== 0;) {
          key = keys[i];
          if (predicate[key] !== x[key]) {
            return false;
          }
        }
        return true;
      });
    }
    return values.find(predicate);
  }

  /**
   * Creates an array of flattened values by running each element in collection
   * through iteratee and concatenating its result to the other mapped values
   */
  public static flatMap<T = any, S = any>(values: T[], predicate?: ((value: T, index: number, collection: T[]) => S[]) | string): S[] {
    if (typeof predicate === 'string') {
      return [].concat(...values.map((x) => x[predicate]));
    }
    if (!predicate) {
      return [].concat(...values);
    }
    return [].concat(...values.map(predicate));
  }

  /**
   * flatten recursive array structure
   */
  public static flatten<T>(xs: NestedArray<T>): T[] {
    return xs.reduce((acc: T[], x) => {
      if (Array.isArray(x)) {
        return acc.concat(this.flatten(x));
      } else {
        return acc.concat(x);
      }
    }, [] as T[]);
  }

  /**
   * flatten recursive array structure
   */
  public static flattenBy<T extends Partial<Record<K, T[]>>, K extends keyof T>(xs: T[], field: K): T[] {
    return xs.reduce((acc, x) => {
      acc = acc.concat(x);
      if (x[field]) {
        acc = acc.concat(this.flattenBy(x[field], field));
      }
      return acc;
    }, []);
  }

  /**
   * get all properties including inherited
   */
  public static getAllProperties(object: any): { [x: string]: PropertyDescriptor } {
    const prototypeOf = Object.getPrototypeOf(object);

    if (prototypeOf === Object.prototype) {
      return {};
    }

    return {...Object.getOwnPropertyDescriptors(prototypeOf), ...this.getAllProperties(prototypeOf)};
  }

  /**
   * Creates an object composed of keys generated from the results of running each element of collection through iteratee
   * The corresponding value of each key is an array of the elements responsible for generating the key
   */
  public static groupBy<T = any>(values: T[] = [], predicate: ((value: T) => string) | string): { [key: string]: T[] } {
    let key;
    if (typeof predicate === 'string') {
      return values.reduce((acc, value) => {
        key = value[predicate];
        (acc[key] = acc[key] || []).push(value);
        return acc;
      }, {});
    }
    return values.reduce((acc, value) => {
      key = predicate(value);
      (acc[key] = acc[key] || []).push(value);
      return acc;
    }, {});
  }

  /**
   * Returns everything but the last entry of the array. Pass n to exclude the last n elements from the result
   */
  public static initial<T = any>(values: T[]): T[] {
    return values.slice(0, -1);
  }

  /**
   * Creates an array of unique values that are included in all of the provided arrays
   */
  public static intersection<T = any>(array: T[] = [], ...arrays: T[][]): T[] {
    const values = new Set(...arrays);
    return array.filter((x) => values.has(x));
  }

  /**
   * This method is like _.intersection except that it accepts iteratee which is invoked for each
   * element of each arrays to generate the criterion by which uniqueness is computed
   */
  public static intersectionBy<T1 = any, T2 = any>(array1: T1[] = [], array2: T2[] = [], iteratee: string): T1[] {
    const values = new Set(array2.map((x) => x[iteratee]));
    return array1.filter((x) => values.has(x[iteratee]));
  }

  /**
   * This method is like _.intersection except that it accepts comparator which is invoked to compare elements of arrays
   * The order and references of result values are determined by the first array
   */
  public static intersectionWith<T1 = any, T2 = any>(array1: T1[] = [], array2: T2[] = [], comparator: (a: T1, b: T2) => boolean): T1[] {
    return array1.reduce((target, a) => {
      if (array2.some((b) => comparator(a, b))) {
        target.push(a);
      }
      return target;
    }, []);
  }

  /**
   * Checks if value is null, undefined, empty array, empty object but ignoring empty string und 0
   */
  public static isEmpty(value: any): boolean {
    return this.isNull(value) || (typeof value === 'object' ? Array.isArray(value) ? value.length === 0 : Object.keys(value).length === 0 : false);
  }

  /**
   * Performs a deep comparison between two values to determine if they are equivalent
   */
  public static isEqual(value: any, other: any, customizer?: (value: any, other: any, key: string) => boolean | undefined): boolean {
    if (value === other) {
      return true;
    }

    // begin deep comparison if values are objects
    if (value && other && typeof value === 'object' && typeof other === 'object') {
      if (value.constructor !== other.constructor) {
        return false;
      }

      let i;
      let length;
      if (Array.isArray(value)) {
        length = value.length;
        if (length !== other.length) {
          return false;
        }
        for (i = length; i-- !== 0;) {
          if (!this.isEqual(value[i], other[i])) {
            return false;
          }
        }
        return true;
      }

      if (value.constructor === RegExp) {
        return value.source === other.source && value.flags === other.flags;
      }
      if (value.valueOf !== Object.prototype.valueOf) {
        return value.valueOf() === other.valueOf();
      }
      if (value.toString !== Object.prototype.toString) {
        return value.toString() === other.toString();
      }

      // allow custom keys function for magic stuff
      const keys = value.keys?.() ?? Object.keys(value);
      length = keys.length;
      if (!customizer) {
        if (length !== (other.keys?.() ?? Object.keys(other)).length) {
          return false;
        }
        for (i = length; i-- !== 0;) {
          if (!Object.prototype.hasOwnProperty.call(other, keys[i])) {
            return false;
          }
        }
      }
      let key;
      let result;
      for (i = length; i-- !== 0;) {
        key = keys[i];
        if (customizer) {
          result = customizer(value[key], other[key], key);
        }
        if (result === undefined) {
          if (!this.isEqual(value[key], other[key])) {
            return false;
          }
        } else if (result === false) {
          return false;
        } else {
          result = undefined;
        }
      }

      return true;
    }

    return value !== value && other !== other;
  }

  /**
   * This method is like _.isEqual except that it accepts customizer which is invoked to compare values
   * If customizer returns undefined comparisons are handled by the method instead
   */
  public static isEqualWith(value: any, other: any, customizer: (value: any, other: any, key: string) => boolean | undefined): boolean {
    return this.isEqual(value, other, customizer);
  }

  /**
   * Checks if value is undefined or null
   */
  public static isNull(value: any): boolean {
    return value === null || value === undefined;
  }

  /**
   * Checks if value is undefined, null or empty string
   */
  public static isNullOrEmpty(value: any): boolean {
    return value === '' || this.isEmpty(value);
  }

  /**
   * Checks if value is the language type of Object. (e.g. arrays, functions, objects, regexes, new Number(0), and new String(''))
   */
  public static isObject(value: any): boolean {
    const type = typeof value;
    return value !== null && (type === 'object' || type === 'function');
  }

  /**
   * Checks if maybe promise is a promise
   */
  public static isPromise(maybePromise: MaybePromise<any>): boolean {
    return maybePromise && !!maybePromise.then;
  }

  /**
   * Get last value of array
   */
  public static last<T = any>(values: T[]): T {
    return values[values.length - 1];
  }

  /**
   * This method is like _.max except that it accepts iteratee which is invoked for
   * each element in array to generate the criterion by which the value is ranked
   */
  public static maxBy<T = any>(values: T[] = [], predicate: any): T | undefined {
    if (typeof predicate === 'string') {
      const max = Math.max(...values.map((x) => x[predicate]));
      return values.find((x) => x[predicate] === max);
    }
    const max = Math.max(...values.map(predicate as (x) => number));
    return values.find((x) => predicate(x) === max);
  }

  /**
   * Evaluates a maybe promise without forcing a promise
   */
  public static maybeAwait<T = any>(maybePromise: MaybePromise<T>, fn: (x: T) => void): void {
    if (this.isPromise(maybePromise)) {
      (maybePromise as Promise<T>).then(value => fn(value));
    } else {
      fn(maybePromise as T);
    }
  }

  /**
   * This method is like _.min except that it accepts iteratee which is invoked for
   * each element in array to generate the criterion by which the value is ranked
   */
  public static minBy<T = any>(values: T[] = [], predicate: any): T | undefined {
    if (typeof predicate === 'string') {
      const min = Math.min(...values.map((x) => x[predicate]));
      return values.find((x) => x[predicate] === min);
    }
    const min = Math.min(...values.map(predicate as (x) => number));
    return values.find((x) => predicate(x) === min);
  }

  /**
   * The opposite of _.pick; this method creates an object composed of the own
   * and inherited enumerable properties of object that are not omitted
   */
  public static omit<T = any>(value: T, ...paths: any[]): Partial<T> {
    let path;
    value = {...value};
    for (path of paths) {
      delete value[path];
    }
    return value;
  }

  /**
   * Pads string on the left and right sides if it's shorter than length
   * Padding characters are truncated if they can't be evenly divided by length
   */
  public static pad(str: string = '', length: number = 0, chars: string = ' '): string {
    const prePad = Math.floor((length - str.length) / 2) + str.length;
    return str.padStart(prePad, chars).padEnd(length, chars);
  }

  /**
   * Pads string on the right side if it's shorter than length
   * Padding characters are truncated if they exceed length
   */
  public static padEnd(str: string = '', length: number = 0, chars: string = ' '): string {
    return str.padEnd(length, chars);
  }

  /**
   * Like Promise.all but executed in chunks, so you can have some async concurrency control
   */
  public static async parallelDo(values: any[], func: (value, index) => void, threads: number = 10): Promise<void> {
    const entries = values.entries();
    const worker = async (): Promise<void> => {
      for (const [index, value] of entries) {
        await func(value, index);
      }
    };
    await Promise.all(
      Array.from({length: Math.min(values.length, threads)}, worker)
    );
  }

  /**
   * Like Promise.all but executed in chunks, so you can have some async concurrency control
   */
  public static async parallelMap(values: any[], func: (value, index) => any, threads: number = 10, inPlace: boolean = false): Promise<any[]> {
    const results = inPlace ? values : Array(values.length);
    const entries = values.entries();
    const worker = async (): Promise<void> => {
      for (const [index, value] of entries) {
        results[index] = await func(value, index);
      }
    };
    await Promise.all(
      Array.from({length: Math.min(values.length, threads)}, worker)
    );
    return results;
  }

  /**
   * Applies callback function to each element and then flattens the resulting array but executed in chunks, so you can have some async concurrency control
   */
  public static async parallelFlatMap(values: any[], func: (value, index) => any, threads: number = 10, inPlace: boolean = false): Promise<any[]> {
    return _.flatten(await _.parallelMap(values, func, threads, inPlace));
  }

  /**
   * Creates an object composed of the object properties predicate returns truthy for
   */
  public static pick<T extends { [key: string]: any }>(value: T, keys: string[], clone: boolean = false): Partial<T> {
    return keys.reduce((target, key) => {
      target[key] = clone ? this.cloneDeep(value[key]) : value[key];
      return target;
    }, {});
  }

  /**
   * Removes all provided values from the given array using strict equality for comparisons, i.e. ===
   */
  public static pull<T = any>(arr: T[], ...removeList: T[]): T[] {
    const removeSet = new Set(removeList);
    return arr.filter((el) => !removeSet.has(el));
  }

  /**
   * Produces a random number between the inclusive lower and upper bounds
   * If only one argument is provided a number between 0 and the given number is returned
   */
  public static random(a: number = 1, b: number = 0, float?: boolean): number {
    const random = (): number => {
      const lower = Math.min(a, b);
      const upper = Math.max(a, b);
      return lower + Math.random() * (upper - lower);
    };
    const randomInt = (): number => {
      const lower = Math.ceil(Math.min(a, b));
      const upper = Math.floor(Math.max(a, b));
      return Math.floor(lower + Math.random() * (upper - lower + 1));
    };
    return float ? random() : randomInt();
  }

  /**
   * Checks if predicate returns truthy for any element of collection
   * iteration is stopped once predicate returns truthy
   */
  public static some<T = any>(values: T[] | { [key: string]: T }, predicate: any): boolean {
    values ??= [];
    if (!Array.isArray(values)) {
      return this.some(Object.values(values), predicate);
    }
    if (typeof predicate === 'string') {
      return values.some((x) => !!x[predicate]);
    }
    if (typeof predicate === 'object') {
      let i;
      let key;
      const keys = Object.keys(predicate);
      const length = keys.length;
      return values.some((x) => {
        for (i = length; i-- !== 0;) {
          key = keys[i];
          if (predicate[key] !== x[key]) {
            return false;
          }
        }
        return true;
      });
    }
    return values.some(predicate);
  }

  /**
   * Creates an array of elements, sorted in ascending order by the results of running each element in a collection thru each iteratee
   * This method performs a stable sort, that is, it preserves the original sort order of equal elements
   */
  public static sortBy<T = any>(array: T[] = [], predicate?: any): T[] {
    return sort(array).asc(predicate);
  }

  /**
   * This method is like _.sum except that it accepts iteratee which is invoked
   * for each element in array to generate the value to be summed
   */
  public static sumBy<T = any>(values: T[], predicate: ((value: T) => number) | string): number {
    if (typeof predicate === 'string') {
      return values.reduce((acc, value) => acc + (value[predicate] ?? 0), 0);
    }
    return values.reduce((acc, value) => acc + (predicate(value) ?? 0), 0);
  }

  /**
   * Interpolates given data properties and returns a string containing the whole data
   */
  public static template(str: string = '', params: { [key: string]: any } = {}, interpolate: RegExp = /\${([\s\S]+?)}/g): string {
    const placeholders = this.uniq(str.match(interpolate));
    for (const placeholder of placeholders) {
      // split and join at current placeholder position with inner keys
      str = str.split(placeholder).join(placeholder.match(/\w+/g)
        // now do a generic property lookup on params to get correct value
        .reduce((value: any, property: string) => value[property], params)
      );
    }
    return str;
  }

  /**
   * Creates a throttled function that only invokes func at most once per every wait milliseconds
   */
  // eslint-disable-next-line space-before-function-paren
  public static throttle<T extends (...args: any) => any>(func: T, delay: number, leading?: boolean): T {
    let waiting;
    return function (this, ...args) {
      if (waiting === undefined && leading) {
        func.call(this, ...args);
      }
      if (!waiting) {
        waiting = true;
        setTimeout(() => {
          func.call(this, ...args);
          waiting = false;
        }, delay);
      }
    } as T;
  }

  /**
   * Removes leading and trailing whitespace or specified characters from string
   */
  public static trim(str: string = '', chars: string = '\\s'): string {
    return str.replace(new RegExp(`^([${chars}]*)(.*?)([${chars}]*)$`), '$2');
  }

  /**
   * will try to resolve a promise within the given timeout
   * if timeout is hit the returned promise will be rejected, otherwise the value of the given promise is returned
   */
  public static tryPromiseWithTimeout(promise: Promise<any>, timeout: number): Promise<any> {
    const timeoutPromise = new Promise((res, rej) => setTimeout(rej, timeout));
    return Promise.race([promise, timeoutPromise]);
  }

  /**
   * Creates a duplicate-free version of an array
   */
  public static uniq<T = any>(values: T[]): T[] {
    return [...new Set(values)];
  }

  /**
   * Similar to _.uniq except that it accepts comparator which is invoked to compare elements of array
   * the order of result values is determined by the order they occur in the array
   */
  public static uniqWith<T = any>(arr: T[], fn: (a: T, b: T) => boolean): T[] {
    return arr.filter((element, index) => arr.findIndex((step) => fn(element, step)) === index);
  }

  /**
   * This method is like uniq except that it accepts iteratee
   */
  public static uniqBy<T = any>(values: T[], iteratee: string): T[] {
    return [...new Map(values.map(v => [v[iteratee], v])).values()];
  }

  /**
   * Creates an array of unique values, in order, from all given arrays
   */
  public static union<T = any>(...values: T[][]): T[] {
    return this.uniq([].concat(...values));
  }

  /**
   * This method is like _.union except that it accepts iteratee which is invoked for each
   * element of each arrays to generate the criterion by which uniqueness is computed
   */
  public static unionBy<T = any>(array1: T[], array2: T[], iteratee: string): T[] {
    return this.uniqBy([].concat(array1, array2), iteratee);
  }

  /**
   * This method accepts two arrays, one of property identifiers and one of corresponding values
   */
  public static zipObject<T = any>(properties: string[], values: T[]): { [key: string]: T } {
    return properties.reduce((accumulator, key, index) => {
      accumulator[key] = values[index];
      return accumulator;
    }, {});
  }
}

export const _ = Underscore;

import {ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {NG_VALIDATORS, NG_VALUE_ACCESSOR} from '@angular/forms';
import {
  _,
  CodeEditorLanguages,
  CodeEditorOptions,
  ConditionValueType,
  CriteriaConditionOperatorOnly,
  CriteriaOperator,
  CriteriaQuery,
  DateType,
  DisplayTransformation,
  Field,
  KolibriEntity,
  MaybePromise,
  Relation,
  Utility
} from '@wspsoft/frontend-backend-common';
import * as uuidv4 from 'uuid-random';
import {CircularService, ModelService} from '../../../../../api';
// noinspection ES6PreferShortImport
import {CustomInput} from '../custom-input';


@Component({
  selector: 'ui-field-value-selector',
  templateUrl: './field-value-selector.component.html',
  styleUrls: ['./field-value-selector.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => FieldValueSelectorComponent),
    multi: true
  }, {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => FieldValueSelectorComponent),
    multi: true,
  }, {
    provide: CustomInput,
    useExisting: forwardRef(() => FieldValueSelectorComponent),
    multi: true
  }],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FieldValueSelectorComponent extends CustomInput<any> implements OnInit, OnChanges {
  @Input()
  public recordEntityClass: string;
  @Input()
  public queryEntityClass: string;
  @Input()
  public entityName: string;
  @Input()
  public ignoreMappingEntity: boolean = false;
  @Input()
  public data: any;
  @Input()
  public operator: CriteriaOperator | CriteriaConditionOperatorOnly;
  @Input()
  public stringValue: boolean;
  @Input()
  public conditionValueType: ConditionValueType;
  public id: string = uuidv4();
  @Input()
  public field: string;
  @Input()
  public fieldMeta: Field;
  @Input()
  public transformId: string;
  @Input()
  public codeEditorOptions: CodeEditorOptions[];
  @Input()
  public scriptLanguage: CodeEditorLanguages;
  @Input()
  public filter: (result: CriteriaQuery<KolibriEntity>) => MaybePromise<any>;
  public transformEntity: DisplayTransformation = {};

  public constructor(public modelService: ModelService, public circularService: CircularService,
                     cdr: ChangeDetectorRef) {
    super(cdr);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    // set value to undefined when operator changes to avoid ngFor error #4266
    if(changes.operator && !changes.operator.firstChange) {
      this.value = undefined;
    }
  }

  public get fieldId(): string {
    return this.fieldMeta.id;
  }

  /**
   * the type for the input
   * @return type
   */
  public get type(): string {
    if (this.operator === CriteriaOperator.DATE_RANGE) {
      return 'DateRange';
    } else if (this.stringValue) {
      return 'String';
    } else if (this.conditionValueType === ConditionValueType.SCRIPTED_VALUE) {
      return 'Code';
    } else if (this.conditionValueType === ConditionValueType.SCRIPTED) {
      return 'KolibriEntity';
    } else {
      return this.modelService.getTypeName(this.fieldMeta);
    }
  }

  /**
   * gets the type name of the field
   * @return name of the field type or entity type on relations
   */
  public get typeName(): string {
    if (Utility.isAttribute(this.fieldMeta)) {
      return this.modelService.getFieldType(this.fieldMeta).name;
    } else {
      return this.modelService.getEntityByType(this.fieldMeta).name;
    }
  }

  public get value(): any {
    return super.value;
  }

  public set value(value: any) {
    super.value = value;

    if (this.data) {
      Utility.doDotWalk(this.data, this.field, () => {
      }, this.value, _.isEmpty(this.value));
    }
  }

  public ngOnInit(): void {
    this.transformEntity = this.modelService.getDisplayTransformation(this.transformId || this.fieldMeta.displayTransformId);
    // apply display converter
    if (this.data) {
      Utility.doDotWalk(this.data, this.field, (x) => {
        this.value = x;
      });
    }
    this.transformStyle();
  }

  /**
   * @returns {any} the styling changes that are supposed to be applied to the Input
   */
  public transformStyle(): void {
    _.maybeAwait(this.circularService.scriptExecutor.runScript(this.transformEntity?.transformScript, {
        newValue: this.value,
        record: this.data
      }, undefined, `FieldValueSelector:${this.field}:TransformationEntity:${this.transformEntity?.name}:transformScript`).result,
      (x: any) => this.styleData = x?.style);
  }

  public search(query: CriteriaQuery<KolibriEntity>): MaybePromise<void> {
    query.descendants((this.fieldMeta as Relation)?.descendants);
    return this.filter?.(query);
  }

  public get step(): number {
    return this.transformEntity?.decimalPlaces === 0 ? 1 : 0.1;
  }

  public get decimalPlaces(): number {
    return this.transformEntity?.decimalPlaces ?? 1;
  }

  public get symbol(): string {
    return this.transformEntity?.symbol;
  }

  public get showTime(): boolean {
    return this.transformEntity?.dateType === DateType.DATETIME;
  }

  public get timeOnly(): boolean {
    return this.transformEntity?.dateType === DateType.TIME;
  }
}

import {Component} from '@angular/core';

@Component({
  selector: 'login-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent {

  public constructor() {
  }

  public nav(): string {
    return window.location.href = '/';
  }

}

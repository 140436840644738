import {AggregationFunction} from './aggregation-function';
import {AggregationTimeGranularity} from './aggregation-time-granularity';
import {Layout} from './layout';
import {LayoutFullCalendarToResource} from './layout-full-calendar-to-resource';


export abstract class LayoutFullCalendar extends Layout {
  public aggregation: AggregationFunction;
  public aggregationGranularity: AggregationTimeGranularity;
  public initialZoom: FullCalendarZoomLevel;
  public calendarToResources: LayoutFullCalendarToResource[];
}

export enum FullCalendarZoomLevel {
  RESOURCE_TIMELINE_DAY = 'resourceTimelineDay', RESOURCE_TIMELINE_WEEK = 'resourceTimelineWeek', RESOURCE_TIMELINE_MONTH = 'resourceTimelineMonth',
  RESOURCE_TIMELINE_YEAR = 'resourceTimelineYear'
}


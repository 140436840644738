import {ScriptAction} from '../..';
import {DesignerEntity} from './designer.entity';


export enum EmailReceiveType {
  INCOMING = 'incoming', OUTGOING = 'outgoing'
}

export abstract class EmailRule extends DesignerEntity {
  public emailTemplateId?: string;
  public entityId?: any;
  public recipientsScript?: string;
  public type?: EmailReceiveType;
  public replyEmailTemplateId?: string;
  public recipientsScripted?: boolean;
  public accountId?: string;
  public actionScript?: string;
  public recipients?: Array<string>;
  public action?: ScriptAction;
  public sendReply?: boolean;
  public executeCondition?: string;
}

<ui-autocomplete [(ngModel)]="value" [dropdown]="true" [required]="true" [size]="30" [suggestions]="availablePresets" class="rule-operator-container"
                 field="label" name="preset"></ui-autocomplete>

<ng-container *ngIf="value?.name === 'Custom'">
  <ui-input-number [(ngModel)]="value.amount" (onChange)="updateValue()" [locale]="translate.currentLang" label="{{'DateRange.Amount' | translate}}"
                   name="amount"></ui-input-number>
  <ui-autocomplete [(ngModel)]="value.unit" (onChange)="updateValue()" [converter]="dateUnitConverter" [dropdown]="true" [forceSelection]="true" [required]="true" [size]="30"
                   [suggestions]="availableDateUnits" class="rule-operator-container" field="label" name="unit"></ui-autocomplete>
  <p-checkbox [(ngModel)]="value.fromToday" (onChange)="updateValue()" [binary]="true" label="{{'DateRange.FromToday' | translate}}" name="fromToday"></p-checkbox>
</ng-container>

<ng-container *ngTemplateOutlet="hasError ? placeholder : (content ? cardContent : loader)"></ng-container>

<ng-template #cardContent>
  <div [innerHTML]="content | safe: 'html'" class="one-cards__content"></div>
</ng-template>

<ng-template #placeholder>
  <div class="one-cards__content one-cards__content--center">
    <ui-field-translator [data]="record" field="representativeString" typeName="KolibriEntity"></ui-field-translator>
    <span [showDelay]="150" class="fas fa-fw fa-exclamation-triangle one-cards__icon" pTooltip="{{'Cards.GenerationFailed' | translate}}"
          tooltipPosition="top"></span>
  </div>
</ng-template>

<ng-template #loader>
  <div class="one-cards__content one-cards__content--center">
    <span class="fa fa-fw fa-pulse fa-spinner one-cards__icon"></span>
  </div>
</ng-template>
import {ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges, ViewChild} from '@angular/core';
import {Subject, Subscription} from 'rxjs';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';

@Component({
  selector: 'ui-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchInputComponent implements OnDestroy, OnChanges {
  @Output()
  public search: EventEmitter<string> = new EventEmitter<string>();
  @Input()
  public icon: boolean = true;
  @Input()
  public inline: boolean = false;
  @Input()
  public name: string;
  @Input()
  public value: string;
  public modelChanged: Subject<string> = new Subject<string>();
  @Input()
  public isCompact: boolean = false;
  public showInput: boolean = true;
  private subscription: Subscription;
  @ViewChild('inputElement', {static: false})
  private inputElement: ElementRef;

  public constructor() {
    this.subscription = this.modelChanged
      .pipe(
        debounceTime(300),
        distinctUntilChanged())
      .subscribe($event => {
        this.search.emit($event);
      });
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  /**
   * If the resolution changes, the isCompact flag may change so check if it does
   *
   */
  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.isCompact) {
      this.showInput = !this.isCompact;
    }
  }

  public focus(): void {
    this.inputElement.nativeElement.focus();
  }
}

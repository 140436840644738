import {DesignerEntity} from './designer.entity';

export abstract class WizardSection extends DesignerEntity {
  public layoutId: any;

  public condition?: string;
  public onLoadScript?: string;
  public onDisplayScript?: string;
  public onNextScript?: string;
  public onBackScript?: string;
}

<ng-template #queryBuilderContent>
  <div class="one-conditionbuilder__rule-content">
    <ui-field-autocomplete (convertChange)="selectField($event)" (onSelect)="selectField($event)" [(ngModel)]="columnName"
                           [disable]="disable" [allowDotWalk]="queryBuilderDataService.allowDotWalk"
                           [entityName]="queryBuilderDataService.entityMeta.name" [isTransient]="queryBuilderDataService.isTransient"
                           [localized]="queryBuilderDataService.localized" [required]="true" class="one-conditionbuilder__rule-filter"
                           label="{{'QueryBuilder.Sort.Field' | translate}}" name="field"></ui-field-autocomplete>

    <ui-autocomplete (completeMethod)="searchOrder($event)" (onSelect)="this.forceUpdate()" *ngIf="selectedField" [(ngModel)]="value.order"
                     [converter]="operatorConverter" [dropdown]="true" [required]="true" [size]="30" [suggestions]="orders"
                     [disable]="disable" class="one-conditionbuilder__rule-operator" field="label"
                     label="{{'QueryBuilder.Sort.Order.Tooltip' | translate}}" name="order"></ui-autocomplete>
  </div>

  <div class="one-conditionbuilder__actions">
    <ui-button (onDragEnd)="moveOrder()" [spinner]="false" (onDragStart)="moveOrder(value)" class="one-conditionbuilder__drag" dragEffect="move"
               icon="fas fa-fw fa-arrows-alt" [disable]="disable" [draggable]="!disable" pDraggable="querySortElement" type="icon-only"></ui-button>
    <ui-button (clicked)="removeOrder()" [spinner]="false" *ngIf="deletable" [showDelay]="150" [type]="['icon-only', 'red']"
               class="one-conditionbuilder__delete" [disable]="disable" data-delete="rule" icon="fas fa-fw fa-trash"
               label="{{'QueryBuilder.Sort.Remove' | translate}}" tooltipPosition="top"></ui-button>
  </div>
</ng-template>

<ng-template #breadCrumbContent>
  <span class="one-filter-nav__item-text one-filter-nav__item-text--sort">
    {{breadcrumbString}}
  </span>
  <p-menu #menu [model]="breadcrumbMenu" [popup]="true" appendTo="body"></p-menu>
  <ui-button (clicked)="menu.toggle($event.originalEvent); $event.cb();" class="one-filter-nav__item-button" icon="fa fa-fw fa-ellipsis-v"
             type="icon-only" [disable]="disable"></ui-button>
</ng-template>

<ng-container *ngIf="breadcrumb" [ngTemplateOutlet]="breadCrumbContent"></ng-container>
<ng-container *ngIf="!breadcrumb" [ngTemplateOutlet]="queryBuilderContent"></ng-container>

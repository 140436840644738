import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginComponent} from './components/login/login.component';
import {LogoutComponent} from './components/logout/logout.component';
import {MultiAuthComponent} from './components/multi-auth/multi-auth.component';
import {PasswordResetComponent} from './components/password-reset/password-reset.component';
import {RequestPasswordResetComponent} from './components/request-password-reset/request-password-reset.component';
import {SelfRegistrationComponent} from './components/self-registration/self-registration.component';


const routes: Routes = [
  {
    path: 'auth/logout',
    component: LogoutComponent
  },
  {
    path: 'auth/register',
    component: SelfRegistrationComponent
  },
  {
    path: 'auth/passwordReset',
    component: PasswordResetComponent
  },
  {
    path: 'auth/requestPasswordReset',
    component: RequestPasswordResetComponent
  },
  {
    path: 'auth/multiAuth',
    component: MultiAuthComponent
  },
  {
    path: '**',
    component: LoginComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {relativeLinkResolution: 'legacy'})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}

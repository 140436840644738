<p-dataView #dataView (onChangeLayout)="saveTableState($event)" [layout]="currentDataViewType" [value]="values" class="one-dataview">
  <ng-template pTemplate="header">
    <header class="one-section__header">
      <h2 *ngIf="title" class="one-section__title">{{title}}</h2>
      <ng-container *ngIf="header" [ngTemplateOutlet]="header"></ng-container>

      <div *ngIf="values.length" class="one-section__tools">
        <ng-container *ngIf="tools" [ngTemplateOutlet]="tools"></ng-container>
        <ng-container *ngTemplateOutlet="toolbarButton ; context: {table: this}"></ng-container>
        <ui-guided-tour-step reservedStepName="resDataViewGlobalSearchStep">
          <ui-search-input (search)="loadPage({globalFilter: $event, first: 0})" *ngIf="globalSearch" [value]="tableState.filters.global?.value"
                           name="search"></ui-search-input>
        </ui-guided-tour-step>

        <ng-container *ngIf="hasSwitchOption">
          <p-dataViewLayoutOptions></p-dataViewLayoutOptions>
        </ng-container>
      </div>
    </header>
    <div *ngIf="subHeader && values.length" class="one-section__subheader">
      <ng-container [ngTemplateOutlet]="subHeader"></ng-container>
    </div>
  </ng-template>

  <ng-template let-record pTemplate="listItem">
    <div (click)="viewOnClick($event, record)" class="col-12 one-dataview__item one-dataview__item--row">
      <ng-container *ngTemplateOutlet="rowItemContent; context: {$implicit: record}"></ng-container>
    </div>
  </ng-template>

  <ng-template let-record pTemplate="gridItem">
    <div (click)="viewOnClick($event, record)" class="one-dataview__item one-dataview__item--card">
      <ng-container *ngTemplateOutlet="gridItemContent; context: {$implicit: record}"></ng-container>
    </div>
  </ng-template>

  <ng-template pTemplate="empty">
    {{'AutoComplete.NoResults' | translate}}
  </ng-template>

  <ng-template pTemplate="footer">
    <p-paginator (onPageChange)="loadPage($event)" *ngIf="totalRecords > 0" [first]="tableState.first" [pageLinkSize]="7"
                 [rowsPerPageOptions]="rowsPerPageOptions" [rows]="tableState.rows ?? rows" [showCurrentPageReport]="true" [showFirstLastIcon]="true"
                 [showPageLinks]="true" [templateLeft]="paginatorleft" [templateRight]="paginatorright" [totalRecords]="exactTotalRecords ?? totalRecords"
                 currentPageReportTemplate="{{(!exactTotalRecords ? 'List.EstimatedPaginatorText' : 'List.PaginatorText') | translate}}"
                 dropdownAppendTo="body"></p-paginator>
  </ng-template>

  <ng-template #paginatorleft>
    <ui-button (clicked)="count()" [spinner]="false" icon="fa fa-fw fa-calculator" label="{{'List.Count' | translate}}" type="icon-only"></ui-button>
  </ng-template>

  <ng-template #paginatorright>
    <ui-button (clicked)="refresh()" [spinner]="false" icon="fa fa-fw fa-sync" label="{{'List.Refresh' | translate}}" type="icon-only"></ui-button>
  </ng-template>
</p-dataView>

import {Injectable} from '@angular/core';
import {JoyrideService} from 'ngx-joyride';
import {ModelService} from '../../../api';
import {UiUtil} from '../util/ui-util';

@Injectable()
/** Service Class for the GuidedTour functions*/
export class GuidedTourService {
  private waitingTime = 1;
  private themeColor: string = UiUtil.getVariable('lightbox').trim().substr(0, 7);

  public constructor(private readonly joyrideService: JoyrideService, private modelService: ModelService) {
  }

  /**
   * Starts a guidedTour
   * @param {string} tourName - The Name of the Tour that is supposed to start
   * */
  public startTour(tourName: string): void {
    const stepData = this.modelService.getGuidedTour(tourName).mapping.map(e => this.modelService.getGuidedTourStep(e.guidedTourStepId));
    const stepsArray = [];
    // searches all steps of the tour with the given tourName and create a proper redirect string if needed
    for (const st of stepData) {
      if (st.redirectPath) {
        stepsArray.push(st.name + '@' + st.redirectPath);
        // this might not be enough but let's see
        this.waitingTime = 400;
      } else {
        stepsArray.push(st.name);
      }
    }
    this.joyrideService.startTour(
      {
        steps: stepsArray,
        themeColor: this.themeColor,
        // needed when navigating to anotherPage because of Loading times, defaults to = 1
        waitingTime: this.waitingTime
      }
    );
  }

  /**
   * Starts a Tour with one Step only
   * */
  public startTourSingleStep(name: string): void {
    this.joyrideService.startTour(
      {
        steps: [name],
        themeColor: this.themeColor
      }
    );
  }
}


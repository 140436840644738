import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {KolibriEntity} from '@wspsoft/frontend-backend-common';

@Component({
  selector: 'ui-cards-content',
  templateUrl: './cards-content.component.html',
  styleUrls: ['./cards-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardsContentComponent {
  @Input()
  public hasError: boolean;
  @Input()
  public content: string;
  @Input()
  public record: KolibriEntity;
}

import {DesignerEntity} from './designer.entity';

export abstract class VirtualCollectionLink extends DesignerEntity {
  public parent?: VirtualCollection;
  public parentId?: string;
  public child?: VirtualCollection;
  public childId?: string;
}

export abstract class VirtualCollection extends DesignerEntity {
  public search?: string;
  public get?: string;
  public remove?: string;
  public save?: string;
  public childVirtualCollections?: VirtualCollectionLink[];
}

import {DesignerEntity} from './designer.entity';
import {ListLayout} from './list-layout';

export class LayoutKanban extends ListLayout {
  public cardContent: any;
  public orderFieldId?: string;
  public kanbanToColumns: LayoutKanbanToKanbanColumn[];
}

export class KanbanColumn extends DesignerEntity {
  public entityId: string;
  public dropCondition: string;
  public filterScript: string;
  public newButton: string;
  public rendered: string;
  public onDrop: any;
}

export class LayoutKanbanToKanbanColumn extends DesignerEntity {
  public kanbanColumnId: string;
  public layoutKanbanId: string;
  public kanbanColumn: KanbanColumn;
  public layoutKanban: LayoutKanban;
}

import * as uuidv4 from 'uuid-random';
import {AbstractKolibriRecordFactory} from '../../api/abstract-kolibri-record-factory';

import {AbstractCriteriaFactory} from '../../criteria/abstract-criteria-factory';

import {CriteriaOperator} from '../../criteria/criteria-operator';
import {CriteriaType} from '../../criteria/criteria-type';


import {KolibriEntity} from '../../model/database/kolibri-entity';

import {Entity} from '../../model/xml/entity';

import {Relation} from '../../model/xml/relation';
import {_, MaybePromise} from '../../util/underscore';

import {Utility} from '../../util/utility';

import {AbstractModelService} from '../coded/abstract-model.service';

import {EntityModel} from '../entities/entity-model';

import {AbstractEntityService} from '../generated/abstract-entity-service';

export interface MappingFieldValueOption {
  fieldName: string;
  valueFn: (sourceEntity: KolibriEntity, destinationEntity: KolibriEntity) => MaybePromise<any>;
}

export interface MappingOptions {
  doSave?: boolean;
  formId?: string;
  fieldValueOptions?: MappingFieldValueOption[];
}

export type RelationInfo = {
  parentRelation: Relation;
  sourceRelation: Relation;
  destinationRelation: Relation;
};

export abstract class AbstractRelationService {

  protected constructor(protected modelService: AbstractModelService, protected criteriaFactory: AbstractCriteriaFactory,
                        protected kolibriRecordFactory: AbstractKolibriRecordFactory) {
  }

  private static getDifferences(outcomeEntities: KolibriEntity[], existingEntities: KolibriEntity[]): { toRemove: KolibriEntity[]; toAdd: KolibriEntity[] } {
    return {
      toRemove: _.differenceBy(existingEntities, outcomeEntities, 'id'),
      toAdd: _.differenceBy(outcomeEntities, existingEntities, 'id'),
    };
  }

  /**
   * deletes all the mappings with the source and destination entity
   * @param sourceRecord the source entity
   * @param sourceRelation the name of the source relation of the mapping entity
   * @param mappingEntityName name of the mapping entity
   */
  public async removeAllMappingEntities(sourceRecord: KolibriEntity, sourceRelation: Relation, mappingEntityName: string): Promise<void> {

    const query = this.criteriaFactory.get(mappingEntityName);
    query.addCondition(sourceRelation.name, CriteriaOperator.IS, sourceRecord);
    const allMappings = await query.getResults();

    if (allMappings.length) {
      await this.kolibriRecordFactory.massDelete(mappingEntityName, allMappings);
    }
  }

  /**
   * deletes the mappings with the source and destination entity
   * @param sourceRecord the source entity
   * @param destinationEntities destination entities to remove
   * @param destinationRelationName the name of the destination relation of the mapping entity
   * @param sourceRelationName the name of the source relation of the mapping entity
   * @param mappingEntityName name of the mapping entity
   */
  public async removeMappingEntities(sourceRecord: KolibriEntity, destinationEntities: KolibriEntity[], sourceRelationName: string,
                                     destinationRelationName: string, mappingEntityName: string): Promise<void> {
    if (destinationEntities.length) {
      const mappingsQuery = this.criteriaFactory.get(mappingEntityName, CriteriaType.DELETE);
      mappingsQuery.addCondition(sourceRelationName, CriteriaOperator.IS, sourceRecord);
      mappingsQuery.addCondition(destinationRelationName, CriteriaOperator.IN, destinationEntities.map(value => value.id));
      await mappingsQuery.execute();
    }
  }

  /**
   * creates an array of the mapping entity.
   * if options.doSave is true all mappings will be saved with batchSave.
   * options can have additional fields to set in mapping entity.
   * @param sourceRecord the actual KolibriEntity that should get the destinationEntities
   * @param destinationEntities entities that should be added to the sourceRecord
   * @param destinationRelationName name of the destination relation of the mapping entity
   * @param sourceRelationName the name of the source relation of the mapping entity
   * @param mappingEntityService the service of the mapping entity
   * @param options the options for additional fieldSettings
   * @return created mapping entity
   */
  public async setMappingEntities<T extends KolibriEntity>(sourceRecord: KolibriEntity, destinationEntities: KolibriEntity[],
                                                           sourceRelationName: string, destinationRelationName: string,
                                                           mappingEntityService: AbstractEntityService<T>,
                                                           options: MappingOptions = {}): Promise<T[]> {

    const mappingsQuery = this.criteriaFactory.get(mappingEntityService.entityMeta.name);
    mappingsQuery.addCondition(sourceRelationName, CriteriaOperator.IS, sourceRecord);
    const alreadyThere = await mappingsQuery.getResults();

    // everything looks for ID and not more
    const {toRemove, toAdd} = AbstractRelationService.getDifferences(destinationEntities,
      alreadyThere.map(mapping => ({id: mapping[Utility.parameterizeEntityName(destinationRelationName)]})));

    if (toRemove.length) {
      await this.removeMappingEntities(sourceRecord, toRemove, sourceRelationName, destinationRelationName, mappingEntityService.entityMeta.name);
    }
    if (toAdd.length) {
      return this.createMappingEntities(sourceRecord, toAdd, sourceRelationName, destinationRelationName, mappingEntityService, options);
    }
  }

  /**
   * creates an array of the mapping entity.
   * if options.doSave is true all mappings will be saved with batchSave.
   * options can have additional fields to set in mapping entity.
   * @param sourceEntity the actual KolibriEntity that should get the destinationEntities
   * @param destinationEntities entities that should be added to the sourceEntity
   * @param destinationRelationName the name of the destination relation of the mapping entity
   * @param sourceRelationName the name of the source relation of the mapping entity
   * @param mappingEntityService the service of the mapping entity
   * @param options the options for additional fieldSettings
   * @return created mapping entity
   */
  public async createMappingEntities<T extends KolibriEntity>(sourceEntity: KolibriEntity, destinationEntities: KolibriEntity[],
                                                              sourceRelationName: string,
                                                              destinationRelationName: string, mappingEntityService: AbstractEntityService<T>,
                                                              options: MappingOptions = {}): Promise<T[]> {
    if (!destinationEntities.length) {
      return [];
    }
    const alreadyThere = await this.criteriaFactory.get(mappingEntityService.entityMeta.name)
      .addCondition(sourceRelationName, CriteriaOperator.IS, sourceEntity)
      .addCondition(destinationRelationName, CriteriaOperator.IN, destinationEntities.map(e => e.id))
      .getResults();
    if (alreadyThere.length) {
      _.remove(destinationEntities, e =>
        alreadyThere.map(mapping => mapping[Utility.parameterizeEntityName(destinationRelationName)]).indexOf(e.id) !== -1);
    }
    const newMapping = await mappingEntityService.getNewEntity(options.formId);
    const mappings = [];
    for (const destinationEntity of destinationEntities) {
      const mapping = newMapping.copy();
      mapping[destinationRelationName] = destinationEntity;
      mapping[sourceRelationName] = sourceEntity;
      for (const option of options.fieldValueOptions || []) {
        let value = option.valueFn(sourceEntity, destinationEntity);
        if (_.isPromise(value)) {
          value = await value;
        }
        mapping[option.fieldName] = value;
      }
      mapping.id = uuidv4();
      mappings.push(mapping);
    }
    if (options.doSave) {
      await this.doBatchSave(mappings, mappingEntityService);
    }
    return mappings;
  }

  public async createOrGetMappingEntities<T extends KolibriEntity>(sourceEntity: KolibriEntity, destinationEntities: KolibriEntity[],
                                                                   sourceRelationName: string, destinationRelationName: string,
                                                                   mappingEntityService: AbstractEntityService<T>): Promise<T[]> {
    if (!destinationEntities.length) {
      return [];
    }
    const mappings = [];
    const alreadyThere = await this.criteriaFactory.get(mappingEntityService.entityMeta.name)
      .addCondition(sourceRelationName, CriteriaOperator.IS, sourceEntity)
      .addCondition(destinationRelationName, CriteriaOperator.IN, destinationEntities.map(e => e.id))
      .getResults();
    if (alreadyThere.length) {
      _.remove(destinationEntities, e =>
        alreadyThere.map(mapping => mapping[Utility.parameterizeEntityName(destinationRelationName)]).indexOf(e.id) !== -1);
      mappings.push(...alreadyThere);
    }
    const newMapping = await mappingEntityService.getNewEntity();
    for (const destinationEntity of destinationEntities) {
      const mapping = newMapping.copy();
      mapping[destinationRelationName] = destinationEntity;
      mapping[sourceRelationName] = sourceEntity;
      mapping.id = uuidv4();
      mappings.push(mapping);
    }
    return mappings;
  }

  /**
   * returns the RelationInfo of the given relation
   * @param fieldIdOrName the id or name of the relation field
   * @param sourceEntityMeta the parentEntity meta
   * @param entityMeta the possible mapping entity
   * @return the calculated relations
   */
  public findInverseRelations(fieldIdOrName: string, sourceEntityMeta: EntityModel, entityMeta: Entity): RelationInfo {
    const parentRelation: Relation = this.modelService.getRelation(sourceEntityMeta.name, fieldIdOrName);

    const destinationRelation = this.modelService.getRelation(entityMeta.id, entityMeta.destinationRelationId);
    const sourceRelation = this.modelService.getRelation(entityMeta.id, entityMeta.sourceRelationId);
    const isInverse = entityMeta.mappingEntity && parentRelation.targetRelationName === destinationRelation.name;

    return {
      parentRelation,
      sourceRelation: isInverse ? destinationRelation : sourceRelation,
      destinationRelation: isInverse ? sourceRelation : destinationRelation
    };
  }

  protected abstract doBatchSave<E extends KolibriEntity>(entities: E[], entityService: AbstractEntityService<E>): Promise<E[]>;
}

<ng-template #queryBuilderContent>
  <dd class="one-conditionbuilder__group-content">
    <ul (onDrop)="moveElement($event, 0)" class="one-conditionbuilder__rules-list" dropEffect="relocate" pDroppable="querySortElement">
      <ng-container *ngFor="let aOrder of value.orders; index as i; first as first;">
        <li (onDrop)="moveElement($event, i)" (onUpdate)="forceUpdate()" [(valueBinding)]="value.orders[i]" [deletable]="!first" [group]="this"
            class="one-conditionbuilder__rule one-conditionbuilder__rule--small Rule{{i}}" dropEffect="relocate" pDroppable="querySortElement"
            [disable]="disable" ui-query-builder-sort-rule></li>
      </ng-container>

      <li class="one-conditionbuilder__actions">
        <ui-button (clicked)="addRule()" [spinner]="false" class="one-conditionbuilder__action one-button" data-add="rule" [disable]="disable"
                   icon="fas fa-fw fa-plus" label="{{'QueryBuilder.Group.AddOrder' | translate}}" type="layout"></ui-button>
      </li>
    </ul>
  </dd>
</ng-template>
<ng-template #breadCrumbContent>
  <ng-container *ngFor="let condition of getNonEmptyOrders(); index as i; first as first">
    <div (onExecute)="onExecute.emit()" (onUpdate)="forceUpdate()" [(valueBinding)]="nonEmptyOrders[i]" [breadcrumb]="true" [group]="this"
         class="one-filter-nav__item Rule{{i}}" ui-query-builder-sort-rule></div>
  </ng-container>
</ng-template>

<ng-container *ngIf="breadcrumb" [ngTemplateOutlet]="breadCrumbContent"></ng-container>
<ng-container *ngIf="!breadcrumb" [ngTemplateOutlet]="queryBuilderContent"></ng-container>



<div class="one-background"></div>

<login-authentication-page [showLogo]="false">
  <ng-template #header>
    <div class="logout_icons">
      <i class="one-spinner fas fa-fw fa-spinner"></i>
      <i class="one-confirm far fa-fw fa-check-circle"></i>
    </div>
  </ng-template>
  <ng-template #content>
    <div class="logout_done">
      <h2>{{'Logout.SuccessfulLogout' | translate}}</h2>
      <ui-button (clicked)="nav(); $event.cb()" class="logout_button" icon="fas fa-fw fa-sign-out-alt" label="{{'Logout.SignIn' | translate}}"
                 type="primary"></ui-button>
    </div>
  </ng-template>
</login-authentication-page>

<div class="one-gradient"></div>

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Injector,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, NgControl, ValidationErrors} from '@angular/forms';
import {_, ConditionBuilderOptions, Entity, Field, MaybePromise, Utility} from '@wspsoft/frontend-backend-common';
import {ModelService, ModelTranslationService} from '../../../../../../api';

import {FieldConverterService} from '../../../converter/field-converter.service';
import {CustomInput} from '../../custom-input';

@Component({
  selector: 'ui-field-autocomplete',
  templateUrl: './field-autocomplete-input.component.html',
  styleUrls: ['../autocomplete.scss', './field-autocomplete-input.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => FieldAutocompleteInputComponent),
    multi: true
  }, {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => FieldAutocompleteInputComponent),
    multi: true,
  }, {
    provide: CustomInput,
    useExisting: forwardRef(() => FieldAutocompleteInputComponent),
    multi: true
  }, FieldConverterService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FieldAutocompleteInputComponent extends CustomInput<Field | Field[]> implements OnInit, OnChanges {
  @Input()
  public isTransient: (field: Field, dotWalk: boolean) => boolean;
  @Input()
  public entityName: string;
  @Input()
  public localized: boolean = true;
  @Input()
  public allowDotWalk: boolean = true;
  @Input()
  public additionalFields: Field[] = [];
  @Input()
  public search: (result: Field[]) => MaybePromise<Field[]>;
  @Input()
  public conditionBuilderOptions: ConditionBuilderOptions[];
  @Output()
  public onSelect: EventEmitter<Field> = new EventEmitter();
  public model: NgControl = {} as any;
  public overlayVisible: boolean;
  public entityMeta: Entity;

  public constructor(private modelTranslationService: ModelTranslationService, private modelService: ModelService,
                     cdr: ChangeDetectorRef, private injector: Injector,
                     private fieldConverter: FieldConverterService) {
    super(cdr);
    this.fieldConverter.entityMetaLoader = () => this.loadMetaData();
    this.converter = this.fieldConverter;
  }

  public get filled(): boolean {
    return this.multiple ? !!(this.value as Field[])?.length : !!this.value;
  }

  /**
   * split value label into two pieces
   *
   * the first part is allowed to be shortened (...)
   * the second part is always visible
   */
  public getValueLabel(field: Field): string[] {
    if (!field) {
      return [];
    }

    const label = field.label;
    const labels = label.split('.');
    const singleField = labels.length === 1;
    const finalLabels = [labels.slice(0, labels.length - 1).join('.'), labels[labels.length - 1]].filter(x => !!x);

    // if the single field has an icon add it as i tag
    if (singleField && field.icon) {
      // add to first label
      finalLabels[0] = `<i class="${field.icon}"></i> ${finalLabels[0]}`;
    }

    return finalLabels;
  }

  public ngOnInit(): void {
    this.model = this.injector.get(NgControl, {});
    this.fieldConverter.additionalFields = this.additionalFields;
    this.fieldConverter.localized = this.localized;
    this.entityMeta = this.loadMetaData();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.entityName) {
      this.entityMeta = this.loadMetaData();
    }
  }

  public toggleOverlay(): void {
    if (this.disable) {
      return;
    }

    this.overlayVisible = !this.overlayVisible;
    setTimeout(() => {
      this.cdr.detectChanges();
    }, 100);
  }

  public validate(control?: FormControl): ValidationErrors {
    if (!this.value && this.required) {
      return {required: true};
    }
    return null;
  }


  public markAsUntouched(): void {
    this.model.control.markAsUntouched();
    this.model.control.markAsPristine();
  }

  public markAsDirty(): void {
    this.model.control.markAsDirty();
  }

  public clear(): void {
    this.writeValue(undefined);
    this.onSelect.emit();
  }

  public addValue($event: string): void {
    const field = this.converter.getAsObject($event);
    field.path = Utility.getDotWalkPath($event).join('.');
    if (this.multiple) {
      this.value ??= [];
      this.value = [...this.value as Field[], field];
    } else {
      this.value = field;
    }
  }

  public getPreSelectedValue(): string {
    if (!this.multiple) {
      return this.rawValue as string;
    }
  }

  public removeValue(field: Field): void {
    _.remove(this.value as Field[], {path: field.path, name: field.name});
    this.value = [...this.value as Field[]];
  }

  private loadMetaData(): Entity {
    // convert name in case it was provided as my type
    return this.modelService.getEntity(this.entityName);
  }
}

import {CriteriaOperator, Field, Utility} from '@wspsoft/frontend-backend-common';

export class QueryOperator {

  public constructor(public operator: CriteriaOperator, public label: string, public icon: string, private value?: string,
                     public isAllowed: (field: Field, column: string) => boolean = () => true,
                     public isDefaultValue?: (selectedField: Field) => boolean,
                     public isTypedOperator: boolean = true,
                     public withOutValue: boolean = false,
                     public fieldOverride?: Field) {

    this.isDefaultValue ??= this.defaultValueFunction;
  }

  public get isStringType(): boolean {
    return !this.isIdentityOperator() && !this.isCompareOperator() && !this.isTypedOperator;
  }

  public getValue(): string {
    return this.value || CriteriaOperator[this.operator];
  }

  public isEntityIdentityOperator(): boolean {
    return this.operator === CriteriaOperator.IS || this.operator === CriteriaOperator.IS_NOT;
  }

  public isNullOperator(): boolean {
    return this.operator === CriteriaOperator.IS_NULL || this.operator === CriteriaOperator.IS_NOT_NULL;
  }

  public isEqualityOperator(): boolean {
    return this.operator === CriteriaOperator.EQUAL || this.operator === CriteriaOperator.NOT_EQUAL;
  }

  public isInOperator(): boolean {
    return this.operator === CriteriaOperator.IN || this.operator === CriteriaOperator.NOT_IN || this.operator === CriteriaOperator.ALL_IN;
  }

  public isListOperator(): boolean {
    return this.operator === CriteriaOperator.IS_EMPTY || this.operator === CriteriaOperator.IS_NOT_EMPTY || this.operator === CriteriaOperator.MEMBER_OF ||
      this.operator === CriteriaOperator.ALL_IN || this.operator === CriteriaOperator.NOT_MEMBER_OF;
  }

  public isSubstringOperator(): boolean {
    return this.operator === CriteriaOperator.CONTAINS || this.operator === CriteriaOperator.NOT_CONTAINS || this.operator === CriteriaOperator.BEGINS_WITH ||
      this.operator === CriteriaOperator.NOT_BEGINS_WITH || this.operator === CriteriaOperator.ENDS_WITH || this.operator === CriteriaOperator.NOT_ENDS_WITH;
  }

  public isDateOperator(): boolean {
    return Utility.isDateOperator(this.operator);
  }

  public isCompareOperator(): boolean {
    return this.operator === CriteriaOperator.GREATER || this.operator === CriteriaOperator.GREATER_OR_EQUAL ||
      this.operator === CriteriaOperator.LESS || this.operator === CriteriaOperator.LESS_OR_EQUAL;
  }

  public isIdentityOperator(): boolean {
    return this.isEqualityOperator()
      || this.isEntityIdentityOperator();
  }

  public isValueRequired(): boolean {
    return this.operator !== CriteriaOperator.IS_EMPTY && this.operator !== CriteriaOperator.IS_NOT_EMPTY &&
      this.operator !== CriteriaOperator.IS_NULL && this.operator !== CriteriaOperator.IS_NOT_NULL && !this.withOutValue;
  }

  public comparableTypes(): string[] {
    return ['Duration', 'Date', 'Number'];
  }

  private defaultValueFunction(field: Field): boolean {
    if (Utility.isRelation(field)) {
      if (Utility.isToManyField(field)) {
        return this.operator === CriteriaOperator.IN;
      } else {
        return this.operator === CriteriaOperator.IS;
      }
    } else {
      return this.operator === CriteriaOperator.EQUAL;
    }
  }
}

import {KolibriEntity} from '../kolibri-entity';
import {Variable} from './variable';
import {VariableAppliesTo} from './variable-applies-to';
import {VariableScriptType} from './variable-script-type';
import {VariableSet} from './variable-set';

export abstract class VariableScript extends KolibriEntity {
  public appliesTo?: VariableAppliesTo;
  public name?: string;
  public order?: number;
  public script?: string;
  public type?: VariableScriptType;
  public entityId?: string;
  public variableId?: string;
  public variable?: Variable;
  public variableSetId?: string;
  public variableSet?: VariableSet;
}

import {Injectable} from '@angular/core';
import {CriteriaOperator, KolibriEntity} from '@wspsoft/frontend-backend-common';
import {CriteriaFactory, EntityService, EntityServiceFactory} from '../../../../api';
import {Converter} from './converter';

@Injectable()
export class KolibriEntityConverterService implements Converter<KolibriEntity | KolibriEntity[]> {
  public convertToString: boolean = false;
  private service: EntityService<KolibriEntity>;
  private entity: string;

  public constructor(private entityServiceFactory: EntityServiceFactory, private criteriaFactory: CriteriaFactory) {
  }

  public set entityNameOrId(nameOrId: string) {
    this.entity = nameOrId;
    this.service = this.entityServiceFactory.getService(nameOrId);
  }

  /**
   * converts the string/string[] value to a KolibriEntity/KolibriEntity[]
   * @param stringValue converted string/string[] of the KolibriEntity/KolibriEntity[]
   */
  public getAsObject(stringValue: string): Promise<KolibriEntity>;
  public getAsObject(stringValue: string[]): Promise<KolibriEntity[]>;
  public getAsObject(stringValue: string | string[]): Promise<KolibriEntity | KolibriEntity[]> {
    if (typeof stringValue === 'string') {
      return this.service.getEntityById(stringValue);
    }
    if (Array.isArray(stringValue) && stringValue.length > 0 && typeof stringValue[0] === 'string') {
      return this.criteriaFactory.getFrontendQuery(this.entity)
        .addCondition('id', CriteriaOperator.IN, stringValue)
        .getResults();
    }
    return stringValue as any;
  }

  /**
   * converts the KolibriEntity/KolibriEntity[] to a string/string[]
   * @param obj the KolibriEntity/KolibriEntity[] to convert to string/string[]
   */
  public getAsString(obj: KolibriEntity): string;
  public getAsString(obj: KolibriEntity[]): string[];
  public getAsString(obj: KolibriEntity | KolibriEntity[]): string | string[] {
    if (this.convertToString && obj) {
      if (Array.isArray(obj)) {
        if (!obj.length) {
          return obj as any as string[];
        }
        return obj.map(y => y.id);
      } else {
        return obj.id;
      }
    }
    return obj as any;
  }
}

import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {_, CriteriaOrder, CriteriaOrderBy, Field, Utility} from '@wspsoft/frontend-backend-common';
import {MenuItem} from 'primeng/api';
import {EntityServiceFactory, ModelService, ModelTranslationService} from '../../../../../../api';
import {QueryOrder} from '../../../../entities/query-order';
import {QueryBuilderService} from '../../../../service/query-builder.service';
import {Converter} from '../../../converter/converter';
import {CustomWriter} from '../../custom-writer';
import {QueryBuilderSortGroupComponent} from '../query-builder-sort-group/query-builder-sort-group.component';
import {QueryBuilderDataService} from '../query-builder/query-builder-data.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[ui-query-builder-sort-rule]',
  templateUrl: './query-builder-sort-rule.component.html',
  styleUrls: ['./query-builder-sort-rule.component.scss']
})
export class QueryBuilderSortRuleComponent extends CustomWriter<CriteriaOrderBy> implements OnInit {

  @Input()
  public breadcrumb: boolean;
  @Input()
  public deletable: boolean;
  @Input()
  public group: QueryBuilderSortGroupComponent;
  @Input()
  public disable: boolean;
  @Output()
  public onExecute: EventEmitter<void> = new EventEmitter<void>();
  public availableOrders: QueryOrder[] = [];
  public orders: QueryOrder[];
  public breadcrumbMenu: MenuItem[];
  public selectedField: Field;

  public constructor(private translateService: TranslateService, private queryBuilderService: QueryBuilderService,
                     private entityServiceFactory: EntityServiceFactory, public queryBuilderDataService: QueryBuilderDataService,
                     private modelTranslationService: ModelTranslationService, private modelService: ModelService,
                     cdr: ChangeDetectorRef) {
    super(cdr);
  }

  public get id(): string {
    return `${this.value.columnName.replace(/./g, '_')}_rule`;
  }

  public get operatorConverter(): Converter<QueryOrder> {
    return {
      getAsString: (value: QueryOrder) => {
        if (value) {
          return value.getValue();
        }
        return null;
      },
      getAsObject: (s: string) => {
        const value = CriteriaOrder[s];
        const translation = this.modelTranslationService.translateOrder(value);
        return new QueryOrder(value, translation);
      }
    };
  }

  /**
   * Translates the rule build by the query builder to the selected language to be shown in the breadcrumb
   * @returns translated breadcrumb string
   */
  public get breadcrumbString(): string {
    return `${this.modelTranslationService.translateDotWalkField(this.queryBuilderDataService.entityMeta,
      this.value.columnName)}: ${this.modelTranslationService.translateOrder(this.value.order)}`;
  }

  public get columnName(): string {
    return this.value?.columnName ?? null;
  }

  public set columnName(s: string) {
    this.value.columnName = s;
  }

  public ngOnInit(): void {
    this.getAllTranslatedOrders();

    this.breadcrumbMenu = [
      {
        label: this.translateService.instant('QueryBuilder.Rule.Remove'),
        icon: 'fas fa-fw fa-trash-alt',
        styleClass: 'p-menuitem--negative',
        command: () => {
          this.removeOrder();
          this.onExecute.emit();
        }
      }];
  }

  public selectField($event: Field): void {
    this.selectedField = $event;
    if (this.selectedField) {
      this.value.order ??= CriteriaOrder.ASC;
    }
    this.forceUpdate();
  }

  public removeOrder(): void {
    _.remove(this.group.value.orders, rule1 => CriteriaOrderBy.isEqual(rule1, this.value));
    this.forceUpdate();
  }

  public moveOrder(value?: CriteriaOrderBy): void {
    this.queryBuilderService.draggedQueryElement = value;
    this.queryBuilderService.onDrop = () => {
      this.removeOrder();
    };
  }

  public searchOrder($event: any): void {
    this.orders = _.filter(this.availableOrders, order => Utility.matches(order.label, $event.query));

    if ($event.originalEvent.cb) {
      $event.originalEvent.cb();
    }
    this.forceUpdate();
  }

  private getAllTranslatedOrders(): void {
    this.availableOrders.push(new QueryOrder(CriteriaOrder.ASC, this.modelTranslationService.translateOrder(CriteriaOrder.ASC)));
    this.availableOrders.push(new QueryOrder(CriteriaOrder.DESC, this.modelTranslationService.translateOrder(CriteriaOrder.DESC)));
  }
}

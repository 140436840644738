import {Pipe, PipeTransform} from '@angular/core';
import {MomentUtil} from '@wspsoft/frontend-backend-common';
import * as moment from 'moment';

@Pipe({
  name: 'moment'
})
export class MomentPipe implements PipeTransform {

  public transform(value: any, prettyTime: boolean): any {
    if (!prettyTime) {
      return MomentUtil.datetimeToString(value);
    }
    return moment(value).fromNow();
  }

}

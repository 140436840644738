import {ChangeDetectionStrategy, Component, ContentChild, Input, TemplateRef} from '@angular/core';

@Component({
  selector: 'ui-wizard-page',
  templateUrl: './wizard-page.component.html',
  styleUrls: ['./wizard-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WizardPageComponent {
  @Input()
  public name: string;

  @ContentChild(TemplateRef, {static: true})
  public content: TemplateRef<any>;
  public index: number;

  public constructor() {
  }

}

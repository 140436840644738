import {Entity} from '../model/xml/entity';

import {Script, ScriptAction, ScriptTrigger} from '../model/xml/script';

import {AbstractModelService} from '../service/coded/abstract-model.service';
import {_} from '../util/underscore';
import {AbstractKolibriScriptExecutor} from './abstract-kolibri-script-executor';
import {KolibriScriptError, ScriptEventCallback, ScriptParams, ScriptResult} from './script-objects';

export abstract class AbstractKolibriScriptEventHandler {
  private eventCache: { [key: string]: Script[] } = {};
  private events: { [event: string]: { [entity: string]: { [field: string]: ScriptEventCallback<any>[] } } } = {};

  protected constructor(protected modelService: AbstractModelService, protected scriptExecutor: AbstractKolibriScriptExecutor) {
  }

  private static getEvents(script: Script): string[] {
    const events: string[] = [];
    for (const action of script.actions) {
      events.push(action + script.trigger);
    }
    return events;
  }

  private static getSingleEvalScript(script: Script, event: string): string {
    if (!script.conditionScript) {
      return script.payload;
    }
    return `
      if (await (context.runScript(\`${script.conditionScript}\`, {...kc}, undefined, 'Event:${script.name}:${event}:condition', true).result)) {
        ${script.payload}
      }`;
  }

  public onEntityEvents(entityMeta: Entity, layoutId?: string, formId?: string): void {
    // load from backend and create event definitions
    const scripts: Script[] = this.modelService.getScriptTriggers(entityMeta.id);
    this.eventCache[entityMeta.id] = scripts;
    for (const script of scripts.filter(s => !s.layoutId || s.layoutId === layoutId)) {
      if (script.fields?.length) {
        for (const field of script.fields) {
          this.addEntityEvent(formId, script, entityMeta.id, field);
        }
      } else {
        this.addEntityEvent(formId, script, entityMeta.id);
      }
    }
  }

  /**
   * do not forget to add the user parameter in parameter
   */
  public async fire<T>(scriptAction: ScriptAction, trigger: ScriptTrigger, params: ScriptParams, entityMeta: Entity | string, field: string = 'none',
                       viewId?: string): Promise<T> {
    if (typeof entityMeta === 'string') {
      entityMeta = this.modelService.getEntity(entityMeta);
    }

    const fullEventName = scriptAction + trigger;
    if (fullEventName in this.events && entityMeta.id in this.events[fullEventName] && field in this.events[fullEventName][entityMeta.id]) {
      const eventElement = this.events[fullEventName][entityMeta.id][field];
      if (viewId) {
        params.viewId = viewId;
      }

      if (eventElement) {
        for (const event of eventElement) {
          try {
            const result = await event.cb(params)?.result;

            // if the script returned a result, stop here and return the result
            if (!_.isNull(result)) {
              return result;
            }
          } catch (e: any) {
            if (e.type === KolibriScriptError.type) {
              throw e;
            }
          }
        }
      }
    }

    if (field !== 'none') {
      return this.fire(scriptAction, trigger, params, entityMeta, 'none', viewId);
    }
  }

  public reset(): void {
    this.eventCache = {};
    this.events = {};
  }

  private addEntityEvent(formId: string, script: Script, targetId: string, field: string = 'none'): void {
    const events = AbstractKolibriScriptEventHandler.getEvents(script);

    for (const event of events) {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const self = this;
      this.on(event, {
        cb(params: ScriptParams): ScriptResult<any> {
          return self.scriptExecutor.runScript(this.script, params, params.viewId || formId, `Event:${script.name}:${event}:payload`, true);
        },
        field,
        script: AbstractKolibriScriptEventHandler.getSingleEvalScript(script, event),
        targetId
      });
    }
  }

  private on(event: string, callback: ScriptEventCallback<any>): void {
    if (!(event in this.events)) {
      this.events[event] = {};
    }
    if (!(callback.targetId in this.events[event])) {
      this.events[event][callback.targetId] = {};
    }
    this.events[event][callback.targetId][callback.field] ??= [];
    const eventElementElement = this.events[event][callback.targetId][callback.field];
    eventElementElement.push(callback);
  }
}

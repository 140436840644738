import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import {NG_VALIDATORS, NG_VALUE_ACCESSOR} from '@angular/forms';
import {AspectRatio} from '@wspsoft/frontend-backend-common';
import {ImageCroppedEvent, ImageTransform} from 'ngx-image-cropper';
import {CustomInput} from '../custom-input';

@Component({
  selector: 'ui-image-cropper',
  templateUrl: './image-cropper.component.html',
  styleUrls: ['./image-cropper.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ImageCropperComponent),
    multi: true
  }, {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => ImageCropperComponent),
    multi: true,
  }, {
    provide: CustomInput,
    useExisting: forwardRef(() => ImageCropperComponent),
    multi: true
  }],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageCropperComponent extends CustomInput<File> implements OnInit {
  @Input()
  public image: File;
  @Output()
  public onCrop: EventEmitter<any> = new EventEmitter();
  @Input()
  public enableZoom: boolean = true;
  @Input()
  public cropperType: AspectRatio = AspectRatio.circle;
  public aspectRatio: number = 1;
  public canvasRotation: number = 0;
  public roundCropper: boolean = true;
  public maintainAspectRatio: boolean = true;
  public transform: ImageTransform;
  private scale: number;

  public constructor(cdr: ChangeDetectorRef) {
    super(cdr);
  }

  public ngOnInit(): void {
    this.scale = 1;
    this.transform = {};
    switch (this.cropperType) {
      case AspectRatio.square:
        this.aspectRatio = 1;
        this.maintainAspectRatio = true;
        this.roundCropper = false;
        break;
      case AspectRatio.shitInShitOut:
        this.maintainAspectRatio = false;
        this.roundCropper = false;
        break;
      case AspectRatio.circle:
      default:
        this.aspectRatio = 1;
        this.roundCropper = true;
        this.maintainAspectRatio = true;
        break;
    }
    this.value = this.image;
  }

  /**
   * transforms the image and zoom out
   */
  public zoomOut(): void {
    this.scale -= .1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }

  /**
   * transforms the image and zoom in
   */
  public zoomIn(): void {
    this.scale += .1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }

  public async imageCropped(image: ImageCroppedEvent): Promise<void> {
    const res = await fetch(image.base64);
    const blob = await res.blob();
    this.value = new File([blob], this.image.name, {type: this.image.type});
  }

  public rotateLeft(): void {
    this.canvasRotation--;
    this.flipAfterRotate();
  }

  public rotateRight(): void {
    this.canvasRotation++;
    this.flipAfterRotate();
  }

  private flipAfterRotate(): void {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH
    };
  }
}

import {FieldTypes} from '../../xml/section-types';
import {KolibriEntity} from '../kolibri-entity';
import {VariableAppliesTo} from './variable-applies-to';
import {VariableRule} from './variable-rule';
import {VariableScript} from './variable-script';
import {VariableSet} from './variable-set';

export abstract class Variable extends KolibriEntity {
  public appliesTo?: VariableAppliesTo;
  public decimalPlaces?: number;
  public defaultValue?: string;
  public name?: string;
  public order?: number;
  public questionText?: string;
  public uiType?: FieldTypes;
  public entityId?: string;
  public showTime?: boolean;
  public target?: string;
  public targetForChoice?: string;
  public variableRules?: VariableRule[];
  public variableScripts?: VariableScript[];
  public variableSetId?: string;
  public variableSet?: VariableSet;
}

import {DesignerEntity} from './designer.entity';
import {LayoutSection} from './layout-section';
import {SectionField} from './section-field';

/**
 * Connection between configured fields and sections
 */
export abstract class LayoutSectionToSectionField extends DesignerEntity {
  public x: number;
  public y: number;
  public rows: number;
  public cols: number;
  public minItemRows?: number;
  public minItemCols?: number;
  public layoutSection?: LayoutSection;
  public layoutSectionId?: string;
  public sectionField?: SectionField;
  public sectionFieldId?: string;
}

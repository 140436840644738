import {HttpClient} from '@angular/common/http';
import {Inject, Injectable, Optional} from '@angular/core';
import {AbstractGitlabService, KolibriEntity, VcsProjectSchema} from '@wspsoft/frontend-backend-common';
import {Configuration} from '../../configuration';
import {BASE_PATH} from '../../variables';
import {AbstractService} from '../abstract-service';

@Injectable()
export class VcsService extends AbstractService implements AbstractGitlabService {

  public constructor(httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string,
                     @Optional() configuration: Configuration) {
    super(httpClient, basePath, configuration);

    this.basePath = '/api/rest/private/designer/vcs';
  }

  public getAllProjects(search?: string): Promise<VcsProjectSchema[]>;
  public getAllProjects(search?: string): Promise<any> {
    // eslint-disable-next-line prefer-const
    let {queryParameters, headers} = this.getHeaders();
    queryParameters = queryParameters.append('name', search);

    return this.doGet<VcsProjectSchema[]>(`${this.basePath}/projects`, queryParameters, headers);
  }

  public getBranchesFromProject(projectId: string, search?: string): Promise<any[]> {
    // eslint-disable-next-line prefer-const
    let {queryParameters, headers} = this.getHeaders();
    queryParameters = queryParameters.append('name', search);

    return this.doGet<any>(`${this.basePath}/projects/${projectId}/branches`, queryParameters, headers);
  }

  public getCommitsFromBranch(projectId: string, branch: string): Promise<any[]> {
    const {queryParameters, headers} = this.getHeaders();

    return this.doGet<any>(`${this.basePath}/projects/${projectId}/branches/${branch}/commits`, queryParameters, headers);
  }

  public getWorkspaces(application: string): Promise<KolibriEntity[]> {
    const {queryParameters, headers} = this.getHeaders();

    return this.doGet<KolibriEntity[]>(`/api/rest/private/${application}/workspaces`, queryParameters, headers);
  }
}

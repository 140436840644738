import {VariableHandler} from '../../../service/util/handler/variable-handler';
import {KolibriEntity} from '../kolibri-entity';
import {Variable} from './variable';
import {VariableSetToEntity} from './variable-link';
import {VariableRule} from './variable-rule';
import {VariableScript} from './variable-script';

export abstract class VariableEntity extends KolibriEntity {
  public currentVariables?: VariableHandler<this>;
  public variables?: Variable[];
  public variableSetToEntities?: VariableSetToEntity[];
  public variableRules?: VariableRule[];
  public variableScripts?: VariableScript[];
}

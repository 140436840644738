import {DesignerEntity} from './designer.entity';
import {Entity} from './entity';
import {ListColumn} from './list-column';

/**
 * connection between related lists and columns
 */
export abstract class EntityToListColumn extends DesignerEntity {
  public entity?: Entity;
  public entityId?: string;
  public listColumn?: ListColumn;
  public listColumnId?: string;
}

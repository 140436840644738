import {
  _,
  AggregationFunction,
  CriteriaOrder,
  CriteriaQuery,
  CriteriaQueryJson,
  GaugeMaxType,
  GaugeThreshold,
  GaugeType,
  Gradient,
  PrefQueryConverter,
  Visibility,
  Widget,
  WidgetDataSet,
  WidgetGranularity,
  WidgetType
} from '@wspsoft/frontend-backend-common';
import {DbJsonWrapper} from './db-json-wrapper';

export class WidgetEntity implements DbJsonWrapper<Widget> {
  public criteria: CriteriaQueryJson = {};
  private readonly json: Widget;
  public deleted: boolean;
  public constructor(widget: Widget) {
    this.json = widget;
    if(!widget.entityClass){
      this.deleted = true;
      return;
    }
    widget.aggregationField ??= 'id';
    widget.aggregationFunction ??= AggregationFunction.COUNT;
    if (!widget.query) {
      this.criteria = this.emptyQueryWithDescendant();
    } else if (!_.isEmpty(widget.query)) {
      this.criteria = widget.query;
      this.criteria.descendants = widget.descendants;

      switch (this.criteria.version) {
        case '1.0':
          break;

        default:
          this.criteria = widget.query;

          // just read out the json and add conditions
          this.groupBy = PrefQueryConverter.removeSuffix(this.groupBy);

          if (this.columns) {
            this.columns = (widget.columns as any as string).split(';').map(value => PrefQueryConverter.removeSuffix(value));
          }
          const criteriaQuery = new CriteriaQuery<any>();
          PrefQueryConverter.convertPrefQuery(criteriaQuery, this.criteria);
          this.criteria = criteriaQuery.getJson();
          break;
      }
    } else {
      this.criteria = this.emptyQueryWithDescendant();
    }
  }

  public get id(): string {
    return this.json.id;
  }

  public get name(): string {
    return this.json.name;
  }

  public set name(value: string) {
    this.json.name = value;
  }

  public get type(): WidgetType {
    return this.json.type;
  }

  public set type(value: WidgetType) {
    this.json.type = value;
  }

  public get entity(): string {
    return this.json.entity;
  }

  public set entity(value: string) {
    this.json.entity = value;
  }

  public get initScript(): string {
    return this.json.initScript;
  }

  public set initScript(value: string) {
    this.json.initScript = value;
  }

  public get query(): any {
    return this.json.query;
  }

  public set query(value: any) {
    this.json.query = value;
  }

  public get groupBy(): string {
    return this.json.groupBy;
  }

  public set groupBy(value: string) {
    this.json.groupBy = value;
  }

  public get limit(): boolean {
    return this.json.limit;
  }

  public set limit(value: boolean) {
    this.json.limit = value;
  }

  public get disableDrilldown(): boolean {
    return this.json.disableDrilldown;
  }

  public set disableDrilldown(value: boolean) {
    this.json.disableDrilldown = value;
  }

  public get layout(): string {
    return this.json.layout;
  }

  public set layout(value: string) {
    this.json.layout = value;
  }


  public get descendants(): boolean {
    return this.json.descendants ?? false;
  }

  public set descendants(value: boolean) {
    this.json.descendants = value;
  }

  public get order(): CriteriaOrder {
    return this.json.order;
  }

  public set order(value: CriteriaOrder) {
    this.json.order = value;
  }

  public get count(): number {
    return this.json.count;
  }

  public set count(value: number) {
    this.json.count = value;
  }

  public get ownerId(): string {
    return this.json.ownerId;
  }

  public set ownerId(value: string) {
    this.json.ownerId = value;
  }

  public get url(): string {
    return this.json.url;
  }

  public set url(value: string) {
    this.json.url = value;
  }

  public get icon(): string {
    return this.json.icon;
  }

  public set icon(value: string) {
    this.json.icon = value;
  }

  public get openInNewTab(): boolean {
    return this.json.openInNewTab;
  }

  public set openInNewTab(value: boolean) {
    this.json.openInNewTab = value;
  }

  public get columns(): string[] {
    return this.json.columns;
  }

  public set columns(value: string[]) {
    this.json.columns = value;
  }

  public get granularity(): WidgetGranularity {
    return this.json.granularity;
  }

  public set granularity(value: WidgetGranularity) {
    this.json.granularity = value;
  }

  public get gradient(): Gradient {
    return this.json.gradient;
  }

  public set gradient(value: Gradient) {
    this.json.gradient = value;
  }

  public get gaugeType(): GaugeType {
    return this.json.gaugeType;
  }

  public set gaugeType(value: GaugeType) {
    this.json.gaugeType = value;
  }

  public get gaugeMaxType(): GaugeMaxType {
    return this.json.gaugeMaxType;
  }

  public set gaugeMaxType(value: GaugeMaxType) {
    this.json.gaugeMaxType = value;
  }

  public get fixedGaugeValue(): number {
    return this.json.fixedGaugeValue;
  }

  public set fixedGaugeValue(value: number) {
    this.json.fixedGaugeValue = value;
  }

  public get scriptGaugeValue(): string {
    return this.json.scriptGaugeValue;
  }

  public set scriptGaugeValue(value: string) {
    this.json.scriptGaugeValue = value;
  }

  public get gaugeThreshold(): GaugeThreshold {
    return this.json.gaugeThreshold;
  }

  public set gaugeThreshold(value: GaugeThreshold) {
    this.json.gaugeThreshold = value;
  }

  public get customThreshold(): number {
    return this.json.customThreshold;
  }

  public set customThreshold(value: number) {
    this.json.customThreshold = value;
  }

  public get _rev(): string {
    return this.json._rev;
  }

  public get thresholdInPercent(): boolean {
    return this.json.thresholdInPercent;
  }

  public set thresholdInPercent(value: boolean) {
    this.json.thresholdInPercent = value;
  }

  public get drilldownListId(): string {
    return this.json.drilldownListId;
  }

  public set drilldownListId(value: string) {
    this.json.drilldownListId = value;
  }

  public get aggregationFunction(): AggregationFunction {
    return this.json.aggregationFunction;
  }

  public get aggregationField(): string {
    return this.json.aggregationField;
  }

  public get visibility(): Visibility {
    return this.json.visibility;
  }

  public get advancedConfig(): boolean {
    return this.json.advancedConfig;
  }

  public get datasets(): WidgetDataSet[] {
    return this.json.datasets;
  }

  public set datasets(value: WidgetDataSet[]) {
    this.json.datasets = value;
  }

  public getJson(): Widget {
    // destroy reference, then remove all ids (would break qb otherwise) and then stringify
    this.json.query = _.cloneDeep(this.criteria);
    return this.json;
  }

  /**
   * creates an empty query with all needed fields and with descendants is set to this.descendants.
   * @private
   */
  private emptyQueryWithDescendant(): CriteriaQueryJson {
    const criteriaQuery = new CriteriaQuery<any>();
    criteriaQuery.pDescendants = this.descendants;
    return criteriaQuery.getJson();
  }
}

import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, forwardRef, OnInit, Output} from '@angular/core';
import {NG_VALIDATORS, NG_VALUE_ACCESSOR} from '@angular/forms';
import * as Chroma from 'chroma-js';
import {UiUtil} from '../../util/ui-util';
import {CustomInput} from '../data/custom-input';

@Component({
  selector: 'ui-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ColorPickerComponent),
    multi: true
  }, {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => ColorPickerComponent),
    multi: true,
  }, {
    provide: CustomInput,
    useExisting: forwardRef(() => ColorPickerComponent),
    multi: true
  }],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ColorPickerComponent extends CustomInput<string> implements OnInit {
  @Output()
  public colorPickerSelect: EventEmitter<any> = new EventEmitter();
  public presets: string[] = [];
  private cssVars: string[] = [
    'primary',
    'secondary',
    'text',
    'darkGray',
    'mediumGray',
    'lightGray',
    'background',
    'navigationBg',
    'red',
    'green',
    'blue',
    'orange'];

  public constructor(cdr: ChangeDetectorRef) {
    super(cdr);
  }

  public ngOnInit(): void {
    // set presets from registered cssVars. Ignore duplicates and null values
    for (const cssVar of this.cssVars) {
      try {
        const value = Chroma(UiUtil.getColor(cssVar)).hex();
        if (value && !this.presets.includes(value)) {
          this.presets.push(value);
        }
      } catch (e) {
        // ignore
      }
    }
  }
}
